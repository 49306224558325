.hd {
    .ecoDelivery {
        &__cont {
            padding: 35px 0;
            font-size: 22px;
            color: #888;
            line-height: 36px;
        }

        &__form {
            border-top: 3px solid $gcolor;

            ul {
                li {
                    margin-top: 25px;
                    font-size: 0;

                    .form__title {
                        display: inline-block;
                        width: 100px;
                        vertical-align: middle;
                        font: {
                            size: 22px;
                            weight: 600;
                        }
                        line-height: 32px;

                        &.block {
                            display: block;
                            width: auto;
                            margin-bottom: 25px
                        }
                    }

                    .form__cont {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 22px;
                        line-height: 32px;
                    }
                }
            }
        }

        .dawnDelivery-info {
            margin-top: 30px;
        }

        &__notice {
            margin-top: 30px;

            p {
                padding-left: 10px;
                font-size: 22px;
                color: #888;
                line-height: 36px;
                text-indent: -10px;
                word-break: keep-all;
            }
        }

        .accordion-menu {
            margin-top: 30px;
            border-top: 1px solid #BCBCBC;

            ul {
                border-top: 0;
            }
        }
    }

    .ecopay {
        .greating-pay {
            border-top: 3px solid $gcolor;

            .greating-pay__sec.no-card .btn__add-card {
                min-height: 443px;
            }
        }
    }

    .ecoComplete {
        .ecoComplete {
            &__msg {
                display: block;
                margin-bottom: 25px;
                text-align: center;
                font: {
                    size: 30px;
                    weight: 600;
                }
    
                &:before {
                    content: '';
                    display: block;
                    width:80px;
                    height: 80px;
                    margin: 40px auto 35px;
                    background: url(/front_mo/images/icon_comm_smile.png) no-repeat 50% 50%;
                    text-align: center;
                }
            }

            &__desc {
                text-align: center;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    .myEcobag {
        padding: 70px 0 90px 0;
        border:1px solid #BCBCBC;
        border-top: 3px solid $gcolor;
        background: #F9F9F9;
        text-align: center;
        font-size: 0;

        &:before {
            content:'';
            display: block;
            width: 134px;
            height: 134px;
            margin: 0 auto 25px;
            background: $gcolor url(/front_mo/images/ico_ecoBag.png) no-repeat 50% 50%;
            border-radius: 50%;
        }

        span {
            display: block;
            margin-bottom: 15px;
            font-size: 24px;
        }

        strong {
            display: inline-block;
            position: relative;
            z-index: 1;
            font-size: 36px;

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: -3px;
                z-index: -1;
                width: 100%;
                height: 8px;
                background: $ocolor;
            }
        }

        &__btn {
            text-align: center;
            font-size: 0;
            margin-top: 35px;

            a {
                display: inline-block;
                vertical-align: top;
                width: 210px;
                height: 55px;
                margin: 0 4px;
                border: 1px solid #BCBCBC;
                background: #FFF;
                font-size: 22px;
                line-height: 53px;
                box-sizing: border-box;
            }
        }
    }

    .useAgree {
        border-top: 1px solid #BCBCBC;
        margin-top: 25px;
        padding-top: 25px;
        line-height: 30px;

        .checkbox__label {
            padding-left: 50px;
            font-size: 22px;
            letter-spacing: -0.0025em;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                margin-right:0;
            }
        }
    }

    .myEco {
        margin-top: 40px;
    }
}