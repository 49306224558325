.hd {

	&__popup-v2__title{
		span {color:#888; font-size:22px; font-weight:normal;}
	}
	&__popup-v2__cont{
		.cate-reset {margin-top: 5px;text-align:right;}
	}



    &__search {

		.filter-area {
			padding: 20px 10px;

			&__title__wrap {
				display: flex;
				padding:10px 0 30px;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
			}
			&__title {font-size:26px; font-weight:bold;
				span {color:#888; font-size:20px; font-weight:normal;}
			}
			.cate-reset{position: relative;}
			.btn-green {width:100%;}
		}

		// .filter-area {display:flex; margin:20px 0 0; padding:20px 10px 30px; border:solid 1px $gcolor; border-bottom:0; align-items:center; justify-content:space-between; flex-wrap:wrap;
		// 	&__title {font-size:26px; font-weight:bold;
		// 	span {color:#888; font-size:20px;}
		// 	}
		// }
        
        .search-wrap {
            margin-top: 32px;

            .search-area {
                position: relative;
				display:flex;
                width: 580px;
                height: 70px;

                .search-input {
                    width: 100%;
                    height: 100%;
                    padding: 0 20px;
                    border: 3px solid $gcolor;
                    @extend %font_s;
                    box-sizing: border-box;
                    border-radius: 35px;
					& + button {
                        flex:0 0 120px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 80px;
                        height: 70px;
                        font-size: 0;
                        background: url(/front_pc/images/ico_csCenter_search.png?ver=210317) no-repeat 50% 50%;
                        border: 0;
                        text-indent: -999em;
                    }

                    &:focus{
                        outline: 0;
                    }
                }
            }
        }

        .cate-wrap {
			@extend %clear;

            .cate-reset {
                position: relative;
                @extend %font_xs;
                color: #888888;
                &-btn {
                    &:before {
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        right: 60px;
                        width: 30px;
                        height: 29px;
                        margin-right: 8px;
                        background: url(/front_mo/images/btn_comm_reset.png) no-repeat 0 0;
                        content: '';
                    }
                }
            }

            .cate-area {

                & + .cate-area {
                    margin-top: 40px;
				}
                &__title {margin-bottom:20px; font-size:22px; font-weight:bold; color: #3D3D3D}
				&__listbox {display:flex; margin-top:-7px; align-items:center; flex-wrap:wrap;}
				
                &__list {
					flex:0 0 106px; 
					@extend %font_s;
					margin-top:7px; margin-right:7px; overflow: hidden;

                    &:nth-child(5n) {margin-right: 0;}

                    label{
                        display: block;
                        text-align:center; 
                        box-sizing:border-box; 
                        font-size:21px; 
                        line-height:53px;height:55px; 
                        background:#f4f4f4; 
                        border:1px solid #bfbfbf;border-radius: 30px;
                    }

                    // &:nth-child(4n + 1) {
                    //     label {
                    //         margin-left: 0;
                    //     }
                    // }
    
                    // &:nth-child(n + 5) {
                    //     label {
                    //         margin-top: 15px;
                    //     }
                    // }
    
                    input[type=checkbox] {
                        display: none;
                    }

                    input[type=checkbox]:checked + label {
                        
                        background: $gcolor;
                        border-color: $gcolor;
                        color: #fff;
                    }
                }
            }
        }

        .btn-area {
            margin-top: 50px;

            button {
                font-weight: bold;
            }
        }
    }

    &__searchResult {
        &__contents {
			// margin-top: 30px;
			// width:600px; 
			margin:30px auto 0;
            .contents-top {
                @extend %clear;

                &__total {
                    @extend %font_xs;
                }

                &__filter {
                    float: right;
                    width: 400px;
                    z-index: 0;
                    text-align: right;
                    
                    span.checkbox{
                        display: inline-block;
                        margin-top: -3px;

                        &__label{
                            display: inline-block;
                        }

                        + .sorting{
                            display: inline-block;
                            position: relative;
                            float: right;
                            margin: 0;
                            width: auto;
                            margin-left: 20px;
                            padding-left: 20px;


                            &:before{
                                position: absolute;
                                left: 1px;
                                top: 2px;
                                width: 1px;
                                height: 23px;
                                background: $colorBC;
                                content: '';
                            }

                            .sorting__tit{
                                width: auto;
                            }

                            .select-area{
                                left: -61px;
                                text-align: left;
                            }
                        }
                    }

                    select{
                        display: inline-block;
                        margin: -3px 0 0 20px;
                        padding: 0 30px 0 8px;
                        width: 140px;
                        height: 45px;
                        background: url("/front_mo/images/ico_comm_select.png") no-repeat right 10px top 50%;
                        font-size: 21px !important;
                        background-size: 17px !important;
                    }
                }

                .sorting {
                    float: right;
                    position: relative;
                    background: #fff;
                    
                    &__tit {
                        display: block;
                        position: relative;
                        width: 169px;
                        padding-right: 20px;
                        color: #636363;
                        text-align: right;
                        @extend %font_xs;

                        &:after {
                            display: block;
                            top: 10px;
                            right: 0;
                            position: absolute;
                            width: 13px;
                            height: 8px;
                            background: #fff url(/front_mo/images/btn_sorting_drop-down.png) no-repeat 50% 50%;
                            content: '';
                        }
                    }

                    &__list {
                        position: relative;
                        padding: 0 20px;
                        @extend %font_xs;


                        a {
                            display: block;
                            color: #888888;
                        }
                    }

                    .select-area {
                        display: none;
                        position: absolute;
                        top: 58px;
                        left: -15px;
                        z-index: 2;
                        padding: 25px 30px;
                        border: 1px solid #636363;
                        background: #fff;

                        &__inner {
                            position: relative;
                        }

                        &__list {
                            position: relative;
                            width: 140px;
                            @extend %font_xs;

                            &:not(:first-child) {
                                padding-top: 20px;
                            }

                            &--on {
                                a {
                                    color: $gcolor;
                                }
                                
                                &:after {
                                    display: block;
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    width: 18px;
                                    height: 20px;
                                    background: #fff url(/front_mo/images/icon_sorting_check-on.png) no-repeat 0 0;
                                    content: '';
                                }
                            }
                        }

                        &:after {
                            display: block;
                            position: absolute;
                            top: -11px;
                            right: 28px;
                            width: 20px;
                            height: 20px;
                            border: 1px solid #636363;
                            border-right: transparent;
                            border-bottom: transparent;
                            background: #fff;
                            transform: rotate(45deg);
                            content: '';
                        }

                        &--show {
                            display: block;
                        }
                    }
                }
            }

            // &__box {display:flex; flex-wrap:wrap; justify-content:space-between;}

            // &__list {
			// 	width: 290px;
			// 	flex:0 0 290px;
			// 	margin-bottom: 40px;
			// 	padding-bottom:20px;
			// 	border-bottom:solid 1px #BCBCBC;
			// 	position: relative;
				
            //     .item{
            //         position: relative;
            //         width: 100%;
            //         @extend %vertical;
			// 		a {display:block;}
			// 		&__tag { display:flex; overflow:hidden;
			// 			margin-bottom:5px;
			// 			span {overflow:hidden; height:26px; border:solid 1px #c2c2c2; border-radius:3px; color:$gcolor; line-height:26px; text-align:center; font-size:16px;
			// 				padding:0 5px; min-width: 50px;
			// 				& + span {margin-left:5px;}
			// 			}
			// 			// 태그가 있을때 여백 제거
			// 			& + .item__title {margin-top:0;}
			// 		}
            //         &__thumb {
            //             width: 100%;
            //             height: 290px;
            //         }
            //         &__info {
            //             position: relative;
            //             margin-top: 5px;
            //         }
			// 		&__sub {color:#888; line-height:30px; font-size:20px; @include line(2);}
            //         &__name {display:block; width:100%; min-height:60px; margin-bottom:5px; line-height:30px; font-weight:bold; @extend %font_xs; @include line(2);}

			// 		&__price-ori {
			// 			color:#888;
            //             @extend %font_xs;
			// 			text-decoration: line-through;
			// 			& + .item__price-dc{margin-top:0;}
			// 		}
					

            //         &__price-dc {
			// 			display:block;
			// 			margin-top:33px;
            //             @extend %font_xs;
            //             text-decoration: none;
            //             strong {
            //                 @extend %font_m;
            //             }
			// 		}

            //         .hd__btn-heart {
            //             right: 12px;
            //             top: 178px;
			// 		}
			// 	}
				
			// 	.box__list__cart {position:absolute; right:10px; bottom:0; z-index: 1;
			// 		span {display:block; position:relative; font:0/0 a;
			// 			&:before {content:""; display:block; background:url(/front_pc/images/icon_cart.png) no-repeat center center; background-size:100% 100%; width:55px; height:50px; margin:0 auto;}
			// 		}
			// 	}


            //     // .icon-area {
            //     //     margin-top: 40px;
            //     //     padding: 10px 15px;
            //     //     border: {
            //     //         top: 1px solid #bcbcbc;
            //     //         bottom: 1px solid #bcbcbc;
            //     //     }

            //     //     span {
            //     //         display: inline-block;
            //     //         width: 33px;
            //     //         height: 33px;

            //     //         &:not(:first-child){
            //     //             margin-left: 16px;
            //     //         }

            //     //         img {
            //     //             display: block;
            //     //             max-width: 100%;
            //     //         }
            //     //     }
            //     // }
            // }

            .searchResult-slide {
                margin-top: 50px;

                &__tit{
                    margin: 20px 0;
                    padding-bottom: 20px;
                    @extend %font_l;
                    font-weight: bold;
                    border-bottom: 3px solid $gcolor;
                }

                &__box {
                    @extend %clear;
                }

                &__list {
                    float: left;
                    width: 310px;

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }

                .goods-list {
                    @extend %vertical;

                    &__thumb {
                        width: 290px;
                        height: 290px;
                    }

                    &__name {
                        // font-size: 20px;
                        @extend %font_xs;
                    }

                    &__info {
                        display: block;
                        width: 290px;
                        margin-top: 20px;
                    }

                    &__price {
                        display: block;
                        margin-top: 15px;
                        // font-size: 20px;
                        @extend %font_xs;

                        strong {
                            font-size: 26px;
                            @extend %font_m;
                        }

                        &-origin {
                            display: inline-block;
                            margin-left: 15px;
                            @extend %font_s;
                        }

                        &-dc {
                            display: inline-block;
                            @extend %font_s;
                            text-decoration: none;

                            strong {
                                // font-size: 30px;
                                @extend %font_l;
                            }
                        }

                    }

                }

                .swiper-pagination {
                    display: table;
                    margin: 40px auto;
                }

            }
        }

        .tab__area{
            margin-bottom: 30px;

            li{
                width: 50%;
            }
        }
    }

    &__searchNoResult {
        &__desc {
            @extend %font_xs;
            text-align: center;
            line-height: 36px;

            strong {
                display: block;
                margin-bottom: 25px;
                @extend %font_m;
                word-break: normal;
                word-wrap: break-word;
            }

            &:before {
                display: block;
                width: 68px;
                height: 68px;
                margin: 50px auto 30px;
                background: url(/front_mo/images/icon_list-no.png) no-repeat 0 0;
                content: '';
            }
        }
    }
}