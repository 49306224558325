.hd {
    &__service-board {
        margin-top: 40px;

        &__head {
            padding: 0 0 30px;
            border-bottom: 1px solid $colorBC;
        }

        &__title {
            display: block;
            margin-bottom: 15px;
            line-height: 1.5;
            @extend %font_s;

            .notice {
                @extend %font_s;
                color: $gcolor;
            }
        }

        &__date {
            @extend %font_xs;
            color: $color88;
        }

        &__detail {
            padding: 35px 0;
            line-height: 1.5;
            font-size: 20px;
            border-bottom: 1px solid $colorBC;

            .btn__download {
                display: inline-block;
                margin-top: 70px;
                padding-left: 40px;
                background: url(/front_mo/images/ico_comm_download.png) no-repeat 0 5px;
                @extend %font_xs;
            }
        }

        &__txt {
            line-height: 32px;
            @extend %font_xs;
        }

        .btn-full {
            display: block;
            width: 290px;
            height: 70px;
            margin: 40px auto 0;
            text-align: center;
            line-height: 70px;
        }

        .list_text {
            word-break: normal;
            word-wrap: break-word;

            > * {
                margin: 0;
                padding: 0;
                border: 0;
                font: inherit;
            }

            > img {
                vertical-align: top;
            }
        }
    }

    .userGuide {
        margin-top: 30px;

        .cont-info__banner {
			text-align: center;
			margin-top: 20px;
            line-height: 32px;
			@extend %font_xs;
			
			&:first-child {
				margin-top: 0;
			}
        }
    }
}
