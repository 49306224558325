.hd {

    &__myOrderDetail {
        padding-top: 190px;

        .planmeals {
            .meals-delivery {
                margin-top: 20px;
                padding: 25px 0;
                border-top: 1px solid #BCBCBC;
                font-size: 22px;
                font-weight: 600;

                &__tit {
                    display: inline-block;
                    text-align: left;
                }

                &__price {
                    display: inline-block;
                    float: right;
                    text-align: right;
                }
            }
        }

        .item-box{
            & + .item-box{
                border-top: 1px solid #BCBCBC;
            }

            &__list{
                margin-top: -1px;

                .inner{
                    &:last-child{
                        border-bottom: 0;
                    }
                }

                .list-more .item-box__list{
                    border: 0;
                    border-bottom: 1px solid #BCBCBC !important;

                    &:nth-of-type(1){
                        border-top: 1px solid #BCBCBC !important;
                    }
                }
            }
            
            .price-area{
                margin-top: 20px;
                padding-bottom: 0;
            }
            
            .hd__mypage__btns{
                margin-bottom: 0;

                button:last-child{
                    margin-bottom: 30px;
                }
            }
        }
    }

    // 배송요청사항변경
    &__changeRequest {
        &__sect {
            margin-bottom: 40px;

            .head-area__title {
                @include line(1);
            }
        }

        .contents {
            &__tit {
                padding: 20px 0;
                @extend %font_xs;
                font-weight: bold;
            }

            textarea {
                width: 100%;
                height: 180px;
                padding: 25px 30px;
                border: 1px solid #BCBCBC;
                @extend %font_xs;
                box-sizing: border-box;
                resize: none;
            }
        }

        .btn-area {
            margin-top: 0;
        }
    }

    // 배송지변경
    &__changeAddress {
        .change-address {
            border-top: 3px solid $gcolor;

            &__list {
                position: relative;
                padding: 30px 0;
                font-size: 0;

                .infobox {
                    &__list {
                        display: block;
                        @extend %font_xs;

                        &:not(:first-child) {
                            margin-top: 10px;
                        }

                        &-gcolor {
                            color: $gcolor;
                            font-weight: bold;
                        }
                    }
                }

                .btn-choice {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 110px;
                    height: 55px;
                    background: $gcolor;
                    @extend %font_xs;
                    color: #fff;
                }
            }
        }

        .btn-area {
            margin-top: 40px;

            button {
                font-weight: bold;
            }
        }
    }

    // 배송지추가
    &__addAddress {
        .dawnDelivery-info {
            padding: 0;
            border: none;

            .new--checks_box {
                padding: 0;
                border-bottom: none;
                font-weight: bold;
            }

            .infobox {
                display: none;
                margin-top: 25px;

                &--show {
                    display: block;
                }
            }
        }
    }

    // 주문취소 환불내역
    &__orderCancelList {
        &__title {
            padding: 25px 0;
            border-bottom: 1px solid #BCBCBC;
            @extend %font_s;
            font-weight: bold;
        }

        .cont {
            padding: 25px 0;

            &__list {
                display: block;
                @extend %font_xs;

                &:not(:first-child) {
                    margin-top: 20px;
                }
            }
        }

        .infobox {
            padding: 25px 0;
            border-top: 1px solid #BCBCBC;

            &__list {
                display: table;
                table-layout: fixed;

                &:not(:first-child) {
                    margin-top: 15px;
                }
            }

            &__tit {
                display: table-cell;
                width: 290px;
                @extend %font_xs;
                font-weight: bold;
            }

            &__cont {
                display: table-cell;
                width: 290px;
                @extend %font_xs;
                font-weight: bold;
                text-align: right;
            }
        }
    }

    // 결제영수증
    &__receipt {
        .orderReceipt__desc {
            display: none;
            margin-top: 20px;
            font-size: 22px;
        }

        &__desc {
            position: relative;
            margin-bottom: 30px;
            padding-left: 15px;
            color: #888;
            @extend %font_xs;
            line-height: 32px;
            word-break: keep-all;

            &:before {
                display: inline-block;
                position: absolute;
                left: 0;
                content: '-';
            }
        }        

        .btn-area {
            margin: 10px 0 20px;
            text-align: center;

            button {
                width: 100%;
                font-weight: bold;
            }
        }

        .product-wrap{
             .btn-area{
                margin: 10px 20px 20px;
            }

            .pay-info {
                padding: 0 20px;
            }
        }

        .pay-info {
            li {
                margin-top: 25px;
                @extend %clear;
            }

            &__lft {
                float: left;
                font-size: 22px;
                line-height: 32px;
            }

            &__rgt {
                float: right;
                font-size: 22px;
                line-height: 32px;
            }

            &__total {
                padding: 25px 0;
                border: {
                    top: 1px solid #BCBCBC;
                } 
                font-weight: 600;
            }
        }
    }
}