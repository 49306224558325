%close-area {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 640px;
    height: 70px;
    background: $gcolor;
    transform: translate(-50%, 0);

    a {
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        font-size: 0;
        color: #fff;
        @extend %font_xs;
        transform: translate(0, -50%);

        img {
            margin-left: 5px;
            vertical-align: -4px;
        }

        &.back{
            right: initial;
            left: 20px;
        }
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 11px;
        background: #FFF;
    }

    &__btn-icon {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
        margin-left: 10px;
        vertical-align: -8px;

        &:after,
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 26px;
            background-color: #fff;
        }

        &:before {
            transform: rotate(45deg);
            margin: -13px 0 0 -1px;
        }

        &:after {
            transform: rotate(-45deg);
            margin: -13px 0 0 -1px;
        }

    }

    &--hidden {
        position: absolute;
    }
}

.hd {
    &__planMeals-detail {
        overflow: hidden;
        padding-top: 74px;

        .close-area {
            @extend %close-area;
        }

        .overview {
            width: 580px;
            margin: 30px auto 0;

            &__title-area {
                position: relative;
                @extend %font_xl;
				line-height: 1.3;

                span {
                    display: block;
                    padding: 0 67px 0 18px;
                }

                .strong{
                    display: block;
                    font-weight: 600;
                }
            }

            &__listbox {
                margin-top: 23px;
                border: {
                    top: 1px solid $color3D;
                    bottom: 1px solid $colorBC;
                }

            }

            &__list {
                // font-size: 20px;
                @extend %font_xs;

                &:not(:first-child) {
                    border-top: 1px solid $colorBC;
                }
            }

            &__icon-area {
                // display: table;
                width: 100%;
                height: 69px;

                &__inner {
                    // display: table-cell;
                    // vertical-align: middle;
                    width: 100%;
                    margin: 10px 0;
                }

                span {
                    // display: table-cell;
                    display: inline-block;
                    width: 15.6%;
                    font-size: 16px;
                    // padding: 10px 0 10px 23px;
                    vertical-align: middle;
                    text-align: center;

                    img {
                        display: block;
                        width: 36px;
                        height: 36px;
                        margin: 0 auto 5px;
                    }
                }
            }

            &__gram {
                display: inline-block;
                padding: 20px 40px 20px 20px;
                border-right: 1px solid $colorBC;

                strong {
                    margin-left: 10px;
                }

            }

            &__kcal {
                display: inline-block;
                padding: 20px 0 20px 20px;

                strong {
                    margin-left: 18px;
                }

            }

            &__ingredient {
                padding: 20px;
                font-size: 36px;
            }

            &__thumb {
                position: relative;
                height: 580px;
                margin-top: 26px;
                @extend %vertical;

                figure {
                    height: 100%;
                }

                .swiper-pagination {
                    position: absolute;
                    bottom: 25px;
                    left: 15px;
                    z-index: 1;
                }

                .free-gift {
                    position: absolute;
                    display: block;
                    float: right;
                    z-index: 2;
                    width: 168px;
                    height: 44px;
                    color: #fff;
                    // font-size: 20px;
                    @extend %font_xs;
                    background-color: $gcolor;
                    line-height: 44px;
                    text-indent: 60px;

                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 44px;
                        height: 44px;
                        background: $ocolor url(/front_mo/images/icon_market_check.png) no-repeat center center;
                        background-size: 40%;
                        content: '';
                    }
                }
            }

        }

        .detail-wrap {
            position: relative;
            margin-top: 42px;
            padding-top: 85px;
        }

        &__btn-share {
            display: block;
            float: right;
            width: 33px;
            height: 35px;
            margin: {
                top: 2px;
                right: 17px;
            }
            background: url("/front_mo/images/icon_share.png") no-repeat 0 0;
            background-size: 100% 100%;
            text-indent: -9999px;
        }

        .tab-contents {
            display: none;
            width: 580px;
            margin: 0 auto 50px;

            &.cont-info {
                margin-top: 55px;
            }

            &.cont-question {
                margin-top: 35px;
            }

            &.cont-notice {
                margin-top: 35px;
            }

            &__section {
                margin-top: 100px;

                &__tit {
                    @extend %font_m;
                    font-weight: bold;

                    &.middleLine {
                        position: relative;
                        margin-bottom: 50px;
                        //text-align: center;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            margin-top:-15px;
                            background: #3D3D3D;
                        }

                        > span {
                            display: inline-block;
                            position: relative;
                            padding: 0 25px 0 0;
                            background: #FFF;
                            line-height: 32px;
                            font-size:29px;

                            span {
                                display: block;
                               	margin-top: 8px;
							    font-size: 24px;
							    font-weight: 300;
							    color: #707070;
                            }
                        }
                    }
                }
            }

            &--current {
                display: block;
            }
        }

        .cont-info {
            @extend %vertical;
            
            &__banner {
                width: 580px;
                // height: 180px;
            }

            .message {

                &__to {
                    @extend %font_m;
                    margin-bottom: 35px;
                }

                &__name {
                    font-weight: bold;
                }

                &__advice {
                    line-height: 38px;
                    font-size: 25px;
                    letter-spacing: 0.02em;
                    word-break: break-all;

                    &__from {
                        display: block;
                        margin: 50px 0;
                        @extend %font_m;
                        text-align: right;

                        &:after {
                            display: block;
                            width: 100%;
                            height: 68px;
                            margin-top: 15px;
                            background: url(/front_mo/images/img_greating_sign.png) no-repeat right 0;
                            background-size: 150px auto;
                            content: '';
                        }
                    }
                }
            }

            .nutri,
            .package {
                &__thumb {
                    width: 580px;
                }
            }

            .notification {
                margin-top: 83px;
                border: {
                    top: 3px solid $gcolor;
                    bottom: 3px solid $gcolor;
                };

                &__tit {
                    margin-top: 0;
                    padding: 20px 24px;

                    &:after {
                        display: block;
                        float: right;
                        width: 43px;
                        height: 43px;
                        margin-top: -7px;
                        background: url("/front_mo/images/btn_main_right-arrow.png") no-repeat 0 0;
                        background-size: 100% 100%;
                        content: '';
                    }
                }

                a {
                    display: block;
                    width: 100%;
                }
            }

        }

        .rel-goods {
            margin-top: 0;

            &__box {
                @extend %clear;
            }

            &__list {
                float: left;
                width: 276px;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .goods-list {
                @extend %vertical;

                &__thumb {
                    width: 276px;
                    height: 250px;
                }

                &__name {
                    @extend %font_xs;
                }

                &__info {
                    display: block;
                    width: 276px;
                    margin-top: 20px;
                }

                &__price {
                    display: block;
                    margin-top: 15px;
                    @extend %font_xs;

                    strong {
                        @extend %font_m;
                    }

                }

            }

            .swiper-pagination {
                display: table;
                margin: 40px auto;
            }

        }

        /** 상품 후기 **/
        .cont-review {
            .review__title {
                max-width: 490px;
            }

            .board__inner__detail {
                padding-top: 20px;
                border-top: 0;
            }
        }

        .cont-question {
            .question__inner {
                margin-top: 15px;
            }

            .review__title {
                max-width: 390px;
            }
        }

        .cont-notice {
            margin-bottom: 100px;

            .notice-list {
                margin: 0;

                &__tit {
                    position: relative;
                    padding: 28px 20px;
                    border-bottom: 1px solid #bcbcbc;
                    font-size: 28px;
                    font-weight: bold;

                    &:before {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        width: 27px;
                        height: 2px;
                        background-color: #3d3d3d;
                        content: '';
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 32px;
                        transform: translateY(-50%);
                        width: 2px;
                        height: 27px;
                        background-color: #3d3d3d;
                        content: '';
                    }
                }

                &__cont {
                    display: none;
                    margin-left: 0;
                    padding: 0 20px 60px;
                }

                &__box {
                    @extend %font_xs;
                    line-height: 32px;

                    &__tit {
                        display: block;
                        margin: 40px 0;
                        @extend %font_s;
                        font-weight: bold;
                    }
                }

                &--show {

                    .notice-list__tit {
                        &:before {
                            display: block;
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            transform: translateY(-50%);
                            width: 27px;
                            height: 2px;
                            background-color: #3d3d3d;
                            content: '';
                        }

                        &:after {
                            display: none;
                        }
                    }

                    .notice-list__cont {
                        display: block;
                    }
                }
            }
        }
    }

    &__planMeals-add{
        .close-area {
            @extend %close-area;

            .checkbox{
                position: absolute;
                right: 50px;
                top: 20px;
                transform: scale(0.8);

                &__label{
                    color: rgba(255,255,255,0.8);

                    &:before{
                        background-color: transparent;
                    }
                }

                &__input:checked + .checkbox__label:before{background-color: $color3D}
            }
        }

        .add-cont {
            padding: 70px 0 85px;

            &__top{
                margin: 30px 20px 0;
                line-height: 1.3;
                text-align: center;

                .info{
                    overflow: hidden;

                    dt{
                        float: left;
                        margin-right: 10px;
                        width: 220px;
                        @extend %font_l;
                        font-weight: 600;
                    }

                    dd{
                        float: left;
                        padding-top: 5px;
                        width: 370px;
                        line-height: 1.5;
                        @extend %font_s;
                        color: $color88;
                        text-align: left;
                    }
                }

                .box{
                    display: block;
                    padding: 10px 20px;
                    margin: 30px auto 0;
                    @extend %font_xs;
                    // color: #fff;
                    border-radius: 100px;
                    text-align: center;
                    background-color: $ocolor;
                }
            }

            .options__inner{
                z-index: 11;

                .hd__btn-sec__cock .btn__close__cart{
                    position: relative;
                    left: 0;

                    margin-left: 0;
                }
            }

            .final-count{
                padding: 0 20px 50px;
            }

            .product--money--detail + .product--money--detail{
                border-top: 0;
            }
        }
    }

    .info-notice {
        &__list {
            display: table;
            table-layout: fixed;
            width: 100%;
            border-bottom: 1px solid #bcbcbc;
            padding: 30px 15px;
            @extend %font_xs;
            line-height: 32px;
            box-sizing: border-box;
        }

        &__tit {
            display: table-cell;
            padding-right: 20px;
            width: 215px;
            font-weight: bold;
            word-break: break-all;
            word-wrap: break-word;
        }

        &__cont {
            display: table-cell;
        }
    }

    .sns-share {

        &__icon {
            padding-bottom: 40px;
            border-bottom: 1px solid #bcbcbc;
            @extend %font_xs;
            text-align: center;
            @extend %clear;

            &__list {
                display: block;
                float: left;
                height: 130px;
                color: #3d3d3d;
                font-size: 20px;

                &:not(:first-child) {
                    margin-left: 15px;
                }

                &-kakaoStory {
                    letter-spacing: -2px;
                }

                &-naver {
                    margin-left: 10px !important;
                }

                $list: facebook, kakaoTalk, kakaoStory, naver, naverBand;
                @for $i from 1 through length($list) {
                    &-#{nth($list, $i)}:before {
                        display: block;
                        width: 94px;
                        height: 94px;
                        margin-bottom: 10px;
                        background: url("/front_mo/images/icon_popup_sns.png") no-repeat -#{($i - 1) * 106}px 0;
                        content: '';
                    }
                }

            }
        }

        &__url {
            padding: 30px 0 15px;

            &__txt {
                @extend %font_xs;
                color: #3d3d3d;
                font-size: 20px;
                text-align: center;
            }

            &__copy {
				display: block;
				width:100%;
                padding: 20px 30px;
                margin-top: 20px;
                background: #f5f5f5;
                border: 1px solid #bcbcbc;
                color: #3d3d3d;
                font-size: 20px;
                line-height: 30px;
				text-align: left;
				box-sizing: border-box;
                word-break: break-all;
            }
        }
    }

    .gift {
        .overview__thumb {
            padding-top: 26px;
            border-top: 3px solid $gcolor;
        }
    }
}
