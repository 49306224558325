.hd {

    &__setting {

        &__sect {
            position: relative;
            margin-top: -1px;
            padding: 40px 0;
            border-top: 1px solid #c0c0c0;

            .switch {
                position: absolute;
                top: 30px;
                right: 0;

                @mixin default-transition ($values: all, $speed: 300ms, $transition: cubic-bezier(0.2, 0.79, 0.44, 1)) {
                    $string: 0;
    
                    @each $value in $values {
                        $i: index($values, $value);
    
                        @if ($i == 1) {
                            $string: $value + ' ' + $speed + ' ' + $transition;
                        }
                        @else {
                            $string: $string + ',' + $value + ' ' + $speed + ' ' + $transition;
                        }
                    }
                    $transition: $string;
                }
    
                label {
                    position: relative;
                }
    
                input[type=checkbox] {
                    position: absolute;
                    opacity: 0;
    
                    &:checked {
    
                        & + span {
                            background: $gcolor;
    
                            &:before {
                                left: calc(100% - 48px);
                            }
                        }
                    }
                }
    
                &__container {
                    display: block;
                    position: relative;
                    width: 85px;
                    height: 47px;
                    background: #888;
                    border-radius: 40px;
    
                    &:before {
                        display: block;
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        z-index: 2;
                        width: 45px;
                        height: 45px;
                        margin: 0 1px;
                        background: #fff;
                        border-radius: 100%;
                        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
                        content: '';
                        transition: all 0.3s ease;
                    }
                }
            }
        }

        &__tit {
            @extend %font_m;
            font-weight: bold;
        }

        &__subtit {
            margin-top: 25px;
            @extend %font_xs;
            line-height: 32px;
        }

        &__desc {
            margin-top: 25px;
            color: #888;
            @extend %font_xs;
            line-height: 36px;

            li {
                position: relative;
                padding-left: 15px;
                
                &:before {
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '-';
                }
            }
            
        }

        &__bottom{
            position: absolute;
            width: 580px;
            left: 30px;
            bottom: 30px;
        }

        .version-info {
            position: relative;

            .btn-update {
                position: absolute;    
                bottom: 30px;
                left: 150px;
                width: 138px;
                height: 45px;
                background: #f4f4f4;
                border: 1px solid #bcbcbc;
                box-sizing: border-box;
                @extend %font_xs;
                text-align: center;
            }
        }

    }

}

#setting{
    padding-bottom: 0 !important;
}