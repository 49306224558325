.hd {
    font-family: $noto;

    &__schd {
        padding-top: 190px;
    }

    &.no--doc {
        padding-bottom: 85px;
    }

    &__main__h3.no-border {
        margin: 0 0 20px;
        text-align: center;
        font-weight: 400;

        strong {
            font-weight: 600;
        }

        &:after {
            display: none;
        }
    }

    &__plan__order-list {
        position: relative;
        margin-bottom: 45px;
        text-align: center;
        counter-reset: meals_list;

        &:before {
            content: '';
            position: absolute;
            top: 15px;
            left: 50%;
            height: 1px;
            width: 320px;
            background: $gcolor;
            transform: translate(-50%, 0);
        }

        li {
            display: inline-block;
            position: relative;
            width: 160px;
            margin: 0 5px;
            vertical-align: top;
            @extend %font_xs;
            color: #888;

            &:before {
                counter-increment: meals_list;
                content: counter(meals_list);
                display: block;
                box-sizing: border-box;
                width: 35px;
                height: 35px;
                margin: 0 auto 10px;
                text-align: center;
                line-height: 31px;
                border: 2px solid $gcolor;
                background: #FFF;
                font-size: 14px;
                font-weight: 600;
                color: $gcolor;
                border-radius: 50%;
            }

            &.on {
                color: #3D3D3D;
            }

            &.on:before {
                background: $gcolor;
                color: #FFF;
            }
        }
    }

    .meals {
        position: relative;
        margin-bottom: 30px;
        padding: 25px 0 40px;
        border-top: 3px solid $color3D;
        background: #FFF;
        box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.1);

        &__sec {
            position: relative;
            margin-top: 50px;

            &:first-child {
                margin-top: 0;
            }

            &.title-area {
                position: relative;

                .btn-closed-day {
                    position: absolute;
                    top: -7px;
                    left: 20px;
                    font-size: 22px;
                    color: #888;
                    text-decoration: underline;
                }

                .btn-shipping-area {
                    position: absolute;
                    top: -7px;
                    right: 20px;
                    font-size: 22px;
                    color: #888;
                    text-decoration: underline;
                }
            }

            &-text{
                margin-top: 25px;
                font-size: 21px;
                line-height: 30px;
                text-align: center;

                .green{
                    color: $gcolor;
                }
                .orange{
                    color: $colorDG;
                }

                span{
                    font-size: 20px;
                    color: #888;

                    
                }
            }
        }

        &__title {
            margin-bottom: 30px;
            text-align: center;
            font-size: 30px;
            font-weight: 400;
            color: #3D3D3D;
            font-family: $noto;

            &__desc {
                font-size: 20px;
            }

            strong {
                font-weight: 600;
            }
        }

        &__choice {
            display: table;
            width: auto;
            margin: 0 auto;
            font-size: 0;

            &__list {
                display: table-cell;
                vertical-align: top;
                table-layout: fixed;
                padding: 0 7px;

                &:nth-child(-n+2) {
                    margin-top: 0;
                }

                &:nth-child(odd) {
                    margin-left: 0; 
                }

                input[type="checkbox"],
                input[type="radio"] {
                    @extend %hidden;
                }

                input[type="checkbox"]:checked + label,
                input[type="radio"]:checked + label {
                    border: 1px solid $gcolor;
                    background: $gcolor;
                    color: #FFF;
                }
            }

            &.w142 {
                .meals__choice__list {
                    margin: {
                        top: 0;
                        left: 15px;
                    };
                    
                    &:first-child {
                        margin-left: 0;
                    }
                }

                .btn__default.input {
                    width: 142px;
                }
            }
            
            &.w220 {
                .meals__choice__list {
                    margin: {
                        top: 15px;
                        left: 15px;
                    };                    
                    
                    &:nth-child(odd) {
                        margin-left: 0;
                    }

                    &:nth-child(-n+2) {
                        margin-top: 0;
                    }
                }

                .btn__default.input {
                    width: 220px;
                }
            }

            &.weektype {
                .btn__default.input {
                    display: table;
                    line-height: 27px;

                    .text {
                        display: table-cell;
                        vertical-align: middle;

                        &__desc {
                            display: block;
                            font-size: 20px;
                            font-weight: normal;
                        }
                    }
                }
            }

            &__select{
                width: 520px;
                height: 70px;
                padding: 0 20px;
                font-size: 24px;
                background: #fff url('/front_mo/images/ico_comm_select.png') no-repeat right 20px top 50%;
                background-size: 25px auto;
                box-sizing: border-box;
                -webkit-appearance: none;
                resize: none;
            }

            &__input{
                padding: 0 20px;
                width: 520px;
                height: 66px;
                font-size: 24px;
                border: 1px solid #9f9f9f;
                box-sizing: border-box;
            }
        }

        &__text{
            margin-top: 25px;
            font-size: 21px;
            color: #888;
            line-height: 30px;
            text-align: center;

            dl{
                font-size: 22px;
                color: #3D3D3D;

                dt{
                    font-size: 26px;
                    margin-bottom: 10px;
                    em{color: $gcolor;}
                }
            }


            &.bg{
                margin: 30px auto 0;
                padding: 20px 0;
                max-width: 520px;
                background: #f5f5f5;
            }
        }

        &#exp {
            display: none;
            padding: 45px 0;
            
            .experience {
                &__sec {
                    margin-left: 190px;
                }

                &__img {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate3d(0, -50%, 0);

                    p {
                        text-align: center;
                        font: {
                            size: 24px;
                            weight: 600;
                        }
                        color: $gcolor;
                        line-height: 28px;
                    }
                }
                
                &__title {
                    display: block;
                    margin-bottom: 20px;
                    font-size: 30px;
                    
                    strong {
                        font-weight: 600;
                    }
                }
    
                &__txt {
                    max-width: 415px;
                    margin: 0 auto;
                    font-size: 21px;
                    color: $color88;
                    line-height: 30px;

                    .underline {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .final-count {
        margin-top: 40px;
        text-align: center;

        &__top {
            display: block;
            margin-bottom: 15px;
            @extend %font_s;
        }

        &__btm {
            display: inline-block;
            vertical-align: middle;
            font-size: 36px;

            em {
                display: inline-block;
                position: relative;
                font-weight: 600;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: -1;
                    bottom: -2px;
                    left: 0;
                    width: 100%;
                    height: 8px;
                    background: $ocolor;
                }
            }

            &-txt{
                display: inline-block;
                margin-right: 5px;
                @extend %font_s;
            }
        }

        &__desc {
            margin-top: 50px;
            font-size: 22px;
        }

        &__delivery {
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            font-size: 20px;

            &__tit {
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                font-size: 26px;
            }

            &__price {
                display: inline-block;
                vertical-align: middle;

                span, del, strong {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            &__result {
                margin-left: 20px;
                font-size: 36px;
            }

            .Qmark {
                display: inline-block;
                width: 40px;
                height: 40px;
                vertical-align: middle;
                background: url(/front_mo/images/icon_order-payment_Qmark.png) no-repeat 50% 50%;
            }

        }

        &__calc {
            margin-bottom: 30px;
            font-size: 28px;

            &__price {
                margin-right: 10px;
            }

            .count {
                display: inline-block;
                position: relative;
                width: 190px;
                height: 55px;
                
                margin-left: 10px;
                padding: 0 53px;
                border: 1px solid #BCBCBC;
                text-align: center;
                line-height: 55px;
                color: #3D3D3D;
                box-sizing: border-box;
    
                input {
                    width: 100%;
                    height: 53px;
                    padding: 0;
                    border: 0;
                    text-align: center;
                    vertical-align: top;
                    line-height: 53px;
                    color: #3D3D3D;
                }
                
                .btn__count {
                    position: absolute;
                    top: 0;
                    width: 55px;
                    height: 55px;
                    border: 1px solid #BCBCBC;
                    background: #F5F5F5;
    
                    &-down {
                        top: -1px;
                        left: -1px;
    
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: 21px;
                                    height: 3px;
                                    background: #3D3D3D;
                                    transform: translate3d(-50%, -50%, 0);
                                }
                            }
    
                            &-up {
                                top: -1px;
                                right: -1px;
    
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: 21px;
                                    height: 3px;
                                    background: #3D3D3D;
                                    transform: translate3d(-50%, -50%, 0);
                                }
    
                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: 3px;
                                    height: 21px;
                                    background: #3D3D3D;
                                    transform: translate3d(-50%, -50%, 0);
                                }
                            }   
                        }
    
                        button:disabled {
                            background: #BCBCBC;
                        }
                    }
        }

        .product--money{
            &--title{
                position: relative;
                padding-bottom: 20px;
                font-size: 24px;
                text-align: left;
                letter-spacing: -1px;
            }

            &--select{
                position: absolute;
                right: 0;
                top: -6px;
                font-size: 22px;

                select{
                    position: relative;
                    width: 90px;
                    height: 42px;
                    padding: 0 15px;
                    font-size: 20px !important;
                    border-radius: 23px;
                    appearance: none;
                    background: #fff;
                    border-color: #BCBCBC;
                }

                &:after{
                    position: absolute;
                    content: "";
                    top: 20px;
                    right: 20px;
                    width: 0;
                    height: 0;
                    border: 6px solid transparent;
                    border-color: #000 transparent transparent transparent;
                }

                .select-input{
                    position: absolute;
                    right: 13px;
                    top: 8px;
                    width: 62px;
                    border: 0;
                    background: #fff;
                    z-index: 2;
                }
            }

            &--detail{
                padding: 25px 0;
                border-top: 3px solid $color3D;
                border-bottom: 1px solid $color3D;

                li{
                    font-size: 22px;
                    margin-top: 15px;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:after {
                        content: '';
                        display: block;
                        clear: both;
                    }

                    .txt {float: left;}
                    .price {
                        float: right;
                        font-weight: 400;
                    }
                }

                .detail-box{
                    padding: 5px 0 5px 15px;
                    overflow: hidden;
                    width: 100%;
                    box-sizing: border-box;

                    li{
                        margin-top: 10px;
                        font-size: 21px;
                        color: $gcolor;

                        .price{font-weight: 600;font-size: 22px;}
                    }
                }
            }

            &--total {
                margin-top: 25px;
                font-weight: 600;

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }

                .txt {
                    float: left;
                    padding-top: 10px;
                    font-size: 26px;
                }

                .price {
                    position: relative;
                    float: right;
                    font-size: 36px;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        z-index: -1;
                        bottom: -2px;
                        left: 0;
                        width: 100%;
                        height: 8px;
                        background: $ocolor;
                    }
                }
            }
        }
    }
}
