.hd {
    &__monthStory-detail {
    }

    .monthStory-detail{
        &__video {
            &__sec {
                position: relative;
            }

            &__player {
                width: 100%;
                height: 380px;
                background: #DDD;
            }

            &__btn {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 75px;
                height: 75px;
                transform: translate3d(-50%, -50%, 0);

                &.play {
                    background: url(/front_mo/images/ico_video_play.png) no-repeat 0 0;
                }

                &.stop {
                    background: url(/front_mo/images/ico_video_stop.png) no-repeat 0 0;
                }
            }
        }

        &__define {
            margin-top: 50px;
            overflow-x: hidden;

            dt {
                margin: 0;
                padding: 0;
                text-align: center;
                @extend %font_l;
                font-weight: 600;

                .title{
                    &-sub {
                        display: inline-block;
                        padding-bottom:10px;
                        border-bottom: 1px solid $color3D;
                        @extend %font_xs;
                        font-weight: 400;
                    }

                    &-main {
                        display: block;
                        margin-bottom: 15px;
                    }
                }
            }

            dd {
                // min-height:500px;
                margin: 30px auto 0;
                padding: 0;
                line-height: normal;
                @extend %font_xs;
            }
        }

        &__reply {
            margin-top: 100px;

            &__sec {
                margin-top: 50px;
                border-top: 3px solid $color3D;
            }

            &__total {
                height:70px;
                line-height: 70px;
                @extend %font_s;

                span {
                    font-weight: 600;
                }
            }

            &__write {
                position: relative;
                padding-right: 110px;

                .reply_input {
                    width: 100%;
                    height: 70px;
                    padding: 0 25px;
                    background: #FFF;
                    border: 1px solid $gcolor;
                    color: $color3D;
                    @extend %font_s;
                    border-radius: 0;
                    box-sizing: border-box;
                    line-height: 64px;
                    &:focus {
                        outline: 3px solid $gcolor;
                    }
                }

                .btn-full {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 110px;

                }
            }

            &__list {
                .reply {
                    &__sec {
                        position: relative;
                        padding: 30px 15px;
                        border-bottom: 1px solid $colorBC;

                        &.anws {
                            .reply__txt {
                                padding-left: 35px;
    
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 18px;
                                    height: 18px;
                                    border: {
                                        bottom: 3px solid $color3D;
                                        left: 3px solid $color3D;
                                    }
                                    box-sizing:border-box;
                                }
                            }

                            .reply__info {
                                padding-left: 35px;
                            }
                        }
                    }

                    &__txt {
                        position: relative;
                        display: block;
                        line-height: 36px;
                        @extend %font_xs;

                        .btn_goto {
                            font-weight: 600;;
                        }

                        .best {
                            color: $gcolor;
                        }
                    }

                    &__info {
                        display: block;
                        position: relative;
                        margin-top: 15px;
                        font-size: 20px;
                        color: $color88;
                        
                        &__div {
                            color: $colorBC;
                        }

                        &__btn{
                            position: absolute;
                            right: 70px;
                            top: 0;
                            color: $color88;

                            &.type2{
                                right: 0;
                            }
                        }
                    }

                    &__modify {
                        position: absolute;
                        bottom: 30px;
                        right: 15px;
                        font-size: 0;

                        &:before {
                            content: '|';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            @extend %font_xs;
                            transform: translate3d(-50%, 0, 0);
                        }

                        > li {
                            display: inline-block;
                            margin: 0 13px;
                            vertical-align: top;

                            a {
                                @extend %font_xs;
                            }
                        }

                    }

                    &__input {
                        position: relative;
                        padding-right: 90px;

                        .input_text {
                            text-align: left;
                        }

                        .btn-full {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 90px;
                        }
                    }
                }    
            }
        }

        &__more-product {
            text-align: center;
        }
    }

    .relation-product {
        
        .swiper-wrapper {
            height: auto;
        }

        &__list {
            float: left;
            width: 275px;
            // margin-left: 15px;

            // &:first-child {
            //     margin-left: 0;
            // }

            a {
                display: block;
            }
        }

        &__thumb {
            width: 275px;
            height: 275px;
        }

        &__info {
            display: block;
            margin-top: 15px;
        }

        &__name {
            display: block;
            height: 60px;
            @extend %font_xs;
            line-height: 30px;
            @include line(2);
        }

        &__price {            
            margin-top: 5px;
            @extend %font_xs;

            .discount {
                display: block;
                margin-top: 25px;
                line-height: 1.5;
                font-weight: 600;

                &-rate{
                    display: inline-block;
                    margin-right: 5px;
                    font-size: 20px;
                    font-weight: 600;
                    color: #ff8a2f;
                }
            }

            .origin {
                display: inline-block;
                font-size: 20px;
                color: $color88;

                + .discount{
                    margin-top: 0;
                }
            }
        }

        .swiper-pagination {
            text-align: center;
            &-bullet {
                float: none;
            }
        }
    }
}