.hd {

    &__myClaimDetail {
        padding-top: 190px;

        .annc {
            position: relative;
            margin-bottom: 25px;
            padding-left: 15px;
            color: #888;
            @extend %font_xs;
            line-height: 1.6;
            word-break: keep-all;

            &:before {
                display: inline-block;
                position: absolute;
                left: 0;
                content: '-';
            }
        }

        .borderB-no {
            border-bottom: none;
        }

        .btn-area {
            margin-top: 40px;

            .btn-full {
                display: block;
                text-align: center;
            }
        }

        .planmeals {
            .meals-delivery {
                padding: 25px 0;
                border-top: 1px solid #BCBCBC;
                font-size: 22px;
                font-weight: 600;

                &__tit {
                    display: inline-block;
                    text-align: left;
                }

                &__price {
                    display: inline-block;
                    float: right;
                    text-align: right;
                }
            }
        }

        .list-more-btn{
            top: 50px;
        }
    }
}
