.hd {
    &__main {
        max-width: 640px;
        overflow: hidden;
        letter-spacing: -1px;

        + .hd__footer{
            padding-top: 0;
            padding-bottom: 100px;
        }

        .swiper-container {
            overflow: visible;
        }
       
        &__h3 {
            font-size: 32px;
            padding: 0 30px;

            &-more{
                display: inline-block;
                margin: 5px 0 0 5px;
                width: 25px;
                height: 25px;
                line-height: 23px;
                font-size: 0;
                text-indent: -999em;
                text-align: center;
                border: 1px solid $colorBC;
                border-radius: 50%;
                vertical-align: top;

                &:before{
                    display: inline-block;
                    margin: 2px 0 0 -2px;
                    width: 6px;
                    height: 6px;
                    vertical-align: middle;
                    border: solid $color3D;
                    border-width: 2px 2px 0 0;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    content: '';
                }
            }
        }

        &__desc {
            margin-bottom: 6px;
            padding: 0 30px;
            color: $color88;
            font-size: 25px;
            line-height: 30px;
        }

        &__visual {
            overflow: hidden;
            position: relative;
            top: 0;
            left: 0;
			// height: 1038px;
			height:790px;
			// background: #f7f4f0;
			> h3 {
				font-size: 30px;
				font-weight: bold;
				top: 190px;
				position: absolute;
				left: 60px;
				right: 60px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2; 
				-webkit-box-orient: vertical;
				span {
					font-size:23px;
					color:$gcolor;
				}
			}

            &__title {
                position: absolute;
                top: 270px;
                left: 60px;
                z-index: 3;
                font-size: 46px;
                line-height: 60px;

                strong {
                    color: $gcolor;
                }
            }

            .visual-box {
                display: none;
                overflow: hidden;
				// height: 912px;
				height:790px;
                &__img {
                    overflow: hidden;
                    width: 640px;
                    height: 790px;
                    opacity: 0;
                    transition: opacity 1s;

                    &:first-child {
                        opacity: 1;
                    }

                    img {
                        max-width: 100%;
                    }
                }

                &.swiper-slide-active {
                    opacity: 1;
                }
            }

            .visual__slide {
                overflow: hidden;
            }

            .meal-area {
                position: absolute;
				// top: 684px;
				top: 148px;
                left: 0;
                width: 640px;
                z-index: 1;

                .meal-box {
                    // float: left;
                    overflow: hidden;
					position: relative;
					width:640px;
                    height: 640px;
                    //height: 440px;
                    background: #fff;
                    //box-shadow: 9px 9px 27px rgba(0, 0, 0, 0.1);

                    img{
                        display: block;
                    }

                    &__tit {
                        width: 370px;
                        padding: {
                            top: 29px;
                            left: 45px;
                            right: 35px;
                        }
                        color: #3d3d3d;
                        @extend %font_xl;
                        font-weight: bold;

                        &__num {
                            position: relative;
                            float: left;
                            padding: 0 22px 0 10px;
                            margin-right: 22px;
                            font-weight: normal;

                            &:after {
                                display: block;
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 1px;
                                height: 100%;
                                border-right: 1px solid #3d3d3d;
                                content: '';
                            }
                        }
                    }

                    &__desc {
                        position: relative;
                        padding: 35px 45px 0;
                        color: #282828;
                        @extend %font_xs;
                        line-height: 30px;
                        word-break: break-all;
                        white-space: pre-line;

                        &:after {
                            display: block;
                            position: absolute;
                            top: 10px;
                            left: 42px;
                            width: 375px;
                            height: 3px;
                            background: $gcolor;
                            content: '';
                        }
                    }
                }

                .swiper-counter {
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    background: rgba(0,0,0,0.15);
                    width: 60px;
                    border-radius: 5px;
                    color: rgba(255,255,255,0.9);
                    z-index: 1;
                    line-height: 30px;
                    text-align: center;
                    font-size: 17px;
                    font-weight: 100;

                    span{
                        display: inline-block;
                        padding: 0 2px;
                        vertical-align: top;
                    }
                }

			}
			
			.meal-pagination {position:absolute; left:60px; bottom:20px; margin:0;}
        }

        &__btn-start {
            display: block;
            width: 100%;
            height: 100%;

            // &:after {
            //     display: block;
            //     float: right;
            //     width: 55px;
            //     height: 55px;
            //     margin: 20px 33px 0 0;
            //     background: url(/front_mo/images/btn_main_right-arrow.png) no-repeat 0 0;
            //     background-size: 100% 100%;
            //     border-radius: 100%;
            //     content: '';
            // }
        }

        &__market {
            overflow: hidden;
            padding: 60px 0 0 20px;
			&__title {
				display: flex;
				border-bottom: solid 3px $gcolor;
				align-items: center;
				padding-bottom: 10px;
				margin-right: 30px;
				h3 {
					flex:0 0 auto; 
					margin-right:20px;
					& + p {margin-top:0; margin-left:0px;}
				}
			}
            .food-area {
				height: auto;
                margin-top: 30px;
                @extend %clear;

                &__box {
                    width: 450px;
                    height: 590px;
                    float: left;
                    @extend %clear;

                    .fullbox {
                        position: relative;
                        overflow: hidden;
                        width: 100%;
                        @extend %vertical;

                        a {
                            display: block;
                        }

                        &__thumb {
                            overflow: hidden;
                            width: 450px;
                            height: 280px;
                            background-size: cover;
                        }

                        &__tit {
                            position: absolute;
                            top: 38px;
                            left: 38px;
                            z-index: 1;
                            @extend %font_m;
                            font-weight: bold;
                        }

                        &__desc {
                            position: absolute;
                            top: 80px;
                            left: 38px;
                            z-index: 1;
                            width: 370px;
                            @extend %font_xs;
                            color: $color88;
                            line-height: 30px;
                            word-break: break-all;
                            white-space: pre-line;
                        }
                    }

                    .halfbox {
                        float: left;
                        width: 220px;
                        margin-top: 10px;
                        @extend %vertical;

                        &__thumb {
                            overflow: hidden;
                            width: 220px;
                            height: 220px;
                        }

                        & + .halfbox {
                            margin-left: 10px;
                        }

                        &__name {
                            display: block;
                            margin-top: 20px;
                            @extend %font_xs;
                            @include line(1);
                        }

                        &__price {
                            margin-top: 10px;
                            @extend %font_xs;

                            .discount {
                                margin-right: 5px;
                                font-weight: 600;
                            }
                            
                            .price {
                                color: $color88;
                            }
                        }
                    }
                }
            }
        }

        &__story {
            overflow: hidden;
            margin-left: 20px;
            padding-top: 60px;
            .greating-story {
                margin-top: 30px;
                // margin-left: -60px;
                height: 452px;
                // transition: opacity 0.6s ease, transform 0.3s ease;
            }

            .greating-story.loading {
                opacity: 0;
                visibility: hidden;
            }

            &__contents {
                // height: auto;
                
                .story-box {
                    box-sizing: border-box;
                    overflow: hidden;
                    width: 450px;      
                    margin-right: 20px;              
                    // padding: 0 20.341% 0 9.388%;
                    box-sizing: border-box;

                    &__thumb {
                        display: block;
                        overflow: hidden;
                        position: relative;
                        width: 100%;
                        height: 340px;

                        figure {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            // background-repeat: no-repeat;
                            // background-position: 50% 50%;
                            // background-size: 100% auto;
                        }
                    }

                    &__info {
                        margin-top: 15px;
                    }

                    &__subtit {
                        display: inline-block;
                        color: #3d3d3d;
                        @extend %font_xs;
                        border-bottom: 1px solid #484848;
                        line-height: 38px;

                    }

                    &__tit {
                        overflow: hidden;
                        margin-top: 20px;
                        color: #3d3d3d;
                        @extend %font_m;
                        font-weight: bold;
                        line-height: 38px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &__desc {
                        margin-top: 15px;
                        color: $color88;
                        @extend %font_xs;
                        line-height: 30px;
                        word-break: break-all;
                        white-space: pre-line;
                    }
                }
            }
        }

        // 추천
        &__recomend{
            margin-top: 73px;

            &-tab{
                margin-top: 20px;
                padding: 0 30px 21px;
                overflow-y: auto;
                white-space: nowrap;
                &::-webkit-scrollbar {
                    display: none;
                }

                li{
                    display: inline-block;
                    margin-right: 6px;
                    border: 1px solid $color88;
                    border-radius: 30px;

                    a{
                        display: block;
                        padding: 13px 18px;
                        @extend %font_xs;
                        color: $color88;
                    }

                    &.on{
                        border: 2px solid $gcolor;

                        a{
                            font-weight: 600;
                            color: $gcolor;
                        }
                    }
                }
            }

            &-btn{
                display: block;
                margin: 30px 30px 0;
                line-height: 68px;
                @extend %font_xs;
                font-weight: 600;
                color: $gcolor;
                text-align: center;
                border: 1px solid $gcolor;
            }
        }

        // 케어식단
        &__care{
            margin-top: 73px;

            .sale{
                display: inline-block;
                margin-top: 0;
                padding: 4px 9px;
                font-size: 15px;
                font-style: inherit;
                background: #e2e735; 
                border: 2px solid $color3D;
                border-radius: 30px;
                vertical-align: top;
                text-transform: uppercase;
                letter-spacing: 0;

                animation: saleMotion 0.4s ease-out 0s infinite alternate;
            }

            .swiper{
                &-container{
                    margin-top: 28px;
                    padding-top: 30px;
                }

                &-text{
                    opacity: 0;
                    transition: all 0.3s;
                }

                &-title{
                    position: relative;
                    font-size: 26px;
                    font-weight: 600;
                    color: $color3D;
                }

                &-desc{
                    margin-bottom: 5px;
                    font-size: 21px;
                    color: $color88;
                }

                &-slide{
                    display: inline-block;
                    margin: 0 15px;
                    width: 420px;
                    transition: all 0.5s;

                    img{
                        width: 100%;
                        border-radius: 30px;
                    }

                    a{
                        position: relative;

                        &:after{
                            position: absolute;
                            right: 20px;
                            bottom: 20px;
                            padding: 11px 15px;
                            color: #fff;
                            font-size: 20px;
                            background: rgba(0,0,0,0.5);
                            border-radius: 20px;
                            content: '관리 시작하기';
                            opacity: 0;
                        }
                    }

                    &-active{
                        margin-top: -30px;

                        .swiper-text, .swiper-btn, a:after, a:before{
                            opacity: 1;
                            transition: all 0.5s;
                        }

                        .swiper-text{
                            padding-top: 20px;
                        }
                    }

                    &.slide1{
                        a:after{
                            background: rgba(255, 125, 38, 0.7);
                        }

                        .swiper-desc{
                            color: #e17d26;
                        }
                    }

                    &.slide2{
                        a:after{
                            background: rgba(107, 180, 63, 0.7);
                        }

                        .swiper-desc{
                            color: #6bb43f;
                        }
                    }

                    &.slide3{
                        a:after{
                            background: rgba(63, 77, 180, 0.7);
                        }

                        .swiper-desc{
                            color: #3f4db4;
                        }
                    }

                    &.slide4{
                        a:after{
                            background: rgba(237, 98, 81, 0.7);
                        }

                        .swiper-desc{
                            color: #ed6251;
                        }
                    }

                    &.slide5{
                        a:after{
                            background: rgba(83, 165, 224, 0.7);
                        }

                        .swiper-desc{
                            color: #53a5e0;
                        }
                    }

                }

                &-btn{
                    position: absolute;
                    right: 0;
                    bottom: 5px;
                    z-index: 1;
                    opacity: 0;

                    button{
                        margin-left: 5px;
                        padding: 0 15px;
                        line-height: 50px;
                        font-size: 20px;
                        color: #222;
                        border-radius: 30px;
                        border: 0;
                        background: #efefef;
                    }
                }

                // &-counter{
                //     position: absolute;
                //     right: 20px;
                //     bottom: -3px;
                //     width: 60px;
                //     border-radius: 5px;
                //     color: $color3D;
                //     z-index: 1;
                //     line-height: 30px;
                //     text-align: center;
                //     font-size: 18px;
                //     font-weight: 100;

                //     span{
                //         display: inline-block;
                //         padding: 0 2px;
                //         vertical-align: top;
                //     }
                // }

                // &-pagination-progressbar{
                //     position: relative;
                //     margin: 35px 0 10px;
                //     width: 520px;
                //     height: 2px;
                //     border-radius: 5px;
                //     overflow: hidden;
                //     background: rgba(0,0,0,.25);

                //     &-fill{
                //         position: absolute;
                //         left: 0;
                //         top: 0;
                //         width: 100%;
                //         height: 100%;
                //         background: $gcolor;
                //         transform: scale(0);
                //         transform-origin: left top;
                //         transition: all 0.3s;
                //         transform: translate3d(0,0,0);
                //         z-index: 10;
                //     }
                // }
            }
        }

        // 챌린지
        &__challenge{
            margin-top: 63px;

            .swiper{
                &-container{
                    margin-top: 28px;
                }

                &-slide{
                    display: inline-block;
                    width: 640px;
                    text-align: center;

                    img{
                        width: 100%;
                    }
                }

                &-counter{
                    position: absolute;
                    right: 20px;
                    bottom: -12px;
                    width: 60px;
                    border-radius: 5px;
                    color: $color3D;
                    z-index: 1;
                    line-height: 30px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 100;

                    span{
                        display: inline-block;
                        padding: 0 2px;
                        vertical-align: top;
                    }
                }

                &-pagination-progressbar{
                    position: relative;
                    margin: 35px 0 0 30px;
                    width: 520px;
                    height: 2px;
                    border-radius: 5px;
                    overflow: hidden;
                    background: rgba(0,0,0,.25);

                    &-fill{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: $gcolor;
                        transform: scale(0);
                        transform-origin: left top;
                        transition: all 0.3s;
                        transform: translate3d(0,0,0);
                        z-index: 10;
                    }
                }
            }
        }

        // 지금사야 득템
        &__now{
            margin-top: 73px;
            
            .sale{
                display: inline-block;
                margin-top: 0;
                padding: 4px 9px;
                font-size: 15px;
                font-style: inherit;
                background: #e2e735; 
                border: 2px solid $color3D;
                border-radius: 30px;
                vertical-align: top;
                text-transform: uppercase;
                letter-spacing: 0;

                animation: saleMotion 0.4s ease-out 0s infinite alternate;
            }

            @keyframes saleMotion {
                0% {margin-top: 0;}
                100% {margin-top: 7px;}
            }

            &-top{
                margin-top: 28px;
                padding: 0 20px 0 30px;

                .product__wrap__list--item{
                    position: relative !important;
                    margin-top: 0 !important;
                    border-bottom: 0 !important;
                    width: 590px !important;

                    .item{
                        &__thumb{
                            width: 350px !important;
                            height: 350px !important;
                        }

                        &__info{
                            position: absolute !important;
                            right: 0;
                            top: 50%;
                            margin: 0 !important;
                            width: 220px;
                            transform: translateY(-50%);

                            .item__title{
                                margin-top: 0;
                                height: 100px;

                                .item__sub{
                                    font-size: 18px !important;
                                    -webkit-line-clamp: 1 !important;
                                }
                            }

                            .item__price{
                                &-ori{
                                    display: block;
                                }

                                &-dc-rate{
                                    @extend %font_m;
                                }
                            }
                        }

                        &__cart{
                            top: initial !important;
                            bottom: 50px !important;
                        }

                        &__tag{
                            min-height: 28px;
                        }
                    }
                }
            }
        }

        // 신상품
        &__new{
            margin-top: 73px;

            .swiper-prudect{
                margin-top: 28px;
            }
        }

        // 브랜드
        &__brand{
            margin-top: 73px;

            .swiper{
                &-container{
                    margin: 0 20px;
                    overflow: hidden;
                }

                &-slide{
                    display: inline-block;
                    margin-right: 5px;
                    padding: 0 10px;
                    width: 300px;
                    overflow: hidden;
                    box-sizing: border-box;
                    
                    img{
                        width: 100%;
                        display: block;
                    }
                }

                &-pagination-progressbar{
                    position: relative;
                    margin: 40px auto 0;
                    width: 580px;
                    height: 2px;
                    border-radius: 5px;
                    overflow: hidden;
                    background: rgba(0,0,0,.25);

                    &-fill{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: $gcolor;
                        transform: scale(0);
                        transform-origin: left top;
                        transition: all 0.3s;
                        transform: translate3d(0,0,0);
                        z-index: 10;
                    }
                }
            }

            .list{
                &-wrap{
                    position: relative;
                    flex-wrap: wrap;
                    display: flex;
                    margin: 28px 0 15px;
                    overflow: hidden;
                    border-radius: 15px;
                    box-shadow: 0 0 5px rgba(0,0,0,0.2)
                }

                &-img{
                    display: inline-block;
                    width: 135px;
                    box-shadow: 0 0 5px rgba(0,0,0,0.2);

                    &:nth-of-type(1){
                        margin-bottom: 10px;
                        width: 100%;
                    }

                    &:nth-of-type(3){
                        margin-left: 10px;
                    }

                    &__more{
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 135px;
                        height: 135px;
                        line-height: 135px;
                        border-radius: 0 0 15px 0;
                        text-align: center;
                        z-index: 1;
                        background: rgba(0,0,0,0.3);

                        &:before ,&:after{
                            position: absolute;
                            right: 43px;
                            bottom: 65px;
                            width: 50px;
                            height: 3px;
                            background: #fff;
                            content: '';
                        }
                        &:after{
                            transform: rotate(90deg);
                        }

                    }
                }

                &-tit{
                    display: block;
                    padding: 0 10px;
                    font-size: 26px;
                    @include line(1);
                }

                &-txt{
                    margin-top: 10px;
                    padding: 0 10px;
                    @extend %font_xs;
                    color: $color88;
                    @include line(1);
                }
            }
        }

        // 라이브러리
        &__library{
            margin-top: 73px;

            .swiper{
                &-container{
                    margin: 28px 0 0 30px;
                    height: 160px;
                    overflow: hidden;
                }

                &-slide{
                    display: inline-block;
                    margin-right: 20px;
                    width: 450px;
                    
                    img{
                        width: 100%;
                    }
                }
            }
        }

        // 매거진
        &__magazine{
             margin-top: 73px;

            .magazine-slider{
                position: relative;
                margin-top: 30px;
                padding: 65px 0 55px;

                &:after{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    content: '';
                }
            }

            .magazine-list{
                position: relative;
                padding-bottom: 10px;
                z-index: 2;

                .swiper{
                    &-slide{
                        position: relative;
                        margin: 30px 15px 0;
                        width: 300px;
                        height: 470px;
                        border-radius: 15px;
                        overflow: hidden;
                        transition: all 0.5s;

                        a{
                            &:before{
                                position: absolute;
                                left: 30px;
                                top: 30px;
                                width: 78px;
                                height: 15px;
                                content: '';
                                background: url('/front_pc/images/img_header_logo_w.png') no-repeat;
                                background-size: 100% auto;
                                z-index: 1;
                            }

                            &:after{
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background: rgba(0,0,0,0.2);
                                content: '';
                                z-index: 0;
                            }
                        }

                        &-active{
                            margin-top: 0;

                            .list-txt{
                                z-index: 1;
                            }
                        }
                    }
                    
                    &-pagination{
                        margin-top: 0;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                    }
                }

                .list{
                    &-txt{
                        position: absolute;
                        left: 30px;
                        bottom: 90px;
                        width: 240px;
                        color: #fff;

                        &-sort{
                            display: inline-block;
                            margin-bottom: 15px;
                            padding: 0 10px;
                            line-height: 30px;
                            font-size: 15px;
                            color: $gcolor;
                            background: #fff;
                            border-radius: 30px;

                            &.c1{color: #307d0e;}
                            &.c2{color: #918c00;}
                            &.c3{color: #a4111f;}
                            &.c4{color: #378a9f;}
                        }

                        &-title{
                            display: block;
                            line-height: 1.2;
                            width: 99%;
                            font-size: 34px;
                            @include line(2);
                            word-break: normal;
                            word-wrap: keep-all;
                        }

                        &-desc{
                            display: block;
                            margin-top: 20px;
                            line-height: 1.2;
                            width: 99%;
                            font-size: 18px;
                            @include line(1);
                        }
                    }

                    &-img{
                        position: relative;
                        height: 100%;

                        img{
                            position: absolute;
                            left: 0;
                            top: 50%;
                            width: 100%;
                            max-height: initial;
                            transform: translateY(-50%);
                        }
                    }
                }
            }

            .magazine-bg{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;

                img{
                    margin-top: -25%;
                    width: 100%;
                    -webkit-filter: blur(10px); 
                    -moz-filter: blur(10px); 
                    -o-filter: blur(10px); 
                    -ms-filter: blur(10px); 
                    filter: blur(10px); 
                    transform: scale(1.02);
                }

                .swiper-slide{
                    overflow: hidden;
                    position: relative;
                    transition: all 0.5s;
                    
                    &:after{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,0.5);
                        content: '';
                        z-index: 0;
                    }
                }
            }
        }

        // 그리팅TV
        &__tv{
            margin-top: 73px;
            padding: 0 30px;

            .hd__main__h3{
                margin-bottom: 28px;
                padding: 0;
            }

            a{
                display: inline-block;
            }
        }

        // 공지사항
        &__notice{
            position: relative;
            margin-top: 80px;
            padding: 0 140px;
            background: $gcolor;

            &-h3{
                position: absolute;
                left: 30px;
                top: 50%;
                font-size: 20px;
                color: rgba(255,255,255,0.85);
                transform: translateY(-50%);

                &:after{
                    display: inline-block;
                    margin: -3px 0 0 15px;
                    width: 2px;
                    height: 12px;
                    background: #fff;
                    content: '';
                    vertical-align: middle;
                }
            }

            &-btn{
                position: absolute;
                right: 30px;
                top: 50%;
                font-size: 20px;
                color: rgba(255,255,255,0.85);
                transform: translateY(-50%);
            }

            .swiper{
                &-container{
                    height: 60px;
                    overflow: hidden;

                    a{
                        display: block;
                        width: 90%;
                        font-size: 20px;
                        color: #fff;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &-slide{
                    width: 390px;
                    height: 60px;
                    line-height: 58px;
                    color: #fff;
                }
            }
        }

        // 배너
		&__banner {
            margin-top: 80px;
            overflow: hidden;

            [class^="swiper-button-"]{
                position: absolute;
                top: 50%;
                margin-top: -15px;
                width: 10px;
                height: 30px;
                vertical-align: middle;
                background: url(/front_mo/images/sp_ico.png) no-repeat -36px 0;
                background-size: 640px auto;
                transform: scale(1.6);
                opacity: 0.25;
                z-index: 1;
            }
            .swiper-button-prev{left: 20px;}
            .swiper-button-next{right: 20px;background-position-x: -57px}
            .swiper-pagination{
                position: absolute;
                left: 0;
                bottom: 20px;
                width: 100%;
                z-index: 1;
            }
        }

        // swiper-prudect
        .swiper-prudect{
            margin-left: 30px;
            overflow: hidden;

            .product__wrap__list--item{
                margin-right: 20px;
                margin-top: 0 !important;
                padding-bottom: 0 !important;
                border-bottom: 0 !important;
                flex: 0 0 220px !important;

                .item{
                    &__thumb{
                        width: 220px;
                        height: 220px !important;

                        img{
                            display: block;
                            width: 220px;
                            height: 220px;
                        }
                    }

                    &__info{
                        width: 220px;

                        .item__sub{
                            font-size: 19px;
                            -webkit-line-clamp: 1 !important;
                        }

                        .item__name{
                            font-size: 21px;
                            word-break: keep-all;
                        }

                        .item__title{
                            margin-top: 15px !important;
                        }
                    }

                    &__cart{
                        top: initial;
                        bottom: 0;
                    }
                }
            }

        }

        /* parallax */
        @-webkit-keyframes fadeInUp {
            0% {
                opacity: 0;
                transform: translate3d(0, 30px, 0);
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
        @keyframes fadeInUp {
            0% {
                opacity: 0;
                transform: translate3d(0, 30px, 0);
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        @-webkit-keyframes fadeInDown {
            0% {
                opacity: 0;
                transform: translate3d(0, -30px, 0);
            }

            50% {
                opacity: 0.5;
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        @keyframes fadeInDown {
            0% {
                opacity: 0;
                transform: translate3d(0, -30px, 0);
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        .hPara {
            opacity: 0;
        }

        .fadeInUp {
            -webkit-animation: fadeInUp 0.9s both 0.1s cubic-bezier(0.22, 0.61, 0.87, 1.27);
                    animation: fadeInUp 0.9s both 0.1s cubic-bezier(0.22, 0.61, 0.87, 1.27);
        }
        .fadeInDown {
            -webkit-animation: fadeInDown 0.9s both 0.1s cubic-bezier(0.22, 0.61, 0.87, 1.27);
                    animation: fadeInDown 0.9s both 0.1s cubic-bezier(0.22, 0.61, 0.87, 1.27);
        }
    }

    .teaser-wrap {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #FFF;
    }
    
    .teaser-cont {
        position: relative;
        height: 100%;

        img {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            // height: 100%;
        }

        a {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8.5%;
        }
    }

    .tutorial {
        // display: none;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        overflow: hidden;

        &-wrap {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);

            .swiper-button-next {
                width: 50px;
                height: 90px;
                position: absolute;
                top: 525px;
                right: 28px;
                z-index: 99999;
                -webkit-tap-highlight-color: transparent;

                &:focus{
                    outline:none;
                }
            }

            .swiper-button-prev {
                width: 50px;
                height: 90px;
                position: absolute;
                top: 525px;
                left: 28px;
                z-index: 99999;
                -webkit-tap-highlight-color: transparent;

                &:focus{
                    outline:none;
                }
            }
        }

        &-cont {
            position: relative;
            height: 100%;
    
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                // height: 100%;
            }
        }

        &-close {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 999;
            padding: 20px 30px 20px 100px;
            background: url(/front_mo/images/tutorial_icon_close.png) no-repeat 50px 20px;
            color:#fff; 
            font-size: rem(50px); 
            text-align:center;
            -webkit-tap-highlight-color: transparent;

            &:focus{
                outline:none;
            }
        }

        &--show {
            display: block;
        }
    }

}


#main{
    padding-bottom: 100px;
    
    .hd__nav-area{
        transform: translate3d(-50%, 0, 0) !important;
        -webkit-transform: translate3d(-50%, 0, 0) !important;
    }   
}