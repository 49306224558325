.hd {

    &__winnerDetail {

        .banner {
            overflow: hidden;
            width: 100%;
            height: 380px;
            @extend %vertical;
        }

        &__container {
            width: 580px;
            margin: 35px auto 0;
            line-height: 32px;

            .annc {
                word-break: keep-all;
                @extend %font_xs;

                &__info {
                    margin-top: 25px;

                    &__list {
                        position: relative;
                        padding-left: 15px;

                        &:before {
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            content: '-';
                        }
                    }
                }
            }

            .table {
                margin: 35px 0 50px;

                table {
                    margin-bottom: 30px;
                    border-top: 3px solid $gcolor;

                    tr {
                        height: 64px;
                        border-bottom: 1px solid #bcbcbc;
                        line-height: 64px;

                        th, td {
                            text-align: center;
                            font-weight: normal;
                            @extend %font_xs;
                        }
                    }
                }

                &__desc {
                    text-align: center;
                    @extend %font_xs;
                }

            }
        }
        
        .btn-area {
            text-align: center;
        }
    }

}