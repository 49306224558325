.hd {
    &__member-join {
        margin-top:45px;
    }

    .member-join {
        position: relative;
        margin-bottom: 30px;
        padding: 25px 0 50px;
        border-top: 3px solid $color3D;
        background: #FFF;
        text-align: center;
        box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);

        &__title {
            margin-bottom:35px;

            span {
                display: inline-block;
                position: relative;
                z-index:1;
                @extend %font_l;
                font-weight: 600;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: -1;
                    bottom: -2px;
                    left: 0px;
                    width: 100%;
                    height: 8px;
                    background: $ocolor;
                }
            }
        }

        &__desc {
            margin-top: 35px;
            line-height: 32px;
            @extend %font_xs;
        }

        .btn-full {
            display: block;
            max-width: 500px;
            margin: 20px auto 0;
            font-weight: 600;
        }

        .member-join__desc + .btn-full{
            margin-top:40px;
        }

        &__sns {
            margin-top: 45px;
            
            &__title {
                display: block;
                
                @extend %font_s;
                font-weight: 600;
            }

            &__list {
                margin-top:25px;

                li {
                    display: inline-block;
                    margin:0 20px;
                    font-size: 0;
                }
            }

            &__txt {
                display: block;
                margin-top:15px;
                @extend %font_xs;
            }

        .sns-login {
            padding-top: 33px;
            text-align: center;

            &__tit {
                margin-bottom: 26px;
                font-weight: bold;
                @extend %font_s;
            }

            a {
                display: inline-block;
                position: relative;
                width: 80px;
                height: 80px;
                // line-height: 70px;
                text-align: center;
                border-radius: 50%;
                @extend %font_s;

                & + a {
                    margin-left: 42px;
                }

                span {
                    display: inline-block;
                    padding-top: 95px;
                    line-height: 32px;
                }
            }

            $list : naver, kakao, google;
            @for $i from 1 through length($list) {
              &-#{nth($list, $i)}:before {
                display: block;
                position: absolute;
                top: 7px;
                left: 10px;
                width: 40px;
                height: 70px;
                margin-left: 12px;
                background: url(/front_mo/images/icon_sns-login.png) no-repeat -#{($i - 1) * 46}px;  
                content: '';
              }
            }

            &-kakao {
                &:before {
                    width: 42px;
                    margin-left: 10px;
                    background-position-x: -44px;
                }
            }

            &-naver {
                background: #3bab36;
                border:1px solid #c7c7c7;
                box-sizing: border-box;

                a {
                    color: #fff;
                }
            }

            &-kakao {
                background: #fae100;
            }

            &-google {
                background: #f0f0f0;
			}
			
			&-apple {
				background: #000;
				&:before{
					content: '';
					background: url(/front_mo/images/apple_logo_white.svg) 0 0 no-repeat;
					width:100%; height:100%;
					position: absolute;
					background-size:100% 100%;
					border-radius: 50%;
					left:0;
				}
            }
        }
        }
    }

    &__sns-join {
        

        .member-box__devide {
            border-top: 1px solid #BCBCBC;
            
            p {
                height:70px;
                margin: 20px 0;
                padding:0 20px;
                background: #FFEDDF;
                line-height:70px;
                @extend %font_xs;
                strong {
                    font-weight: 600;
                }
            }
        }
    }
}