.hd {
    .healthCare {

        &__visual {
            overflow: hidden;
            position: relative;
            min-height: 1010px;

            &__img {
                max-width: 100%;
            }

            &__banner {
                position: absolute;
                top: 684px;
                left: 60px;
                z-index: 1;

                .swiper-container {
                    overflow: visible;
                }

                .swiper-slide {
                    float: left;
                    overflow: hidden;
                    position: relative;
                    height: 270px;
                    background: #fff;
                    font-size: 0;
                    box-shadow: 9px 9px 27px rgba(0, 0, 0, 0.1);

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        &List__banner {
            margin-top: 40px;

            li {
                margin-top: 30px;
                font-size: 0;

                &:first-child {
                    margin-top: 0;
                }

                figure {
                    display: block;
                    height: 230px;
                }
            }
        }

        &Detail {
            &__cont {
                font-size: 0;
            }

            &__txt {
                min-height: 500px;
                margin: 30px auto;
                padding: 0;
                line-height: normal;
                @extend %font_s;
            }

            .btn-full {
                display: block;
                float: none;
                text-align: center;
            }
        }
    }
}