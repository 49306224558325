@import "../node_modules/normalize.css/normalize";
@import "../node_modules/swiper/src/swiper";

@import "../node_modules/sass-rem/_rem.scss";
$rem-baseline : 20px;
html{
    @include rem-baseline;
    height: -webkit-fill-available;
}


/*
	display
	clear
	float
	overflow
	position
	top
	right
	botton
	left
	z-index
	width
	height
	margin
	padding
	background:#color (url) no-repeat x y;
	background-size:;
	border
	color
	font-size
	font-weight
	text-overflow
	text-align
	line-height
	text-indent
	transform
	transition
	box-sizing
	white-space

	::after {
	display: block;
	clear: both;
	content: "";
	};

	*/

%font_xs {
    font-size: 22px;
}
%font_s {
    font-size: 24px;
}
%font_m {
    font-size: 26px;
}
%font_l {
    font-size: 30px;
}
%font_xl {
    font-size: 36px;
}

$gcolor: #918c00;
$ocolor: #F9D983;
$colorDG: #e0ba53;
$color88: #888;
$color3D: #1c1c1c;
$colorBC: #ccc;
$colorBG: #f5f5f5;
$colorBG2: #f2f2e9;
$colorRD: #e32d3e;
$noto: 'Noto Sans Korean';

@font-face {
    font-family: 'Noto Sans Korean';
    font-style: normal;
    font-weight: 400;
    src: local('Noto Sans Regular'), local('NotoSans-Regular'), url(/front_mo/font/NotoSansKR-Regular-Hestia.eot), url("/front_mo/font/NotoSansKR-Regular-Hestia.eot?#iefix") format('embedded-opentype'), url("/front_mo/font/NotoSansKR-Regular-Hestia.woff") format('woff'), url("/front_mo/font/NotoSansKR-Regular-Hestia.otf") format('opentype');
}

@font-face {
    font-family: 'Noto Sans Korean';
    font-style: normal;
    font-weight: 700;
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(/front_mo/font/NotoSansKR-Bold-Hestia.eot), url("/front_mo/font/NotoSansKR-Bold-Hestia.eot?#iefix") format('embedded-opentype'), url("/front_mo/font/NotoSansKR-Bold-Hestia.woff") format('woff'), url("/front_mo/font/NotoSansKR-Bold-Hestia.otf") format('opentype');
}

%clear {
    &:after {
        display: block;
        clear: both;
        content: "";
    }
}

%vertical {
    figure {
        text-align: center;
        font-size: 0;

        img {
            max-width: 100%;
            vertical-align: middle;
        }

        &:after {
            display: inline-block;
            position: relative;
            width: 0;
            height: 100%;
            vertical-align: middle;
            content: "";
        }
    }
}

@mixin line($lineCount:1) {
    /* 사용법 : @include line(라인수) */
    @if ($lineCount == 1) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    } @else {
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: $lineCount;
    }
}
/* 구분선 ex) userid | 2018-04-30 */
%division {
    position: relative;
    margin-right: 20px;

    &:after {
        display: block;
        position: absolute;
        top: 2px;
        right: -20px;
        width: 20px;
        text-align: center;
        //line-height: 16px;
        font-size: 12px;
        color: #ddd;
        content: '|';
    }
};

%count {
    display: block;
    position: relative;
    text-align: center;

    input {
        vertical-align: top;
        width: calc(100% - 120px);
        height: 55px;
        border: 0;
        background: #F0F0F0;
        line-height: 55px;
        text-align: center;
        @extend %font_xs;
        font-weight: 600;
        color: $colorBC;
        box-sizing: border-box;
    }

    input.on {
        border: 1px solid $gcolor;
        background: #FFF;
        color: #3D3D3D;
    }

    .btn__count {
        position: absolute;
        top: 0;
        width: 55px;
        height: 55px;
        background: $gcolor;

        &-down {
            left: -1px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 21px;
                height: 3px;
                background: #FFF;
                transform: translate(-50%, -50%);
            }
        }

        &-up {
            right: -1px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 21px;
                height: 3px;
                background: #FFF;
                transform: translate(-50%, -50%);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 3px;
                height: 21px;
                background: #FFF;
                transform: translate(-50%, -50%);
            }
        }   
    }

    button:disabled {
        background: $colorBC;
    }
}

$desktop-width: 1200px;
@mixin desktop {
    @media (max-width: #{$desktop-width}) {
        @content;
    }
}

@import "./divide/layout";
@import "./divide/common";
@import "./divide/common1";
@import "./divide/main";
@import "./divide/lows_schd";
@import "./divide/lows_delivery";
@import "./divide/lows_mchoice";
@import "./divide/lows_confirm";
@import "./divide/planMeals";
@import "./divide/planMeals-detail";
@import "./divide/planMeals_write";
@import "./divide/market";
@import "./divide/market_list";
@import "./divide/market_designation";
@import "./divide/chinese_room";
@import "./divide/market_detail";
@import "./divide/market_designation_detail";
@import "./divide/login";
@import "./divide/member_join";
@import "./divide/caulse_agree";
@import "./divide/greating_join";
@import "./divide/default-caulse";
@import "./divide/search";
@import "./divide/best_list";
@import "./divide/display_list";
@import "./divide/display_detail";
@import "./divide/story";
@import "./divide/brandStory";
@import "./divide/brandStory_detail";
@import "./divide/monthStory_list";
@import "./divide/monthStory_detail";
@import "./divide/greatingLife_list";
@import "./divide/greatingLife_detail";
@import "./divide/greatingTable_list";
@import "./divide/greatingTable_detail";
@import "./divide/event_list";
@import "./divide/winner_list";
@import "./divide/event_detail";
@import "./divide/setting";
@import "./divide/winnerDetail";
@import "./divide/myOrderList";
@import "./divide/myOrderDetail";
@import "./divide/healthCare";
@import "./divide/applyOrderCancel";
@import "./divide/applyOrderExchange";
@import "./divide/applyOrderExchangeConfirm";
@import "./divide/applyOrderExchangeDone";
@import "./divide/myLoginLog";
@import "./divide/myClaimList";
@import "./divide/service_center";
@import "./divide/myClaimDetail";
@import "./divide/myShippingItemList";
@import "./divide/myReview";
@import "./divide/ecoDelivery";
@import "./divide/amore";
@import "./divide/coupon";
@import "./divide/careFood";
@import "./divide/groupOrder";
@import "./divide/memberShip";
@import "./divide/product";
@import "./divide/gift";
@import "./divide/checkAddress.scss";


        
html {
    font-size: 125%;
    height: 100%;
}

@media screen and (min-device-width: 320px) {
    html {
        font-size: 62.5%;
    }
}

@media screen and (min-device-width: 360px) {
    html {
        font-size: 70.3125%;
    }
}

@media screen and (min-device-width: 375px) {
    html {
        font-size: 73.2421%;
    }
}

@media screen and (min-device-width: 412px) {
    html {
        font-size: 80.4688%;
    }
}

@media screen and (min-device-width: 414px) {
    html {
        font-size: 80.8594%;
    }
}

@media screen and (min-device-width: 640px) {
    html {
        font-size: 125%;
    }
}

.wdw-type {
    
    body {
        width: 100%;
        max-width: initial;
    }

    h1, h2, h3, h4 {
        font-size: rem(14px);
    }

    %font_xs {
        font-size: rem(22px);
    }
    %font_s {
        font-size: rem(24px);
    }
    %font_m {
        font-size: rem(26px);
    }
    %font_l {
        font-size: rem(30px);
    }
    %font_xl {
        font-size: rem(36px);
    }
}



body {
    margin: 0 auto;
    max-width: 640px;
    height: 100%;
    overflow: auto;
}

a {
    color: #1c1c1c;
    text-decoration: none;
}

ol, ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

h1, h2, h3, h4 {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
}

em {
    font-style: normal;
}

figure,
p {
    margin: 0;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    min-width: 0;
}

table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
}

legend {
    border: 0;
    background-color: transparent;
    color: transparent;
    font: 0/0 a;
    text-shadow: none;
}

table caption {
    display: none;
}

.cascading select {
    width: 100px;
    height: 200px;
}

button {
    padding: 0;
    border: 0;
    color: $color3D;
    background: none;
    cursor: pointer;
}

input {
    margin: 0;
    padding: 0;
}

input::-ms-clear {
    display: none;
}

dd, dl, dt {
    margin: 0;
    padding: 0;
}

form {
    margin: 0;
    padding: 0;
}

ins {
    text-decoration: none;
}

blockquote {
    margin: 0;
}
