.hd {

	&__marketDesignation {
		padding-top: 150px;

		.banner {
			overflow: hidden;
			width: 100%;
			height: 188px;
			margin-bottom: 30px;
			border-bottom: 1px solid #bcbcbc;
			@extend %vertical;
		}

		.item {
			display: flex;
			flex-flow: row wrap;
			width: 580px;
			margin: 0 auto;
			@extend %clear;

			&__list {
				position:relative;
				width: 275px;
				float: left;
				@extend %vertical;

				&:nth-child(even) {
					margin-left: 30px;
				}
				
				&__thumb {
					position: relative;

					a {
						display: block;
						font-size: 0;

						figure {
							width: 275px;
							height: 275px;
						}
					}

					input[type="checkbox"]:checked + label:before {
						border-color:$gcolor;
					}
				}
				
				&:nth-child(1n+3) {
					margin-top: 40px;
				}
			}

			&__info {
				margin-bottom: 90px;
			}

			&__date{
				display: block;
				margin-top: 25px;
				@extend %font_m;

				strong {
					&:not(:first-child) {
						&:before {
							display: inline-block;
							color: #888;
							content: '/';
						}
					}
				}
			}

			&__menu {
				margin-top: 15px;

				&-list {
					@extend %font_xs;
					line-height: 30px;
					word-break: break-all;

					&:before {
						display: inline-block;
						padding-right: 5px;
						content: '-';
					}
				}
			}

			.flex-wrap {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
			}

			&__price {
				display: block;
				padding: 20px 0;
				border-top: 1px solid #888;
				@extend %font_xs;

				.box__list__price-discounted {
					display: inline-block;
					font: {
						size: 22px;
						weight: 600;
					} 

					text-decoration: none;
				}

				.box__list__price-origin {
					display: inline-block;
					margin-left: 8px;
					font-size: 20px;
					color: #888;
				}

				strong {
					@extend %font_m;
				}
			}

			&__count {
				display: block;
				position: relative;
				margin-top: 5px;
				text-align: center;

				input {
					vertical-align: top;
					width: 154px;
					height: 55px;
					border: 0;
					background: #F0F0F0;
					line-height: 55px;
					text-align: center;
					@extend %font_xs;
					font-weight: 600;
					color: #BCBCBC;
					box-sizing: border-box;
				}

				input.on {
					border: 1px solid $gcolor;
					background: #FFF;
					color: #3D3D3D;
				}

				.btn__count {
					position: absolute;
					top: 0;
					width: 55px;
					height: 55px;
					background: $gcolor;

					&-down {
						left: -1px;

						&:before {
							content: '';
							display: block;
							position: absolute;
							top: 50%;
							left: 50%;
							width: 21px;
							height: 3px;
							background: #FFF;
							transform: translate(-50%, -50%);
						}
					}

					&-up {
						right: -1px;

						&:before {
							content: '';
							display: block;
							position: absolute;
							top: 50%;
							left: 50%;
							width: 21px;
							height: 3px;
							background: #FFF;
							transform: translate(-50%, -50%);
						}

						&:after {
							content: '';
							display: block;
							position: absolute;
							top: 50%;
							left: 50%;
							width: 3px;
							height: 21px;
							background: #FFF;
							transform: translate(-50%, -50%);
						}
					}   
				}

				button:disabled {
					background: #BCBCBC;
				}
			}
		}
	}
}