// VenderPrefix Mixin
// $property : 속성, $value : 속성명
@mixin prefix($property, $value) {
    -webkit-#{$property}: $value;
    -moz-#{$property}: $value;
    -ms-#{$property}: $value;
    -o-#{$property}: $value;
    #{$property}: $value;
}

// Keyframes Mixin
// $keyName : keyframes이름, @content : 들어갈 내용
@mixin keyframe($keyName) {
    @-webkit-keyframes #{$keyName} {
        @content;
    }
    @keyframes #{$keyName} {
        @content;
    }
}

.hd {
    &__hidden {
        @extend %hidden;
    }

    // 히든 & 숨김 처리
    .hidden {
        overflow: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
        clip: rect(1px 1px 1px 1px);
    }

    %hidden {
        overflow: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
        clip: rect(1px 1px 1px 1px);
    }

    .hide {
        display: none !important;
    }

    .zipPopupClose {
        display: block !important;
    }

    // ***** 레이아웃 ***** //
    // 게시판
    .board {
        &__wrap {
            border-top: 1px solid $colorBC;
        }

        &__list {
            position: relative;
            border-bottom: 1px solid $colorBC;

            &:after{
                position: absolute;
                top: 55px;
                right: 0;
                width: 30px;
                height: 20px;
                background: url("/front_mo/images/icon_common_drop-down.png") no-repeat;
                background-size: 100% auto;
                content: '';
                z-index: -1;
            }

            &.on {
                &:after {
                    background-image: url("/front_mo/images/icon_common_drop-down-on.png");
                }

                .review__info__btn{
                    display: inline-block;
                }
            }
        }

        &__inner {
            &__review {
                position: relative;
                padding-top: 25px;
                cursor: pointer;

                .review {
                    &__div {
                        display: inline-block;
                        @extend %font_s;
                        vertical-align: middle;
                        margin-right: 5px;
                    }

                    &__title {
                        display: inline-block;
                        overflow: hidden;
                        @extend %font_s;
                        font-weight: normal;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: middle;

                        .best {
                            display: inline-block;
                            padding: 0 8px;
                            height: 30px;
                            background: $gcolor;
                            text-align: center;
                            vertical-align: 2px;
                            line-height: 30px;
                            font: {
                                size: 18px;
                                weight: 600;
                            }
                            color: #FFF;
                        }
                    }

                    &__info {
                        position: relative;
                        margin: 20px 0 25px;
                        font-size: 20px;
                        color: #BCBCBC;

                        &__date,
                        &__id,
                        &__time {
                            color: $color88;
                        }

                        &__id{
                            display: inline-block;
                            margin-right: 5px;
                            height: 27px;
                            max-width: 95px;
                            overflow: hidden;
                            vertical-align: middle;
                        }

                        &__date{
                            margin-left: 5px;
                        }

                        &__time{
                            margin-left: 15px;
                        }

                        &__btn{
                            display: none;
                            margin-left: 70px;
                            vertical-align: middle;
                            color: $color88;

                            &.type2{
                                margin-left: 30px;
                            }
                        }
                    }

                    &__cont {
                        display: none;
                        padding: 20px 0;
                        line-height: 1.5;
                        font: {
                            size: 23px;
                            weight: normal !important;
                        }
                        color: #3D3D3D;
                        word-break: break-all;
                    }
                }

                .answer__wait {
                    display: block;
                    position: absolute;
                    left: 330px;
                    top: 72px;
                    font-size: 20px;
                    color: $gcolor;
                }
            }

            &__detail {
                display: none;
                padding: 40px 15px 45px;
                border-top: 1px solid #C0C0C0;
            }
        }
    }

    .board__inner__detail.detail__reply {
        border-top: 1px solid #C0C0C0;

        .detail__cont {
            position: relative;
            margin-top: 0;
            padding-left: 35px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 18px;
                height: 18px;
                border-bottom: 3px solid $gcolor;
                border-left: 3px solid $gcolor;
                box-sizing: border-box;
            }
        }

        .detail__info {
            margin-top: 10px;
            padding-left: 35px;
        }
    }

    .detail {
        &__icon {
            display: inline-block;
            padding: 0 15px;
            height: 36px;
            margin-bottom: 15px;
            line-height: 36px;
            font-size: 20px;
            color: #FFF;
            text-align: center;
            background: $color3D;
        }

        &__options {
            display: block;
            margin-bottom: 20px;
            font-size: 22px;
            font-weight: 400;
            color: $gcolor;
        }

        &__info {
            @extend %font_xs;
            color: #BCBCBC;

            &__date,
            &__id,
            &__time {
                color:$color88;
            }

            span:last-child {
                margin-left: 15px;
            }
        }

        &__cont {
            margin-top: 20px;
            height: auto;
            line-height: 1.5;
            font-size: 23px;
            font-weight: normal;
            color: #3D3D3D;
            word-break: normal;
            word-wrap: break-word;
        }

        &__img {
            margin-top: 35px;

            img {
                display: block;
                margin-top: 20px;
                font-size: 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    // 탭 메뉴
    .tab__style {
        text-align: center;
        font-size: 0;

        li {
            display: inline-block;
            vertical-align: top;
            width: 290px;

            a {
                display: block;
                width: 100%;
                height: 70px;
                border: 1px solid #BFBFBF;
                background: #F4F4F4;
                line-height: 68px;
                @extend %font_xs;
                font-weight: 600;
                color: #3D3D3D;
                box-sizing: border-box;

                span {
                    font-weight: 400;
                }
            }

            &.on a {
                border: 1px solid $gcolor;
                background: $gcolor;
                color: #FFF;
            }
        }
    }

    // 정렬 관련(최신순, 평점순, 조회순)
    .sorting__wrap {
        margin: 20px 0;
        @extend %font_xs;
        color: #BCBCBC;

        a {
            display: inline-block;
            vertical-align: -2px;
            text-align: center;
            color: #BCBCBC;
        }
        p {margin-top:10px; color:#888; letter-spacing:-.5px; font-size:20px; word-break:keep-all;}
        a.on {
            color: #3D3D3D;
        }
    }

    // 등록 된 게시물이 없는 경우
    .page__list-none {
        display: block;
        text-align: center;
        min-height: 700px;
        height: 700px;

        &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 100%;
        }

        p {
            display: inline-block;
            vertical-align: middle;
            line-height: 1.4;
            padding: 90px 50px;
            background: url("/front_mo/images/icon_list-no.png?ver=1112") no-repeat 50% 0;
            @extend %font_xs;
        }
    }

    // 미니카트
    .options {
        &__modal {
            visibility: hidden;
            position: fixed;
            z-index: 9;
            top: 0;
            left: 50%;
            width: 100%;
            max-width: 640px;
            height: 100%;
            margin-left: -320px;
            background: rgba(0, 0, 0, 0.8);
            opacity: 0;
            transition: visibility 0.35s linear, opacity 0.35s linear;

            &.modal-open {
                visibility: visible;
                opacity: 1;
            }
        }

        &__inner {
            position: fixed;
            z-index: 9;
            bottom: 0;
            left: 50%;
            width: 100%;
            max-width: 640px;
            margin-left: -320px;
            padding-top: 35px;
            transform: translate3d(0, 100%, 0);
            transition: transform 0.35s linear;

            .btn__close__cart {
                position: absolute;
                top: 0;
                left: 50%;
                z-index: 3;
                width: 100px;
                height: 46px;
                margin-left: -50px;
                background: #FFF url("/front_mo/images/ico_cart_close.png") no-repeat 50% 50%;
            }

            .no_data {
                padding: 88px 0 20px !important;
                background-position: 50% 0;
            }
        }

        &__sec {
            position: relative;
            padding: 35px 30px;
            height: 56vh;
            background: #FFF;

            // ul.options__select__list{
            //     height: 51vh;
            // }
        }

        &__choice {
            width: 100%;
            max-width: 580px;

            &__title {
                display: block;
                margin-bottom: 20px;
                @extend %font_s;
                font-weight: 600;
                color: #3D3D3D;
            }
        }
 
        &__box {
            margin-bottom: 15px;

            select {
                width: 100%;
                max-width: 580px;
                height: 70px;
                padding: 0 15px;
                background: url("/front_mo/images/ico_comm_select.png") no-repeat 95% 50%;
                box-sizing: border-box;
                -webkit-appearance: none;
            }
        }

        &__select__list { 
            overflow: hidden;
            overflow-y: auto;
            //max-height: 350px;
            max-height: 48vh;
            padding-bottom: 15px;

            li {
                visibility: hidden;
                margin-top: 15px;
                border: 1px solid #BCBCBC;
                opacity: 0;
                @include prefix(animation, addList 0.35s linear forwards);

                &:first-child {
                    margin-top: 0;
                }

                &.delete {
                    @include prefix(animation, deleteList 0.5s linear forwards);
                }
            }

            .list {
                &__detail {
                    position: relative;
                    padding: 20px;
                }

                &__title {
                    margin-bottom: 20px;
                    padding-right: 50px;
                    height: 54px;
                    @extend %font_s;
                    font-weight: 600;
                    @include line(2);
                }

                &__count {
                    display: block;
                    position: absolute;
                    top: 94px;
                    right: 20px;
                    padding: 0 53px;
                    width: 175px;
                    height: 55px;
                    line-height: 55px;
                    border: 1px solid #BCBCBC;
                    text-align: right;
                    @extend %font_xs;
                    color: #3D3D3D;
                    box-sizing: border-box;
                    z-index: 1;

                    input {
                        width: 100%;
                        height: 53px;
                        padding: 0;
                        border: 0;
                        text-align: center;
                        vertical-align: top;
                        line-height: 53px;
                        color: #3D3D3D;

                    }

                    button {
                        position: absolute;
                        top: -1px;
                        width: 55px;
                        height: 55px;
                        border: 1px solid #BCBCBC;
                        background: #f8f8f8;

                        &:disabled {
                            &:before,&:after{
                                background: #cdcdcd;
                            }
                        }
                    }

                    .btn__count {
                        &-down {
                            left: -1px;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 21px;
                                height: 3px;
                                background: #3D3D3D;
                                transform: translate3d(-50%, -50%, 0);
                            }
                        }

                        &-up {
                            right: -1px;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 21px;
                                height: 3px;
                                background: #3D3D3D;
                                transform: translate3d(-50%, -50%, 0);
                            }

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 3px;
                                height: 21px;
                                background: #3D3D3D;
                                transform: translate3d(-50%, -50%, 0);
                            }
                        }
                    }
                }

                &__price {
                    display: block;
                    position: relative;
                    height: 55px;
                    @extend %font_m;
                    font-weight: 600;

                    &-ori{
                        display: inline-block;
                        font-size: 20px;
                        font-weight: normal;
                        color: $color88;
                    }

                    &-dc{
                        display: block;
                        padding-top: 15px;

                        &-rate{
                            display: inline-block;
                            margin-right: 5px;
                            font-size: 20px;
                            color: #ff8a2f;
                        }
                    }

                    .list__price-ori + .list__price-dc{
                        padding-top: 0;
                    }
                }

                &__text {
                    margin-top: 15px;
                    display: block;
                    font-size: 20px;
                    font-weight: 100;
                    color: $gcolor;
                    text-align: right;

                    .error{
                        position: absolute;
                        bottom: -27px;
                        left: 0;
                        font-size: 19px;
                        color: $colorRD;
                        white-space: nowrap;

                        &:before {
                            display: inline-block;
                            margin-right: 5px;
                            width: 20px;
                            height: 20px;
                            line-height: 18px;
                            font-size: 17px;
                            font-weight: 600;
                            text-align: center;
                            border: 1px solid $colorRD;
                            border-radius: 50%;
                            content: '!';
                        }
                    }
                }

                &__btn {
                    position: absolute;
                    top: 94px;
                    right: 20px;
                    width: 175px;
                    height: 55px;
                    line-height: 53px;
                    @extend %font_xs;
                    background: #f8f8f8;
                    border: 1px solid #BCBCBC;
                    z-index: 1;

                    .ico_notify{
                        transform: scale(0.7);
                    }
                }

                &__delete {
                    display: block;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 26px;
                    height: 26px;
                    padding: 2px;

                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 2px;
                        height: 30px;
                        background: #3d3d3d;
                        transform: rotate(45deg);
                        content: '';
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 2px;
                        height: 30px;
                        background: #3d3d3d;
                        transform: rotate(-45deg);
                        content: '';
                    }
                }
            }
        }

        &__total__price {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 30px;
            width: 640px;
            text-align: right;
            @extend %font_s;
            font-weight: 600;
            background: #fff;
            box-sizing: border-box;
            border-top: 1px solid $colorBC;
           
            strong {
                display: inline-block;
                position: relative;
                z-index: 1;
                vertical-align: -2px;
                margin-left: 10px;
                font-size: 36px;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: -1;
                    bottom: -2px;
                    left: 0;
                    width: 100%;
                    height: 8px;
                    background: $ocolor;
                }
            }

            &-text{
                position: absolute;
                left: 30px;
                top: 40px;
                @extend %font_xs;
                font-weight: normal;
                color: $gcolor;
            }
        }

        &-open {
            transform: translate3d(0, -85px, 0);
        }
    }

    // 모달창 (레이어팝업)
    .hd__popup {
        display: none;
        position: fixed;
        z-index: 9998 !important;
        top: 0;
        left: 0;
        width: 100%;
        // height: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.8);
        box-sizing: border-box;

        &__modal {
            position: absolute;
            z-index: 9;
            top: 50%;
            left: 50%;
            width: 580px;
            background: #FFF;
            transform: translate3d(-50%, -50%, 0);

            & .modal {

                &__sec {
                    position: relative;
                    // height: calc(100% - 85px);
                    // padding-bottom:85px;

                    &.pb {
                        padding-bottom: 85px;
                    }

                    &__title {
                        display: block;
                        height: 84px;
                        padding-left: 28px;
                        border-bottom: 1px solid #BCBCBC;
                        line-height: 84px;
                        @extend %font_m;
                        font-weight: 600;
                    }

                    &__cont {
                        padding: 35px 25px 25px;
                        box-sizing: border-box;

                        &.scroll {
                            overflow: hidden;
                            overflow-y: auto;
                            max-height: 600px;
                            height: 600px;
                            -webkit-overflow-scrolling: touch;
                        }
                    }

                    &__txt {
                        li {
                            margin-top: 20px;
                            @extend %font_xs;
                            line-height: 1.6;

                            &:first-child {
                                margin-top: 0;
                            }

                            strong {
                                display: block;
                                @extend %font_s;
                                font-weight: 600;
                            }
                        }
                    }

                    &__txt {
                        font-size: 22px;
                        line-height: 32px;
                    }

                    &__desc {
                        position: relative;
                        margin-top: 10px;
                        padding-left: 15px;
                        line-height: 1.4;
                        @extend %font_xs;
                        word-break: keep-all;

                        &:before {
                            content: '-';
                            position: absolute;
                            top: -2px;
                            left: 0;
                        }

                        &:first-child {
                            margin-top: 0;
                        }

                        .safe-number {
                            color: $gcolor;
                        }
                    }
                }
            }

            .btn__check {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 84px;
                border-top: 1px solid #BCBCBC;
                line-height: 84px;
                text-align: center;
                @extend %font_m;
                font-weight: 600;
                color: #3D3D3D;
            }

            .btn__close-x {
                position: absolute;
                z-index: 2;
                top: 23px;
                right: 26px;
                width: 30px;
                height: 30px;
                padding: 5px;

                &:before {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 3px;
                    height: 40px;
                    background: #3d3d3d;
                    transform: rotate(45deg);
                    content: '';
                }

                &:after {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 3px;
                    height: 40px;
                    background: #3d3d3d;
                    transform: rotate(-45deg);
                    content: '';
                }
            }
        }

        &.popup__modal-open {
            display: block;
        }

        &-v2 {
            display: none;
            position: absolute;
            // position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            // height: 100% !important;
            height: 100vh !important;
            min-height: -webkit-fill-available;
            background: #fff;

            &__inner {
                // position: relative;
                // height: 100%;
                height: 100vh;
                padding: 70px 30px 150px;
                overflow-y: auto;
                box-sizing: border-box;

                .btn-search {
                    padding-bottom: 200px;
                }
            }

            & .modal {
                &__sec {
                    position: relative;
                    height: calc(100% - 85px);
                    padding-bottom: 85px;

                    &__title {
                        display: block;
                        height: 84px;
                        padding-left: 28px;
                        border-bottom: 1px solid #BCBCBC;
                        line-height: 84px;
                        @extend %font_m;
                        font-weight: 600;
                    }

                    &__cont {
                        overflow-y: scroll;
                        max-height: 640px;
                        padding: 35px 25px 25px;
                    }

                    &__txt {
                        li {
                            margin-top: 20px;
                            @extend %font_xs;
                            line-height: 1.6;

                            &:first-child {
                                margin-top: 0;
                            }

                            strong {
                                display: block;
                                @extend %font_s;
                                font-weight: 600;
                            }
                        }
                    }

                    &__desc {
                        padding-left: 10px;
                        line-height: 1.4;
                        @extend %font_xs;
                        text-indent: -10px;
                    }
                }
            }

            &__head {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                border-bottom: 1px solid #BCBCBC;
            }

            &__title {
                padding: 19px 30px;
                font-size: 28px;
                font-weight: bold;
                background: #fff;
            }

            &__btn-close {
                position: absolute;
                z-index: 2;
                top: 50%;
                right: 30px;
                width: 30px;
                height: 30px;
                padding: 5px;
                transform: translate3d(0, -50%, 0);

                &:before {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 3px;
                    height: 40px;
                    background: #3d3d3d;
                    transform: rotate(45deg);
                    content: '';
                }

                &:after {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 3px;
                    height: 40px;
                    background: #3d3d3d;
                    transform: rotate(-45deg);
                    content: '';
                }

            }

            &__cont {
                width: 100%;

                .contents {
                    padding-top: 30px;
                    font-size: 20px;
                }
            }

            &--show {
                display: block;
            }

            .full-popup {
                padding-top: 70px;

                .hd__popup-v2__head {
                    top: 0;
                    left: 0;
                    z-index: 2;
                    width: 100%;
                    height: 69px;
                    border-bottom: 1px solid #BCBCBC;
                    line-height: 69px;
                }

                .hd__popup-v2__title {
                    float: none;
                    padding: 0 0 0 30px;
                    background: #FFF;
                }

                .hd__popup-v2__btn-close {
                    top: 50%;
                    right: 30px;
                    transform: translate3d(0, -50%, 0);
                }

                .hd__popup-v2__cont {
                    margin-top: 0;
                }
            }
        }

        &.popup_reviewList {
            .reviewList {
                &__name {
                    display: block;
                    padding-bottom: 20px;
                    border-bottom: 1px solid #BCBCBC;
                    font: {
                        size: 24px;
                        weight: 600;
                    }
                }

                &__box {
                    &__list {
                        border-top: 1px solid #BCBCBC;

                        &:first-child {
                            border-top: 0 solid #000;
                        }
                    }
                }

                &__inner {
                    position: relative;
                    padding: 20px 0;
                }

                &__img {
                    overflow: hidden;
                    position: absolute;
                    top: 20px;
                    left: 0;
                    width: 120px;
                    height: 120px;
                }

                &__cont {
                    min-height: 120px;
                    margin: 0 0 20px 140px;
                    font-size: 22px;
                    line-height: 32px;

                    &__name {
                        display: block;
                        margin-bottom: 5px;
                        font-weight: 600;
                        @include line(2);
                    }
                }

                .product__gift {
                    position: relative;
                    margin-top: 15px;
                    padding-left: 60px;
        
                    figure {
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 50px;
                        height: 45px;
                    }
        
                    &-name {
                        padding-top: 4px;
                        font-size: 22px;
                        line-height: 32px;
                    }
                }
            }
        }

        &.popup_businessInfo {
            background: #f6f6f6;
            box-sizing: border-box;

            .hd__popup-v2__inner {
                padding: 70px 0 150px;

                .wrap-iframe {
                    width: 100%;
                    height: 100%;

                    iframe {
                        padding-bottom: 70px;
                    }
                }
            }
        }

        &.popup_greatingPayCard,
        &.popup_greatingPayPw {
            height: 100vh !important;
        }
    }

    // ***** form 스타일 ***** //
    // input 스타일
    .form__input {
        width: 100%;
        max-width: 580px;
        padding: 25px 20px;
        border: 1px solid #BEBEBE;
        @extend %font_s;
        font-weight: 400;
        color: #3D3D3D;
        box-sizing: border-box;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .form__input::-webkit-input-placeholder {
        color: #BDBDBD;
    }

    // ***** 버튼 ***** //
    // 버튼 sec
    &__btn-sec {
        display: flex;
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 50%;
        width: 100%;
        max-width: 640px;
        font-size: 0;
        transform: translate3d(-50%, 0, 0);
        -webkit-transform: translate3d(-50%, 0, 0);

        &__cock {
            width: calc(100% - 85px);
            padding-left: 85px;

            &.col3{
                display: flex;
                padding-left: 0;
                width: 100%;
            }

        }

        .btn__notify{
            position: relative !important;
            width: 100px !important;
        }
    }
    
    &__btn-sec2 {
        .hd__btn-sec__cock {
            width:100%; padding:0;
            .btn__default.orange{display:block; width:100%;}
        }
    }

    // 버튼
    .btn {
        // 기본버튼
        &__default {
            position: relative;
            display: block;
            width: 100%;
            height: 85px;
            text-align: center;
            background: #A8A8A8;
            @extend %font_m;
            font-weight: 600;
            color: #FFF;
            line-height: 85px;
            transform:translateZ(0) rotate(.001deg) perspective(500px);
            -webkit-transform: translate3d(0, 0, 0);

            .count{
                &-sec {
                    font-weight: 400;
                }

                &-wrap{
                    padding: 0 30px;
                    text-align: left;
                    overflow: hidden;

                    .count{
                        &-sec{

                        }

                        &-box{
                            margin-right: 20px;
                            padding: 2px 20px 4px;
                            @extend %font_xs;
                            color: $color3D;
                            border-radius: 30px;
                            background: #fff;
                        }

                        &-text{
                            float: right;
                        }
                    }
                }
            }

            // 다음 페이지가 있는 버튼
            &.next {
                > span {
                    padding-right: 50px;
                    background: url("/front_mo/images/icon_common_next-page.png") no-repeat 100% 50%;
                }
            }

             + .btn__default.next{
                margin-left: -1px;
                border-left: 1px solid #fff;
             }

            &.active + .btn__default.next{border-left: 0;}

            // 버튼 활성화 시
            &.active {
                background: $gcolor;
                color: #FFF;

                .count-wrap{
                    .count-box{
                        color: $gcolor;
                    }
                }
            }

            &.next.active{
                .count-wrap .count-text{
                    padding-right: 50px;
                    background: url("/front_mo/images/icon_common_next-page.png") no-repeat 100% 50%;
                }
            }

            // 주황색 버튼
            &.orange {
                display: inline-block;
                width: 100%;
                height: 85px;
                background: #fff;
                border: 1px solid $gcolor;
                text-align: center;
                @extend %font_m;
                font-weight: 600;
                color: $gcolor;
                line-height: 85px;
                box-sizing: border-box;
            }

            // 초록색 버튼
            &.green {
                display: inline-block;
                width: 100%;
                height: 85px;
                background: $gcolor;
                text-align: center;
                @extend %font_m;
                font-weight: 600;
                color: #FFF;
                line-height: 85px;
            }

            // 흰색, 검정라인
            &.white {
                display: inline-block;
                width: 100%;
                height: 85px;
                background: #fff;
                text-align: center;
                @extend %font_m;
                font-weight: 600;
                color: #000;
                line-height: 82px;
                border: 1px solid #000;
                box-sizing: border-box;
                vertical-align: bottom;
            }

            // 인풋(체크박스, 라디오) 버튼
            &.input {
                display: block;
                box-sizing: border-box;
                width: 220px;
                height: 70px;
                text-align: center;
                border: 1px solid #BFBFBF;
                background: #F4F4F4;
                @extend %font_s;
                font-weight: 600;
                color: #3D3D3D;
                line-height: 68px;

                &.active {
                    border: 1px solid $gcolor;
                    background: $gcolor;
                    color: #FFF;
                }
            }

            &.gift{
                position: relative;
                display: inline-block;
                color: #fff;
                border: 0;
                background: #e5b83f;
                box-sizing: border-box;

                &:before{
                    display: inline-block;
                    width: 27px;
                    height: 29px;
                    background: url('/front_mo/images/sp_ico.png?ver=210309') no-repeat -185px -73px;
                    background-size: 640px;
                    vertical-align: middle;
                    content: '';
                    transform:rotate(.001deg);
                    -webkit-transform: translate3d(0, 0, 0);
                }
            }
        }

        // 더 보기 더튼
        &__more-view {
            display: block;
            width: 100%;
            height: 68px;
            margin-top: 40px;
            border: 1px solid #BFBFBF;
            background: #F4F4F4;
            text-align: center;
            line-height: 68px;
            @extend %font_xs;
            color: #3D3D3D;

            span {
                padding-right: 35px;
                background: url("/front_mo/images/btn_comm_more.png") no-repeat 100% 50%;
            }
        }

        // 상품문의 작성하기 버튼
        &__write {
            display: block;
            height: 70px;
            background: $gcolor;
            text-align: center;
            line-height: 70px;
            @extend %font_s;
            font-weight: 700;
            color: #FFF;
        }

        &__heart__cock {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 85px;
            height: 85px;
            background: #fff;
            border: 1px solid #BCBCBC;

            span {
                &:after {
                    content: "";
                    display: block;
                    width: 33px;
                    height: 30px;
                    margin: 0 auto;
                    background: url("/front_mo/images/btn_heart-off.png") no-repeat 0 0;
                }
            }

            &.active span:after {
                background: url("/front_mo/images/btn_heart-on.png") no-repeat 0 0;
            }

        }

        &__notify {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 85px;
            height: 85px;
            background: #fff;
            border: 1px solid #BCBCBC;

            span {
                &:after {
                    content: "";
                    display: block;
                    width: 32px;
                    height: 40px;
                    margin: 0 auto;
                    background: url("/front_mo/images/sp_ico.png") no-repeat -61px -34px;
                }
            }

            &.active span:after {
            }
            
        }
    }

    // 버튼 스타일 (흰색, 초록색)
    &__btn {

        &__sec {
            text-align: center;
            font-size: 0;

            li {
                display: inline-block;
                vertical-align: top;
                width: 50%;

                a {
                    display: block;
                    width: 100%;
                    height: 70px;
                    line-height: 68px;
                    font: {
                        size: 20px;
                        weight: 600;
                    }
                    box-sizing: border-box;
                }
            }

            .color {
                &-off a {

                    border: 1px solid #BFBFBF;
                    background: #F4F4F4;
                    color: #3D3D3D;
                }

                &-on a {
                    border: 1px solid $gcolor;
                    background: $gcolor;
                    color: #FFF;
                }
            }
        }
    }

    .greatingPay__pwd .input_type.input_six input[type=number] {
        -webkit-text-security:disc;
    }

}

.cartHidden{
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overscroll-behavior: none;
    isolation: isolate;
}


// keyframes 작성
@include keyframe(addList) {
    0% {
        visibility: hidden;
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
    }
}

@include keyframe(deleteList) {
    0% {
        visibility: visible;
        opacity: 1;
    }

    100% {
        visibility: hidden;
        opacity: 0;
    }
}

.hd {
    .deposit,
    .safety-number {
        padding-bottom: 60px !important;
    }

    .popup_orderDeadline {
        .btn__default {
            max-width: 530px;
            margin: 0 auto 45px;
        }
    }

    .order-deadline {
        &__sec {
            margin-top: 35px;
            border-top: 1px solid #BCBCBC;
        }

        &__list {
            border-bottom: 1px solid #BCBCBC;
            padding: 25px 0;
        }

        &__delivery {
            display: block;
            margin-bottom: 25px;
            @extend %font_s;
            font-weight: 600;
        }

        &__menu {
            li {
                line-height: 36px;

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }

                strong {
                    float: left;
                    @extend %font_xs;
                    font-weight: 400;
                }

                span {
                    float: right;
                    @extend %font_xs;
                    font-weight: 600;
                }
            }
        }
    }

    .delivery-list {
        padding: 0 25px !important;

        &__list {
            position: relative;
            padding: 30px 0 45px;
            border-bottom: 1px solid #BCBCBC;
        }

        &__user {
            padding-right: 200px;
            line-height: 38px;
            @extend %font_xs;

            &-name {
                display: block;
                font-weight: 600;
                span {
                    font-weight: 400;
                }
            }

            &-addr {
                display: block;
                margin-top: 0.5rem;
                line-height: 1.5;
                font-weight: 600;
                letter-spacing: -0.05rem;
            }

            &-phone {
                display: block;
                font-weight: 400;
            }

            .default {
                @extend %font_xs;
                font-weight: 600;
                color: $gcolor;
            }
        }

        .btn__default {
            position: absolute;
            top: 75px;
            right: 0;
            width: 110px;
            height: 55px;
            text-align: center;
            line-height: 55px;
        }

        &__notice {
            position: relative;
            margin: 30px 0 60px;
            padding: 0 25px;

            p {
                line-height: 32px;
                @extend %font_xs;

                em {
                    font-weight: 600;
                }
            }

            .btn__address__admin {
                position: absolute;
                top: 50%;
                right: 25px;
                width: 150px;
                height: 55px;
                border: 1px solid #C1C1C1;
                text-align: center;
                line-height: 55px;
                @extend %font_xs;
                transform: translate3d(0, -50%, 0);
                -webkit-transform: translate3d(0, -50%, 0);
            }
        }

        .Badge {
            padding: 0 5px;
            background: $color3D;
            font-size: 20px;
            color: #fff;
            font-style: normal;
            font-weight: normal;
            word-break: keep-all;
        }
    }

    .coupon-use { 
        &__notice {
            @extend %font_xs;
        }

        &__sec {
            margin-top: 40px;

            &__title {
                position: relative;
                display: block;
                padding-bottom: 15px;
                margin-bottom: 25px;
                border-bottom: 3px solid $gcolor;
                @extend %font_s;
                font-weight: 600;

                span{
                    @extend %font_xs;
                    font-weight: normal;
                    color: $color88;
                }

                &-checkbox{
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 20px;

                    .checkbox__input:checked + .checkbox__label{
                        color: $gcolor;
                    }

                    .checkbox__label:before{
                        width: 25px;
                        height: 25px;
                        background-size: 70% !important;
                        margin-right: 5px;
                    }
                }
            }
        }

        &__list {
            li {
                padding-bottom: 25px;
                border-bottom: 1px solid #BCBCBC;
            }

            &__detail {
                position: relative;
                margin-top: 25px;
            }

            &__txt {
                display: block;
                padding-right: 125px;
                @extend %font_xs;
                font-weight: 600;
                line-height: 32px;
                @include line(2);
            }

            &__price {
                position: absolute;
                top: 0;
                right: 0;
                @extend %font_xs;
                line-height: 32px;
            }

            &__select {
                width: 100%;
                max-width: 580px;
                height: 70px;
                margin: 25px 0;
                padding: 0 15px;
                border: 1px solid #BDBDBD;
                background: url("/front_mo/images/ico_comm_select.png") no-repeat 95% 50%;
                @extend %font_s;
                color: #3D3D3D;
                box-sizing: border-box;
                -webkit-appearance: none;
            }

            &__discount {
                text-align: right;
                @extend %font_xs;
                font-weight: 600;
            }

            // type2
            &.type2{
                .coupon-use__list__detail{
                    position: relative;
                    padding-left: 170px;
                    line-height: 1.5;

                    .coupon-use__list__left{
                        position: absolute;
                        left: 0;
                        top: 5px;
                        width: 150px;

                        span{
                            display: block;
                            text-align: center;
                        }

                        &-title{
                            font-size: 20px;
                            font-weight: 600;
                            white-space: nowrap;
                        }

                        &-price{
                            font-size: 19px;
                            color: $color88;
                        }
                    }

                    .coupon-use__list__right{
                        &-option{
                            margin-bottom: 5px;
                            font-size: 20px;
                        }

                        .accordion_box{
                            position: relative; 
                            margin: 0;

                            &.on .coupon-use__list__select{
                                border-color: $gcolor;
                                background-image: url('/front_mo/images/icon_arrow_big.png');
                            }
                        }

                        .coupon-use__list__select{
                            margin: 0;
                            padding-right: 55px;
                            font-size: 20px !important;
                            height: 65px;
                            line-height: 63px;
                            @include line(1);

                            &-list{
                                position: absolute; 
                                left: 0;
                                top: 65px;
                                width: 100%;
                                max-height: 203px;
                                overflow-y: auto;
                                background-color: #fff;
                                border: {
                                    left: 1px solid $colorBC;
                                    right: 1px solid $colorBC;
                                    bottom: 1px solid $colorBC;
                                };
                                z-index: 1;
                                box-sizing: border-box;

                                ul{
                                    li{
                                        padding: 15px 10px;
                                        font-size: 20px;
                                        @include line(1);
                                    }
                                }
                            }


                            &-guide{
                                margin-top: 10px;
                                font-size: 19px;
                                color: $color88;

                                &.green{
                                    color: $gcolor;
                                }
                            }

                            + .coupon-use__list__right-option{
                                margin-top: 15px;
                            }
                        }
                    }
                }

                .coupon-use__list__discount{
                    margin-top: 25px;
                }
            }
        }

        &__number{
            padding-bottom: 15px;
            text-align: center;

            &-text{
                display: inline-block;
                font-size: 20px;

                &:not(:first-child){
                    margin-left: 15px;

                    &:before{
                        display: inline-block;
                        margin-right: 15px;
                        width: 2px;
                        height: 15px;
                        background-color: $colorBC;
                        vertical-align: baseline;
                        content: '';
                    }
                }
            }
        }

        &__discount-total {
            margin-top: 55px;
            text-align: right;
            @extend %font_s;
            font-weight: 600;

            em {
                display: inline-block;
                position: relative;
                margin-left: 10px;
                @extend %font_xl;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: -1;
                    bottom: -2px;
                    left: 0;
                    width: 100%;
                    height: 8px;
                    background: $ocolor;
                }
            }
        }

        &__btn-sec {
            max-width: 528px;
            margin: 0 auto;
            padding: 30px 0;
            text-align: center;
            font-size: 0;

            a {
                display: inline-block;
                width: 50%;
                height: 70px;
                vertical-align: top;
                line-height: 70px;
                @extend %font_s;
                font-weight: 600;
                box-sizing: border-box;
            }

            .coupon-use__discount-total{
                margin-top: 0 !important;
                padding: 0 0 20px;
            }
        }

        &__cancel {
            border: 1px solid #BFBFBF;
            color: #3D3D3D;
        }

        &__using {
            background: $gcolor;
            color: #FFF;
        }

        .normal-product {
            margin-top: 5px;

            &__opts {
                display: inline-block;
                vertical-align: middle;
                font-size: 20px;
            }

            &__count {
                display: inline-block;
                vertical-align: middle;
                font-size: 20px;
            }
        }
    }

    // 에러페이지
    &__error {
        position: fixed;
        top: 50%;
        left: 0;
        width: 100%;
        text-align: center;
        transform: translate3d(0, -50%, 0);
        -webkit-transform: translate3d(0, -50%, 0);

        &__title {
            margin-top: 35px;
            font-size: 30px;
            font-weight: 600;
        }

        &__txt {
            margin-top: 30px;
            line-height: 32px;
            font-size: 22px;
        }

        .btn-area {
            width: 460px;
            margin: 60px auto 0;
            font-size: 0;

            a {
                display: inline-block;
                float: none;
                width: 50%;
                vertical-align: top;
                font-size: 24px;
                font-weight: 600;
            }
        }
    }

    .detailViewer {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 380px;

        img {
            max-width: 100%;
        }
    }

    .alertBox {
        margin-top: 10px;
        @extend %font_xs;

        &.fail {
            color: $colorRD;
        }

        &.success {
            color: $gcolor;
        }
    }
}

.wdw-type {
    
    .hd {

        // ***** 레이아웃 ***** //
        // 게시판
        .board {
            &__list {
                &:after{
                    top: rem(55px);
                    width: rem(30px);
                    height: rem(20px);
                }
            }

            &__inner {
                &__review {
                    padding-top: rem(25px);

                    .review {
                        &__div {
                            margin-right: rem(5px);
                        }

                        &__title {
                            .best {
                                padding: rem(0 8px);
                                height: rem(30px);
                                vertical-align: rem(2px);
                                line-height: rem(30px);
                                font: {
                                    size: rem(18px);
                                    weight: 600;
                                }
                            }
                        }

                        &__info {
                            margin: rem(20px 0 25px);
                            font-size: rem(20px);

                            span:last-child {
                                margin-left: rem(15px);
                            }
                        }

                        .answer__wait {
                            left: rem(330px);
                            top: rem(73px);
                        }
                    }
                }

                &__detail {
                    padding: rem(40px 15px 45px);
                }
            }
        }

        .board__inner__detail.detail__reply {
            border-top: rem(1px) solid #C0C0C0;

            .detail__cont {
                position: relative;
                margin-top: 0;
                padding-left: rem(35px);

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: rem(18px);
                    height: rem(18px);
                    border-bottom: rem(3px) solid $gcolor;
                    border-left: rem(3px) solid $gcolor;
                    box-sizing: border-box;
                }
            }

            .detail__info {
                margin-top: rem(10px);
                padding-left: rem(35px);
            }
        }

        .detail {
            &__icon {
                padding: rem(0 15px);
                height: rem(36px);
                margin-bottom: rem(15px);
                line-height: rem(36px);
                font-size: rem(20px);
            }

            &__options {
                display: block;
                margin-bottom: rem(20px);
                font-size: rem(22px);
                font-weight: 400;
            }

            &__info {
                span:last-child {
                    margin-left: rem(15px);
                }
            }

            &__cont {
                margin-top: rem(20px);
                height: auto;
                font: {
                    size: rem(23px);
                }
                word-break: normal;
                word-wrap: break-word;
            }

            &__img {
                margin-top: rem(35px);

                img {
                    display: block;
                    margin-top: rem(20px);
                    font-size: 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        // 탭 메뉴
        .tab__style {
            text-align: center;
            font-size: 0;

            li {
                display: inline-block;
                vertical-align: top;
                width: rem(290px);

                a {
                    display: block;
                    width: 100%;
                    height: rem(70px);
                    border: 1px solid #BFBFBF;
                    background: #F4F4F4;
                    line-height: rem(68px);
                    @extend %font_xs;
                    font-weight: 600;
                    color: #3D3D3D;
                    box-sizing: border-box;

                    span {
                        font-weight: 400;
                    }
                }

                &.on a {
                    border: rem(1px) solid $gcolor;
                    background: $gcolor;
                    color: #FFF;
                }
            }
        }

        // 정렬 관련(최신순, 평점순, 조회순)
        .sorting__wrap {
            margin: rem(20px 0);
            @extend %font_xs;
            color: #BCBCBC;

            a {
                display: inline-block;
                padding: rem(0 8px);
                vertical-align: rem(-2px);
                text-align: center;
                color: #BCBCBC;
            }
            a.on {
                color: #3D3D3D;
            }
        }

        // 등록 된 게시물이 없는 경우
        .page__list-none {
            display: block;
            text-align: center;
            min-height: rem(700px);
            height: rem(700px);

            &:after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 0;
                height: 100%;
            }

            p {
                display: inline-block;
                vertical-align: middle;
                padding: rem(90px 50px);
                background: url("/front_mo/images/icon_list-no.png?ver=1112") no-repeat 50% 0;
                background-size: rem(68px 68px);
                @extend %font_xs;
            }
        }

        // 미니카트
        .options {
            &__modal {
                visibility: hidden;
                position: fixed;
                z-index: 7;
                top: 0;
                left: 50%;
                width: 100%;
                max-width: rem(640px);
                height: 100%;
                margin-left: rem(-320px);
                background: rgba(0, 0, 0, 0.8);
                opacity: 0;
                transition: visibility 0.35s linear, opacity 0.35s linear;

                &.modal-open {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &__inner {
                position: fixed;
                z-index: 7;
                bottom: 0;
                left: 50%;
                width: 100%;
                max-width: rem(640px);
                margin-left: rem(-320px);
                padding-top: rem(35px);
                transform: translate3d(0, 100%, 0);
                transition: transform 0.35s linear;

                .btn__close__cart {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    z-index: 3;
                    width: rem(100px);
                    height: rem(46px);
                    margin-left: rem(-50px);
                    background: #FFF url("/front_mo/images/ico_cart_close.png") no-repeat 50% 50%;
                }

                .no_data {
                    padding: rem(88px 0 20px) !important;
                    background-position: 50% 0;
                }
            }

            &__sec {
                position: relative;
                background: #FFF;
                padding: rem(35px 30px);
            }

            &__choice {
                width: 100%;
                max-width: rem(580px);

                &__title {
                    display: block;
                    margin-bottom: rem(20px);
                    @extend %font_s;
                    font-weight: 600;
                    color: #3D3D3D;
                }
            }

            &__box {
                margin-top: rem(15px);

                select {
                    width: 100%;
                    max-width: rem(580px);
                    height: rem(70px);
                    padding: rem(0 15px);
                    background: url("/front_mo/images/ico_comm_select.png") no-repeat 95% 50%;
                    background-size: rem(31px 18px);
                    box-sizing: border-box;
                    -webkit-appearance: none;
                }
            }

            &__select__list {
                overflow: hidden;
                overflow-y: auto;
                max-height: rem(350px);
                margin-top: rem(15px);

                > li {
                    visibility: hidden;
                    margin-top: rem(15px);
                    border: rem(1px) solid #BCBCBC;
                    opacity: 0;
                    @include prefix(animation, addList 0.35s linear forwards);

                    &:first-child {
                        margin-top: 0;
                    }

                    &.delete {
                        @include prefix(animation, deleteList 0.5s linear forwards);
                    }
                }

                .list {
                    &__detail {
                        position: relative;
                        padding: rem(20px);
                    }

                    &__title {
                        display: block;
                        margin-bottom: rem(20px);
                        @extend %font_s;
                        font-weight: 600;
                    }

                    &__count {
                        display: block;
                        position: relative;
                        width: rem(190px);
                        height: rem(55px);
                        padding: rem(0 53px);
                        border: rem(1px) solid #BCBCBC;
                        text-align: center;
                        line-height: rem(55px);
                        @extend %font_xs;
                        color: #3D3D3D;
                        box-sizing: border-box;

                        input {
                            width: 100%;
                            height: rem(53px);
                            padding: 0;
                            border: 0;
                            text-align: center;
                            vertical-align: top;
                            line-height: rem(53px);
                            color: #3D3D3D;

                        }

                        button {
                            position: absolute;
                            top: rem(-1px);
                            width: rem(55px);
                            height: rem(55px);
                            border: rem(1px) solid #BCBCBC;
                            background: #F5F5F5;
                        }

                        .btn__count {
                            &-down {
                                left: rem(-1px);

                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: rem(21px);
                                    height: rem(3px);
                                    background: #3D3D3D;
                                    transform: translate3d(-50%, -50%, 0);
                                }
                            }

                            &-up {
                                right: rem(-1px);

                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: rem(21px);
                                    height: rem(3px);
                                    background: #3D3D3D;
                                    transform: translate3d(-50%, -50%, 0);
                                }

                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: rem(3px);
                                    height: rem(21px);
                                    background: #3D3D3D;
                                    transform: translate3d(-50%, -50%, 0);
                                }
                            }
                        }
                    }

                    &__price {
                        position: absolute;
                        bottom: rem(35px);
                        right: rem(20px);
                        @extend %font_m;
                        font-weight: 600;
                    }

                    &__delete {
                        display: block;
                        position: absolute;
                        top: rem(20px);
                        right: rem(20px);
                        width: rem(26px);
                        height: rem(26px);
                        padding: rem(2px);

                        &:before {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            width: rem(2px);
                            height: rem(30px);
                            background: #3d3d3d;
                            transform: rotate(45deg);
                            content: '';
                        }

                        &:after {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            width: rem(2px);
                            height: rem(30px);
                            background: #3d3d3d;
                            transform: rotate(-45deg);
                            content: '';
                        }
                    }
                }
            }

            &__total__price {
                margin-top: rem(25px);
                text-align: right;
                @extend %font_s;
                font-weight: 600;

                strong {
                    display: inline-block;
                    position: relative;
                    z-index: 1;
                    vertical-align: rem(-2px);
                    margin-left: rem(10px);
                    font-size: rem(36px);

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        z-index: -1;
                        bottom: rem(-2px);
                        left: 0;
                        width: 100%;
                        height: rem(8px);
                        background: $ocolor;
                    }
                }
            }

            &-open {
                transform: translate3d(0, -85px, 0);
            }
        }

        // 모달창 (레이어팝업)
        .hd__popup {
            display: none;
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.8);

            &__modal {
                position: absolute;
                z-index: 9999;
                top: 50%;
                left: 50%;
                width: rem(580px);
                background: #FFF;
                transform: translate3d(-50%, -50%, 0);

                & .modal {

                    &__sec {
                        position: relative;
                        // height: calc(100% - 4.25rem);
                        // padding-bottom:85px;

                        &.pb {
                            padding-bottom: rem(85px);
                        }

                        &__title {
                            display: block;
                            height: rem(84px);
                            padding-left: rem(28px);
                            border-bottom: rem(1px) solid #BCBCBC;
                            line-height: rem(84px);
                            @extend %font_m;
                            font-weight: 600;
                        }

                        &__cont {
                            padding: rem(35px 25px 25px);
                            box-sizing: border-box;

                            &.scroll {
                                overflow: hidden;
                                overflow-y: auto;
                                max-height: rem(600px);
                                height: rem(600px);
                                -webkit-overflow-scrolling: touch;
                            }
                        }

                        &__txt {
                            li {
                                margin-top: rem(20px);
                                @extend %font_xs;
                                line-height: 1.6;

                                &:first-child {
                                    margin-top: 0;
                                }

                                strong {
                                    display: block;
                                    @extend %font_s;
                                    font-weight: 600;
                                }
                            }
                        }

                        &__txt {
                            font-size: rem(22px);
                            line-height: rem(32px);
                        }

                        &__desc {
                            position: relative;
                            margin-top: rem(10px);
                            padding-left: rem(15px);
                            line-height: 1.4;
                            @extend %font_xs;
                            word-break: keep-all;

                            &:before {
                                content: '-';
                                position: absolute;
                                top: rem(-2px);
                                left: 0;
                            }

                            &:first-child {
                                margin-top: 0;
                            }

                            .safe-number {
                                color: $gcolor;
                            }
                        }
                    }
                }

                .btn__check {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: rem(84px);
                    border-top: rem(1px) solid #BCBCBC;
                    line-height: rem(84px);
                    text-align: center;
                    @extend %font_m;
                    font-weight: 600;
                    color: #3D3D3D;
                }

                .btn__close-x {
                    position: absolute;
                    z-index: 2;
                    top: rem(23px);
                    right: rem(26px);
                    width: rem(30px);
                    height: rem(30px);
                    padding: rem(5px);

                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: rem(3px);
                        height: rem(40px);
                        background: #3d3d3d;
                        transform: rotate(45deg);
                        content: '';
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: rem(3px);
                        height: rem(40px);
                        background: #3d3d3d;
                        transform: rotate(-45deg);
                        content: '';
                    }
                }
            }

            &.popup__modal-open {
                display: block;
            }

            &-v2 {
                display: none;
                position: fixed;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                width: 100%;
                // height: 100% !important;
                height: 100vh !important;
                height: -webkit-fill-available;
                background: #fff;

                &__inner {
                    // position: relative;
                    // height: 100%;
                    height: 100vh;
                    padding: rem(70px 30px 150px);
                    overflow-y: auto;
                    box-sizing: border-box;
                }

                & .modal {
                    &__sec {
                        position: relative;
                        height: calc(100% - 4.25rem);
                        padding-bottom: rem(85px);

                        &__title {
                            display: block;
                            height: rem(84px);
                            padding-left: rem(28px);
                            border-bottom: 1px solid #BCBCBC;
                            line-height: rem(84px);
                            @extend %font_m;
                            font-weight: 600;
                        }

                        &__cont {
                            overflow-y: scroll;
                            max-height: rem(640px);
                            padding: rem(35px 25px 25px);
                        }

                        &__txt {
                            li {
                                margin-top: rem(20px);
                                @extend %font_xs;
                                line-height: 1.6;

                                &:first-child {
                                    margin-top: 0;
                                }

                                strong {
                                    display: block;
                                    @extend %font_s;
                                    font-weight: 600;
                                }
                            }
                        }

                        &__desc {
                            padding-left: rem(10px);
                            line-height: 1.4;
                            @extend %font_xs;
                            text-indent: rem(-10px);
                        }
                    }
                }

                &__head {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    width: 100%;
                    border-bottom: rem(1px) solid #BCBCBC;
                }

                &__title {
                    padding: rem(19px 30px);
                    font-size: rem(28px);
                    font-weight: bold;
                }

                &__btn-close {
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    right:rem( 30px);
                    width: rem(30px);
                    height: rem(30px);
                    padding: rem(5px);
                    transform: translate3d(0, -50%, 0);

                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: rem(3px);
                        height: rem(40px);
                        background: #3d3d3d;
                        transform: rotate(45deg);
                        content: '';
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: rem(3px);
                        height: rem(40px);
                        background: #3d3d3d;
                        transform: rotate(-45deg);
                        content: '';
                    }

                }

                &__cont {
                    width: 100%;

                    .contents {
                        padding-top: rem(30px);
                        font-size: rem(20px);
                    }

                    // #iForm {
                    //     margin-bottom: rem(150px);
                    // }
                }

                &--show {
                    display: block;
                }

                .full-popup {
                    padding-top: rem(70px);

                    .hd__popup-v2__head {
                        top: 0;
                        left: 0;
                        z-index: 2;
                        width: 100%;
                        height: rem(69px);
                        border-bottom: rem(1px) solid #BCBCBC;
                        line-height: rem(69px);
                    }

                    .hd__popup-v2__title {
                        float: none;
                        padding: rem(0 0 0 30px);
                        background: #FFF;
                    }

                    .hd__popup-v2__btn-close {
                        top: 50%;
                        right: rem(30px);
                        transform: translate3d(0, -50%, 0);
                    }

                    .hd__popup-v2__cont {
                        margin-top: 0;
                    }
                }
            }

            &.popup_reviewList {
                .reviewList {
                    &__name {
                        display: block;
                        padding-bottom: rem(20px);
                        border-bottom: rem(1px) solid #BCBCBC;
                        font: {
                            size: rem(24px);
                            weight: 600;
                        }
                    }

                    &__box {
                        &__list {
                            border-top: rem(1px) solid #BCBCBC;

                            &:first-child {
                                border-top: 0 solid #000;
                            }
                        }
                    }

                    &__inner {
                        position: relative;
                        padding: rem(20px 0);
                    }

                    &__img {
                        overflow: hidden;
                        position: absolute;
                        top: rem(20px);
                        left: 0;
                        width: rem(120px);
                        height: rem(120px);
                    }

                    &__cont {
                        min-height: rem(120px);
                        margin: rem(0 0 20px 140px);
                        font-size: rem(22px);
                        line-height: rem(32px);

                        &__name {
                            display: block;
                            margin-bottom: rem(5px);
                            font-weight: 600;
                            @include line(2);
                        }
                    }

                    .product__gift {
                        position: relative;
                        margin-top: rem(15px);
                        padding-left: rem(60px);
            
                        figure {
                            overflow: hidden;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: rem(50px);
                            height: rem(45px);
                        }
            
                        &-name {
                            padding-top: rem(4px);
                            font-size: rem(22px);
                            line-height: rem(32px);
                        }
                    }
                }
            }
        }

        // ***** form 스타일 ***** //
        // input 스타일
        .form__input {
            width: 100%;
            max-width: rem(580px);
            padding: rem(25px 15px);
            border: rem(1px) solid #BEBEBE;
            @extend %font_s;
            font-weight: 400;
            color: #3D3D3D;
            box-sizing: border-box;
            border-radius: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }

        .form__input::-webkit-input-placeholder {
            color: #BDBDBD;
        }

        // ***** 버튼 ***** //
        // 버튼 sec
        &__btn-sec {
            position: fixed;
            z-index: 8;
            bottom: 0;
            left: 50%;
            width: 100%;
            max-width: rem(640px);
            font-size: 0;
            transform: translate3d(-50%, 0, 0);
            -webkit-transform: translate3d(-50%, 0, 0);

            &__cock {
                width: calc(100% - 4.25rem);
                padding-left: rem(85px);
            }
        }

        // 버튼
        .btn {
            // 기본버튼
            &__default {
                display: block;
                width: 100%;
                height: rem(85px);
                text-align: center;
                background: #A8A8A8;
                @extend %font_m;
                font-weight: 600;
                color: #FFF;
                line-height: rem(85px);

                // 다음 페이지가 있는 버튼
                &.next {
                    > span {
                        padding-right: rem(50px);
                        background: url("/front_mo/images/icon_common_next-page.png") no-repeat 100% 50%;
                    }

                    .count-sec {
                        font-weight: 400;
                    }
                }

                // 버튼 활성화 시
                &.active {
                    background: $gcolor;
                    color: #FFF;
                }

                // 주황색 버튼
                &.orange {
                    display: inline-block;
                    width: 50%;
                    height: rem(85px);
                    background: #fff;
                    border: 1px solid $gcolor;
                    text-align: center;
                    @extend %font_m;
                    font-weight: 600;
                    color: $gcolor;
                    line-height: rem(85px);
                    box-sizing: border-box;
                }

                // 초록색 버튼
                &.green {
                    display: inline-block;
                    width: 50%;
                    height: rem(85px);
                    background: $gcolor;
                    text-align: center;
                    @extend %font_m;
                    font-weight: 600;
                    color: #FFF;
                    line-height: rem(85px);
                }

                // 인풋(체크박스, 라디오) 버튼
                &.input {
                    display: block;
                    box-sizing: border-box;
                    width: rem(220px);
                    height: rem(70px);
                    text-align: center;
                    border: rem(1px) solid #BFBFBF;
                    background: #F4F4F4;
                    @extend %font_s;
                    font-weight: 600;
                    color: #3D3D3D;
                    line-height: rem(68px);

                    &.active {
                        border: rem(1px) solid $gcolor;
                        background: $gcolor;
                        color: #FFF;
                    }
                }
            }

            // 더 보기 더튼
            &__more-view {
                display: block;
                width: 100%;
                height: rem(68px);
                margin-top: rem(40px);
                border: 1px solid #BFBFBF;
                background: #F4F4F4;
                text-align: center;
                line-height: rem(68px);
                @extend %font_xs;
                color: #3D3D3D;

                span {
                    padding-right: rem(35px);
                    background: url("/front_mo/images/btn_comm_more.png") no-repeat 100% 50%;
                }
            }

            // 상품문의 작성하기 버튼
            &__write {
                display:block;
                height: rem(70px);
                background: $gcolor;
                text-align: center;
                line-height: rem(70px);
                @extend %font_s;
                font-weight: 700;
                color: #FFF;
            }

            &__heart__cock {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: rem(85px);
                height: rem(85px);
                background: #fff;
                border: rem(1px) solid #BCBCBC;

                span {
                    &:after {
                        content: "";
                        display: block;
                        width: rem(33px);
                        height: rem(30px);
                        margin: 0 auto;
                        background: url("/front_mo/images/btn_heart-off.png") no-repeat 0 0;
                    }
                }

                &.active span:after {
                    background: url("/front_mo/images/btn_heart-on.png") no-repeat 0 0;
                }
            }
        }

        // 버튼 스타일 (흰색, 초록색)
        &__btn {

            &__sec {
                text-align: center;
                font-size: 0;

                li {
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;

                    a {
                        display: block;
                        width: 100%;
                        height: rem(70px);
                        line-height: rem(68px);
                        font: {
                            size: rem(20px);
                            weight: 600;
                        }
                        box-sizing: border-box;
                    }
                }

                .color {
                    &-off a {

                        border: rem(1px) solid #BFBFBF;
                        background: #F4F4F4;
                        color: #3D3D3D;
                    }

                    &-on a {
                        border: rem(1px) solid $gcolor;
                        background: $gcolor;
                        color: #FFF;
                    }
                }
            }
        }

        .greatingPay__pwd .input_type.input_six input[type=number] {
            -webkit-text-security:disc;
        }

        
    .deposit,
    .safety-number {
        padding-bottom: rem(60px) !important;
    }

    .popup_orderDeadline {
        .btn__default {
            max-width: rem(530px);
            margin: 0 auto rem(45px);
        }
    }

    .order-deadline {
        &__sec {
            margin-top: rem(35px);
            border-top: rem(1px) solid #BCBCBC;
        }

        &__list {
            border-bottom: rem(1px) solid #BCBCBC;
            padding: rem(25px 0);
        }

        &__delivery {
            display: block;
            margin-bottom: rem(25px);
            @extend %font_s;
            font-weight: 600;
        }

        &__menu {
            li {
                line-height: rem(36px);

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }

                strong {
                    float: left;
                    @extend %font_xs;
                    font-weight: 400;
                }

                span {
                    float: right;
                    @extend %font_xs;
                    font-weight: 600;
                }
            }
        }
    }

    .delivery-list {
        padding: rem(0 25px) !important;

        &__list {
            position: relative;
            padding: rem(30px 0 45px);
            border-bottom: rem(1px) solid #BCBCBC;
        }

        &__user {
            padding-right: rem(200px);
            line-height: rem(38px);
            @extend %font_xs;

            &-name {
                display: block;
                font-weight: 600;
                span {
                    font-weight: 400;
                }
            }

            &-addr {
                display: block;
                font-weight: 600;
            }

            &-phone {
                display: block;
                font-weight: 400;
            }

            .default {
                @extend %font_xs;
                font-weight: 600;
                color: $gcolor;
            }
        }

        .btn__default {
            position: absolute;
            top: rem(75px);
            right: 0;
            width: rem(110px);
            height: rem(55px);
            text-align: center;
            line-height: rem(55px);
        }

        &__notice {
            position: relative;
            margin: rem(30px 0 60px);
            padding: rem(0 25px);

            p {
                line-height: rem(32px);
                @extend %font_xs;

                em {
                    font-weight: 600;
                }
            }

            .btn__address__admin {
                position: absolute;
                top: 50%;
                right: rem(25px);
                width: rem(150px);
                height: rem(55px);
                border: rem(1px) solid #C1C1C1;
                text-align: center;
                line-height: rem(55px);
                @extend %font_xs;
                transform: translate3d(0, -50%, 0);
                -webkit-transform: translate3d(0, -50%, 0);
            }
        }

        .Badge {
            padding: rem(0 5px);
            font-size: rem(20px);
        }
    }

    .coupon-use {
        &__notice {
            @extend %font_xs;
        }

        &__sec {
            margin-top: rem(40px);

            &__title {
                display: block;
                padding-bottom: rem(15px);
                margin-bottom: rem(25px);
                border-bottom: rem(3px) solid $gcolor;
                @extend %font_s;
                font-weight: 600;

                &-checkbox{
                    .checkbox__label{
                        font-size: rem(20px);

                        &:before{
                            width: rem(25px);
                            height: rem(25px);
                            margin-right: rem(5px);
                        }
                    }
                }
            }
        }

        &__list {
            li {
                padding-bottom: rem(25px);
                border-bottom: rem(1px) solid #BCBCBC;
            }

            &__detail {
                margin-top: rem(25px);
            }

            &__txt {
                display: block;
                padding-right: rem(125px);
                @extend %font_xs;
                font-weight: 600;
                line-height: rem(32px);
                @include line(2);
            }

            &__price {
                position: absolute;
                top: 0;
                right: 0;
                @extend %font_xs;
                line-height: rem(32px);
            }

            &__select {
                width: 100%;
                max-width: rem(580px);
                height: rem(70px);
                margin: rem(25px 0);
                padding: rem(0 15px);
                border: rem(1px) solid #BDBDBD;
                background: url("/front_mo/images/ico_comm_select.png") no-repeat 95% 50%;
                background-size: rem(31px 18px);
                @extend %font_s;
                color: #3D3D3D;
                box-sizing: border-box;
                -webkit-appearance: none;
            }

            &__discount {
                text-align: right;
                @extend %font_xs;
                font-weight: 600;
            }

            // type2
            &.type2{
                .coupon-use__list__detail{
                    padding-left: rem(170px);

                    .coupon-use__list__left{
                        top: rem(5px);
                        width: rem(150px);

                        &-title{
                            font-size: rem(20px);
                        }

                        &-price{
                            font-size: rem(19px);
                        }
                    }

                    .coupon-use__list__right{
                        &-option{
                            margin-bottom: rem(5px);
                            font-size: rem(20px);
                        }

                        .coupon-use__list__select{
                            padding-right: rem(55px);
                            font-size: rem(20px) !important;
                            height: rem(65px);
                            line-height: rem(63px);

                            &-list{
                                top: rem(65px);
                                max-height: rem(203px);

                                ul{
                                    li{
                                        padding: rem(10px 15px);
                                        font-size: rem(20px);
                                    }
                                }
                            }

                            &-guide{
                                margin-top: rem(10px);
                                font-size: rem(19px);
                            }

                            + .coupon-use__list__right-option{
                                margin-top: rem(15px);
                            }
                        }
                    }
                }

                .coupon-use__list__discount{
                    margin-top: rem(25px);
                }
            }
        }

        &__number{
            padding-bottom: rem(15px);

            &-text{
                font-size: rem(20px);

                &:not(:first-child){
                    margin-left: rem(15px);

                    &:before{
                        margin-right: rem(15px);
                        width: rem(2px);
                        height: rem(15px);
                    }
                }
            }
        }

        &__discount-total {
            margin-top: rem(55px);
            text-align: right;
            @extend %font_s;
            font-weight: 600;

            em {
                display: inline-block;
                position: relative;
                margin-left: rem(10px);
                @extend %font_xl;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    z-index: -1;
                    bottom: rem(-2px);
                    left: 0;
                    width: 100%;
                    height: rem(8px);
                    background: $ocolor;
                }
            }
        }

        &__btn-sec {
            max-width: rem(528px);
            margin: 0 auto;
            padding: rem(30px 0);
            text-align: center;
            font-size: 0;

            a {
                display: inline-block;
                width: 50%;
                height: rem(70px);
                vertical-align: top;
                line-height: rem(70px);
                @extend %font_s;
                font-weight: 600;
                box-sizing: border-box;
            }

            .coupon-use__discount-total{
                padding: rem(0 0 20px);
            }
        }

        &__cancel {
            border: rem(1px) solid #BFBFBF;
            color: #3D3D3D;
        }

        &__using {
            background: $gcolor;
            color: #FFF;
        }

        .normal-product {
            margin-top: rem(5px);

            &__opts {
                display: inline-block;
                vertical-align: middle;
                font-size: rem(20px);
            }

            &__count {
                display: inline-block;
                vertical-align: middle;
                font-size: rem(20px);
            }
        }
    }

    // 현금영수증
    .cash_receipts{
        &__type{
            display: flex;
            flex-wrap: wrap;
            width: 100%;  
            margin-bottom: rem(30px);  

            li{
                flex: 1 0 48%;
                width: 9.2rem;
                height: 3.4rem;
                line-height: 3.4rem;
                margin-right: 1%;
                border: 0.05rem solid #BCBCBC;
                background: #fff;
                color: #000;
                font-size: 1.1rem;
                text-align: center;
                box-sizing: border-box;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }

                &.on{
                    background-color: $gcolor;
                    border: none;

                    a{
                        color: #fff;
                    }
                }
            }
        }

        .input-box{
            .modal__sec__txt{
                margin-bottom: 10px;
            }
        }

        .check-box{
            position: relative; 
            margin-top: rem(20px);
            padding-left: rem(50px);

            .checkbox__label{
                font-size: rem(20px);
                line-height: rem(30px);
                
                &:before{
                    position: absolute;
                    left: rem(-50px);
                    top: 0;
                }
            }
        }

        .btn-area{
            .btn-half{
                width: 50%;
            }
        }
    }

    // 선물 주소 개인정보 수집동의
    .popup_adressAgree{
        @extend %font_xs;
        
        .agree{
            &-text{
                display: block;
                padding-bottom: 20px;
            }

            &-table{
                table{
                    border: 1px solid $colorBC;
                    border-bottom: 0;
                    overflow: hidden;

                    th,td{
                        padding: 10px;
                        font-weight: normal;
                        color: #555;
                        text-align: center;
                        border-bottom: 1px solid $colorBC;
                        border-right: 1px solid $colorBC;
                    }

                    th{
                        background: $colorBG;
                    }
                }

                p{
                    margin-top: 10px;
                    color: $gcolor;
                }
            }
        }
    }

    // 에러페이지
    &__error {
        position: fixed;
        top: 50%;
        left: 0;
        width: 100%;
        text-align: center;
        transform: translate3d(0, -50%, 0);
        -webkit-transform: translate3d(0, -50%, 0);

        &__title {
            margin-top: rem(35px);
            font-size: rem(30px);
            font-weight: 600;
        }

        &__txt {
            margin-top: rem(30px);
            line-height: rem(32px);
            font-size: rem(22px);
        }

        .btn-area {
            width: rem(460px);
            margin: rem(60px auto 0);
            font-size: 0;

            a {
                display: inline-block;
                float: none;
                width: 50%;
                vertical-align: top;
                font-size: rem(24px);
                font-weight: 600;
            }
        }
    }

    .detailViewer {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: rem(380px);

        img {
            max-width: 100%;
        }
    }

    .alertBox {
        margin-top: rem(10px);
        @extend %font_xs;

        &.fail {
            color: $colorRD;
        }

        &.success {
            color: $gcolor;
        }
    }


    .hd__popup {
        .sect {
            font-size: rem(24px);
            line-height: rem(38px);
        }
        .sect + .sect {
            margin-top: rem(30px);
        }
        .sect .sect-title {
            margin-bottom: rem(10px);
            font-size: rem(26px);
        }
        .sect .text {
            margin-bottom: rem(10px);
        }
        .sect .desc {
            font-size: rem(23px);
        }
        .sect .box,
        .sect .box .innerbox {
            display: block;
            padding-left: rem(20px);
        }

        .sect {
            font-size: rem(24px);
            line-height: rem(38px);
        }
        .sect + .sect {
            margin-top: rem(30px);
        }
        .sect .sect-title {
            margin-bottom: rem(10px);
            font-size: rem(26px);
        }
        .sect .text {
            margin-bottom: rem();
        }
        .sect .desc {
            font-size: rem(23px);
        }
        .sect .list-area li + li {
            margin-top: rem(5px);
        }
        .sect .table-area {
            margin-bottom: rem(10px);
        }
        .sect .table-area caption {
            display: table-caption;
            margin-bottom: rem(10px);
            text-align: left;
        }
        .sect .table-area tr {
            padding: rem(5px);
        }
        .sect .table-area tr td,
        .sect .table-area tr th {
            padding: rem(5px);
            border: 1px solid #888;
            text-align: center;
            font-weight: normal;
            word-wrap: break-word;
        }
    }

    }
}