.hd {
    &__applyOrderExchange {
        padding-top: 190px;

        .btn-area {
            margin-top: 40px;

            button {
                font-weight: bold;
            }
        }

        .item-box .checks {
            position: absolute;
            top: 25px;
            left: 0;
        }

        .singleCase .inner {margin-left: 50px;}
        .multiCase .txt-area__title {padding-left: 50px;}

        .way-area {
            border-top: 1px solid #BCBCBC;
        }

        .inner-sect {
            &__tit {
                padding: 25px 0;
                @extend %font_xs;
                font-weight: bold;
            }

            &__subtit {
                display: block;
                margin-top: 10px;
                @extend %font_xs;
                font-weight: 400;
                line-height: 32px;
            }
        }

        &__desc {
            li {
                position: relative;
                padding-left: 15px;
                color: #888;
                @extend %font_xs;
                line-height: 1.6;

                &:before {
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    content: '-';
                }
            }
        }
    }
}