.hd {

    &__displayList {
        
        .display-banner {
            width: 100%;
            height: 200px;
            display: none;

            .swiper-pagination {
                position: absolute;
                bottom: 24px;
                left: 32px;
                z-index: 1;
            }
        }

        .display-cont {
            width: 580px;
            margin: 0 auto;

            .display-list {
                margin-top: 30px;

                &__thumb {
                    overflow: hidden;
                    height: 230px;
                }

                .textbox {
                    padding-top: 20px;

                    &__tit {
                        @extend %font_m;
                    }

                    &__period {
                        padding-top: 15px;
                        @extend %font_xs;

                        .d-day {
                            position: relative;
                            margin-left: 15px;
                            padding-left: 15px;
                            color: $ocolor;
                            font-weight: bold;

                            &:before {
                                display: inline-block;
                                position: absolute;
                                top: 5px;
                                left: 0;
                                width: 0;
                                height: 25px;
                                border: 1px solid #575757;
                                content: '';
                            }
                        }
                    }
                }
            }
        }

        .tab-area{
            padding: 30px;
            box-sizing: border-box;
        }
    }
    
}