.hd {

    &__winnerList {
        
        .winner-banner {
            width: 100%;
            height: 382px;

            .swiper-pagination {
                position: absolute;
                bottom: 24px;
                left: 32px;
                z-index: 1;
            }
        }

        &__container {
            width: 580px;
            margin: 40px auto 0;
        }

        .winner-cont {

            .winner-list {
                position: relative;
                padding: 40px 0;
                border-bottom: 1px solid #bcbcbc;

                .textbox {

                    &__tit {
                        @extend %font_m;
                    }

                    &__period {
                        padding-top: 15px;
                        @extend %font_xs;
                    }
                }
            }
        }
    }

}