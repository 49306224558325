.hd {
    .story{
        &-cont {
            margin-top: 80px;

            &__list {
                margin-top: 30px;

                &:first-child {
                    margin-top: 0;
                }

                a {
                    display: block;
                    font-size: 0;

                    figure {
                        width: 100%;
                        height: 250px;
                    }
                }
            }

            &__title {
                display: block;
                margin-top: 25px;
                padding: 0 30px;
                font-size: 32px;
                font-weight: 600;

                &-more{
                    display: inline-block;
                    margin: 7px 0 0 5px;
                    width: 25px;
                    height: 25px;
                    line-height: 23px;
                    font-size: 0;
                    text-indent: -999em;
                    text-align: center;
                    border: 1px solid $colorBC;
                    border-radius: 50%;
                    vertical-align: top;

                    &:before{
                        display: inline-block;
                        margin: 2px 0 0 -2px;
                        width: 6px;
                        height: 6px;
                        vertical-align: middle;
                        border: solid $color3D;
                        border-width: 2px 2px 0 0;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        content: '';
                    }
                }
            }

            &__desc{
                margin: 10px 0 30px;
                padding: 0 30px;
                font-size: 24px;
            }

            &.main{
                position: relative;
                margin-top: 0;

                .main-img{
                    &__wrap{
                        position: sticky;
                        position: -webkit-sticky;
                        top: 0;
                        height: 100vh;

                        img{
                            max-width: 640px;
                            width: 100vw;
                            height: 100vh;
                            object-fit: cover;
                        }
                    }

                    &__dimmed{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: #000;
                        opacity: 0.2;
                        z-index: 1;
                    }
                }

                .main-txt{
                    &__wrap{
                        position: relative;
                        margin-top: -40vh;
                        padding: 0 30px 60px;
                    }

                    &__eng img{
                        width: 425px;
                    }

                    &__kor{
                        margin: 10px 0 15px;
                        line-height: 50px;
                        font-size: 38px;
                        font-weight: 600;
                        color: #fff;
                    }

                    &__list{
                        overflow: hidden;
                        
                        li{
                            position: relative;
                            display: block;
                            margin-top: 20px;
                            padding: 40px;
                            line-height: 30px;
                            font-size: 21px;
                            color: $color88;
                            background: #fff;
                            letter-spacing: -0.5px;
                            box-sizing: border-box;

                            span{
                                display: block;
                                font-size: 20px;
                                color: $gcolor;
                                letter-spacing: 0;
                            }

                            strong{
                                display: block;
                                margin: 5px 0 15px;
                                padding-bottom: 15px;
                                font-size: 28px;
                                color: $color3D;
                                border-bottom: 2px solid $gcolor;
                            }

                            &:before{
                                position: absolute;
                                right: 30px;
                                top: 30px;
                                width: 80px;
                                height: 80px;
                                content: '';
                                background: url("/front_pc/images/ico_story_main.png");
                            }
                        }

                        @for $i from 1 through 3 {
                            .list0#{($i)}:before{
                                background-position-x: (#{80-(($i)*80)}px);
                            }
                        }
                    }

                    &__grt{
                        margin: 40px 0;
                        font-size: 38px;
                        font-weight: 600;
                        color: #fff;

                        em{
                            display: inline-block;
                            text-indent: -10px;

                            &.timesaletext {
                                animation: TextTimeSale 1.5s  forwards infinite ease;
                            }
                            &.timesaletext1 {
                                animation-delay:1s;
                                margin-left: 10px;
                            } 
                            &.timesaletext2 {
                                animation-delay:1.25s;
                            } 
                            &.timesaletext3 {
                                animation-delay:1.5s;
                            } 
                        }

                        @keyframes TextTimeSale {
                            0% {
                                transform:translate(0);
                            }
                            20% {
                                transform:translate(0,-10px);    
                            }
                            100% {
                                transform:translate(0);     
                            }
                        }
                    }
                }

                .main-btn{
                    display: block;
                    width: 580px;
                    padding: 22px 0;
                    font-size: 26px;
                    color: #fff;
                    text-align: center;
                    background: $gcolor;

                    span{
                        display: block;
                        margin-bottom: 10px;
                        font-size: 23px;
                        color: #d0d0d0;
                    }
                }
            }

            &.magazine{
                .magazine-slider{
                    position: relative;
                    padding: 65px 0 55px;

                    &:after{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        content: '';
                    }
                }

                .magazine-list{
                    position: relative;
                    padding-bottom: 10px;
                    z-index: 2;

                    .swiper{
                        &-slide{
                            position: relative;
                            margin: 30px 15px 0;
                            width: 300px;
                            height: 470px;
                            border-radius: 15px;
                            overflow: hidden;
                            transition: all 0.5s;

                            a{
                                &:before{
                                    position: absolute;
                                    left: 30px;
                                    top: 30px;
                                    width: 78px;
                                    height: 15px;
                                    content: '';
                                    background: url('/front_pc/images/img_header_logo_w.png') no-repeat;
                                    background-size: 100% auto;
                                    z-index: 1;
                                }

                                &:after{
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                    background: rgba(0,0,0,0.2);
                                    content: '';
                                    z-index: 0;
                                }
                            }

                            &-active{
                                margin-top: 0;
                                .list-txt{
                                    z-index: 1;
                                }
                            }
                        }
                        
                        &-pagination{
                            margin-top: 0;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                        }
                    }

                    .list{
                        &-txt{
                            position: absolute;
                            left: 30px;
                            bottom: 90px;
                            width: 240px;
                            color: #fff;

                            &-sort{
                                display: inline-block;
                                margin-bottom: 15px;
                                padding: 0 10px;
                                line-height: 30px;
                                font-size: 15px;
                                color: $gcolor;
                                background: #fff;
                                border-radius: 30px;

                                &.c1{color: #307d0e;}
                                &.c2{color: #918c00;}
                                &.c3{color: #a4111f;}
                                &.c4{color: #378a9f;}
                            }

                            &-title{
                                display: block;
                                line-height: 1.2;
                                width: 99%;
                                font-size: 34px;
                                @include line(2);
                                word-break: normal;
                                word-wrap: keep-all;
                            }

                            &-desc{
                                display: block;
                                margin-top: 20px;
                                line-height: 1.2;
                                width: 99%;
                                font-size: 18px;
                                @include line(1);
                            }
                        }

                        &-img{
                            position: relative;
                            height: 100%;

                            img{
                                position: absolute;
                                left: 0;
                                top: 50%;
                                width: 100%;
                                max-height: initial;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }

                .magazine-bg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;

                    img{
                        margin-top: -25%;
                        width: 100%;
                        -webkit-filter: blur(10px); 
                        -moz-filter: blur(10px); 
                        -o-filter: blur(10px); 
                        -ms-filter: blur(10px); 
                        filter: blur(10px); 
                        transform: scale(1.02);
                    }

                    .swiper-slide{
                        overflow: hidden;
                        position: relative;
                        
                        &:after{
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: rgba(0,0,0,0.5);
                            content: '';
                            z-index: 0;
                        }
                    }
                }
            }

            &.tv{
                position: relative;
                height: 618px;

                &:after{
                    position: absolute;
                    left: 0;
                    bottom: -60px;
                    width: 100%;
                    height: 370px;
                    content: '';
                    background: $gcolor;
                    z-index: -1;
                }

                .swiper{
                    &-container{
                        width: 580px;
                        white-space: nowrap;
                        text-align: left;
                    }

                    &-slide{
                        display: inline-block;
                        margin-right: 16px;
                        border-radius: 20px;
                        overflow: hidden;
                        vertical-align: top;
                        box-sizing: border-box;
                        background: #fff;
                    }

                    &-pagination-bullet-active{
                        background: $color3D;
                    }
                }

                .tv-box{
                    padding: 20px;
                    text-align: center;
                }

                .tv-text{
                    margin: 0 auto 10px;
                    width: 400px;   
                    font-size: 18px;
                    @include line(1);
                }

                .tv-title{
                    margin: 0 auto; 
                    width: 400px;   
                    font-size: 28px;
                    @include line(1);
                }

                .tv-view{
                    display: block;
                    margin-top: 10px;
                    font-size: 16px;
                    color: #a7a7a7;
                    text-transform: uppercase;
                }

                .slick-dots{
                    bottom: -30px;
                }
            }

            &.brand{
                .story{
                    &-h3{
                        margin: 0 30px;
                        + .story-tit{
                           margin-top: 20px;
                        }
                    }
                }
            }

            &.slogan{
                padding-top: 70px;
                text-align: center;
            }

            &.care .story-scroll{width: 4080px;}
            &.market .story-scroll{width: 3420px;}
        }

        &-video{
            position: relative;
            min-height: 1000px;
            background: url('/front_mo/images/story_main.jpg') no-repeat;

            &:after{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(30, 34, 15, 0.4);
                content: '';
                z-index: 1;
            }

            &-txt{
                position: absolute;
                left: 50%;
                top: 50%;
                text-align: center;
                z-index: 2;
                transform: translate3d(-50%, -50%, 0);

                .txt{
                    margin-top: 30px;
                    font-size: 30px;
                    color: #fff;
                    letter-spacing: -0.5px;
                }
            }
        }

        &-tit, &-txt{
            margin: 0 30px;
        }

        &-tit{
            margin-top: 40px;
        }

        &-txt{
            margin-top: 20px;
        }

        &-img{
            margin-top: 50px;
        }

        &-scroll{
            display: flex;
            margin-top: 10px;
            padding: 0 5px;
            box-sizing: border-box;

            > div{
                display: inline-block;
                margin: 0 5px;
            }

            @keyframes care {
                0% {
                    transform: translateX(0)
                }

                to {
                    transform: translateX(-2960px)
                }
            }

            @keyframes market {
                0% {
                    transform: translateX(0)
                }

                to {
                    transform: translateX(-2220px)
                }
            }
        }

        &-ban{
            display: flex;
            margin-top: 40px;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            a{
                margin: 10px;

                img{
                    display: block;
                }
            }
        }

        &-slider{
            position: relative;

            [class^='swiper-button-']{
                position: absolute;
                top: 40%;
                transform: translateY(-50%);
                z-index: 1;

                &:after{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 30px;
                    height: 30px;
                    vertical-align: middle;
                    border: solid #ccc;
                    border-width: 3px 3px 0 0;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    content: '';
                }
            }

            .swiper-button{
                &-prev{
                    left: 30px;

                    &:after{
                        -webkit-transform: rotate(-135deg);
                        transform: rotate(-135deg);
                    }
                }

                &-next{
                    right: 70px;
                }
            }
        }

        &-youtube{
            position: relative;
            margin: 25px 30px 70px;
            padding-bottom: 51.25%;
            height: 0;
            overflow: hidden;
            box-shadow: 0 5px 10px rgba(0,0,0,0.5);

            iframe{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &-popup{
            .hd__popup__modal{
                padding-bottom: 20px;
                border-radius: 30px;
                text-align: center;
            }

            &-btn{
                display: inline-block;
                margin: 35px auto 0;
                padding: 5px 30px;
                font-size: 28px;
                border-radius: 50px;
            }
        }
    }

    &__story {
        overflow: hidden;

        .brandStory__program.box {
            height: 250px;
            padding: 30px 45px 40px;
            border: 0; 
            box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;

            .brandStory__program__title {
                font-size: 36px;
            }

            .brandStory__program__desc {
                margin-top: 20px;
                color: #888;
            }
            
            .brandStory__program__link {
                right: 45px;
                bottom: 30px;
            }
        }
    }

    .pre-txt {
        display: inline-block;
        width: 100%;
        white-space: pre-wrap;
		word-wrap: break-word;
    }

    .story__tabmenu{
        border-bottom: 1px solid $colorBC;

        &-inner{
            margin-top: 25px;
            padding-left: 30px;
            text-align: center;
        }

        ul{
            overflow-x: auto;
            &::-webkit-scrollbar {
                display: none;
            }

            li{
                display: inline-block;
                width: auto !important;

                a{
                    display: block;
                    padding: 20px 26px;
                    @extend %font_xs;
                    color: #6a6a6a;

                    &:hover{
                        color: $color3D;
                    }
                }

                &.on{
                    a{
                        font-weight: 600;
                        color: $gcolor;
                        border-bottom: 2px solid $gcolor;
                    }
                }

                &:last-child{
                    padding-right: 30px;
                }
            }
        }

        // [class^="swiper-button"]{
        //     position: absolute;
        //     left: 0;
        //     bottom: 22px;
        //     width: 20px;
        //     height: 20px;
        //     line-height: 15px;
        //     background: $gcolor;
        //     border-radius: 50%;
        //     cursor: pointer;
        //     z-index: 3;

        //     &:before{
        //         font-size: 11px;
        //         font-weight: 600;
        //         font-family: 'dotum';
        //         color: #fff;
        //         content: '<';
        //     }
        // }
        // .swiper{
        //     &-container-initialized{
               
        //         &:before,&:after{
        //             position: absolute;
        //             top: 0;
        //             left: 0;
        //             width: 30px;
        //             height: 100%;
        //             background: rgb(255,255,255);
        //             content: '';
        //             z-index: 2;
        //         }

        //         &:after{
        //             left: inherit;
        //             right: 0;
        //             width: 50px;
        //             background: linear-gradient(90deg, rgba(255,255,255,0) 0%, white 100%);
        //         }

        //         .swiper-wrapper{
        //             justify-content: normal;
        //         }
        //     }

        // }
        
    }
}