.hd {
    &__market {
        &__dim {
            visibility: hidden;
            position: fixed;
            z-index: 3;
            top: 0;
            left: 50%;
            width: 100%;
            max-width: 640px;
            height: 100%;
            margin-left: -320px;
            opacity: 0;
            background: rgba(0, 0, 0, 0.8);
            transition: visibility 0.35s linear, opacity 0.35s linear;

            &--show {
                visibility: visible;
                opacity: 1;
            }
        }

        &__cate-wrap {
            position: fixed;
            width: 100%;
            max-width: 640px;
            top: 149px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3;
            background: #FFF;
            border-bottom: 1px solid #BCBCBC;

            &--move {
                width: 100%;
                top: 150px;
                left: 50%;
                transform: translate3d(-50%, -81px, 0);
                transition: transform 0.25s linear;
            }
        }

        &__cate {
            position: relative;
            padding-right: 68px;
            @extend %clear;

            .cate-slider {
                width: 100%;
                height: 68px;
                line-height: 68px;
                @extend %clear;

                &__list {
                    float: left;
                    width: auto;

                    a {
                        display: block;
                        height: 68px;
                        padding: 0 33px;
                        @extend %font_xs;
                        font-weight: bold;
                    }

                    &--on {
                        &:after {
                            display: block;
                            width: 100%;
                            margin-top: -3px;
                            border-bottom: 3px solid $ocolor;
                            content: '';
                        }
                    }
                }
            }
        }

        .cate-open {
            display: none;
            padding: 30px;
            @extend %clear;

            &__list {
                float: left;
                width: 139px;
                height: 44px;
                font-weight: bold;
                text-align: center;
                @extend %font_xs;
                line-height: 42px;

                a {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    &:after {
                        display: block;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        border: 1px solid #BCBCBC;
                        box-sizing: border-box;
                        content: '';

                    }
                }

                &:not(:nth-child(4n+1)) {
                    margin-left: 8px;
                }

                &:nth-child(n+5) {
                    margin-top: 8px;
                }

                &--on {

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;

                        &:after {
                            display: block;
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            border: 3px solid $ocolor;
                            box-sizing: border-box;
                            content: '';
                        }
                    }
                }
            }

            &--show {
                display: block;
                border-top: 1px solid #BCBCBC;
            }
        }

        .cate-btn {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 999;
            // width: 67px;
            width: 100px;
            height: 69px;
            border: {
                bottom: 1px solid #BCBCBC;
                left: 1px solid #BCBCBC;
            };
            box-sizing: border-box;
            cursor: pointer;

            &:after {
                display: block;
                width: 100%;
                height: 100%;
                background: #FFF url(/front_mo/images/icon_market_drop-down-on.png) no-repeat 50% 50%;
                content: "";
                cursor: pointer;
            }

            &--on {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        .category{
            border-top: 1px solid $colorBC;
            background: #fff;

            &__depth1{
                position: relative;
                height: 141px;
                border-bottom: 1px solid $colorBC;

                .swiper-container{
                    overflow: hidden;
                    box-sizing: border-box;
                    background: #fff;
                }

                &__list{
                    float: left;
                    width: 150px;
                    height: 140px;
                    box-sizing: border-box;

                    &:not(:first-child){
                        border-left: 1px solid $colorBC;
                    }

                    a{
                        display: table;
                        width: 100%;
                        height: 100%;
                        font-size: 19px;
                        text-align: center;

                        span{
                            // display: table-cell;
                            // vertical-align: middle;
                            display: block;
                            margin-top: 26px;

                            i{
                                display: block;
                                margin: 0 auto 10px;
                                width: 50px;
                                height: 50px;
                                background-image: url('/front_mo/images/ico_category.png?ver=220207');
                                background-repeat: no-repeat;
                                background-size: 300%;
                            }

                            &:after{
                                display: block;
                            }
                        }
                    }

                    &:nth-of-type(1) i, &.all i{
                        background-image: none;

                        &:before{
                            display: inline-block;
                            margin-top: 15px;
                            font-size: 25px;
                            font-style: initial;
                            content: 'ALL';
                        }
                    }

                    @for $i from 2 through 15 {
                        &:nth-of-type(#{($i)}) i{
                            background-position-y: (-#{350+(($i)*50)}px);
                        }
                    }

                    // $category: 전체보기, 건강반찬, 진한 국/찌개, 자연 담은 면, 영양가득 밥/죽, 샐러드/샌드위치, 베이커리/스프, 착한 간식/음료, 안심키즈, 부드러운 연화식, 1Table, 비건/라이프, 스페셜 패키지, 반찬 정기구독;
                    // @for $i from 1 through length($category) {
                    //     &:nth-of-type(#{($i)}) a span:after{
                    //         content: '' + nth($category, $i) + '';
                    //     }
                    // }

                    &:nth-of-type(1) a span:after{
                        display: none;
                    }

                    &.active{
                        a{
                            color: #fff;
                            background: $gcolor !important;

                            i{
                                background-position-x: right;
                            }
                        }
                    }
                }

                &.v2{
                    height: auto;
                    min-height: 140px;

                   .category__depth1__list{
                        width: 140px;
                        
                        a span{
                            i{
                                position: relative;
                                width: 50px;
                                height: 50px;
                                background-image: none;
                                overflow: hidden;

                                img{
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    max-width: none;
                                    height: 50px;
                                }
                            }

                            &:after{
                                display: none;
                            }
                        }

                        // @for $i from 1 through 15 {
                        //     &.ico#{($i)} i{
                        //         background-image: url('/front_mo/images/ico_category2.png');
                        //         background-position: 0 (-#{-66+(($i)*50)}px);
                        //         background-size: 100%;
                        //     }
                        // }

                        &.active{
                            i img{
                                left: -50px;
                            }                            
                        }
                   }
                }

                .accordion{
                    &_box{
                        margin-top: 0;

                        &.on{
                            .accordion_title:after {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    &_title{
                        position: absolute;
                        right: 0;
                        top: -1px;
                        width: 70px;
                        height: 140px;
                        font-size: 0;
                        background: #fff;
                        border: 1px solid #9e9e9e;
                        border-right: 0;
                        z-index: 1;

                        &:after {
                            display: block;
                            width: 100%;
                            height: 100%;
                            background: #FFF url(/front_mo/images/icon_market_drop-down-on.png) no-repeat 50% 50%;
                            content: "";
                        }
                    }

                    &_con{
                        padding: 20px 0;
                        border-top: 1px solid $colorBC;
                        // position: absolute;
                        // left: 0;
                        // top: 143px;
                        // padding: 30px 0;
                        // width: 100%;
                        // z-index: 2;
                        // background: #fff;
                        // box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.20);

                        > ul{
                            margin: 0 auto;
                            width: 604px;
                            overflow: hidden;

                            .category__depth1__list{
                                width: 150px;
                                border: 0;

                                a{
                                    margin: 0 auto;
                                    width: 130px;
                                    height: 130px;
                                    font-size: 20px;
                                    border-radius: 50%;
                                    background: #f5f5f5;
                                }
                            }
                        }
                    }
                }
            }
            
            &__depth2{
                height: 65px;

                .swiper-container{
                    padding-top: 15px;
                    border-bottom: 1px solid $colorBC;
                    background: #fff;
                }

                &__list{
                    display: inline-block;
                    margin: 0 25px; 
                    width: auto;

                    a{
                        display: block;
                        height: 55px;
                        line-height: 54px;
                        @extend %font_xs;
                    }

                    &.active{
                        a{
                            font-weight: 600;
                            color: $gcolor;

                            &:after {
                                position: absolute;
                                left: 0;
                                bottom: -1px;
                                width: 100%;
                                height: 4px;
                                background: $gcolor;
                                content: '';
                            }
                        }
                    }
                }

                &.v2{
                    margin-top: -3px;
                    padding-top: 20px;
                    height: auto;
                    background: #fff;

                    ul {
                        margin: 0 40px;
                    }

                    .category__depth2{
                        &__list{
                            margin: 0;
                            padding: 0 10px; 
                            width: 32%;
                            overflow: hidden;
                            box-sizing: border-box;
                            text-align: center;

                            a{
                                position: relative;
                                display: inline-block;
                                @extend %font_s;
                            }
                        }
                    }
                }
            }

            &__depth3{
                margin-top: 5px;

                .swiper-container{
                    padding: 20px 10px 0 20px;
                }
                li{
                    display: inline-block;
                    margin-right: 10px;
                    width: auto;

                    a{
                        display: inline-block;
                        padding: 9px 17px 11px;
                        font-size: 20px;
                        color: #666;
                        border-radius: 30px;
                        border: 1px solid $colorBC;
                    }

                    &.active{
                        a{
                            font-weight: bold;
                            color: $gcolor;
                            border-color: $gcolor;
                        }
                    }
                }
            }

            &--move{
                .category__depth1 .swiper-container{
                    position: fixed;
                    left: 0;
                    top: 0px;
                    width: 100%;
                    z-index: 3;
                    border-bottom: 1px solid $colorBC;

                    .category__depth1__list{
                        min-width: 140px;
                        width: auto;
                        height: 60px;

                        a{
                            padding: 0 20px;
                            font-size: 22px;
                            box-sizing: border-box;

                            span{
                                margin-top: 17px;

                                i{display: none;}
                            }
                        }
                    }
                }

                .category__depth2 .swiper-container{
                    position: fixed;
                    left: 0;
                    top: 60px;
                    width: 100%;
                    z-index: 3;
                    border-top: 1px solid $colorBC;
                }

                .category__depth3 .swiper-container{
                    position: fixed;
                    left: 0;
                    top: 68px;
                    padding: 20px 10px 20px 20px;
                    width: 100%;
                    z-index: 3;
                    background: #fff;
                    border-bottom: 1px solid $colorBC;
                }

                .category__depth2 + .category__depth3 .swiper-container{
                    top: 132px;
                }
            }
            
            &.brand .category__depth1__list a span{
            	padding: 0 11px;
            }
        }

        &__contents {
            margin-top: 35px;
            // padding: 0 20px;
            .contents-top {
                position: relative;
				padding:0 20px 0;
                margin-bottom: -20px;
                min-height: 40px;
                @extend %clear;

                &__total {
                    position: absolute;
                    left: 20px;
                    top: 0;
                    line-height: 39px;
                    @extend %font_xs;
                    z-index: 1;

                    .sorting{
                        strong{
                            color: $color3D !important;
                        }

                        .select-area{
                            left: 0 !important;

                            &:after{
                                left: 28px;
                                right: inherit;
                            }
                        }

                        &.none{
                            strong:after{
                                display: none;
                            }
                        }
                    }
                }

                &__filter {
                    width: 600px;
                    z-index: 0;
                    text-align: right;
                    
                    .sorting{
                        position: relative;
                        float: right;
                        width: 50%;
                        margin: 55px 0 -20px;
                    }

                    span.checks_box{
                        float: none;
                        margin: 0;
                        width: auto;

                        + .sorting{
                            display: inline-block;
                            float: none;
                            margin: 0;
                            width: auto;
                            margin-left: 20px;
                            padding-left: 20px;


                            &:before{
                                position: absolute;
                                left: 0;
                                top: 10px;
                                width: 1px;
                                height: 23px;
                                background: $colorBC;
                                content: '';
                            }

                            .select-area{
                                left: -68px;
                            }
                        }
                    }

                    select{
                        display: inline-block;
                        margin: -3px 0 0 20px;
                        padding: 0 30px 0 8px;
                        width: 140px;
                        height: 45px;
                        background: url("/front_mo/images/ico_comm_select.png") no-repeat right 10px top 50%;
                        font-size: 21px !important;
                        background-size: 17px !important;
                    }
                }

                &__title{
                    position: absolute;
                    font-size: 34px;
                    font-weight: bold;
                    z-index: 1;

                    + .contents-top__filter{
                        width: 580px;

                        .sorting{
                            margin: 0;
                        }
                    }
                }

                .sorting {
                    background: #FFF;

                    strong {
                        display: inline-block;
                        position: relative;
                        height: 40px;
                        padding-right: 20px;
                        text-align: right;
                        line-height: 40px;
                        color: #636363;
                        @extend %font_xs;
                        font-weight: 400;
                        cursor: pointer;

                        &:after {
                            display: block;
                            top: 15px;
                            right: 0;
                            position: absolute;
                            width: 13px;
                            height: 8px;
                            background: #FFF url(/front_mo/images/btn_sorting_drop-down.png) no-repeat 50% 50%;
                            content: '';
                        }
                    }

                    &__list {
                        position: relative;
                        padding: 0 20px;
                        line-height: 1.1;
                        @extend %font_xs;

                        a {
                            display: block;
                            color: #888888;
                        }
                    }

                    .select-area {
                        display: none;
                        position: absolute;
                        top: 58px;
                        left: 100px;
                        z-index: 2;
                        padding: 25px 30px;
                        border: 1px solid #636363;
                        background: #FFF;
                        text-align: left;

                        &__inner {
                            position: relative;
                        }

                        &__list {
                            line-height: 1; 
                            position: relative;
                            width: 140px;
                            @extend %font_xs;

                            &:not(:first-child) {
                                padding-top: 20px;
                            }

                            &--on {
                                a {
                                    color: $gcolor;
                                }

                                &:after {
                                    display: block;
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                    margin-top: -10px;
                                    width: 18px;
                                    height: 20px;
                                    background: #FFF url(/front_mo/images/icon_sorting_check-on.png?ver=210226) no-repeat 0 0;
                                    content: '';
                                }
                            }
                        }

                        &:after {
                            display: block;
                            position: absolute;
                            top: -11px;
                            right: 28px;
                            width: 20px;
                            height: 20px;
                            border: 1px solid #636363;
                            border-right: transparent;
                            border-bottom: transparent;
                            background: #FFF;
                            transform: rotate(45deg);
                            content: '';
                        }

                        &--show {
                            display: block;
                        }
					}
					
                }

                .btn__filter {
                    position: absolute;
                    right: 20px;
                    top: 0;
                    width: 107px;
                    height: 38px;
					line-height: 39px;
					margin-left:25px;
                    background: url(/front_mo/images/icon_sorting_filter.png?ver=210226) no-repeat 50% 50%;
                    background-color: $gcolor;
                    border: 3px solid $gcolor;
                    box-sizing: border-box;
                }

                .checks_box{
                    display: inline-block;
                    float: left;
                    width: 50%;
                    margin: 55px 0 -20px;
                    height: 35px;
                    line-height: 40px;
                    white-space: nowrap;
                    text-align: left;
                }

                .tab-type3{
                    margin: 50px 0 10px;
                }
			}
			
			.marketList__sect.info {
				div {height:200px;
					img {max-width:100%; vertical-align:top;}
				}
			}

			// &__box {
			// 	display:flex; 
			// 	flex-wrap:wrap; 
			// 	justify-content:space-between;
			// }

            // &__list {
			// 	width:290px; 
			// 	flex:0 0 290px; 
			// 	margin-bottom:40px; 
			// 	padding-bottom:20px; 
			// 	border-bottom:solid 1px #bcbcbc;

            //     .item {
			// 		$self : &;
            //         position: relative;
			// 		width: 100%;
			// 		@extend %vertical;
			// 		a {display:block;}
			// 		&__tag { display:flex; overflow:hidden;
			// 			margin-bottom:5px;
			// 			span {overflow:hidden; height:26px; border:solid 1px #c2c2c2; border-radius:3px; color:$gcolor; line-height:26px; text-align:center; font-size:16px;
			// 				padding:0 5px; min-width: 50px;
			// 				& + span {margin-left:5px;}
			// 			}
			// 			// 태그가 있을때 여백 제거
			// 			& + .item__title {margin-top:0;}
			// 		}

            //         &__thumb {
            //             width: 100%;
			// 			height: 290px;
			// 			position: relative;
			// 			figure{
			// 				background: #ddd;
			// 				height:100%;
			// 			}
            //         }

            //         &__info {
            //             position: relative;
			// 			margin-top: 5px;
            //         }

			// 		&__title {margin-top:37px;}

            //         &__sub {
            //             font-size: 20px;
            //             line-height: 30px;
			// 			color: #888;
            //             @include line(2);
            //         }

			// 		&__name {
			// 			display: block;
            //             width: 100%;
			// 			min-height:60px;
            //             line-height: 30px;
			// 			font-weight: bold;
			// 			margin-bottom:5px;
            //             @extend %font_xs;
            //             @include line(2);
			// 		}
					

            //         &__price-ori {
			// 			color:#888;
            //             @extend %font_xs;
			// 			text-decoration: line-through;
			// 			& + .item__price-dc{margin-top:0;}
			// 		}
					

            //         &__price-dc {
			// 			display:block;
			// 			margin-top:33px;
            //             @extend %font_xs;
			// 			text-decoration: none;
            //             strong {
            //                 @extend %font_m;
            //             }
			// 		}
					
			// 		&__cart {position:absolute; right:10px; bottom:0; z-index:1;
			// 			span {display:block; position:relative; font:0/0 a;
			// 			&:before {content:""; display:block; background:url(/front_pc/images/icon_cart.png) no-repeat center center; background-size:100% 100%; width:55px; height:50px; margin:0 auto; box-sizing:border-box;}}
			// 		}

            //         .hd__btn-heart {
            //             right: 12px;
            //             top: 178px;
            //         }
            //     }
            // }
        }

        .brand{
            &-area{
                position: relative;
                margin: 0 30px 40px;
                width: 580px;
                z-index: 0;

                .swiper{
                    &-wrapper{
                        display: block;
                    }
                    &-prudect{
                        position: relative;
                        margin-top: -90px;
                        overflow: hidden;
                        z-index: 2;

                        ul{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                        }

                        .product__wrap__list--item{
                            width: 260px;
                            margin: 0 10px;
                            margin-top: 0 !important;
                            padding-bottom: 0 !important;
                            border-bottom: 0 !important;
                            flex: 0 0 260px !important;

                            .item{
                                &__thumb{
                                    height: 260px !important;

                                    img{
                                        display: block;
                                        width: 260px;
                                        height: 260px;
                                    }
                                }

                                &__info{
                                    .item__sub{
                                        font-size: 19px;
                                        -webkit-line-clamp: 1 !important;
                                    }

                                    .item__name{
                                        font-size: 21px;
                                        word-break: keep-all;
                                    }

                                    .item__title{
                                        margin-top: 15px !important;
                                    }
                                }

                                &__cart{
                                    top: 370px;
                                }
                            }
                        }
                    }
                }
            }

            &-list{
                margin-bottom: 30px;
                padding-bottom: 30px;
                width: 580px;
                box-sizing: border-box;
                border: 1px solid #eee;

                &:not(:first-child){
                    margin-top: 55px;
                }
            }

            &-info{
                position: relative;
                height: 380px;
                overflow: hidden;

                .logo{
                    position: relative;
                    text-align: center;

                    img{
                        display: inline-block;
                        width: 100%;
                    }

                    &:after{
                        position: absolute;
                        left: 0;
                        top:  0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,0.5);
                        content: '';
                        z-index: 1;
                    }
                }

                &__tit{
                    position: absolute;
                    left: 0;
                    top: 85px;
                    width: 100%;
                    font-size: 42px;
                    font-weight: bold;
                    color: #fff;
                    text-align: center;
                    z-index: 2;

                    i{
                        display: inline-block;
                        position: relative;
                        margin: -7px 0 0 10px;
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        background: rgba(0,0,0,0.4);
                        vertical-align: middle;

                        &:after{
                            position: absolute;
                            right: 50%;;
                            top: 50%;
                            margin: -4px -2px 0 0;
                            width: 6px;
                            height: 6px;
                            vertical-align: middle;
                            border: solid #fff;
                            border-width: 2px 2px 0 0;
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                            content: '';
                        }
                    }
                }

                &__txt{
                    position: absolute;
                    left: 0;
                    top: 145px;
                    padding: 0 20px;
                    width: 100%;
                    line-height: 1.4;
                    font-size: 23px;
                    color: rgba(255,255,255,0.6);
                    text-align: center;
                    box-sizing: border-box;
                    z-index: 2;
                }
            }

            &-banner{
                border-bottom-right-radius: 60% 45px;
                border-bottom-left-radius: 60% 45px;
                overflow: hidden;

                .swiper{
                    &-slide{
                        a{
                            img{
                                display: block;
                            }
                        }
                    }

                    &-pagination{
                        margin-top: 0;
                    }

                    &-pagination-progressbar{
                        position: absolute;
                        left: 30px;
                        top: 547px;
                        margin-top: 0;
                        width: 100px;
                        height: 3px;
                        overflow: hidden;
                        background: rgba(255,255,255,.25);
                        z-index: 1;

                        &-fill{
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: #fff;
                            transform: scale(0);
                            transform-origin: left top;
                            transition: all 0.3s;
                            transform: translate3d(0,0,0);
                            z-index: 10;
                        }
                    }
                }
            }

            .category{
                position: relative;
                margin-top: -1px;
                padding-top: 30px;
                border-top: 0;
                z-index: 1;

                .category__depth1{
                    border-bottom: 0;

                    &__list{
                        margin-right: 20px;
                        width: 120px;
                        height: 120px;
                        border-left: 0 !important;

                        &:first-child{
                            margin-left: 30px;
                        }

                        &:last-child{
                            margin-right: 30px;
                        }
                        
                        a{
                            border-radius: 50%;
                            background: $colorBG;

                            span{
                                margin-top: 15px;
                                padding: 0 5px;
                                // overflow-wrap: break-word;
                                // white-space: pre-wrap;
                                // word-wrap: break-word;
                                word-break: keep-all;
                                -webkit-hyphens: none;

                                i{
                                    background-image: url('/front_mo/images/ico_category3.png?ver=220125') !important;
                                    &:before{display: none;}
                                }
                            }
                        }

                        @for $i from 1 through 11 {
                            &:nth-of-type(#{($i)}) i{
                                background-position-y: (-#{-50+(($i)*50)}px);
                                &:after{display: none;}
                            }
                        }
                    }
                }
            }

            &.category .category__depth1__list{
                width: 140px;

                a i{
                    background-image: url('/front_mo/images/ico_category3.png?ver=220125') !important;

                    &:before{display: none;}
                }

                @for $i from 1 through 11 {
                    &:nth-of-type(#{($i)}) i{
                        background-position-y: (-#{-50+(($i)*50)}px);
                    }
                }

            }

            .contents-top{
                padding: 35px 0;
                margin: -17px 30px 0;
                border-top: 1px solid $colorBC;
            }

            @keyframes fadeInUp {
                0% {
                    opacity: 0;
                    transform: translate3d(0, 30px, 0);
                }

                50% {
                    opacity: 1;
                }

                100% {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }

            .hPara {
                opacity: 0;
            }

            .fadeInUp {
                -webkit-animation: fadeInUp 0.9s both 0.1s cubic-bezier(0.22, 0.61, 0.87, 1.27);
                        animation: fadeInUp 0.9s both 0.1s cubic-bezier(0.22, 0.61, 0.87, 1.27);
            }
        }
    }

    .cardEvent_list{
        padding: 0 30px;
        max-height: 450px;
        overflow-y: auto;

        li{
            padding: 20px 0;

            &:not(:first-child){
                border-top: 1px solid $colorBC;
            }

            .name{
                font-size: 24px;
            }

            .benefit{
                margin-top: 10px;
                font-size: 23px;
                font-weight: 600;
            }
            .condition{
                margin-top: 10px;
                font-size: 22px;
            }
            .period{
                margin-top: 10px;
                font-size: 20px;
                color: $color88;
            }
        } 
    }
}
