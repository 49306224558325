.hd {
    &__myClaimList {
        padding-top: 190px;

        .item-box__list {
            
            .img-box.hasBadge {
                
                &:before {
                    content: '교환상품';
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 30px;
                    background: $gcolor;
                    color: #FFF;
                    font-size: 22px;
                    text-align: center;
                    line-height: 30px;
                }
            }

        }

        .price-area{
            padding: 0 !important;
        }
    }

    [class*="myClaim"]{
        .item-box__list{
            .txt-area {
                width: -webkit-calc(100% - 265px) !important;
                width: calc(100% - 265px) !important;
            }

            .multiCase .mealTop-area__inner{
                padding: 25px 0;
            }
        }

        .reason-area, .infobox{
            padding: 25px 20px !important;
        }
        .refund-desc{
            padding: 20px !important;
        }
    }
}
