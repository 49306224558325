.hd {

    .overview {

        .delivery-menu {
            padding: 15px 20px;

            &__list {
				position: relative;
				padding-left: 18px;
                @extend %font_xs;
				line-height: 38px;

                &:before {
                    content: '-';
					position: absolute;
					top: 0;
					left: 0;
                }

                span {
                    display: block;
                    float: right;
                }
            }
        }
    }
}
