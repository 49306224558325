.hd {
    &#chinese_room {
        .top-menu {
            display: none;
        }
    }

    &__chinese {

        .exclusive {
            padding: {
                top: 50px;
                bottom: 50px;
            }
            border-bottom: 3px solid $gcolor;

            &:first-child {
                padding-top: 0;
            }

            &:nth-child(2),
            &:nth-child(3) {
                padding-bottom: 40px;
            }

            // 제목
            &__title {
                margin-bottom: 35px;
                text-align: center;
                @extend %font_m;
                font-weight: 400;
                color: #3D3D3D;

                strong {
                    @extend %font_m;
                    font-weight: 600;
                }
            }

            //주문안내
            &__order-info {
                width: 550px;
                margin: 0 auto;

                li {
                    position: relative;
                    margin-top: 10px;
                    padding-left: 20px;
                    line-height: 1.5;
                    @extend %font_xs;
                    color: #3D3D3D;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        width: 6px;
                        height: 6px;
                        background: #3D3D3D;
                        border-radius: 50%;
                    }
                }
            }

            &__list {
                width: 540px;
                margin: 0 auto;
                font-size: 0;

                li {
                    display: inline-block;
                    vertical-align: top;
                    margin: {
                        top: 15px;
                        left: 15px;
                    }

                    &:nth-child(-n+3) {
                        margin-top: 0;
                    }

                    &:nth-child(3n+1) {
                        margin-left: 0;
                    }

                    input[type="radio"],
                    input[type="checkbox"] {
                        @extend %hidden;
                    }

                    .btn__default.input {
                        width: 170px;
                        height: 60px;
                        line-height: 58px;
                    }

                    input[type="radio"]:checked + .btn__default.input,
                    input[type="checkbox"]:checked + .btn__default.input {
                        border: 1px solid $gcolor;
                        background: $gcolor;
                        color: #FFF;
                    }
                }
            }

            &__menu {
                @extend %vertical;
                font-size: 0;
                margin: 0 -15px;

                &__list {
                    display: inline-block;
                    width: 275px;
                    margin: 0 15px 40px;
                    vertical-align: top;
                }

                &__date {
                    display: block;
                    height: 65px;
                    background: $gcolor;
                    text-align: center;
                    line-height: 65px;
                    @extend %font_s;
                    font-weight: 600;
                    color: #FFF;
                }

                &__img {
                    position: relative;
                    width: 275px;
                    height: 275px;
                }

                &__title {
                    display: block;
                    padding: 15px 0;
                    line-height: 32px;
                    @extend %font_xs;
                    @include line(2);
                }

                &__icon {
                    border-top: 1px solid #BCBCBC;
                    padding: 10px 0;

                    li {
                        display: inline-block;
                        vertical-align: top;
                        margin-left: 14px;
                    }
                }

                .boxInner {
                    margin-bottom: 15px;
                    line-height: 30px;
                    font-size: 20px;

                    &__list {
                        padding-left: 8px;
                        text-indent: -8px;
                    }
                }
            }
        }
    }
}