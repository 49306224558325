.hd{
	.product__wrap {
		padding:0 20px;
		clear: both;
		
		&__list {
			display:flex; flex-wrap:wrap; justify-content:space-between; counter-reset:best_list;
			
			&--item {
				position:relative; width:290px; flex:0 0 290px; margin-top:40px; 
				padding-bottom: 15px; border-bottom:solid 1px #bcbcbc;

				.checks {
					display:block; position:relative;
					
					&-wrap {
						display:block; position:absolute; top:15px; right:15px; z-index:1;
					}
					
					&::before {
						margin-right:0;
					}
				}

				&--no {
					display: inline-block;
					position: relative;
					margin-bottom: 24px;
					padding: 0 5px;
					@extend %font_l;
					&:after {
						display: inline-block;
						counter-increment : best_list;
						content:counter(best_list, decimal-leading-zero);
						font-weight:600;
					}
					&:before {
						display: inline-block;
						position: absolute;
						left: 0;
						bottom: 0;
						z-index: -1;
						width: 100%;
						height: 8px;
						background: $ocolor;
						content: '';
					}
				}
				.item {
					$self: &;
					position: relative;
					width: 100%;
					@extend %vertical;
		
					a {
						display: block;
					}
		
					&__tag {
						display: flex;
						overflow: hidden;
						height: 28px;
		
						span {
							overflow: hidden;
							height: 24px;
							border: solid 1px #c2c2c2;
							border-radius: 3px;
							color: $gcolor;
							line-height: 24px;
							text-align: center;
							font-size: 15px;
							padding: 0 5px;
							min-width: 40px;
		
							&+span {
								margin-left: 5px;
							}
						}
		
						// 태그가 있을때 여백 제거
						&+.item__title {
							margin-top: 0;
						}
					}
		
					&__thumb {
						width: 100%;
						height: 290px;
						position: relative;
					    border: 1px solid rgba(0,0,0,0.05);
					    box-sizing: border-box;
		
						figure {
							height: 100%;
						}
					}
		
					&__info {
						position: relative;
						margin-top: 5px;
					}
		
					&__title {
						margin-top: 33px;
					}
		
					&__sub {
						display: block;
						height: 30px;
						font-size: 20px;
						line-height: 30px;
						color: #555;
						@include line(1);
					}
		
					&__name {
						display: block;
						width: 100%;
						min-height: 60px;
						line-height: 30px;
						font-weight: bold;
						margin-bottom: 5px;
						@extend %font_xs;
						@include line(2);
					}
		
		
					&__price{
						margin-top: 5px;
						letter-spacing: 0;

							&-ori {
								display: inline-block;
								color: $color88;
								font-size: 20px;
								text-decoration: line-through;


								+ .item__price-dc{margin-top: 0;}
							}
			 
			
						&-dc {
							display: block;
							margin-top: 28px;

							@extend %font_xs;
							text-decoration: none;
			
							strong {
								@extend %font_m;
							}

							&-rate{
								display: inline-block;
								margin-right: 5px;
								font-size: 20px;
								font-weight: 600;
								color: #ff8a2f;
							}
						}
					}
		
					&__cart {
						position: absolute;
						right: 10px;
						top: 425px;
						z-index: 1;
		
						span {
							display: block;
							position: relative;
							font: 0/0 a;
		
							&:before {
								content: "";
								display: block;
								background: url(/front_mo/images/icon_cart.png) no-repeat center center;
								background-size: 100% auto;
								width: 50px;
								height: 50px;
								margin: 0 auto;
								box-sizing: border-box;
							}
						}
					}

					&__add {
						position: absolute;
						right: 10px;
						bottom: 85px;
						height: 0;
						z-index: 1;

						span {
							display: block;
							position: relative;
							font: 0/0 a;
		
							&:before {
								display: block;
								margin: 0 auto;
								width: 50px;
								height: 50px;
								line-height: 40px;
								font-size: 49px;
								color: #fff;
								box-sizing: border-box;
								background: #c5c5c5;
								background-size: 100% auto;
								border-radius: 50%;
								content: "+";
							}
						}
					}
		
					&__text{
						display: block;
						min-height: 33px;
						overflow: hidden;

						> span{
							display: block;
							margin-top: 10px;
							font-size: 20px;
							font-weight: 600;

							&:nth-of-type(1){
								margin-top: 10px;
							}
						}

						.green{color: $gcolor;}
						.orange{
							font-weight: normal;
							color: $color88;
						}
						.option{
							line-height: 1.4;
							font-weight: normal;
						}
					}

					&__label{
						display: table;
						position: absolute;
						left: 0;
						top: 0;
						width: 50px;
						height: 50px;
						font-size: 17px;
		                font-weight: 600;
		                color: #fff;
		                text-align: center;
						background: rgba(145,140,0,0.7);
						z-index: 0;

						&-txt{
							display: table-cell;
							padding: 0 5px;
							vertical-align: middle;
						}
					}

					&__count {
						margin-top: 15px;
						@extend %count;
					}

					&__value{
						position: relative;
						margin-top: 15px;
						height: 33px;
						border: 1px solid $gcolor;
						border-radius: 30px;

						span{
							font-size: 18px;
							color: #666;

							&:not(:first-child):before{
								display: inline-block;
								margin: -3px 9px 0 5px;
								width: 1px;
								height: 15px;
								background: #999;
								vertical-align: middle;
								content: '';
							}

							&:first-child:before{
								position: relative;
								display: inline-block;
								margin: -4px 8px 0 -3px;
								width: 37px;
								height: 40px;
								vertical-align: middle;
								background: url("/front_mo/images/sp_ico.png?ver=20211214") no-repeat -267px -100px;
							    transform: scale(0.60);
								content: '';
								z-index: 1;
							}

							&:first-child:after{
		                        position: absolute;
		                        left: -1px;
		                        top: -1px;
		                        width: 35px;
		                        height: 35px;
		                        background: $gcolor;
		                        content: '';
		                        z-index: 0;
		                        border-radius: 50%;
		                    }
						}
					}

					.hd__btn-heart {
						right: 12px;
						top: 178px;
					}
				}
			}
		}
		

		&__btn{
			display: flex;
			
			button{
				width: 290px;

				&:nth-of-type(1){
					margin-right: 20px;
				}
			}	
		}
	}
}