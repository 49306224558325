.mem__grade{
	height:196px; position:relative;
	.mem__grade__progress{
		height:10px; position:absolute; background:#efefef; left:10px; right:10px; bottom:50px;
		&-bar {
			background:$gcolor; height:10px; transition:all .2s;
			span {
				position:absolute; top:-56px; text-align:center; transform:translateX(-50%); font-size:22px;
				&.friends {left:20px;
					&::before {background:#95b3c4;}
				}
				&.white {left:180px;
					&::before {background:#e0e0e0;}
				}
				&.green {left:340px;
					&::before {background:#006450;}
				}
				&.black {left:500px;
					&::before {background:#000;}
				}
				&::before {
					content: "";
					position: absolute;
					width: 14px;
					height: 30px;
					bottom: -52px;
					border-radius: 10px;
					left: 50%;
					transform: translateX(-50%);
				}
				&::after{
					content: "";
					display: none;
					position: absolute;
					bottom: -10px;
					left: 50%;
					transform: translateX(-50%);
					width: 0px;
					height: 0px;
					border-top: 12px solid #cfcfcf;
					border-bottom: 0px solid transparent;
					border-right: 12px solid transparent;
					border-left: 12px solid transparent;
				}
			}
		}
	}	
}

.mem__fix {
	border-top:solid 1px #bcbcbc; text-align:center; padding:50px 0;
	h3 {display:inline-block; padding-bottom:5px; border-bottom:solid 1px #626262; color:$color3D; font-size:22px; font-weight:bold;}
	p {margin:30px 0 34px; color:$color3D; font-size:40px; font-weight:bold;}
	a {display:block; background:&gcolor; width:260px; height:70px; margin:0 auto; border-radius:10px; color:#fff; line-height:68px; text-align:center; font-size:22px;}
}

.mem__next__grade {
	border-top:solid 1px #bcbcbc; text-align:center; padding:50px 0; border-bottom:solid 3px $gcolor;
	h3 {display:inline-block; padding-bottom:5px; border-bottom:solid 1px #626262; color:$color3D; font-size:22px; font-weight:bold;}
	div {
		p {padding-top:25px; color:$color3D; font-size:28px;
			strong {font-size:28px;}
		}
		> strong {display:block; padding-top:15px; font-size:28px;}
	}
}

.mem__benefit {
	text-align:center; padding-bottom:100px;
	&::before {content:""; display:block; padding:60px 0 65px;}
	a {display:inline-block; padding-bottom:4px; border-bottom:solid 2px $gcolor; color:$color3D; font-size:22px; font-weight:bold;}
	&-info {margin-top:60px; padding-top:40px; border-top:solid 1px #ddd; text-align:left;
		h3 {color:$color3D; font-size:20px; font-weight:bold;}
		ul {margin-top:25px;
			li {padding-left:10px; color:#6e6e6e; line-height:2; text-indent:-10px; font-size:19px;}
		}
	}
}
// sub-cont grade-w
@mixin memberType($bg) {
	background:$bg; width:70px; height:70px; line-height:70px; border-radius:50%; transform:translateX(-50%); color:#fff; top:-102px; font-size:18px;
}
.mem__grade__wrap{
	padding:0 18px;
	&.grade-f {
		.mem__grade__progress {
			&-bar {width:20px;
				.friends {
					@include memberType(#95b3c4);
					&::before{background: #95b3c4;}
					&::after {display:block; border-top-color:#95b3c4;}
				}
			}
		}
		.mem__next__grade-type {color:#c7c7c7;}
		.mem__benefit::before {background:url(/front_mo/images/grade-y.png?ver=20210218) center center no-repeat; background-size: 100% auto; height:242px;}

		&.next {
			.mem__next__grade-type {color:#a6a6a6;}
			.mem__benefit::before {background:url(/front_mo/images/grade-w.png?ver=20210218) center center no-repeat; height:319px;}
		}
	}
	&.grade-w {
		.mem__grade__progress {
			&-bar {width:180px;
				.white {
					@include memberType(#cfcfcf);
					&::before{background: #cfcfcf;}
					&::after {display:block; border-top-color:#cfcfcf;}
				}
			}
		}
		.mem__next__grade-type {color:#cfcfcf;}
		.mem__benefit::before {background:url(/front_mo/images/grade-w.png?ver=20210218) center center no-repeat; height:319px;}

		&.next {
			.mem__next__grade-type {color:#006450;}
			.mem__benefit::before {background:url(/front_mo/images/grade-g.png?ver=20210218) center center no-repeat; height:319px;}
		}
	}

	&.grade-g {
		.mem__grade__progress {
			&-bar {width:340px;
				.green {
					@include memberType(#006450);
					&::before{background: #006450;}
					&::after {display:block; border-top-color:#006450;}
				}
			}
		}
		.mem__next__grade-type {color:#006450;}
		.mem__benefit::before {background:url(/front_mo/images/grade-g.png?ver=20210218) center center no-repeat; height:319px;}

		&.next {
			.mem__next__grade-type {color:#000;}
			.mem__benefit::before {background:url(/front_mo/images/grade-b.png?ver=20210218) center center no-repeat; height:319px;}
		}
	}

	&.grade-b {
		.mem__grade__progress {
			&-bar {width:500px;
				.black {
					@include memberType(#000);
					&::before{background: #000;}
					&::after {display:block; border-top-color:#000;}
				}
			}
		}
		.mem__next__grade-type {color:#000;}
		.mem__benefit::before {background:url(/front_mo/images/grade-b.png?ver=20210218) center center no-repeat; height:319px;}
	}
}