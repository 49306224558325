.hd { 
    // 실페이지 내 사용하는 class 커스텀
    &.amore {
        .hd__inner {
            padding-top: 70px !important;
            padding-bottom: 100px;

            &.hd__plan-meals .overview,
            .hd__main__h3 {
                padding-top: 30px;
            }
        }

        &.docbar--hide {
            padding-bottom: 0;
        }

        &.no--doc {
            padding-bottom: 85px;
        }
    
        &.docbar--hide .amore__nav {
            transform: translate3d(-50%, 101px, 0);
            transition: transform 0.5s linear;
        }

        .meals_txt {
            text-align: center;
            font-size: 18px;

            span {
                display: block;
                margin-bottom: 10px;
                font-size: 24px;
                font-weight: 600;
                color: $gcolor;
            }
        }

        .weekend {

            .btn__default.input {
                display: table;
                line-height: 30px;
                text-align: center;

                .text {
                    display: table-cell;
                    vertical-align: middle;
                }

                .text__desc {
                    display: block;
                    font-size: 20px;
                    font-weight: normal;
                }
            }
        }

        .meals-area__cont__desc {
            font-size: 20px;
        }

        .board__list {
            list-style: none;
        }

        .type-box {
            margin-top: 20px;
            box-shadow: 9px 9px 20px 5px rgba(0, 0, 0, 0.1);
        }

        .item-confirm__desc {
            display: none;
        }
    }

    .amore {
        &__header {
            position: fixed;
            z-index: 4;
            width: 100%;
            max-width: 640px;
            height: 69px;
            background: #FFF;
            border-bottom: 1px solid #BCBCBC;
            text-align: center;

            &__title {
                position: absolute;
                top: 50%;
                left: 50%;
                font: {
                    size: 30px;
                    weight: 600;
                };
                transform: translate(-50%, -50%);
            }
        }

        &__nav {
            position: fixed;
            bottom: 0;
            left: 50%;
            z-index: 2;
            width: 100%;
            max-width: 640px;
            height: 100px;
            background: #FFF;
            border-top: 1px solid #DDD;
            transform: translate(-50%, 0);
    
            &__list {
                display: block;
                position: relative;
                float: left;
                width: 33.333%;
                height: 100px;
                text-align: center;
                font: {
                    size: 14px;
                    weight: 600;
                };
                color: #3D3D3D;
    
                strong {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 20px;
                }
    
                $icons: ("home": "home", "cart": "cart", "my": "mypage");
                @each $name, $files in $icons {
                    &.#{$name}:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 15px;
                        left: 50%;
                        width: 40px;
                        height: 40px;
                        margin-left: -20px;
                        background: url(/front_mo/images/ico_common_#{$files}.png) no-repeat 0 0;
                        background-size: contain;
                    }
                }
            }
        }
    }

    .mchoice__list {
        position: relative;

        .soldout {
            display: none;
            width: 252px;
            height: 55px;
            margin-top: 5px;
            background: #A8A8A8;
            line-height: 55px;
            font-size: 22px;
            color: #FFF;
            text-align: center;

            &--show {
                display: block;
            }
        }

    }
}

.wdw-type {
    .hd {
        // 실페이지 내 사용하는 class 커스텀
        &.amore {
            .hd__inner {
                padding-top: rem(70px) !important;
                padding-bottom: rem(100px);
    
                &.hd__plan-meals .overview,
                .hd__main__h3 {
                    padding-top: rem(30px);
                }
            }
    
            &.docbar--hide {
                padding-bottom: 0;
            }
    
            &.no--doc {
                padding-bottom: rem(85px);
            }
        
            &.docbar--hide .amore__nav {
                transform: translate3d(-50%, 5.05rem, 0);
                transition: transform 0.5s linear;
            }
    
            .meals_txt {
                text-align: center;
                font-size: rem(18px);
    
                span {
                    display: block;
                    margin-bottom: rem(10px);
                    font-size: rem(24px);
                    font-weight: 600;
                    color: $gcolor;
                }
            }
        }
    
        .amore {
            &__header {
                position: fixed;
                z-index: 4;
                width: 100%;
                height: rem(69px);
                background: #FFF;
                border-bottom: rem(1px) solid #BCBCBC;
                text-align: center;
    
                &__title {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font: {
                        size: rem(30px);
                        weight: 600;
                    };
                    transform: translate(-50%, -50%);
                }
            }
    
            &__nav {
                position: fixed;
                bottom: 0;
                left: 50%;
                z-index: 2;
                width: 100%;
                max-width: rem(640px);
                height: rem(100px);
                background: #FFF;
                border-top: rem(1px) solid #DDD;
                transform: translate(-50%, 0);
        
                &__list {
                    display: block;
                    position: relative;
                    float: left;
                    width: 33.333%;
                    height: rem(100px);
                    text-align: center;
                    font: {
                        size: rem(14px);
                        weight: 600;
                    };
                    color: #3D3D3D;
        
                    strong {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: rem(20px);
                    }
        
                    $icons: ("home": "home", "cart": "cart", "my": "mypage");
                    @each $name, $files in $icons {
                        &.#{$name}:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: rem(15px);
                            left: 50%;
                            width: rem(40px);
                            height: rem(40px);
                            margin-left: rem(-20px);
                            background: url(/front_mo/images/ico_common_#{$files}.png) no-repeat 0 0;
                            background-size: contain;
                        }
                    }
                }
            }

            .input_type.phone span {
                margin: rem(-10px 11px 0);
            }
        }
    
        .mchoice__list {
            position: relative;
    
            .soldout {
                display: none;
                width: rem(252px);
                height: rem(55px);
                margin-top: rem(5px);
                background: #A8A8A8;
                line-height: rem(55px);
                font-size: rem(22px);
                color: #FFF;
                text-align: center;
    
                &--show {
                    display: block;
                }
            }
    
        }
    }
}