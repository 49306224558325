.hd {
    &__default-caulse {
        margin-top:35px;
    }

    .default-caulse {
        &__txt {
            line-height:34px;
            @extend %font_xs;
            white-space: pre-line;
            word-break: keep-all;
        }

        &__history {
            margin-top: 50px;
            padding: 0 15px;
            background: url(/front_mo/images/ico_comm_select.png) no-repeat 95% 50%;
            -webkit-appearance: none;
        }
    }

    &.defaultCaulse {
        .default-caulse {
            &__txt {
                line-height:34px;
                @extend %font_xs;
                white-space: pre-line;
                word-break: keep-all;
            }
    
            &__history {
                margin-top: 0;
                padding: 0 15px;
                background: url(/front_mo/images/ico_comm_select.png) no-repeat 95% 50%;
                -webkit-appearance: none;
            }
        }
    }
}