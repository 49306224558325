.hd {
    .myReview {
        margin-top: 30px;
		.myReview__title {
			display: flex;
			align-items: center;
			margin-bottom:15px;
			p {margin-bottom:0; flex:0 0 auto;}
			em {margin-left:50px; padding-left:20px; color:#888; letter-spacing:-.5px; text-indent:-20px; font-size:20px; word-break:keep-all;}
		}
        &-list {
            border-top: 3px solid $gcolor;

            > li {
                border-bottom: 1px solid #BCBCBC;

                &:first-child {
                    margin-top: 0;
                }
            }

            &__view {
                position: relative;
                min-height: 100px;
                padding: 25px 50px 25px 120px;
                background: url("/front_mo/images/icon_common_drop-down.png") no-repeat 100% 50%;

                &.on {
                    background: url("/front_mo/images/icon_common_drop-down-on.png") no-repeat 100% 50%;
                }

                &__thumb {
                    position: absolute;
                    top: 25px;
                    left: 0;
                    width: 100px;
                    height: 100px;
                }

                &__img {
                    display: block;
                    position: absolute;
                    z-index: 2;
                    top: 30px;
                    left: 0;

                    figure {
                        width: 100px;
                        height: 100px;
                    }
                }

                // 상품 정보
                &__txt {
                    line-height: 32px;
                    @extend %font_xs;
                }

                &__title {
                    display: block;
                    @include line(2);
                }

                &__pdName {
                    display: block;
                    @include line(1);

                    .best {
                        display: inline-block;
                        height: 24px;
                        margin-right: 10px;
                        padding: 0 12px;
                        vertical-align: 2px;
                        background: #FEA45E;
                        font-size: 14px;
                        text-align: center;
                        line-height: 24px;
                        color: #FFF;
                    }
                }

                &__opts {
                    display: block;
                    position: relative;
                    padding-left: 60px;
                    word-break: normal;
                    word-wrap: break-word;

                    span {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .block {
                    display: block;
                }

                .gift {
                    position: relative;
                    height: 46px;
                    margin-top: 12px;
                    @extend %vertical;

                    &__thumb {
                        width: 50px;
                        height: 46px;
                    }

                    &__name {
                        position: absolute;
                        top: 5px;
                        left: 70px;
                        width: 340px;
                        line-height: 30px;
                        @extend %font_xs;
                        word-break: normal;
                        word-wrap: break-word;
                        @include line(1);
                    }
                }
            }

            &__detail {
                display: none;
                position: relative;
                // padding: 25px 0;
                border-top: 1px solid #BCBCBC;

                &__title {
                    display: block;
                    padding-right: 130px;
                    line-height: 36px;
                    @extend %font_s;
                    word-break: break-all;
                }

                &__soldDate {
                    display: block;
                    @extend %font_xs;
                }

                &__date {
                    display: block;
                    margin-bottom: 25px;
                    color: #888;
                    line-height: 32px;
                    @extend %font_xs;
                }

                &__cont {
                    line-height: 32px;
                    @extend %font_xs;
                    word-break: normal;
                    word-wrap: break-word;

                    // 등록된 상품 이미지
                    .img {
                        display: block;
                        margin-top: 10px;
                        font-size: 0;

                        img {
                            vertical-align: top;
                        }
                    }
                }

                // 수정버튼
                .btn-half {
                    position: absolute;
                    top: 25px;
                    right: 0;
                    width: 110px;
                    height: 55px;
                    line-height: 55px;
                    @extend %font_xs;
                    font-weight: 600;
                }
            }

            &__user {
                padding: 25px 0;
            }

            &__admin {
                padding: 25px;
                background: #f9f9f9;
                border-top: 1px solid #BCBCBC;

                &__cont {
                    padding: 20px 0;
                    font-size: 22px;
                    line-height: 1.4;
                    word-break: normal;
                    word-wrap: break-word;
                }

                &__info {
                    font-size: 22px;
                }

                .detail__info {
                    &__id {
                        font-weight: 600;
                        color: $color3D;
                    }

                    &__date {
                        float: right;
                        font-size: 22px;
                        color: #888;
                    }
                }
            }
        }
    }
}
