.hd {
    .carefood__wrap {
        position: relative;
        padding-bottom: 50px;
 
        .btn-area {
            display: flex;
            position: fixed;
            bottom: 0;
            right: 50%;
            //right: 0;
            margin: 0 -320px 0 0;
            width: 640px;
            //transform: translate3d(0, -100px, 0);
            transform: translateY(-100px);
            transition: transform 1s;
            z-index: 1;

            a {
                display: block;
                position: relative;
                width: 100%;
                height: 90px;
                line-height: 90px;
                text-align: center;
                font-size: 28px;
                font-weight: 600;
                transform:translateZ(0);
                -webkit-transform: translate3d(0, 0, 0);

                &.btn-orange {
                    line-height: 85px;
                    color: $gcolor;
                    text-align: center;
                    background: #fff;
                    border: 1px solid $gcolor;
                }

                &.btn-gift{
                	position: relative;
		        	flex-basis: 62%;
                    padding-left: 5%;
                    max-width: 180px;
		        	color: #fff;
		        	border: 0;
		        	background: #e5b83f;

		        	&:after{
		        		content: '';
		        		position: absolute;
		        		left: 41px;
                        bottom: 30px;
                        width: 27px;
                        height: 29px;
                        background: url('/front_mo/images/sp_ico.png?ver=210309') no-repeat -185px -73px;
                        background-size: 640px;
                        transform:rotate(.001deg);
		        	}
                }
            }
        }

        .options{
            &__inner{
                bottom: -88px;
            }

            &__sec{
                height: auto;
            }
        }
    }

    &.docbar--hide {
        .carefood__wrap {
            .btn-area {
                transform: translateY(0);
            }
        }
    }
}