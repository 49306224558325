.hd {
    &__greatingTable-Detail {
        // padding-bottom: 85px;

        .hd__btn-sec {
            a {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                text-align: center;
                height: 85px;
                line-height: 83px;
                @extend %font_m;
                font-weight: 600;
                box-sizing: border-box;
            }
        }
    }

    .greatingTable-Detail {
        &__img {
            position: relative;
            font-size: 0;
        }

        &__btn-like {
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 60px;
            height: 60px;
            border: 1px solid #BCBCBC;
            background: #FFF url(/front_mo/images/btn_heart-off.png) no-repeat 50% 50%;
            border-radius: 50%;
            box-sizing: border-box;

            &.on {
                background-image: url(/front_mo/images/btn_heart-on.png);
            }
        }

        .chapter {
            margin-top: 20px;
            border-top: 3px solid $gcolor;

            &__list {
                font-size: 0;

                li {
                    display: inline-block;
                    width: 100%;
                    padding:13px 18px;
                    border-bottom: 1px solid #BCBCBC;
                    @extend %font_xs;
                    box-sizing:border-box;

                    &.half {
                        width: 50%;
                        border-left: 1px solid #BCBCBC;
                    }

                    &:nth-child(odd) {
                        border-left: 0;
                    }

                    span {
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 18px;
                    }

                    strong {
                        display: inline-block;
                        vertical-align: top;
                        font-weight: 600;
                    }
                }

                &.line02 {
                    span {
                        width: 50px;
                    }
    
                    strong {
                        width: 470px;
                    }
                }
            }

            &__txt {
                // min-height: 500px;
                padding: 40px 20px;
                line-height: normal;
                @extend %font_xs;                
            }
        }

        &__timer {
            margin-top: 20px;
            
            &__sec {
                position: relative;
                padding-right: 100px;
            }

            &__count {
                display: block;
                position: relative;
                height: 90px;
                padding-left: 120px;
                background: $gcolor url(/front_mo/images/ico_greating_clock.png) no-repeat 27px 50%;
                line-height: 90px;
                font-size: 48px;
                font-weight: 600;
                color: #FFF;
                box-sizing:border-box;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 91px;
                    width: 1px;
                    height: 100%;
                    background: #87B6AD;
                }
            }

            &__btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 90px;
                height: 90px;
                background: $ocolor url(/front_mo/images/ico_timer_play.png) no-repeat 50% 50%;

                &.stop {
                    background-image: url(/front_mo/images/ico_timer_stop.png)
                }
            }

            &__notice {
                margin-top: 20px;
                @extend %font_xs;
                color: #888;
            }
        }
    }
}