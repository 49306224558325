.hd {

  	&__mchoice {
		padding-top: 190px;
		
		.meals {
			padding-bottom: 45px;
		}

    	.mchoice {	
      		background: #FFF;
			box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);

			& + .mchoice {
				margin-top:30px;
			}

      		&__title {
        		display: block;
				height: 70px;
				border: {
					top: 3px solid $color3D;
					bottom: 1px solid $colorBC;
				}
        		text-align: center;
        		line-height: 70px;
        		@extend %font_l;
      		}
      
			&__wrap {
				margin: 0 auto;
				padding: 30px 25px 0;
				border-top: 1px solid #DDD;
				font-size: 0;
				display:flex;
				flex-wrap: wrap;
				justify-content: space-between;
				@extend %vertical;
			}

			&__list {
				display: inline-block;
				width: 252px;
				flex:0 0 252px;
				vertical-align: top;
				margin-bottom: 75px;
				border-bottom:solid 1px $colorBC;
				position: relative;

				&__img {
					position: relative;

					figure {
						overflow: hidden;
						width: 252px;
						height: 252px;
					}

					label {
						&:before {
							content: '';
							display: block;
							position: absolute;
							z-index: 2;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							border: 6px solid transparent;
							box-sizing: border-box;
						}
					}

					input[type="checkbox"]:checked + label:before {
						border-color: $gcolor;
					}
				}
				
				&__count {
					margin-top: 5px;
					@extend %count;
				}

				&__promotion {    
					display: block;
					margin-top: 15px;
					font-size: 20px;
					line-height: 30px;
					color: #888;
					font-weight: normal;
					-webkit-box-orient: vertical;
					display: -webkit-box;
					overflow: hidden;
					text-overflow: ellipsis;
					word-break: break-all;
					-webkit-line-clamp: 2;
				}

				&__price {
					margin-bottom: 10px;
					
					&-ori {
						display: inline-block;
						margin-left: 15px;
						@extend %font_xs;
						text-decoration: line-through;
					}
					&-dc {
						display: inline-block;
						@extend %font_xs;
						text-decoration: none;
						strong {
							@extend %font_m;
						}
					}
				}

				&__title {
					display: block;
					margin-bottom: 10px;
					@extend %font_xs;
					font-weight: 600;
					color: #3D3D3D;
					line-height: 32px;
					@include line(2);
				}

				&__material {
					border-top:0;
					text-align: left;
					position: absolute;
					bottom:-45px;
					left:0; right:0;

					li {
						display: inline-block;
						// width: 20%;
						width: 16%;
						margin-top: 5px;
						vertical-align: top;
						text-align: center;

						// &:nth-child(-n+5) {
						// 	margin-top: 0;
						// }

						img {
							width: 35px;
							height: 35px;
							vertical-align: top;
						}
					}
				}

				&--on {
					.mchoice__list__img {

						label {
							&:before {
								content: '';
								display: block;
								position: absolute;
								z-index: 2;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								border-color: $gcolor;
								box-sizing: border-box;
							}
						}
					}
				}

				&.add{
					min-height: 398px;
					border: 1px solid $gcolor;

					a{
						display: block;
						padding-top: 60px;
						height: 100%;
						@extend %font_xs;
						text-align: center;
						box-sizing: border-box;
					}

					.add{
						&__text{
							display: block;
							line-height: 1.5;
							letter-spacing: -0.5px;

							&:before{
								display: block;
								margin: 0 auto 30px;
								width: 100px;
								height: 100px;
								line-height: 94px;
								font-size: 70px;
								font-weight: 600;
								color: #fff;
								border-radius: 50%;
								background-color: $gcolor;
								content: '+';
							}
						}

						&__label{
							display: inline-block;
							margin-top: 30px;
							padding: 5px 25px 8px;
							@extend %font_xs;
							font-weight: 600;
							border-radius: 30px;
							background-color: $ocolor;
						}
					}
				}
			}

			&__select{
				margin: 0 auto;
				max-width: 520px;
				overflow: hidden;

				li{
					position: relative;
					display: table;
					margin-top: 10px;
					height: 80px;

					&:first-child{
						margin-top: 0;
					}

				}

				.mchoice__list{
					&__banner{
						display: table-cell;
						width: 320px;
						vertical-align: middle;
						font-size: 26px;
						text-align: center;

						.point{
							display: block;
							margin-top: 5px;
							font-size: 20px;
							color: $gcolor;
						}
					}

					&__count{
						display: table-cell;
						width: 200px;
						vertical-align: middle;

						input{
							width: 80px;
						}

						.btn__count{
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}
			}

			&__confirm{
				position: fixed;
				left: 50%;
				bottom: 84px;
				margin-left: -320px;
				padding: 20px 30px 30px;
				width: 640px;
				font-size: 22px;
				background: #fff;
			    box-sizing: border-box;
			    border-top: 1px solid #e5e5e5;

				.close{
					position: absolute;
					right: -10px;
					top: -10px;
					width: 25px;
					height: 25px;
					color: #fff;
					border-radius: 50px;
					text-indent: -999em;
					background: #555 url('/front_mo/images/ico_file_delte.png') no-repeat 7px 7px;
					background-size: 11px;
					z-index: 1;
				}

				.confirm{
					&__list{
					    margin-right: -30px;

						ul{
							padding: 10px 25px 10px 0;
							white-space: nowrap;
							overflow-x: auto;

							li{
								position: relative;
								display: inline-block;
								margin-right: 5px;
								padding: 10px 15px;
								border: 1px solid $colorBC;
								border-radius: 10px;
							}
						}
				    }

				    &__txt{
						margin-top: 10px;

						span{
							float: right;
							font-weight: 600;
							color: $gcolor;
						}
					}
				}
			}
		}

		.gift-Choice {
			margin-top: 30px;
			background: #FFF;
			box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);

			&__title {
				display: block;
				height: 70px;
				border: {
					top: 3px solid $gcolor;
					bottom: 1px solid #DDD;
				}
        		text-align: center;
        		line-height: 70px;
        		@extend %font_l;
			}
			
			&__slider {
				position: relative;
				padding: 25px 35px;

				ul {
					height: auto;
					
					li {
						label {
							position: relative;
							display: block;
							
							&:before {
								content: '';
								display: block;
								position: absolute;
								top: 0;
								right: 0;
								width: 54px;
								height: 54px;
								background: #909090 url(/front_mo/images/icon_menu_check.png) no-repeat 50% 50%;;
							}
						}

						input:checked + label:before {
							background-color:$gcolor;
						}

						.gift-Choice__list {
							&__img {
								overflow: hidden;
								width: 252px;
								height: 252px;
							}

							&__info {
								position: relative;
								margin-top: 20px;
							}

							&__title {
								display: block;
								padding-right: 40px;
								line-height: 36px;
								@extend %font_xs;
								font-weight: 400;
							}

							&__price {
								display: block;
								line-height: 36px;
								@extend %font_xs;

								span {
									font-weight: 600;
								}
							}
						}

						.btn__detail-view {
							display: block;
							position: absolute;
							top: 0;
							right: 0;
							font-size: 0;
						}
					}
				}
			}
		}
	}

	&__icon {
    	&__bundle {
			position: absolute;
			z-index: 0;
			top: 10px;
			left: -6px;
			font-size: 0;
			
			span {
				display: block;
				text-align: left;
				height: 37px;

				img {					
					vertical-align: top;
					max-height: 100%;
				}
			}
    	}
  	}
}


.wdw-type {
	.hd {

		&__icon {

			&__bundle {
			position: absolute;
			z-index: 1;
			top: rem(10px);
			left: rem(-6px);
			font-size: 0;
			
			span {
				display: block;
				text-align: left;
				height: rem(37px);

				img {					
					vertical-align: top;
					max-height: 100%;
				}
			}
    	}
		}

	}
}