.hd {
    .member-box {
        margin-top: 25px;
        border-top: 3px solid $color3D;

        // &:first-child {
        //     margin-top: 0;
        // }

        &__title {
            line-height:76px;
            @extend %font_s;
            font-weight: 600;
        }

        .input-box {
            position: relative;

            &.id {
                padding-right: 150px;

                .btn-full {
                    position: absolute;
                    top:0;
                    right:0;
                    max-width: 130px;
                }
            }

            &.email {
                padding-right: 150px;

                .email {
                    color:#BDBDBD;

                    &__box {
                        display: flex;
                        justify-content: space-between;
                        line-height:70px;
                        @extend %font_s;
                        color:#BDBDBD;

                        input {
                            max-width:170px;
                        }

                        .input_mail {
                            display: none;
                            max-width:220px;
                        }

                        select {
                            max-width:220px;
                            padding: {
                                right:70px;
                                left:20px;
                            }
                            height:70px;
                            border: 1px solid #BDBDBD;
                            background:url(/front_mo/images/ico_member_select.png) no-repeat 90% 50%;
                            line-height:70px;
                            color:$color3D;
                        }
                    }
                }

                & .on {
                    select {
                        display: none;
                    }

                    .input_mail {
                        display: block;
                    }
                }

                .btn-full {
                    position: absolute;
                    top:0;
                    right:0;
                    max-width: 130px;
                }
            }

            &__title {
                display: block;
                margin-bottom: 20px;
                @extend %font_xs;
            }

            &__desc {
                margin-top: 10px;
                color: $color88;
                font-size: 24px;
                line-height: 32px;
            }
            
            .birthday {
                &__input {
                    width: 210px;
                }

                &__desc {
                    height: 70px;
                    line-height: 70px;
                    font-size: 24px;
                    margin-left: 10px;
                }

                &:after {
                    content: "";
                    display: block;
                }
            }

            .cellphone {
                &__number {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    line-height: 70px;
                    @extend %font_s;
                    color:#BDBDBD;

                    input {
                        max-width: 170px;
                    }
                }
            }

            .certification {
                &__check {
                    position: relative;
                    margin-bottom: 20px;
                    
                    input {
                        background: transparent;
                    }
                    
                    .back {
                        position: absolute;
                        z-index: -1;
                        top: 50%;
                        right:30px;
                        @extend %font_s;
                        transform:translate3d(0, -50%, 0);
                    }
                }

                &__ok {
                    display: block;
                    padding: 30px 0;
                    text-align: center;
                    @extend %font_xs;
                }
            }

            &.btn-sec {
                padding-right: 150px;
                
                .btn-full {
                    position: absolute;
                    top:0;
                    right:0;
                    max-width: 130px;
                }
            }
        }

        .info-box{
            @extend %font_s;

            .check-label{
                margin-bottom: 30px;
                padding-left: 50px;
            }
        }

        dl.info-box{
            overflow: hidden;
            margin-bottom: 40px;
            border-top: 1px solid $colorBC;

            dt{
                float: left;
                clear: left;
                margin-top: 20px;
                color: #707070;
            }

            dd{
                float: right;
                width: 70%;
                margin-top: 20px;
                text-align: left;
            }
        }

        .checkbox-area {
            margin-bottom: 25px;
            padding: 25px 0;
            border-bottom: 1px solid $colorBC;
        }

        &__devide {
            border-top: 1px solid $colorBC;
            
            p {
                height:70px;
                margin: 20px 0;
                padding:0 20px;
                background: $colorBG;
                line-height:70px;
                @extend %font_xs;
                strong {
                    font-weight: 600;
                }
            }
        }

        &__complete-title {
            position: relative;
            margin-top: 40px;
            text-align: center;
            line-height: 36px;
            @extend %font_m;
            font-weight: bold;

            strong {
                color: $gcolor;
            }

            &:before {
                content: '';
                display: block;
                width: 80px;
                height: 80px;
                margin: 0 auto 35px;
                background: url(/front_mo/images/icon_important-msg.png) no-repeat center center;
                text-align: center;
                background-size: 100% auto;
            }
        }

        &__complete-txt {
            margin-top:10px;

            p {
                margin-top: 25px;
                text-align: center;
                line-height: 32px;
                font-size: 20px;

                &:first-child {
                    margin-top:0;
                }
            }
        }

        &__check{
            &-title{
                margin-top: 40px;
                text-align: center;
                line-height: 36px;
                @extend %font_m;
                font-weight: bold;
            }

            &-txt{
                margin-top:10px;
                text-align: center;
                line-height: 32px;
                font-size: 20px;
            }
        }

        .domain__check {
            margin-top: 15px;
        }
        
        .alertBox {
            margin-top: 10px;
            @extend %font_xs;

            &.fail {
                display: block !important;
                color:$colorRD;
            }

            &.success {
                display: block !important;
                color:$gcolor;
            }
        }

        .userName-mixChar {
            display: none;
            margin-top: 10px;
            color:$gcolor;
            font-size: 22px;
        }

        .userName-space {
            display: none;
            margin-top: 10px;
            color:$gcolor;
            font-size: 22px;
        }

        .upload {
            position: relative;
            text-align: center;
        }

        .input_file {
            overflow: hidden;
            padding:0 50px 0 25px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .file-delete {
            display: none;
            position: absolute;
            top: 50%;
            right: 160px;
            width:30px;
            height:30px;
            background: $gcolor;
            border-radius: 50%;
            transform: translate3d(0, -50%, 0);
        }
    }

    .health-info {
        border-top:1px solid $colorBC;

        &__notice {
            display: block;
            @extend %font_xs;
            color: $gcolor;
            line-height: 30px;
            
            em {
                font-weight: 600;
            }
        }

        &__desc {
            margin: 25px 0;
            @extend %font_xs;
            line-height: 30px;
        }

        &__sec {
            margin-top: 25px;
            font-size:0;
        }

        &__list {
            display: inline-block;
            width: 180px;
            margin: {
                bottom: 15px;
                left: 20px;
            }
            vertical-align: top;
            &:nth-child(3n+1) {
                margin-left: 0;
            }
            
            label {
                max-width: 100%;
                font-weight: 400 !important;
            }

            input[type="checkbox"]:checked + label{
                border: 1px solid $gcolor;
                background: $gcolor;
                color: #FFF;
            }
        }
    }

    .recommender {
        border-top: 1px solid $colorBC;

        &__notice {
            display: block;
            margin: 25px 0;
            @extend %font_xs;
            color: $gcolor;
            line-height: 30px;
        }
        &__guide {
            margin: -20px 0 25px;
            display: block;
            font-size: 18px;
            color: $color88;
            line-height: 30px;
        }

        &__desc {
            margin-bottom: 25px;
            @extend %font_xs;
            line-height: 30px;
        }
    }

    &__join-complete{
        .btn-full {
            display: block;
            width: 100%;
            text-align: center;
            line-height: 70px;
        }
    }

    &__no-login {
        .member-box {
            margin-top: 70px !important;
            border-top:0;
        }

        .btn-half {
            display: block;
            width: 50%;
            text-align: center;
            line-height: 70px;
        }

        .btn__settings {
            position: absolute;
            top: -55px;
            right: 0;
            padding-left: 40px;
            background: url(/front_mo/images/icon_comm_setting-black.png) no-repeat 0 50%;
            line-height: 38px;
            font-size: 22px;
            color: $color3D;
        }
    }

    .btn-full:disabled {
        background: $colorBC;
        border: 1px solid $colorBC;
        color: #fff;
        line-height: 68px;
    }

    .memberSelect {
        width: 170px !important;
        border: 1px solid #BEBEBE;
    }

    .change-name {
        margin-top: 20px;
        padding-left: 15px;
        font: {
            size: 22px;
        };
        line-height: 32px;
        text-indent: -15px;
    }
}