.hd {
    &__market-detail {
        padding: 150px 0 100px;

        .close-area {
            position: fixed;
            // position: -webkit-sticky;
            // position: sticky;
            z-index: 5;
            top: 0;
            left: 50%;
            margin-left: -320px;
            width: 640px;
            height: 70px;
            background: $gcolor;

            a {
                display: block;
                position: absolute;
                top: 50%;
                left: 30px;
                font-size: 0;
                transform: translate3d(0, -50%, 0);

                img {
                    margin-left: 5px;
                    vertical-align: -4px;
                }
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -10px;
                left: 0;
                width: 100%;
                height: 11px;
                background: #FFF;
            }

            &__btn-icon {
                display: inline-block;
                position: relative;
                width: 32px;
                height: 32px;
                margin-left: 10px;
                vertical-align: -8px;

                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 2px;
                    height: 26px;
                    background-color: #fff;
                }

                &:before {
                    transform: rotate(45deg);
                    margin: -13px 0 0 -1px;
                }

                &:after {
                    transform: rotate(-45deg);
                    margin: -13px 0 0 -1px;
                }

            }

            // &--hidden {
            //     position: absolute;
            // }
        }

        .page-mark {
            display: block;
            position: relative;
            top: 0;
            left: 0;
            float: left;
            width: 100%;
            height: 70px;
            line-height: 70px;
            margin-top: 0;
            @extend %font_m;
            font-weight: normal;
            color: #fff;
            background: transparent;
            text-align: center;
            border-radius: 12px 12px 0 0;
            text-align: center;

            &:after{
                display: none;
            }
        }

        .overview {
            width: 580px;
            margin: 30px auto 0;

            &__title-area {
                position: relative;
                padding-top: 10px;
                @extend %font_xl;
				line-height: 1.3;

                span {
                    display: block;
                    padding: 0 140px 0 0;
                    word-break: break-all;

                    &.strong{
                        font-weight: 600;
                    }
                }
            }

            &__listbox {
                *{
                    font-size: 20px;
                    color: $color3D !important;
                }

                margin-top: 40px;
                border: {
                    top: 1px solid $color3D;
                    bottom: 1px solid $colorBC;
                }

                .overview__list:not(:first-child){
                    border-top: 1px solid $colorBC;
                }
            }

            &__list {
                @extend %font_xs;
                color: $color88;
            }

            &__tag{
                margin-bottom: 20px;

                span{
                    display: inline-block;
                    margin-right: 2px;
                    border: 1px solid $colorBC;
                    padding: 5px 8px;
                    font-size: 17px;
                    color: #777;

                    &.c1{color: $color3D;}
                    &.c2{color: #e14141;}
                    &.c3{color: #264f91;}
                    &.c4{color: #00aa90;}
                } 
            }

            &__icon-area {
                // display: table;
                width: 100%;

                &__inner {
                    display: table-cell;
                    vertical-align: middle;
                }

                span {
                    // display: table-cell;
                    display: inline-block;
                    width: 15.6%;
                    height: 69px;
                    padding: 15px 0 10px;
                    font-size: 17px;
                    // padding: 10px 0 10px 23px;
                    vertical-align: middle;
                    text-align: center;

                    img {
                        display: block;
                        margin: 0 auto 9px;
                    }
                }
            }

            &__gram {
                display: inline-block;
                padding: 20px 40px 20px 20px;
                border-right: 1px solid $colorBC;

                strong {
                    margin-left: 10px;
                }

            }

            &__kcal {
                display: inline-block;
                padding: 20px 0 20px 20px;

                strong {
                    margin-left: 18px;
                }

            }

            &__ingredient {
                padding: 20px;
                line-height: 32px;

                span{
                    display: block;
                    margin: 0 -20px;
                }

                strong {
                    margin-left: 18px;
                }
            }

            &__thumb {
                position: relative;
                height: 580px;
                margin: 25px 0;
                @extend %vertical;

                figure {
                    height: 100%;
                }

                .swiper-pagination {
                    position: absolute;
                    bottom: 24px;
                    left: 32px;
                    z-index: 1;
				}
				.swiper-counter {position:absolute; left:25px; bottom:25px; background:rgba(255,255,255,.8); width:80px; border-radius:5px; color:#3d3d3d; z-index:1; line-height:35px; text-align:center; font-size:18px; font-weight:bold;}

                .free-gift {
                    position: absolute;
                    display: block;
                    float: right;
                    z-index: 2;
                    width: 168px;
                    height: 44px;
                    color: #fff;
                    // font-size: 20px;
                    @extend %font_xs;
                    background-color: $gcolor;
                    line-height: 44px;
                    text-indent: 60px;

                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 44px;
                        height: 44px;
                        background: $ocolor url(/front_mo/images/icon_market_check.png) no-repeat center center;
                        background-size: 40%;
                        content: '';
                    }
                }
            }

            &__btn{
                position: absolute;
                right: 0;
                top: 20px;

                a{
                    display: inline-block;
                    position: relative;
                    top: 0;
                    right: 0;
                    margin-left: 10px;
                }
            }

            .delivery{
                margin-bottom: 20px;

                ul{
                    margin-top: 30px;

                    li{
                        position: relative;
                        padding-left: 102px;
                        line-height: 1.5;
                        font-size: 21px;
                        color:#666;

                        &:not(:first-child){
                            margin-top: 10px;
                        }

                        strong{
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            top: 2px;
                            padding: 5px 10px;
                            line-height: 1;
                            font-size: 19px;
                            font-weight: normal;
                            color: $color3D;
                            background: #fff;
                            border: 1px solid $color3D;
                            border-radius: 25px;

                            &.dawn{
                                color: #2a7baa;
                                border-color: #2a7baa;
                            }
                        }

                        span{
                            font-weight: bold;
                            color: #2a7baa;
                        }

                        &:nth-child(2) span{
                            color: #3D3D3D;
                        }
                    }
                }

                &-link{
                    display: inline-block;
                    position: relative;
                    padding-bottom: 5px;
                    font-size: 21px;
                    color: #8b8b8b;

                    &:after{
                        display: inline-block;
                        position: absolute;
                        right: -30px;
                        top: 50%;
                        margin-top: -10px;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        font-family: 'dotum';
                        font-size: 13px;
                        font-weight: 600;
                        color: #fff;
                        text-align: center;
                        background: #cdcdcd;
                        border-radius: 50%;
                        content: '>';
                    }
                }
            }
        }

        .detail-wrap {
            position: relative;
            margin-top: 20px;
            padding-top: 85px;

            .overview{
                &__listBox{
                    border-bottom-color: $color3D;
                }
            }
        }

        .btn__default{
            &.green{
                width: 100%;
            }

            &.cart{
                position: relative;
                min-width: 85px;
                width: 85px;
                font-size: 0;
                border-color: $colorBC;
                background: #fff;
                box-sizing: border-box;

                &:after{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: url(/front_mo/images/icon-header-basket.png) no-repeat 50% 50%;
                    content: '';
                    opacity: 0.8;
                }
            }
        }

        &__btn-share {
            display: block;
            position: absolute;
            top: 8px;
            right: 18px;
            width: 30px;
            height: 33px;
            background: url("/front_mo/images/icon_share.png") no-repeat 0 0;
            background-size: 100% 100%;
            text-indent: -9999px;
        }

        &__btn-heart {
            display: block;
            position: absolute;
            top: 9px;
            right: 70px;
            width: 33px;
            height: 31px;
            background: url("/front_mo/images/btn_heart-off.png") no-repeat 0 0;
            background-size: 100% auto;
            text-indent: -9999px;

            &.active{
                background: url(/front_mo/images/btn_heart-on.png) no-repeat 0 0;
            }
        }

        .tab-contents {
            display: none;
            width: 580px;
            margin: 0 auto 50px;

            &.cont-info {
                margin-top: 55px;

                .cont-info{
                    &__box{
                        position: relative;
                        max-height: 1025px;
                        padding-bottom: 0;
                        overflow: hidden;

                        &.on{
                            max-height: inherit;
                            padding-bottom: 130px;
                            overflow: visible;

                            .cont-info__more:before{
                                display:none;
                            }

                        }

                    }

                    &__more{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        padding-top: 25px;
                        background-color: #fff;
                        z-index: 1;

                        &:before {
                            content: "";
                            position: absolute;
                            top: -40px;
                            right: 0;
                            width: 100%;
                            height: 40px;
                            background: linear-gradient(0deg, rgba(255,255,255,1), rgba(255,255,255,0));
                        }

                        button{
                            display: block;
                            margin: 0 auto;
                            padding: 0 60px;
                            width: 100%;
                            line-height: 81px;
                            @extend %font_m;
                            font-weight: bold;
                            color: $color3D;
                            border-radius: 50px;
                            background: #fff;
                            border: 2px solid $color3D;

                            &:after{
                                display: inline-block;
                                margin: -14px 0 0 10px;
                                width: 15px;
                                height: 15px;
                                vertical-align: middle;
                                border: solid $color3D;
                                border-width: 0 0 2px 2px;
                                -webkit-transform: rotate(-45deg);
                                transform: rotate(-45deg);
                                content: '';
                            }

                            &.on:after{
                                margin-top: 0;
                                -webkit-transform: rotate(135deg);
                                transform: rotate(135deg);
                            }
                        }
                    }
                }
            }

            &.cont-question {
                margin-top: 35px;
            }

            &.cont-notice {
                min-height: 750px;
                margin-top: 35px;
            }

            &__section {
                margin-top: 100px;

                &__tit {
                    @extend %font_m;
                    font-weight: bold;

                    &.middleLine {
                        position: relative;
                        margin-bottom: 50px;
                        text-align: left;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            margin-top:-15px;
                            background: #3D3D3D;
                        }

                        > span {
                            display: inline-block;
                            position: relative;
                            padding: 0 25px 0 0;
                            line-height: 32px;
                            height: 72px;
                            font-size:29px;
                            background: #FFF;

                            span {
                                position: absolute;
                                left: 0;
                                top: 43px;
                                width: 580px;
                                font-size: 24px;
                                font-weight: normal;
                                color: #707070;
                            }
                        }
                    }
                }
            }

            &--current {
                display: block;
            }
        }

        .cont-info {
            @extend %vertical;

            &__banner {
                margin-bottom: 40px;
                width: 580px;
				// height: 180px;
				a {display:block;
					img {vertical-align:top;}
				}
            }

            .message {
                margin-top: 60px;

                &__to {
                    margin-bottom: 35px;
                    @extend %font_m;
                }

                &__name {
                    font-weight: bold;
                }

                &__advice {
                    @extend %font_xs;
                    line-height: 38px;
                    font-size: 25px;
                    letter-spacing: 0.02em;
                    word-break: break-all;

                    &__from {
                        display: none;
                        margin-top: 50px;
                        font-size: 26px;
                        @extend %font_m;
                        text-align: right;

                        &:after {
                            display: block;
                            float: right;
                            width: 150px;
                            height: 68px;
                            margin-top: 15px;
                            background: url(/front_mo/images/img_greating_sign.png) no-repeat 0 0;
                            background-size: 100% 100%;
                            content: '';
                        }
                    }
                }
            }

            .nutri,
            .package {
                &__thumb {
                    width: 580px;
                }
            }

            .notification {
                margin: 85px 0 20px;
                border: {
                    top: 2px solid $gcolor;
                    bottom: 2px solid $gcolor;
                };

                &__tit {
                    margin-top: 0;
                    padding: 20px 0;

                    &:after {
                        display: block;
                        float: right;
                        width: 40px;
                        height: 40px;
                        margin-top: -5px;
                        background: url(/front_mo/images/btn_main_right-arrow.png?ver=210312) no-repeat 0 0;
                        background-size: 100% 100%;
                        content: '';
                    }
                }

                a {
                    display: block;
                    width: 100%;
                }
            }

            .notice{
                margin: 40px 0;
                line-height: 1.5;
                @extend %font_s;
                word-break: keep-all;
            }

            .gr-value{
                margin: 0 auto 60px;
                padding: 40px 10px;
                width: 520px;
                background-color: $colorBG;
                border-radius: 30px;
                box-sizing: border-box;
                text-align: center;

                &__tit{
                    margin-bottom: 20px;
                    line-height: 32px;
                    font-size: 32px;
                    font-weight: bold;

                    i{
                        display: inline-block;
                        margin: -2px 5px 0 0;
                        width: 37px;
                        height: 40px;
                        background: url(/front_mo/images/sp_ico.png?ver=20211214) no-repeat -267px -145px;
                        vertical-align: middle;
                        transform: scale(0.85);
                    }

                    span{
                        display: inline-block;
                        margin: 5px 0 0 5px;
                        width: 25px;
                        height: 25px;
                        line-height: 23px;
                        font-size: 0;
                        text-indent: -999em;
                        text-align: center;
                        border: 1px solid #ccc;
                        border-radius: 50%;
                        vertical-align: top;

                        &:before{
                            display: inline-block;
                            margin: 2px 0 0 -2px;
                            width: 6px;
                            height: 6px;
                            vertical-align: middle;
                            border: solid #1c1c1c;
                            border-width: 2px 2px 0 0;
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                            content: '';
                        }
                    }
                }

                &__txt{
                    margin-bottom: 20px;
                    padding: 0 20px;
                    line-height: 1.5;
                    font-size: 24px;
                    color: #777;

                    strong{
                        color: $gcolor;
                    }
                }

                &__ico{
                    text-align: center;

                    > span{
                        display: inline-block;
                        margin: 0 10px;
                        width: 86px;
                        font-size: 18px;
                        color: #777;
                        vertical-align: top;

                        i{
                            display: block;
                            margin-bottom: 10px;
                            width: 86px;
                            height: 86px;
                            border-radius: 50%;
                            background-color: #fff;
                            overflow: hidden;

                            img{
                                width: 100%;
                            }
                        }

                        span{
                            display: block;
                            word-break:keep-all;
                            word-wrap: break-word;
                        }
                    }
                }
            }
        }

        .rel-goods {
            margin-top: 0;

            &__box {
                @extend %clear;
            }

            &__list {
                float: left;
                width: 276px;

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .goods-list {
                @extend %vertical;

                &__thumb {
                    width: 276px;
                    height: 276px;
                }

                &__name {
                    display: block;
                    height: 53px;
                    @extend %font_xs;
                    @include line(2);
                }

                &__info {
                    display: block;
                    width: 276px;
                    margin-top: 20px;
                }

                &__price {
                    display: block;
                    margin-top: 5px;
                    // font-size: 20px;
                    @extend %font_xs;

                    strong {
                        @extend %font_m;
                    }

                    &-origin {
                        display: inline-block;
                        @extend %font_s;
                        color: $color88;

                        + .goods-list__price-dc{
                            padding-top: 0;
                        }
                    }

                    &-dc {
                        display: block;
                        padding-top: 27px;
                        @extend %font_s;
                        text-decoration: none;

                        strong {
                            // font-size: 30px;
                            @extend %font_l;
                        }

                        &-rate{
                            display: inline-block;
                            margin-right: 5px;
                            @extend %font_s;
                            font-weight: 600;
                            color:#ff8a2f;
                        }
                    }

                }

            }

            .swiper-pagination {
                display: table;
                margin: 40px auto;
            }

            .tab-contents__section__tit{
                margin: 20px 0;
            }

        }

        /** 상품 후기 **/
        .cont-review {
            .review__title {
                max-width: 490px;
            }

            .board__inner__detail {
                padding-top: 20px;
                border-top: 0;
            }
        }

        .cont-question {
            .question__inner {
                margin-top: 15px;
            }

            .review__title {
                max-width: 390px;
            }
        }

        .cont-notice {
            margin-bottom: 50px;

            .notice-list {
                margin: 0;

                &__tit {
                    position: relative;
                    padding: 28px 20px;
                    border-bottom: 1px solid #bcbcbc;
                    font-size: 28px;
                    font-weight: bold;

                    &:before {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        width: 27px;
                        height: 2px;
                        background-color: #3d3d3d;
                        content: '';
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 32px;
                        transform: translateY(-50%);
                        width: 2px;
                        height: 27px;
                        background-color: #3d3d3d;
                        content: '';
                    }
                }

                &__cont {
                    display: none;
                    margin-left: 0;
                    padding: 0 20px 60px;
                }

                &__box {
                    // font-size: 20px;
                    @extend %font_xs;
                    line-height: 32px;

                    &__tit {
                        display: block;
                        margin: 40px 0;
                        // font-size: 24px;
                        @extend %font_s;
                        font-weight: bold;
                    }
                }

                &--show {

                    .notice-list__tit {
                        &:before {
                            display: block;
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            transform: translateY(-50%);
                            width: 27px;
                            height: 2px;
                            background-color: #3d3d3d;
                            content: '';
                        }

                        &:after {
                            display: none;
                        }
                    }

                    .notice-list__cont {
                        display: block;
                    }
                }
            }
        }

        .hd__btn-sec__cock.col3{
            .btn__default{
                &.gift{
                    min-width: 160px;
                    width: 72% !important;
                }

                &.dvSoldoutBtn{
                    padding-left: 85px;
                }
            }
           
        }
    }

    .popup_cardEvent{
       .modal__sec__title + div{
            min-height: 200px;
            max-height: 620px;
            font-size: 22px;
            overflow-y: auto;
       }
    }
}

.item-info {
    position: relative;
    height: 44px;
    margin: 25px 0;

    &__price {
        position: absolute;
        bottom: 0;

        &-origin {
            display: inline-block;
            margin-left: 10px;
            @extend %font_xs;
            color: $color88;
        }

        &-dc {
            display: inline-block;
            @extend %font_l;
            text-decoration: none;

            strong {
                @extend %font_xl;
            }
        }

        &-dc-rate {
            margin-left: 10px;
            color: $ocolor;
            @extend %font_m;

            strong {
                font-size: 40px;
            }

        }

        &-sale {
            display: inline-block;
            margin-right: 15px;
            @extend %font_m;
            color: #ff8a2f;
        }
    }

    &__coupon {
        position: relative;
        display: block;
        float: right;
        width: 110px;
        height: 44px;
        color: #fff;
        @extend %font_xs;
        background-color: $color3D;
        line-height: 44px;
        text-indent: 14px;
        cursor: pointer;

        &:before {
            display: block;
            position: absolute;
            left: -7px;
            top: 50%;
            width: 14px;
            height: 14px;
            margin-top: -7px;
            background-color: #fff;
            border-radius: 10px;
            content: '';
        }

        &:after {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 44px;
            height: 44px;
            background: $gcolor url(/front_mo/images/btn_download.png) no-repeat center center;
            background-size: 40%;
            content: '';
        }
    }

    &__box{
        *{
            font-size: 20px;
            color: #3D3D3D;
        }

        &-list{
            border-top: 1px solid $colorBC;
            overflow: hidden;

            .store{
                overflow: hidden;
                
                &-name{
                    float: left;
                    width: 280px;

                    &__more{
                        display: inline-block;
                        margin: 2px 0 0 5px;
                        padding: 6px 10px;
                        line-height: 1;
                        font-size: 17px;
                        font-weight: normal;
                        color: $color88;
                        background: #fff;
                        border: 1px solid $colorBC;
                        border-radius: 25px;
                        vertical-align: top;
                        white-space: nowrap;
                    }
                }

                &-tel{
                    float: right;
                    width: 180px;
                    text-align: right;

                    span{
                        &:before{
                            display: inline-block;
                            margin-right: 5px;
                            vertical-align: top;
                            color: $color88;
                            content: 'tel.';
                        }
                    }
                }
            }

        }

        &-tit{
            float: left;
            padding-top: 18px;
            width: 120px;
            vertical-align: top;
        }

        &-cont{
            float: right;
            position: relative;
            padding: 12px 0;
            width: 460px;
            line-height: 35px;
            vertical-align: top;
            letter-spacing: -0.19px;

            .btn-arr{
                margin-top: 10px;
                padding: 5px 20px 5px 10px;
                height: auto;
                line-height: 1.1;
                font-size: 18px;
                color: $color88;
                border-radius: 30px;
                letter-spacing: -0.5px;

                &:after{
                    right: 2px;
                    width: 10px;

                    opacity: 1;
                }
            }
        }

        &-button{
            display: block;
            position: relative;

            &:after{
                position: absolute;
                right: 2px;
                top: 15px;
                width: 6px;
                height: 6px;
                vertical-align: middle;
                border: solid #3D3D3D;
                border-width: 1px 1px 0 0;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                content: '';
            }
        }

        .delivery{
             ul{
                margin: 0 !important;

                li{
                    font-size: 20px !important;
                    &:not(:first-child){
                        margin-top: 5px;
                    }

                    strong{
                        line-height: 1;
                        font-size: 18px !important;
                    }
                }
            }

            &-link{
                font-size: 20px !important;
            }
        }

        .desc{
            &-list{
                margin-top: 5px;
                padding-left: 18px;
                line-height: 1.5;
                font-size: 18px;
                color: $color88;
                text-indent: -18px;
            }
        }

        #OGPLC_id{
            .item-info__box-tit{
                padding-top: 14px;
                font-size: 36px;
            }

            .item-info__box-cont{
                padding: 17px 0 23px 0;

                p{
                    font-size: 36px;
                }
            }
        }
    }
}

.due-desc {
    margin-top: 10px;
    font-size: 22px;

    &__date {
		font-weight: bold;
        color: $gcolor;
		&.due-sesc__preOrder + span{ color:#909090;}
	}
	& + p {margin-top:10px; letter-spacing:-.5px; font-size:21px; color:#909090;}

    &__qty {
        font-weight: bold;
        color: $gcolor;
    }
}

.detailInfo {
    line-height: 38px;
    font-size: 25px;
    letter-spacing: 0.02em;
    word-break: break-all;
}
