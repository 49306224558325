.hd {

    &__eventDetail {
        padding-top: 190px;

        &.hd__eventCheckAttendance {

            .detail-area {
                margin-bottom: 0;

                &__contents {
                    padding-bottom: 0;

                    .banner {
                        img {
                            display: block;
                        }
                    }

                }
            }

        }

        .detail-area {

            &__tit {
                line-height: 1.5;
                width: 480px;
                font-size: 28px;
                font-weight: bold;
            }

            &__period {
                padding: 20px 0 30px;
                @extend %font_xs;
                color: $color88;
                border-bottom: 1px solid $colorBC;

                .noti {
                    position: relative;
                    margin-left: 15px;
                    padding-left: 15px;
                    color: $ocolor;
                    font-weight: bold;

                    &:before {
                        display: inline-block;
                        position: absolute;
                        top: 8px;
                        left: 0;
                        width: 0;
                        height: 18px;
                        border: 1px solid $colorBC;
                        content: '';
                    }
                }
                
                .d-day {
                    position: relative;
                    margin-left: 15px;
                    padding-left: 15px;
                    color: $gcolor;
                    font-weight: bold;

                    &:before {
                        display: inline-block;
                        position: absolute;
                        top: 8px;
                        left: 0;
                        width: 0;
                        height: 18px;
                        border: 1px solid $colorBC;
                        content: '';
                    }
                }
            }

            &__contents {
                margin: 30px 0 0 -30px;
                width: 640px;
                @extend %font_xs;
                line-height: normal;
                word-break: normal;
                word-wrap: break-word;

                img{width: 640px;}

                .banner {
                    img {
                        max-width: 100%;
                    }
                }
            }

        }
 
        &__btn-share {
            display: block;
            float: right;
            width: 33px;
            height: 35px;
            margin-top: 2px;
            margin-right: 17px;
            background: url(/front_mo/images/icon_share.png) no-repeat 0 0;
            background-size: 100% 100%;
            text-indent: -9999px;
        }
        
        .notice {
            padding-top: 30px;
            // border-top: 3px solid $gcolor;

            &__tit {
                @extend %font_xs;
                font-weight: bold;
            }

            &__cont {
                margin-top: 15px;
                @extend %font_xs;
                color: #888;
                line-height: 36px;
                word-break: break-all;
            }

            &__list {
                position: relative;
                padding-left: 15px;

                &:before {
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    content: '-';
                }
            }
        }
    }

    &__eventCheckAttendance {

        .stamp-area {
            width: 640px;
            margin-left: -30px;
            padding-bottom: 80px;
            //background: url(/front_mo/images/img_checkAtt_bg.jpg) no-repeat top center;
            background: #efbc45;

            &__tit {
                width: 100%;
                margin-bottom: 20px;
                text-align: center;

                strong {
                    display: inline-block;
                    position: relative;
                    @extend %font_l;
                    font-weight: bold;

                    &:after {
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        z-index: -1;
                        width: 100%;
                        height: 8px;
                        background: $ocolor;
                        content: '';
                    }
                }
            }

            .stamps {
                padding: 60px 30px 0 30px;
                // border-top: 3px solid $gcolor; 
                @extend %clear;
                counter-reset: day_count;

                &__list {
                    position: relative;
                    float: left;
                    width: 90px;
                    height: 90px;
                    margin: {
                        left: 32px;
                        bottom: 30px;
                    }
                    padding-top: 15px;
                    // margin: 15px 18px;
                    // border-radius: 100%;
                    // border: 3px solid #d9d9d9;
                    // background: #fff;
                    background: url(/front_mo/images/img_checkAtt_off.png) no-repeat center center;
                    text-align: center;
                    color: #c1c1c1;
                    font-size: 16px;
                    box-sizing: border-box;    
                    line-height: 26px;
                    // line-height: 74px;

                    &:before {
                        display: block;
                        font-size: 20px;
                        color: #a7a6a6;
                        // display: inline-block;
                        counter-increment : day_count;
                        content:counter(day_count);
                    }

                    &--on {
                        // background: $ocolor url(/front_mo/images/icon_common_check-type02.png) no-repeat center center;
                        background: url(/front_mo/images/img_checkAtt_on.png?201228) no-repeat center center;
                        // background-size: 40%;
                        border: none;

                        span {
                            font-size: 0;
                        }

                        &:before {
                            font-size: 0;
                        }
                        
                        &:after {
                            background: #ffaa64 !important;
                        }
                    }

                    &:nth-child(1),
                    &:nth-child(10),
                    &:nth-child(11),
                    &:nth-child(20),
                    &:nth-child(21),
                    &:nth-child(30),
                    &:nth-child(31) {
                        margin-left: 0;
                    }

                    &:nth-child(n+6):nth-child(-n+10),
                    &:nth-child(n+16):nth-child(-n+20),
                    &:nth-child(n+26):nth-child(-n+30) {
                        float: right;
                    }

                    &:nth-child(n+2):nth-child(-n+5),
                    &:nth-child(n+12):nth-child(-n+15),
                    &:nth-child(n+22):nth-child(-n+25) {
                        
                        &:after {
                            content: "";
                            display: block;
                            width: 32px;
                            height: 10px;
                            background: #efefef;
                            position: absolute;
                            top: 40px;
                            left: -32px;
                        }
                    }

                    &:nth-child(6),
                    &:nth-child(11),
                    &:nth-child(16),
                    &:nth-child(21),
                    &:nth-child(26),
                    &:nth-child(31) {
                        &:after {
                            content: "";
                            display: block;
                            width: 10px;
                            height: 32px;
                            background: #efefef;
                            position: absolute;
                            top: -31px;
                            left: 39px;
                        }
                    }

                    &:nth-child(n+7):nth-child(-n+10),
                    &:nth-child(n+17):nth-child(-n+20),
                    &:nth-child(n+27):nth-child(-n+30) {
                        &:after {
                            content: "";
                            display: block;
                            width: 32px;
                            height: 10px;
                            background: #efefef;
                            position: absolute;
                            top: 40px;
                            left: 90px;
                        }
                    }
                }

                &__day {
                    display: inline-block;
                    border: {
                        top: 1px solid #d9d9d9;
                        bottom: 1px solid #d9d9d9;
                    };
                    line-height: 23px;
                    font-size: 16px;
                }

            }

            .btn-area {
                margin: 0 auto;
                text-align: center;

                button{
                    height: 85px;
                    line-height: 83px;
                    font-size: 28px;
                    border-radius: 7px;
                }
            }
        }

        // 기획 및 디자인 변경으로 인해 추후 삭제 예정

        // .check-score {
        //     padding: 30px 0 40px;
        //     border-top: 3px solid $gcolor;

        //     &__tit {
        //         @extend %font_m;
        //         text-align: center;
        //         font-weight: bold;
        //     }

        //     &__desc {
        //         margin-top: 20px;
        //         font-size: 36px;
        //         text-align: center;

        //         span {
        //             position: relative;
        //             font-weight: bold;

        //             &:after {
        //                 display: block;
        //                 position: absolute;
        //                 left: 0;
        //                 bottom: 5px;
        //                 z-index: -1;
        //                 width: 100%;
        //                 height: 8px;
        //                 background: $ocolor;
        //                 content: '';
        //             }
        //         }
        //     }

        //     &__cont {
        //         margin-top: 40px;
        //         border-top: 1px solid #bcbcbc;
        //     }

        //     &__list {
        //         position: relative;
        //         height: 65px;
        //         border-bottom: 1px solid #bcbcbc;
        //         line-height: 65px;
        //         @extend %font_xs;
        //     }

        //     &__condi {
        //         display: inline-block;
        //         width: 325px;
        //     }

        //     &__spoon {
        //         display: inline-block;
        //     }

        //     &__btn {
        //         position: absolute;
        //         top: 50%;
        //         right: 0;
        //         transform: translateY(-50%);
        //         width: 86px;
        //         height: 44px;
        //         background: #f4f4f4;
        //         border: 1px solid#bcbcbc;
        //         text-align: center;
        //         line-height: 44px;

        //         &-fail {
        //             background: $ocolor;
        //             border: none;
        //             color: #fff;
        //         }
        //     }

        // }

        // .calendar-area {
        //     border-top: 3px solid $gcolor;

        //     &__tit {
        //         padding: 25px 0;
        //         text-align: center;
        //         @extend %font_m;
        //     }

        //     .calendar {
        //         border-top:1px solid #DDD;
        //         padding:0 7px;
          
        //         table {
        //           thead th {
        //             padding:20px 0;
        //             @extend %font_xs;
        //             font-weight:400;
        //           }
          
        //           .cal {
        //             &__day {
        //               padding:0;
        //               text-align:center;
        //               border: {
        //                 bottom:2px solid #FFF;
        //                 left:2px solid #FFF;
        //               }
          
        //               label{
        //                 display:block;
        //                 padding:18px 0;
        //                 background:#F4F4F4;
        //                 @extend %font_xs;
        //                 color:#3D3D3D;
        //               }

        //               input[type="checkbox"] {
        //                 display: none;
        //               }
          
        //               input[type="checkbox"]:checked + label {
        //                 background:$gcolor;
        //                 color:#FFF; 
        //               }
        //             }
          
        //             &__disabled {
        //               label{
        //                 display: none;
        //               }
        //             }
        //           }
        //         }
        //       }
        // }

    }

    &__eventTyping {

        .quiz-area {
            padding-top: 30px;
            border-top: 3px solid $gcolor;

            &__txt {
                @extend %font_l;
                font-weight: bold;
                text-align: center;

                span {
                    display: block;
                    margin-bottom: 20px;
                }

                input[type=text] {
                    width: 58px;
                    height: 58px;
                    margin-right: 10px;
                    border: 6px solid $gcolor;
                    text-align: center;
                    box-sizing: border-box;
                }
            }

            .btn-area {
                margin-top: 60px;
            }
		}
		
		.coupon__area {
            margin-top: 30px;
            text-align: center;

            &__title {
                display: inline-block;
                padding: 10px 25px;
                border-bottom: solid 2px $gcolor;
                color: $color3D;
                font-size: 30px;
                font-weight: 900;
            }

            .coupon__wrap {
                background: url('/front_mo/images/bg-eventCoupon.jpg?ver=210226') 0 0 no-repeat;
                width: 440px;
                height: 272px;
                margin: 49px auto 30px;

                &__info {
                    width: 370px;
                    padding: 65px 0 0;

                    strong {
                        display: block;
                        margin: 10px 0 20px;
                        color: #3d3d3d;
                        letter-spacing: -2px;
                        font-size: 40px;
                    }

                    p {
                        display: -webkit-box;
                        overflow: hidden;
                        max-height: 50px;
                        margin-top: 5px;
                        padding: 0 25px;
                        color: #8d8d8d;
                        letter-spacing: -1px;
                        font-size: 19px;
                        word-wrap: break-word;
                        white-space: normal;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                &__code {
                    display: inline-block;
                    overflow: hidden;
                    background: $colorBG;
                    max-width: 330px;
                    min-width: 195px;
                    height: 41px;
                    margin: 0 auto;
                    padding: 0 10px;
                    border-radius: 7px;
                    box-sizing: border-box;
                    color: $gcolor;
                    line-height: 41px;
                    text-overflow: ellipsis;
                    font-size: 21px;
                    font-weight: 700;
                }
            }

            .coupon__count {
                margin-top: 30px;
                color: #3d3d3d;
                font-size: 30px;
                font-weight: bold;
            }

            .coupon__copy {
                display: flex;
                background: $gcolor;
                width: 60%;
                height: 78px;
                margin: 30px auto 0;
                color: #fff;
                line-height: 78px;
                letter-spacing: -1px;
                font-size: 24px;
                align-items: center;
                justify-content: center;

                &:after {
                    content: "";
                    display: block;
                    background: url(/front_mo/images/icon_copy.png) 0 0 no-repeat;
                    width: 17px;
                    height: 22px;
                    margin: 5px 0 0 15px;
                }
            }
        }
    }

    &__eventSurvey {

        .survey-area {
            padding-top: 30px;
            border-top: 3px solid $gcolor;
            text-align: center;

            &__tit {
                margin-bottom: 30px;
                text-align: left;
                @extend %font_l;
                line-height: 32px;
                font-weight: bold;
            }

            .checkbox-area {

                .check-label {
                    display: block;
                    text-align: left;
                    margin-bottom: 15px;

                    .check-area {
                        top: 0;
                        transform: none;
                    }

                    .text {
                        display: block;
                        padding-left: 48px;
                        line-height: 40px;
                        @extend %font_l;
                        font-weight: bold;

                        .input_text {
                            margin-top: 10px;
                            width: 470px;

                            &:disabled {
                                background: #F4F4F4;
                            }
                        }
                    }

                    &:last-child {
                        .input_text {
                            margin-top: 0;
                        }
                        
                        .check-area {
                            top: 50%;
                            margin-top: -20px;
                        }
                    }
                }
            }

            .btn-area {
                margin-top: 60px;
            }
        }
    }

    &__eventChallengeCont {

        .challenge-banner {
            width: 100%;
            height: 760px;

            .swiper-slide > *{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate3d(0, -50%, 0);
            }

            img {
                max-height: 100%;
            }
        }
        
        .swiper-pagination {
            display: inline-block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 30px;
        }

        .btn-area {
			margin-top: 0;
			visibility: hidden;
        }
    }

    &__eventReview {

        .review-area {
            margin-top: 30px;
            border-top:  3px solid $gcolor;
            
            &__tit {
                padding: 25px 0;
                @extend %font_s;
            }

            .input-area {
                position: relative;
                width: 100%;
                height: 70px;
                border: 1px solid $gcolor;
                box-sizing: border-box;

                input[type=text] {
                    width: calc(100% - 110px);
                    height: 100%;
                    // padding: 0 135px 0 25px;
                    padding: 0 25px;
                    @extend %font_s; 
                    border: none;
                    box-sizing: border-box;
                }

                .btn-summit {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 110px;
                    height: 100%;
                    // margin-left: calc(100% - 470px);
                    background: $gcolor;
                    color: #fff;
                    @extend %font_s; 
                    text-align: center;
                }
            }

            .review-box {
                .reply {
                    border-bottom: 1px solid #BCBCBC;

                    &__sec {
                        padding: 25px 0 30px;

                        &.anws {
                            .reply__txt {
                                padding-left: 35px;
    
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 18px;
                                    height: 18px;
                                    border: {
                                        bottom: 3px solid $gcolor;
                                        left: 3px solid $gcolor;
                                    }
                                    box-sizing:border-box;
                                }
                            }

                            .reply__info {
                                padding-left: 35px;
                            }
                        }
                    }

                    &__txt {
                        position: relative;
                        display: block;
                        line-height: 31px;
                        @extend %font_xs;

                        .btn_goto {
                            color:$gcolor;
                        }

                        .best {
                            color: $ocolor;
                        }
                    }

                    &__info {
                        display: block;
                        position: relative;
                        margin-top: 20px;
                        @extend %font_xs;
                        color: $color88;
                        
                        &__div {
                            color: #BCBCBC;
                        }

                        &__btn{
                            position: absolute;
                            right: 70px;
                            top: 0;
                            color: $color88;

                            &.type2{
                                right: 0;
                            }
                        }
                    }
                }  
            }
        }
    }

    &__eventRoulette{
        position: relative;
        width: 640px !important;
        overflow: hidden;

        .detail-area{
            margin-left: 30px;
            width: 580px;
        }

        .notice{
            margin: 0 30px;
        }

        .roulette{
            position: relative;
            margin: 115px auto 100px;
            width: 558px;
            height: 558px;
            border-radius: 50%;
            box-shadow: 14px 20px 0 rgba(179,55,22,0.25);

            &:after{
                position: absolute;
                left: 50%;
                top: -36px;
                margin-left: -23.5px;
                width: 61px;
                height: 91px;
                background: url('/front_mo/images/sp_ico.png?210209') no-repeat -280px 0;
                content: '';
            }

            &__img{
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: #fff;
                border: 9px solid #1c1c1c;
                box-sizing: border-box;
            }

            &__btn{
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -93px 0 0 -93px;
                width: 186px;
                height: 186px;
                background: url('/front_mo/images/sp_ico.png?210209') no-repeat -341px 0;
                text-indent: -999em;
                box-shadow: 0 10px 10px rgba(179,55,22,0.25);
                border-radius: 50%;
            }

            &__banner{
                margin-bottom: 50px;

                [class^="swiper-button-"]{
                    position: absolute;
                    top: 50%;
                    margin-top: -15px;
                    width: 10px;
                    height: 30px;
                    vertical-align: middle;
                    background: url('/front_mo/images/sp_ico.png?210209') no-repeat -36px 0;
                    background-size: 640px auto;
                    transform: scale(1.6);
                    opacity: 0.25;
                    z-index: 1;
                }
                .swiper-button-prev{left: 20px;}
                .swiper-button-next{right: 20px;background-position-x: -57px}
            }
        }
    }
}


