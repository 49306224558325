.hd {
    &__plan-meals {
        padding-top: 190px;

        .overview {
            margin-left: 30px;

            &__tit {
                font-size: 40px;
                line-height: 48px;

                span {
                    font-weight: bold;
                }
            }

            &__desc {
                margin-top: 25px;
                color: #888888;
                @extend %font_xs;
                line-height: 32px;
            }

            &__mealchart,
            &__program {
                margin-top: 30px;
                @extend %clear;

                strong {
                    float: left;
                    height: 40px;
                    @extend %font_m;
                    font-weight: bold;
                    line-height: 40px;
                }

                a {
                    display: block;
                    float: left;
                    width: 118px;
                    height: 38px;
                    margin-left: 20px;
                    border: 1px solid #888888;
                    background: #fff;
                    color: $gcolor;
                    font-size: 18px;
                    text-align: center;
                    line-height: 38px;
                }

            }

            &__mealchart {
                margin-top: 20px;
            }
        }

        .wrap__type-box {
            font-size: 0;

            .inner-box{
                display: inline-block;
                width: auto;
                margin-top: 50px;
                font-size: 0;
    
                .type-box {
                    display: block;
                    height: 270px;
                    margin: 10px 0;
                    box-shadow: 9px 9px 20px 5px rgba(0, 0, 0, 0.1);
                    @extend %clear;
                    @extend %vertical;
        
                    a {
                        display: block;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        &__inquiry {
            position: relative;
            margin: 50px 0 0 30px;

            &__tit {
                @extend %font_m;
                font-weight: bold;
            }

            &__desc {
                margin-top: 20px;
                color: #888;
                @extend %font_xs;
                line-height: 32px;
            }

            &__way {
                position: absolute;
                top: 0;
                right: 40px;
                @extend %clear;

                a {
                    display: block;
                    overflow: hidden;
                    position: relative;
                    float: left;
                    width: 94px;
                    height: 94px;
                    background: #fff;
                    border: 1px solid $colorBC;
                    border-radius: 50%;
                    box-sizing: border-box;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    & + a {
                        margin-left: 14px;
                    }
                }

            }
        }

        .moreView {
            margin-top: 50px;

            li {
                margin-top: 15px;
                line-height: 32px;
                font: {
                    size: 26px;
                    weight: 600;
                }

                &:first-child {
                    margin-top:0;
                }

                a {
                    vertical-align: top;

                    img {
                        vertical-align: middle;
                    }
                }
            }
        }

        .whiteBox {
            margin-top: 30px;
            padding: 30px;
            background: #FFF;
            box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.1);

            &__title {
                padding-bottom: 10px;
                border-bottom: 3px solid $gcolor;
                font: {
                    size: 36px;
                    weight: 600;
                }
                line-height: 42px;

                img {
                    width: 40px;
                    vertical-align: -5px;
                }
            }

            &__desc {
                margin-top: 15px;
                font-size: 22px;
                line-height: 32px;
            }

            &__btn {
                margin-top: 20px;
                text-align: center;
                font-size: 0;

                li {
                    display: inline-block;
                    vertical-align: top;

                    a {
                        display: block;
                        position: relative;
                        width: 100%;
                        height: 70px;
                        border: 1px solid #BCBCBC;
                        background: #F5F5F5;
                        font: {
                            size: 24px;
                            weight: 600;
                        };
                        line-height: 32px;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate3d(-50%, -50%, 0);
                        }
                    }
                }

                &.w245 li{
                    width: 245px;
                    margin-left: 15px;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                &.w506 li{
                    width: 506px;
                }
            }
        }

        &__inner{
            .program{
                position: relative;
                overflow: hidden;
                img{display: block;}

                &:nth-of-type(1){
                    margin-top: -40px;
                }

                &_banner{
                    min-height: 1210px;
                    background: $colorBG;

                    .swiper{
                        &-counter{
                            position: absolute;
                            left: 30px;
                            top: 230px;
                            background: rgba(0,0,0,0.15);
                            width: 60px;
                            border-radius: 10px;
                            color: rgba(255,255,255,0.9);
                            z-index: 1;
                            line-height: 30px;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 100;

                            span{
                                display: inline-block;
                                padding: 0 2px;
                                vertical-align: top;
                            }
                        }
                    }
                }

                &_slider{
                    position: absolute;
                    left: 30px;
                    top: 564px;
                    width: 610px;

                    .swiper{
                        &-wrapper{
                            display: block;
                            height: 548px;
                            white-space: nowrap;
                        }

                        &-slide{
                            display: inline-block;
                            margin: 0 20px 0 0;
                            width: 500px;
                            background: #fff;
                            border-radius: 30px;
                            overflow: hidden;

                            &.slide1 .box{
                                .txt{
                                    color: #6bb43f;
                                }

                                .btn-green{
                                    background-color: #6bb43f;
                                    border-color: #6bb43f;
                                }
                            }
                            &.slide2 .box{
                                 .txt{
                                    color: #e17d26;
                                }

                                .btn-green{
                                    background-color: #e17d26;
                                    border-color: #e17d26;
                                }
                            }
                            &.slide3 .box{
                                .txt{
                                    color: #3f4db4;
                                }

                                .btn-green{
                                    background-color: #3f4db4;
                                    border-color: #3f4db4;
                                }
                            }
                            &.slide4 .box{
                                .txt{
                                    color: #ed6251;
                                }

                                .btn-green{
                                    background-color: #ed6251;
                                    border-color: #ed6251;
                                }
                            }
                            &.slide5 .box{
                                .txt{
                                    color: #53a5e0;
                                }

                                .btn-green{
                                    background-color: #53a5e0;
                                    border-color: #53a5e0;
                                }
                            }
                        }

                        &-counter{
                            position: absolute;
                            right: 30px;
                            bottom: 0;
                            width: 60px;
                            border-radius: 5px;
                            color: $color3D;
                            z-index: 1;
                            line-height: 30px;
                            text-align: right;
                            font-size: 18px;
                            font-weight: 100;

                            span{
                                display: inline-block;
                                padding: 0 2px;
                                vertical-align: top;
                            }
                        }

                        &-pagination-progressbar{
                            position: relative;
                            margin: 35px 0 13px;
                            width: 520px;
                            height: 2px;
                            border-radius: 5px;
                            overflow: hidden;
                            background: rgba(0,0,0,.25);

                            &-fill{
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background: $gcolor;
                                transform: scale(0);
                                transform-origin: left top;
                                transition: all 0.3s;
                                transform: translate3d(0,0,0);
                                z-index: 10;
                            }
                        }
                    }

                    .box{
                        position: relative;
                        padding: 30px;
                        letter-spacing: -0.5px;

                        .txt{
                            font-size: 21px;
                            color: #999;
                        }

                        .tit{
                            margin-top: 4px;
                            @extend %font_l;
                            font-weight: bold;
                        }

                        .review{
                            margin-top: 25px;
                            padding-top: 25px;
                            border-top: 1px solid $colorBC;


                            a{
                                strong{
                                    display: block;
                                    margin-bottom: 6px;
                                    @extend %font_xs;
                                    @include line(1);
                                }

                                span{
                                    position: relative;
                                    font-size: 20px;
                                    color: #999;
                                    @include line(1);

                                    i{
                                        &:before{
                                            display: inline-block;
                                            margin: -3px 0 0 5px;
                                            width: 20px;
                                            height: 20px;
                                            border: 1px solid #999;
                                            border-radius: 50%;
                                            vertical-align: middle;
                                            content: '';
                                        }

                                        &:after{
                                            position: absolute;
                                            right: 9.5px;
                                            top: 12px;
                                            width: 6px;
                                            height: 6px;
                                            vertical-align: middle;
                                            border: solid $color3D;
                                            border-width: 1px 1px 0 0;
                                            -webkit-transform: rotate(45deg); 
                                            transform: rotate(45deg);
                                            content: '';
                                        }
                                    }
                                }
                            }
                        }

                        .btn-green{
                            position: absolute;
                            right: 30px;
                            top: 33px;
                            padding: 0 20px;
                            height: 50px;
                            line-height: 48px;
                            @extend %font_s;
                            border-radius: 30px;
                        }
                    }
                }

                &_swiper{
                     position: absolute;
                     left: 0;
                     top: 208px;
                     width: 640px;

                    .swiper{
                        &-slide{
                            display: inline-block;
                            overflow: hidden;
                        }
                        
                        &-counter{
                            position: absolute;
                            right: 30px;
                            bottom: 0;
                            width: 60px;
                            border-radius: 5px;
                            color: $color3D;
                            z-index: 1;
                            line-height: 30px;
                            text-align: right;
                            font-size: 18px;
                            font-weight: 100;

                            span{
                                display: inline-block;
                                padding: 0 2px;
                                vertical-align: top;
                            }
                        }

                        &-pagination-progressbar{
                            position: relative;
                            margin: 35px 0 13px 30px;
                            width: 520px;
                            height: 2px;
                            border-radius: 5px;
                            overflow: hidden;
                            background: rgba(0,0,0,.25);

                            &-fill{
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background: $gcolor;
                                transform: scale(0);
                                transform-origin: left top;
                                transition: all 0.3s;
                                transform: translate3d(0,0,0);
                                z-index: 10;
                            }
                        }
                    }

                }
            }
        }
    }
}