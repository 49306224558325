.hd {
    .greatingLife-list {
        &__cont {
            margin-top: 40px;
    
            &__list {
                position: relative;
                margin-top: 30px;
    
                &:first-child {
                    margin-top: 0;
                }
    
                a {
                    display: block;
                }
            }
            
            &__img {
                font-size: 0;

                img{width: 100%;}
            }


            &__title {
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                text-align: center;
                @extend %font_l;
                font-weight: 600;
                color: #FFF;
                transform: translate3d(0, -50%, 0);
            }
        }

        &__program {
            position: relative;
            margin-top: 40px;
            padding: 40px;
            border: 1px solid #C0C0C0;

            &__title {
                display: block;
                padding-bottom:20px;
                border-bottom: 3px solid $gcolor;
                @extend %font_l;
                font-weight: bold;
            }

            &__desc {
                margin-top: 25px;
                line-height: 32px;
                @extend %font_xs;
            }

            &__link {
                display: block;
                position: absolute;
                right: 33px;
                bottom: 40px;
                text-align: right;
                line-height: 53px;
                @extend %font_m;
                font-weight: bold;
                color: #3d3d3d;
                
                &:after {
                    content:'';
                    display: inline-block;
                    width: 53px;
                    height: 53px;
                    margin-left: 12px;
                    vertical-align: middle;
                    background: url(/front_mo/images/btn_main_right-arrow.png) no-repeat 0 0 / 100%;
                }
            }
        }

        &__swiper{
            position: fixed;
            top: 230px;
            left: 50%;
            width: 530px;
            height: 73vh;
            transform: translateX(-50%);

            &__txt{
                position: absolute;
                left: 30px;
                bottom: 90px;
                width: 470px;
                color: #fff;
                z-index: 1;

                &-sort{
                    display: inline-block;
                    margin-bottom: 15px;
                    padding: 0 25px;
                    line-height: 35px;
                    @extend %font_xs;
                    color: $gcolor;
                    background: #fff;
                    border-radius: 30px;

                    &.c1{color: #307d0e;}
                    &.c2{color: #918c00;}
                    &.c3{color: #a4111f;}
                    &.c4{color: #378a9f;}
                }

                &-title{
                    display: block;
                    line-height: 1.2;
                    width: 99%;
                    font-size: 50px;
                    @include line(2);
                    word-break: normal;
                    word-wrap: keep-all;
                }

                &-desc{
                    display: block;
                    margin-top: 20px;
                    line-height: 1.2;
                    width: 99%;
                    @extend %font_s;
                    @include line(1);
                }
            }

            &__img{
                position: relative;
                height: 100%;

                img{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 100%;
                    max-height: initial;
                    transform: translateY(-50%);
                }
            }

            &__list{
                position: relative;

                a{
                    &:before{
                        position: absolute;
                        left: 30px;
                        top: 30px;
                        width: 110px;
                        height: 20px;
                        content: '';
                        background: url('/front_pc/images/img_header_logo_w.png') no-repeat;
                        background-size: 100% auto;
                        z-index: 1;
                    }

                    &:after{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,0.2);
                        content: '';
                        z-index: 0;
                    }
                }
            }

            .swiper-container{
                position: relative;
                height: 100%;

                .swiper{
                    &-wrapper{
                        display: block;
                        height: 100%;
                    }

                    // &-slide{
                    //     position: relative;
                    //     margin-bottom: 30px;
                    //     min-height: 602px;
                    //     max-height: 830px;
                    //     height: 51vh !important;
                    //     border-radius: 15px;
                    //     overflow: hidden;

                    // }

                    // &-slide{
                    //     position: absolute;
                    //     left: 0;
                    //     top: 0;
                    //     margin-top: 0;
                    //     min-height: 602px;
                    //     max-height: 830px;
                    //     height: 51vh;
                    //     border-radius: 15px;
                    //     overflow: hidden;

                    //     a{
                    //         display: block;
                    //         position: relative;
                    //         height: 100%;
                    //     }

                    //     &-slide-next, &-slide-duplicate-next{
                    //         opacity: 1 !important;
                    //         transform: translate3d(0px, 55vh, 0px) !important;
                    //     }

                    //     &-slide-prev, &-slide-duplicate-prev{
                    //         opacity: 1 !important;
                    //         transform: scale(0.9) translate3d(0px, 0px, 0px) !important;
                    //     }

                    //     &-slide-active, &-slide-duplicate-active{
                    //         transform: translate3d(0px, 0px, 0px) !important;
                    //         z-index: 2;
                    //     }
                    // }

                    &-slide{
                        position: absolute;
                        left: 0;
                        top: 0;
                        margin-top: 0;
                        max-height: 830px;
                        height: 59vh !important;
                        border-radius: 15px;
                        overflow: hidden;
                        opacity: 1 !important;
                        transform: translate3d(0px, 61vh, 0px) !important;
                        transition: all 0.5s;
                        z-index: 4;

                        a{
                            display: block;
                            position: relative;
                            height: 100%;
                        }
                    }

                    &-slide-prev, &-slide-duplicate-prev{
                        opacity: 1 !important;
                        transform: scale(0.9) translate3d(0px, 0px, 0px) !important;
                        z-index: 2;
                    }

                    &-slide-next, &-slide-duplicate-next{
                        opacity: 1 !important;
                        transform: translate3d(0px, 61vh, 0px) !important;
                        z-index: 5;
                    }

                    &-slide-active, &-slide-duplicate-active{
                        transform: translate3d(0px, 0px, 0px) !important;
                        z-index: 4;
                    }
                }
            }
        }
    }

    &__greatingLife-list{
        .story__tabmenu{
            margin-top: -90px;
            border-bottom: 0;

            &-inner{
                padding-left: 0;
                margin-top: 40px !important;
            }

            ul{
                display: block;
                padding: 0 13px;
                white-space: nowrap;
                text-align: center;
                box-sizing: border-box;

                li{
                    padding: 0 !important;
                    margin: 0 10px;
                    width: 100px !important;
                    white-space: normal;

                    a{
                        display: table;
                        padding: 0 10px;
                        width: 100%;
                        height: 100px;
                        font-weight: 600;
                        color: $color3D;
                        border: 1px solid $colorBC;
                        border-radius: 10px;
                        word-break: keep-all;
                        box-sizing: border-box;
                        transition: all 0.3s;

                        span{
                            display: table-cell;
                            vertical-align: middle;
                        }
                    }

                    &.on, &:hover{
                        a{
                            color: $gcolor;
                            border: 1px solid $gcolor;
                        }
                    }

                }
            }
        }
    }

    .greatingLife__banner {
        width: 100%;
        height: 380px;
        font-size: 0;
    }
}

#greatingLifeList{.hd__footer{display: none;}}