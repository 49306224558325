.hd {
    &__coupnregist {
        .regist-area {
            margin-top: 20px;

            input {
                width: 100%;
                height: 70px;
                padding: 0 20px;
                border: 3px solid $gcolor;
                border-radius: 0;
                box-sizing: border-box;
                letter-spacing: -.5px;
                font-size: 24px;
            }
        }

        .btn-area {
            margin-top: 20px;
        }

        .tab-area{
            margin: 40px 0 45px;
        }
    }
}