.grouporder {
	$self: &;
	&__wrap {padding:50px 0 100px;
		&.apply {
			#{$self}__title p {margin-top:0;}
			#{$self}__contents {
				margin:0 auto; 
				padding:0; 
				width: 580px;
				border:0;
			}
		}
	}
	&__title {position:relative; line-height:1.4;
		h2 {color:$color3D; font-size:30px; font-weight: bold;}
		p {margin-top:15px; color:$color88; font-size:26px;}
		.tel {position:absolute; top:50%; right:0; background:url("/front_pc/images/icon_comm_phone.png?ver=210315") no-repeat 50% 50%; background-size:100%; width:94px; height:94px; margin-top:-47px; margin-right:10px; border:1px solid $colorBC; border-radius:100%; font:0/0 a;}
	}

	&__contents{
		border-top:solid 2px $color3D;
		margin:30px -30px 0;
		padding:50px 0;
		#{$self}__apply{
			width:100%;
			margin:0 auto;
			dl {
				margin-top:25px;
				dt {
					font-size:22px;
					font-weight: bold;
					color:$color3D;
					span {font-size:18px; color:$gcolor;}
					em {color:$color88; font-size:18px; font-weight:normal;}
				}
				dd {margin-top:15px;
					input,select {width:100%; height:70px; padding:0 25px; font-size:24px; background: #fff; border:solid 1px #bcbcbc; box-sizing:border-box; border-radius: 0;}
					.phone {
						display:flex; justify-content:space-between; align-items:center;
						input,select {width:170px; flex:0 0 170px;}
					}
				}
				
				&.full{
					dt{ flex:0 0 100%;}
					dd {
						ul {display:flex; justify-content:space-between; flex-wrap:wrap; margin-top:15px;
							li {flex:0 0 280px; height:370px; text-align:center; padding:60px 20px 20px; box-sizing:border-box;
								position: relative;
								h3 {position:relative; color:#fff; font-size:30px; font-weight:bold;
									&::after {content:""; display:block; background:#0ee2e6; width:30px; height:3px; margin:20px auto 25px;}
								}
								p {color:#d8d8d8; line-height:1.4; font-size:23px;}
								.volume {margin-top:50px; text-align:left;
									h4 {color:#a4a4a4; font-size:17px;}
									select {width:100%; height:60px; margin-top:10px; padding:0 20px;
										background:#fff url('/front_mo/images/ico_comm_select.png') no-repeat 95% 50%;
									}
								}
								.hd-checkbox{
									position: absolute;
									top:20px; right:20px;
									input {width:1px; height:1px;}
									label {display:block; width:36px; height:36px;
										padding:0;
										&::before {width:100%; height:100%;}
									}
								}
								&.regular {background:url("/front_pc/images/bg_regular.png") 0 0 no-repeat;}
								&.lunchBox {background:url("/front_pc/images/bg_lunchBox.png?ver=210309") 0 0 no-repeat;
									h3::after{background:#0be09c;}
								}
								&.other {display:flex; height:auto; margin-top:20px; padding:0; flex:0 0 100%; align-items:center;
									h3 {margin-left:10px; color:$color3D; text-align:left; font-size:23px; flex:0 0 70px;
										&::after {display:none;}
									}
									.hd-checkbox{position: static;}
								}
							}
						}
					}
				}
				&.half {
					dt {flex:0 0 50%;}
				}
			}

			.check-cal__data {
				height:70px; position:relative;
				.ui-datepicker-trigger {position:absolute; top:3px; right:0; width:60px;}
			}
			.textarea__wrap {position:relative; margin-top:20px;
				span {position:absolute; top:-45px; right:0; color:#b4b4b4; font-size:22px;}
				textarea {width:100%; min-height:260px; padding:20px; border:solid 1px #bcbcbc; box-sizing:border-box; font-size:24px; color:#b4b4b4;}
			}

			button {display:block; background:$gcolor; width:100%; height:70px; margin-top:40px; color:#fff; font-size:26px;}
		}
	}
	

	&__btn__wrap{
		display: flex;
		align-items: center;
		line-height: 1.4;
		p {color:$color3D; font-size:25px;}
		.btn-order {background:$gcolor; width:100%; height:100px; color:#fff; line-height:100px ; text-align:center; font-size:26px;}
	}
}

.ui-widget.ui-widget-content {padding:0 !important; border-top:3px solid $gcolor !important;}
.ui-widget-header {background:#fff !important;}
.hd .ui-datepicker {left:30px !important; right:30px !important; width:auto; font-size:24px;}
.hd .ui-datepicker-calendar {border-collapse:collapse; border-spacing:0;}
.hd .ui-datepicker-calendar thead th {border-bottom:1px solid #bcbcbc;}
.hd .ui-datepicker-calendar tbody td {padding:0; border:0;}
.hd .ui-datepicker-calendar tbody td .ui-state-default {background:none; padding:10px 0; border:0; text-align:center;}
.hd .ui-datepicker-calendar tbody td a {background:none; padding:10px 0; border:0; text-align:center;}
.hd .ui-datepicker-calendar tbody td a:hover {background:#f5f5f5;}
.hd .ui-datepicker-calendar tbody td a.ui-state-active {background:$gcolor !important; color:#fff; font-weight:600;}
.hd .ui-datepicker-calendar tbody td.ui-datepicker-today a {background:$gcolor; color:#fff; font-weight:600;}
.hd .ui-datepicker-prev,.hd .ui-datepicker-next {top:2px !important; background:none !important; border:none !important;}
.hd .ui-datepicker-prev span,.hd .ui-datepicker-next span {background:url(/front_pc/images/arrow.svg) 0 0 no-repeat;}
.hd .ui-datepicker .ui-datepicker-prev {left:2px !important; transform:rotate(180deg);}
.hd .ui-datepicker .ui-datepicker-next {right:2px !important;}
.hd .ui-datepicker .ui-datepicker-prev-hover {left:2px !important;}
.hd .ui-datepicker .ui-datepicker-next-hover {right:2px !important;}