.hd {

    &__displayDetail { 
		width:600px; margin:0 auto; padding-top:190px;

        .display-detail {

            &__tit {
                line-height: 1.5;
                font-size: 28px;
                font-weight: bold;
            }

            &__subtit {
                padding-top: 15px;
                @extend %font_xs;
            }

            &__period {
                margin-bottom:30px;
                padding: 20px 0 30px;
                @extend %font_xs;
                color: $color88;
                border-bottom:1px solid $colorBC;

                .d-day {
                    position: relative;
                    margin-left: 15px;
                    padding-left: 15px;
                    color: $gcolor;
                    font-weight: bold;

                    &:before {
                        display: inline-block;
                        position: absolute;
                        top: 8px;
                        left: 0;
                        width: 0;
                        height: 18px;
                        border: 1px solid $colorBC;
                        content: '';
                    }
                }
            }

            &__contents {
                position:relative;
                margin-left: -20px;
                width: 640px;
                line-height:normal;  @extend %font_xs;

                img{width: 640px;}
				
                .display-detail__video {
					position:absolute; width:77%; height:3.7%; background:#fff; top:14.2%; left:11.5%;
					#videoPlayer {display:block; width:100%; height:100%;}
				}
			}
        }

        .goods-wrap {
			margin-top: 30px;
            .goods-select {
                width: 100%;
                height: 70px;
                background: url(/front_mo/images/ico_comm_select.png) no-repeat right 20px top 50%;
                border: 3px solid $gcolor;
                @extend %font_xs;
                box-sizing: border-box;
                padding: 0 20px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }

            .goods-cont {
                &__title {
                    display: block;
                    width: 100%;
                    height: 56px;
                    margin-top: 30px;
                    @extend %font_s;
                    line-height: 56px;
					border-bottom: 2px solid $gcolor;
					font-size: 26px;
				}
				.product__wrap {padding:0;}
                // .goods-box {
				// 	margin-top: 40px;
				// 	display: flex;
				// 	flex-wrap: wrap;
				// 	justify-content: space-between;
                //     @extend %clear;

                //     &__list {
                //         width: 275px;
                //         float: left;
                //         margin-top: 40px;

                //         &:nth-child(odd) {
                //             clear: both;
                //         }

                //         &:nth-child(even) {
                //             margin-left: 30px;
                //         }

                //         &:nth-child(-n+2) {
                //             margin-top: 0;
                //         }

                //         .item {
                //             position: relative;
                //             width: 100%;
                //             @extend %vertical;

                //             &__thumb {
                //                 width: 275px;
                //                 height: 275px;
                //             }

                //             &__info {
                //                 position: relative;
                //                 margin-top: 20px;
                //             }

                //             &__name {
                //                 display: block;
                //                 width: 100%;
                //                 margin-bottom: 10px;
                //                 @extend %font_xs;
                //                 font-weight: bold;
                //                 @include line(2);
                //                 line-height: 32px;
                //             }

                //             &__price-ori {
                //                 display: inline-block;
                //                 margin-left: 15px;
                //                 @extend %font_xs;
                //                 text-decoration: line-through;
                //             }

                //             &__price-dc {
                //                 display: inline-block;
                //                 @extend %font_xs;

                //                 strong {
                //                     @extend %font_m;
                //                 }
                //             }

                //             .hd__btn-heart {
                //                 right: 12px;
                //                 top: 178px;
                //             }
                //         }

                //         // .icon-area {
                //         //     padding: 5px 15px;
                //         //     border: {
                //         //         top: 1px solid #BCBCBC;
                //         //         bottom: 1px solid #BCBCBC;
                //         //     }
                //         //     font-size: 0;

                //         //     span {
                //         //         display: inline-block;
                //         //         width: 33px;
                //         //         height: 36px;

                //         //         &:not(:first-child) {
                //         //             margin-left: 16px;
                //         //         }

                //         //         img {
                //         //             display: block;
                //         //             max-width: 100%;
                //         //         }
                //         //     }
                //         // }
                //     }
                // }
            }
        }
    }
}


#displayDetail{
    .hd__header{
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }
}