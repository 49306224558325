.hd {
    &__market {
        &__cont {
            width: 580px;
            margin: 30px auto 0;
            
            & .banner {
                &__wrap {
                    @extend %vertical;
                }
                
                &__list {
                    position: relative;
                    margin-top:30px;

                    &:first-child {
                        margin-top: 0;
                    }

                    a {
                        display: block;
                        font-size: 0;

                        figure {
                            width: 580px;
                            height: 300px;
                        }

                        img {
                            vertical-align: top;
                        }
                    }
                }
            }
        }

        &__banner{
            position: relative;
            margin-top: 10px;

            .swiper-pagination{
                position: absolute;
                left: 0;
                bottom: 20px;
                width: 100%;
                z-index: 1;
            }
        }
    }
}