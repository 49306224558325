.hd {
    position: relative;
    width: 100%;

    &.amore {
        .popup_agreeDetail1 .contents {
            padding-bottom: rem(100px);
        }
        .popup_agreeDetail2 .contents {
            padding-bottom: rem(100px);
        }
        .popup_agreeDetail3 .contents {
            padding-bottom: rem(100px);
        }
        .popup_agreeDetail4 .contents {
            padding-bottom: rem(100px);
        }
    }

    &#main {

    }

    &:not(#main) {
        .hd__footer__inner {
            display: none;
        }
    }

    &#main{
        .hd__header{

            &__inner {
                border-color: #fff;
                background: #FFF;
            }
        }
    }

    &#main {
        .hd__page-title {
            display: none;
        }
    }

    &#planMealsDetail {
        .hd__header {
            display: none;
        }
    }

    &:not(#market) {
        .hd__page-title__dropdown {
            display: none;
        }
    }

    &#market {
        .hd__page-title__txt {
            display: none;
        }
    }

    &#amore {
        padding-bottom: 100px;
    }

    &__header {
        position: fixed;
        top: 0; left: 0;
        z-index: 11;
        width: 100%;
        background: #FFF;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        border-bottom: 1.5px solid $colorBC;

        &__logo {
            padding-top: 23px;
            text-align: center;

            a {
                display: inline-block;
                width: 195px;

                img{
                    width: 100%;
                }
            }
        }

        &__inner {
            height: 80px;
        }

        &__basket {
            position: absolute;
            top: 15px;
            right: 35px;
            width: 40px;
            height: 40px;
            padding: 10px;
            background: url(/front_mo/images/icon-header-basket.png) no-repeat 50% 50%;

            &__count {
                display: block;
                position: absolute;
                top: -5px;
                right: -5px;
                width: 30px;
                height: 30px;
                background: $gcolor;
                color: #FFF;
                font-size: 14px;
                text-align: center;
                line-height: 28px;
                border-radius: 20px;
            }
        }

        &__menu-icon {
            position: absolute;
            top: 5px;
            left: 15px;
            padding: 15px;

            span {
                display: block;
                width: 38px;
                height: 2px;
                background: $color3D;
                margin: 9px 0;
            }
        }

        .top-menu {
            position: relative;
            width: 100%;
            height: 70px;
            box-sizing: border-box;
            border-top: 1px solid $colorBC;
            background: #FFF;
            overflow: hidden;

            &__wrap{
                display: flex;
                position: relative;
                width: 100%;
                height: 70px;
                border-top: 1px solid $colorBC;

               .top-menu{
                    border-top: 0;
               } 

               .fixed{
                    min-width: 145px;
                    z-index: 2;
                    background: #fff;

                    .top-menu__list{
                        line-height: 1.2;

                        a{
                            padding: 10px 0 0;
                            height: 100%;
                            font-weight: bold;
                            box-sizing: border-box;

                            span{
                                display: block;
                                font-size: 14px;
                                font-weight: normal;
                                color: #dd4314;
                            }
                        }
                    }

                    &:after{
                        position: absolute;
                        left: 145px;
                        top: 50%;
                        width: 1px;
                        height: 20px;
                        background: #9b9b9b;
                        transform: translateY(-50%);
                        content: '';
                    }
                }
            }

            &__list {
                display: inline-block;
                padding-right: 24px;
                width: auto;
                height: 70px;
                line-height: 70px;
                color: $color3D;
                @extend %font_xs;
                text-align: center !important;
                box-sizing: border-box;

                &:nth-of-type(1){
                    padding-left: 30px;
                }

                a {
                    position: relative;
                    display: inline-block;
                    font-size: 24px;
                    font-weight: normal;
                    color:#666;
                    white-space: nowrap;
                    letter-spacing: -0.5px;
                }
                &-on a{
                    font-weight: 600;
                    color: $gcolor;

                    &:after{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 4px;
                        background: $gcolor;
                        content: '';
                    }
                }

                &.hmmarket{
                    a{
                        color: $color3D;

                        &:after{
                            background: $gcolor;
                        }

                        span{
                            position: relative;

                            &:after{
                                display: inline-block;
                                margin: 6px 0 0 5px;
                                width: 8px;
                                height: 8px;
                                background: $gcolor;
                                border-radius: 50%;
                                vertical-align: text-top;
                                content: '';
                            }
                        }
                    }
                }
            }
        }

        &__st-event {
            background: #FFF;
            transform: translate3d(0, -81px, 0);
            -webkit-transform: translate3d(0, -81px, 0);
            transition: transform 0.25s linear;
            -webkit-transition: transform 0.25s linear;
        }
    }

    &__footer {
        position: relative;
        padding-top: 130px;
        overflow: hidden;
        clear: both;

        &__inner {
            position: relative;
            padding: 40px 30px 55px;
            background: #f9f9f9;
        }

        &__menu-area {
            @extend %clear;

            a {
                display: block;
                float: left;
                width: auto;
                font-size: 20px;
                color: $color88;

                &:not(:first-child) {
                    margin-left: 20px;
                }
            }
        }

        &__sns {
            position: absolute;
            right: 30px;
            top: 105px;

            @extend %clear;

            a {
                display: block;
                float: left;
                position: relative;
                width: 50px;
                height: 50px;
                font-size: 0;
                border-radius: 50%;

                &:not(:first-child) {
                    margin-left: 20px;
                }
            }

            $list: intagram, youtube, blog, kakao;
            @for $i from 1 through length($list) {
                &-#{nth($list, $i)}:after {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 50px;
                    height: 50px;
                    background: url("/front_mo/images/icon_footer_sns.png?ver=210817") no-repeat -#{($i - 1) * 50}px;
                    background-size: 400%;
                    content: '';
                }
            }
        }

        &__cs {
            margin-top: 30px;

            p {
                margin: 7px 0;
                color: $color88;
                @extend %font_xs;
            }

            &__center {
                color: $color3D !important;
                @extend %font_xs;
                line-height: 34px;

                span {
                    color: $gcolor;
                    font-size: 28px;
                    font-weight: bold;
                }
            }

            &__gcolor {
                color: $gcolor;
            }
        }

        &__desc {
            margin-top: 35px;

            p {
                margin: 7px 0;
                line-height: 28px;
                color: $color88;
                @extend %font_xs;
            }

            &__txt{
                margin: 20px 0 0 0 !important;
                word-break: keep-all;
            }

            &__copyright {
                margin: 20px 0 0 0 !important;
                font-size: 21px !important;
                color: #aaa !important;
            }

            &__seper {
                &:before {
                    display: inline-block;
                    padding: 0 5px;
                    content: '|';
                }
            }

            .accordion_box{
                .greenfood{
                    display: inline-block;
                    vertical-align: middle;
                    width: 252px;
                    height: 35px;
                    font-size: 0;
                    text-indent: -999em;
                    background: url('/front_mo/images/greenfood_logo.png') no-repeat;

                    a{
                        display: block;
                        height: 100%;
                    }
                }
                .accordion_title{
                    display: inline-block;
                    vertical-align: middle;
                    width: -webkit-calc(100% - 260px) !important;
                    width: calc(100% - 260px) !important;
                    text-align: right;

                    p{
                        position: relative;
                        padding-bottom: 0;
                        height: 35px;
                        line-height: 35px;
                        text-align: right;

                        &:after{
                            display: inline-block;
                            margin: -11px 0 0 10px;
                            width: 10px;
                            height: 10px;
                            border: solid $color88;
                            border-width: 2px 2px 0 0;
                            -webkit-transform: rotate(135deg);
                            transform: rotate(135deg );
                            vertical-align: middle;
                            content: '';
                        }
                    }
                }
                .accordion_con{
                    padding-top: 14px;
                }

                &.on{
                    .accordion_title p:after{
                        margin-top: 0;
                        -webkit-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    &__btn-{
        &go-top, &gr-value{
            display: block;
            position: fixed;
            right: 25px;
            bottom: 150px;
            width: 58px;
            height: 58px;
            border: 1px solid #A9A9A9;
            border-radius: 40px;
            font-size: 0;
            background-color: #fff;
            visibility: hidden;
            opacity: 0;
            z-index: 6;
            transition: opacity 0.35s linear, visibility 0.35s linear;

            &.show {
                visibility: visible;
                opacity: 1;
            }

            &.move {
                visibility: visible;

                @-webkit-keyframes fadeInUp {
                    0% {
                        opacity: 0;
                        transform: translate3d(0, -100px, 0);
                        -webkit-transform: translate3d(0, -100px, 0);
                    }

                    50% {
                        opacity: 0.5;
                        transform: translate3d(0, -100px, 0);
                        -webkit-transform: translate3d(0, -100px, 0);
                    }

                    100% {
                        opacity: 1;
                        transform: translate3d(0, -100px, 0);
                        -webkit-transform: translate3d(0, -100px, 0);
                    }
                }

                @keyframes fadeInUp {
                    0% {
                        opacity: 0;
                        transform: translate3d(0, -100px, 0);
                        -webkit-transform: translate3d(0, -100px, 0);
                    }

                    50% {
                        opacity: 0.5;
                        transform: translate3d(0, -100px, 0);
                        -webkit-transform: translate3d(0, -100px, 0);
                    }

                    100% {
                        opacity: 1;
                        transform: translate3d(0, -100px, 0);
                        -webkit-transform: translate3d(0, -100px, 0);
                    }
                }

                @-webkit-keyframes fadeInDown {
                    0% {
                        opacity: 0;
                        transform: translate3d(0, 0, 0);
                        -webkit-transform: translate3d(0, 0, 0);
                    }

                    50% {
                        opacity: 0.5;
                        transform: translate3d(0, 0, 0);
                        -webkit-transform: translate3d(0, 0, 0);
                    }

                    100% {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                        -webkit-transform: translate3d(0, 0, 0);
                    }
                }

                @keyframes fadeInDown {
                    0% {
                        opacity: 0;
                        transform: translate3d(0, 0, 0);
                        -webkit-transform: translate3d(0, 0, 0);
                    }

                    50% {
                        opacity: 0.5;
                        transform: translate3d(0, 0, 0);
                        -webkit-transform: translate3d(0, 0, 0);
                    }

                    100% {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                        -webkit-transform: translate3d(0, 0, 0);
                    }
                }
            }
        }
        
        &go-top{
            background: #FFF url("/front_mo/images/icon_footer_goTop.png") no-repeat 22px 17px;
        }

        &gr-value{
            bottom: 218px;
            text-align: center;
            border-color: $gcolor;

            &:after{
                display: inline-block;
                margin-top: 9px;
                width: 37px;
                height: 40px;
                background: #FFF url("/front_mo/images/sp_ico.png?ver=20211214") no-repeat -267px -145px;
                content: '';
            }
        }

        
    }

    &__btn-tutorial {
        display: none !important;
        position: fixed;
        right: 25px;
        bottom: 120px;
        z-index: 2;
        width: 58px;
        height: 58px;
        background: #fff;
        border: 1px solid #A9A9A9;
        box-sizing: border-box;
        border-radius: 40px;
        color:$gcolor;
        font-size: 22px;
        text-align: center;
        line-height: 54px;
        -webkit-tap-highlight-color: transparent;

        &:focus{
            outline:none;
        }
    }

    &__nav-area {
        position: fixed;
        bottom: -1px;
        left: 50%;
        z-index: 2;
        width: 100%;
        max-width: 640px;
        height: 100px;
        background: #FFF;
        border-top: 1px solid #ddd;
        transform: translate3d(-50%, 0, 0);
        -webkit-transform: translate3d(-50%, 0, 0);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    }

    &__nav {
        display: block;
        position: relative;
        float: left;
        width: 20%;
        height: 100px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;

        strong {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 24px;
            color: #3D3D3D;
        }

        &:after{
            display: block;
            position: absolute;
            top: 15px;
            left: 50%;
            width: 40px;
            height: 36px;
            background: url("/front_mo/images/sp_ico.png?ver=210318") no-repeat;
            background-position-y: -114px; 
            background-size: 640px auto;
            transform: translateX(-50%);
            content: '';
        }

        $icons: lately, story, home, search, my;
        @for $i from 1 through length($icons) {
            &-#{nth($icons, $i)}:after {
                background-position-x: -#{($i - 1) * 40 + 64}px; 
            }
        }

        &-active {
            strong{
                color: $gcolor;
            }
            
            // &:before {
            //     display: block;
            //     position: absolute;
            //     left: 0;
            //     top: -1px;
            //     width: 100%;
            //     height: 4px;
            //     background: $gcolor;
            //     content: '';
            // }

            &:after {
                background-position-y: -150px; 
            }
        }

        &-heathcare:after {
            content: '';
            display: block;
            position: absolute;
            top: 15px;
            left: 50%;
            width: 40px;
            height: 40px;
            background: url(/front_mo/images/ico_common_healthcare.png) no-repeat 0 0;
            background-size: cover;
        }
    }

    &.docbar--hide {
        padding-bottom: 0;
    }

    &.docbar--hide .hd__nav-area {
        transform: translate3d(-50%, 101px, 0);
        -webkit-transform: translate3d(-50%, 101px, 0);
        transition: transform 0.5s linear;
        -webkit-transition: transform 0.5s linear;
    }

    &__page-title {
        position: relative;
        width: 100%;
        height: 70px;
        background: #FFF;
        text-align: center;
        border-top: 1px solid $colorBC;

        &__txt {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 480px;
            @extend %font_m;
            font-weight: bold;
            transform: translate3d(-50%, -50%, 0);
            -webkit-transform: translate3d(-50%, -50%, 0);
        }

        &__dropdown {

            &__txt {
                // position: absolute;
                // top: 50%;
                // left: 50%;
				// padding: 0 30px;
				line-height: 68px;
				display:flex;
				align-items: center;
				justify-content: center;
                @extend %font_m;
                font-weight: bold;
                cursor: pointer;
                // transform: translate3d(-50%, -50%, 0);
                // -webkit-transform: translate3d(-50%, -50%, 0);

                &:after {
                    display: block;
                    // position: absolute;
                    // top: 12px;
					// right: 0;
					margin: 5px 0 0 5px;
                    width: 17px;
                    height: 10px;
                    background: url("/front_mo/images/btn_menu_drop-down.png") no-repeat 50% 50%;
					content: '';
					transition: all .2s;
                }
            }

            ul {
                overflow:hidden; display:none; position:relative; width:100%; margin-top:69px; font-weight:bold; flex-wrap:wrap; background:#fff;
                li {
					flex:0 0 50%; position:relative; height:68px; background:#fff; line-height:68px; box-sizing:border-box;
                    a {
                        display: inline-block;
                        width: 100%;
                        @extend %font_s;
                        &:after {
                            display: block;
                            box-sizing: border-box;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border: none;
                            border-bottom: 1px solid $colorBC;
                            content: '';
                        }
                    }

                    // &:first-child {
                    //     margin-top: 1px;
                    // }
                }

                .on {
                    a {
                        &:after {
                            display: block;
                            box-sizing: border-box;
                            position: absolute;
                            left: 0;
                            top: -1px;
                            right: 0;
                            bottom: 0;
                            border: 3px solid $ocolor;
                            content: '';
                        }
                    }
                }
            }

            &--show {
                overflow: auto;
                ul {
					display: block;
					display: flex;
                }
                .hd__page-title__dropdown__txt {
                    &:after {
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                    }
                }
            }
        }

        &__btn-back {
            display: block;
            position: absolute;
            top: 9px;
            left: 20px;
            width: 40px;
            height: 50px;
            background: url("/front_mo/images/icon_header_back.png?ver=20211126") no-repeat 50% 50%;
        }

        &__btn-link{
            position: absolute;
            right: 30px;
            top: 15px;
            padding: 0 10px;
            height: 40px;
            line-height: 38px;
            font-size: 20px;
            border: 1px solid $colorBC;
            border-radius: 8px;
            box-sizing: border-box;

            &.green{color: $gcolor;}
            &.orange{color: #d7a728;}
        }

        .top-menu{
            border-top: 0;
        }
    }

    &__fullmenu {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        background: #FFF;

        .scroll-area {
            overflow: auto;
            height: calc(100% - 114px);
        }

        &__btns {
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);

            a {
                display: inline-block;
            }

            a + a {
                margin-left: 30px;
            }
        }

        .wrap-user-area {
            position: relative;
            width: 100%;
            font-size: 0;

            .user-area {
                display: block;
                padding: 40px 50px;
                @extend %font_l;
                background: $gcolor;
                color: #FFF;

                a {
                    line-height: 1;
                    @extend %font_l;
                    color: #FFF;

                    & + a {
                        margin-left: 27px;
                        padding-left: 27px;
                        border-left: 1px solid rgba(255,255,255,0.1);
                    }
                }

                strong {
                    font-weight: bold;

                    & + a {
                        margin-left: 38px;
                    }
                }
            }
        }

        .quick-menu {
            display: table;
            table-layout: fixed;
            padding: 30px 0;
            width: 100%;
            background: #847f00;
            box-sizing: border-box;
            overflow: hidden;

            a {
                display: table-cell;
                width: 100%;
                text-align: center;
                color: #FFF;
                font-size: 20px;
                box-sizing: border-box;

                & + a {
                    border-left: 1px solid rgba(255, 255, 255, 0.1);
                }
            }

            $list: mypage, myorder, mycoupon, mypoint;
            @for $i from 1 through length($list) {
                &-#{nth($list, $i)}:before {
                    display: block;
                    width: auto;
                    height: 40px;
                    margin: 0 auto 5px;
                    background: url("/front_mo/images/icon_quick-menu.png?ver=210305") no-repeat -#{($i - 1) * 160}px;
                    background-position-y: -20px;
                    content: '';
                }
            }
        }

        .gnb {
            width: 100%;

            &__1dep {
                position: relative;
                margin-top: 0;
                padding: 30px 0 20px;
                border-top: 30px solid #eee;

                &__title {
                    display: block;
                    padding-left: 30px;
                    height: 70px;
                    line-height: 70px;
                    @extend %font_l;
                    font-weight: bold;

                    > a {
                        position: relative;
                        display: block;
                        height: 100%;

                        /* &:after {
                            position: absolute;
                            right: 40px;
                            top: 50%;
                            width: 16px;
                            height: 16px;
                            vertical-align: middle;
                            border: solid rgba(0, 0, 0, 0.3);
                            border-width: 2px 2px 0 0;
                            -webkit-transform: rotate(45deg) translateY(-50%);
                            transform: rotate(45deg) translateY(-50%);
                            content: '';
                            z-index: 2;
                        } */
                    }

                }

                &.hmmarket{
                    padding: 0 0 50px !important;
                    border-top: 0;

                    .gnb__1dep{
                        &__title{
                            height: 120px;
                            line-height: 1;
                            background: #282828;
                            
                            a{
                                display: table;
                                width: 100%;
                                color: #fff;   

                                &:after{
                                    position: absolute;
                                    right: 40px;
                                    top: 50%;
                                    width: 16px;
                                    height: 16px;
                                    vertical-align: middle;
                                    border: solid rgba(255, 255, 255, 0.8);
                                    border-width: 2px 2px 0 0;
                                    -webkit-transform: rotate(45deg) translateY(-50%);
                                    transform: rotate(45deg) translateY(-50%);
                                    content: '';
                                    z-index: 2;
                                }

                                span{
                                    display: table-cell;
                                    vertical-align: middle;
                                }

                                > span:nth-of-type(1){
                                    position: relative;
                                    width: 150px;

                                    &:after{
                                        position: absolute;
                                        display: inline-block;
                                        left: 112px;
                                        top: 40px;
                                        width: 8px;
                                        height: 8px;
                                        background: #d99528;
                                        border-radius: 50%;
                                        content: '';
                                    }

                                    + span{
                                        margin-left: 30px;
                                        font-size: 20px;
                                        font-weight: normal;
                                        color: rgba(255,255,255,0.8);

                                        .name{
                                            display: inline-block;
                                            max-width: 220px;
                                            vertical-align: top;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        }

                                        /* &:before{
                                            display: block;
                                            margin: -10px 0 0 -26px;
                                            width: 122px;
                                            height: 35px;
                                            background: url('/front_mo/images/sp_ico.png?ver=20211221') no-repeat;
                                            background-position: -126px -191px;
                                            content: '';
                                            transform: scale(0.55);
                                            opacity: 0.4;
                                        } */
                                    }
                                }
                            }
                        }
                    }


                }

                &--service { 

                    .gnb__2dep {
                        padding: 0 !important;
                        width: 100%;

                        &__list{
                            width: 33.333%;
                            
                            a{
                                padding: 0 !important;
                                height: 170px;
                                line-height: 1;
                                text-align: center;

                                i{
                                    display: block;
                                    margin: 35px auto 25px;
                                }

                                &:after{
                                    display: none;
                                }

                            }
                        }

                        // 서비스 아이콘
                        @for $i from 1 through 6 {
                            li:nth-of-type(#{($i)}) i{
                                background-image: url('/front_mo/images/ico_category.png?ver=220207') !important;
                                background-position-y: (-#{1250+(($i)*50)}px); 
                            }
                        }
                    }

                }

                &--story{
                    // 스토리 아이콘
                    @for $i from 1 through 3 {
                        .gnb__2dep li:nth-of-type(#{($i)}) i{
                            background-image: url('/front_mo/images/ico_category.png?ver=220207') !important;
                            background-position-y: (-#{1100+(($i)*50)}px); 
                        }
                    }
                }

                &--cont{
                    padding: 20px 0 45px;

                    .gnb__2dep{
                        margin-top: 16px;
                        text-align: center;

                        &__list{
                            position: relative;
                            display: inline-block;
                            float: none;
                            margin: 0 4px;
                            width: 136px;
                            text-align: center;
                            overflow: hidden;

                            a{
                                padding: 0 !important;
                                line-height: 1 !important;
                                height: auto !important;
                                @extend %font_s;
                                z-index: 1;

                                &:after{
                                    display: none;
                                }

                                &:before{
                                    display: block;
                                    margin-bottom: 15px;
                                    height: 136px;
                                    background: url('/front_mo/images/img_gnb_cont.jpg') no-repeat;
                                    content: '';
                                    border-radius: 30px;
                                }
                            }

                            @for $i from 2 through 4 {
                                &:nth-of-type(#{($i)}) a:before{
                                    background-position-x: (#{136-(($i)*136)}px);
                                }
                            }

                        }
                    }
                }

                &--brand{
                    background: url('/front_mo/images/bg_gnb_brand.jpg') no-repeat;
                    background-size: 100% auto;

                    .gnb__1dep{
                        &__title a{
                            color: #fff;
                        }

                        &__text{
                            display: block;
                            margin-bottom: 40px;
                            padding-left: 30px;
                            @extend %font_s;
                            color: rgba(255,255,255,0.7);
                        }
                    }

                    .gnb__2dep{
                        white-space: nowrap;
                        overflow-y: auto;
                        margin-left: 30px;
                        padding: 20px 0 20px 20px;
                        margin: 0 0 10px 30px;
                        background: #fff;

                        &::-webkit-scrollbar{
                            display: none;
                        }

                        &__list{
                            display: inline-block;
                            float: none;
                            margin-right: 15px;
                            width: 120px;
                            text-align: center;
                            background: #fff;
                            vertical-align: top;
                            
                            &:last-child{
                                margin-right: 20px;
                            }

                            a{
                                width: 100%;
                                height: auto !important;
                                padding: 0 !important;
                                line-height: 1.2 !important;
                                white-space: normal;
                                font-size: 23px;
                                letter-spacing: -2px;

                                &:after{
                                    display: none;
                                }

                                &:before{
                                    display: block;
                                    margin-bottom: 15px;
                                    height: 120px;
                                    background: url('/front_mo/images/img_gnb_brand.jpg?ver=220125') no-repeat;
                                    content: '';
                                }
                            }

                            @for $i from 2 through 10 {
                                &:nth-of-type(#{($i)}) a:before{
                                    background-position-x: (#{120-(($i)*120)}px);
                                }
                            }
                        }
                    }
                }
            }

            .gnb__1dep.on .accordion_title {
                background-image: url("/front_mo/images/icon_arrow_big.png");
            }

            &__2dep {
                display: block;
                position: relative;
                @extend %clear;

                &__title {
                    display: block;
                    padding: 30px 30px 15px;
                    @extend %font_s;
                    
                    a{
                        letter-spacing: -1px;
                    }
                }

                &__list {
                    float: left;
                    width: 50%;
                    box-sizing: border-box;
                    @extend %font_xs;

                    a {
                        display: block;
                        position: relative;
                        padding: 0 30px;
                        height: 90px;
                        line-height: 90px;
                        @include line(1);
                        @extend %font_s;

                        &:after{
                            position: absolute;
                            right: 10px;
                            top: 45%;
                            width: 16px;
                            height: 16px;
                            vertical-align: middle;
                            border: solid rgba(0,0,0,0.1);
                            border-width: 2px 2px 0 0;
                            -webkit-transform: rotate(45deg) translateY(-50%);
                            transform: rotate(45deg) translateY(-50%);
                            content: '';
                            z-index: 2;
                        }

                        i{
                            display: inline-block;
                            width: 50px;
                            height: 50px;
                            margin-right: 15px;
                            vertical-align: middle;
                            background-repeat: no-repeat;
                            background-size: 300%;
                        }
                    }


                    &:nth-child(2n){
                        a:after{
                            right: 40px;
                        }
                    }
                }
            }

            .gnb__1dep:nth-of-type(1){
                // 정기식단 아이콘
                 .gnb__2dep:nth-of-type(1){
                    @for $i from 1 through 5 {
                        li:nth-of-type(#{($i)}) i{
                            background-image: url('/front_mo/images/ico_category.png?ver=220207') !important;
                            background-position-y: (-#{-50+(($i)*50)}px); 
                        }
                    }
                }

                // 챌린지 아이콘
                .gnb__2dep:nth-of-type(2){
                    @for $i from 1 through 4 {
                        li:nth-of-type(#{($i)}) i{
                            background-image: url('/front_mo/images/ico_category.png?ver=220207') !important;
                            background-position-y: (-#{200+(($i)*50)}px); 
                        }
                    }
                }
            }

            // 건강마켓 아이콘
            .gnb__1dep:nth-of-type(2){
                @for $i from 1 through 15 {
                    .gnb__2dep li:nth-of-type(#{($i)}) i{
                        background-image: url('/front_mo/images/ico_category.png?ver=220207') !important;
                        background-position-y: (-#{400+(($i)*50)}px); 
                    }
                }
            }
            
        }

        .quick-svc-btn {
            margin: 35px 0;
            text-align: center;
            font-size: 0;

            a {
                display: inline-block;
                @extend %font_xs;

                & + a {
                    margin-left: 48px;
                    color: $color3D;
                }
            }
        }

        &--show {
            display: block;
        }
    }

    .today-popup {
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);

        &__cont {
            position: absolute;
            top: 53%;
            left: 50%;
            width: 500px;
            @extend %font_s;
            transform: translate3d(-50%, -50%, 0);
            -webkit-transform: translate3d(-50%, -50%, 0);
            overflow: hidden;
            
            img{display: block;}

            .swiper {
                &-wrapper {
                    min-height: 350px;
                }

                &-container{
                    max-height: 768px;
                    overflow: hidden;
                    background: #fff;
                }

                &-pagination {
                    position: absolute;
                    z-index: 2;
                    bottom: 20px;
                    width: 100%;
                    text-align: center;
                }

                &-pagination-bullet {
                    float: none !important;

                    &:first-child {
                        margin-left: 0 !important;
                    }
                }
            }
        }

        &__btn {
            display: flex;
            position: relative;
            margin-top: 0;
            font-size: 0;

            button {
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 100%;
                height: 70px;
                text-align: center;
                line-height: 70px;
                font-size: 22px;
                color: #c3c3c3;
                border: 0;
                background: transparent;

                &:last-child:after{
                    position: absolute;
                    left: 0;
                    top: 23px;
                    width: 1px;
                    height: 25px;
                    background: #c3c3c3;
                    content: '';
                }
            }
        }
    }

    .appDownPopup{
        background: rgba(0, 0, 0, 0.8);

        .today-popup__cont{
            width: 100% !important;
            
            img{
                display: block;
                margin: 0 auto;
                width: auto;
            }

        }

        .top-area__btn-icon{
            position: absolute;
            right: 30px;
            top: 30px;   
            margin-left: 0;
            vertical-align: 0;

            &:before, &:after{
                height: 32px;
            }
        }
    }    

    .progress {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 99999;

        img {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 130px;
            border-radius: 50%;
            overflow: hidden;
            transform: translate3d(-50%, -50%, 0);
            -webkit-transform: translate3d(-50%, -50%, 0);
		}
        
        &:before {
            position:absolute; top:50%; left:50%; width:95px; height:95px; display:block; background:url(/front_mo/images/progress.gif?ver=20210316) no-repeat center center; transform:translate(-50%,-50%); content:"";
            background-size: 100px auto;
            border-radius: 50%;
            overflow: hidden;
        }
		
		&.cart {
			position:fixed; top:50%; left:50%; transform:translate(-50%,-50%); width:140px; height:140px; background:none;
			img {display:none;}
			&:before {content:"";  display:block; background:url(/front_pc/images/addCart.png); width:140px; height:140px; }
			&.fadeOut {-webkit-animation: cartAni .4s ease-in-out both;animation: cartAni .4s ease-in-out both;}
		}
	}
	
	@-webkit-keyframes cartAni {0% {opacity: 1;top: 50%;left: 50%;transform: translate(-50%, -50%) scale(1);} 100% {opacity: .2;top: 0;left: 82%; transform: translate(0, 0) scale(0);}}
	@keyframes cartAni {0% {opacity: 1;top: 50%;left: 50%;transform: translate(-50%, -50%) scale(1);} 100% {opacity: .2;top: 0;left: 82%; transform: translate(0, 0) scale(0);}}

    input[type=number].secret_mode {
        -webkit-text-security:disc;
    }

    .agree-area-mem {
        
        .accordion_con {
            padding: 20px 0;
            border-bottom: 1px solid $colorBC;

            .check-list {
                margin: 10px 0;                
            }
        }
    }

    .change-address {
        
        &__desc {
            margin-top: 20px;
            font-size: 24px;
        }

        &__list {

            &__inner {
                position: relative;

                .title-area {
                    width: calc(100% - 140px);
                    box-sizing: border-box;
                }
            }
        }

        .btn-change {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 0;
        }
    }

    &__idFind {
        .csTel {
            color: $gcolor;
        }
    }

    &__idFindResult {
        .btn-area {
            margin-top: 20px;

            button {
                width: 280px;
            }

            button + button {
                margin-left: 20px;
            }

            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }

        &__desc {
            margin-top: 20px;
            font-size: 22px;
            text-align: center;
        }
    }

    .hd__orderCart{
        .tnb-area{
            position: relative;
            border-bottom: 0;
        }
        .tab-area-v3{
            margin: 0 -30px;
            width: 640px;
        }
    }

    .cart{
        &_delivery{
            position: relative;
            padding: 30px 0 25px;
            border-bottom: 3px solid $color3D;

            dl{
                font-size: 20px;

                dt,dd{display: block;}

                dt{
                    margin-bottom: 10px;
                    font-size: 23px;
                    font-weight: 700;
                }
                dd{
                    em{
                        font-weight: 700;
                        color: $gcolor;
                    }
                    &:nth-of-type(2),&:nth-of-type(3){margin-top: 8px;}
                }

            }

            .progress_wrap{
                .ico_delivery{margin-left: 10px;}

                &.active{
                    .ico_delivery{
                        animation: bounce 0.5s cubic-bezier(0.5,0,0,0.8) forwards;
                    }
                    @keyframes bounce {
                       from,15%{transform:scale(0.8);opacity:1}
                       40%{transform :scale(1.1);opacity:1}
                       70%{animation-timing-function: cubic-bezier(0.215,0.6,0.355,1);transform:scale(0.90)}
                       to{transform: scale(1);opacity:1}
                     }
                }
            }

            .ico_tooltip{
                width: 27px;
                height: 27px;
                background-size: 26px auto;
            }

            &_btn{
                position: absolute;
                right: 0;
                bottom: 25px;
                font-size: 19px;
                letter-spacing: -0.5px;

                &:before{
                    display: inline-block;
                    margin-right: 7px;
                    width: 19px;
                    height: 19px;
                    line-height: 18px;
                    font-size: 18px;
                    color: $gcolor;
                    text-align: center;
                    border: 1px solid $gcolor;
                    border-radius: 50%;
                    content: '+';
                }
            }
        }

        &_gift{
            margin-top: 25px;
            padding-bottom: 25px;
            border-bottom: 3px solid $color3D;
            font-size: 22px;

            .accordion{
                &_wrap{
                    position: relative;
                }

                &_title{
                    position: relative;

                    span{
                        display: block;
                        margin-bottom: 5px;
                        color: $color88;
                    }

                    strong{
                        display: block;
                        @extend %font_s;
                    }

                    &:before {
                        position: absolute;
                        top: 7px;
                        right: 60px;
                        padding: 4px 9px;
                        font-size: 15px;
                        content: 'GIFT';
                        background: #e2e735;
                        border: 2px solid #1c1c1c;
                        border-radius: 30px;
                        vertical-align: top;
                        text-transform: uppercase;
                        letter-spacing: 0;
                        animation: giftMotion 0.4s ease-out 0s infinite alternate;
                    }

                    @keyframes giftMotion {
                        0% {top: 7px;}
                        100% {top: 10px;}
                    }

                    &:after{
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        margin-top: -8px;
                        width: 30px;
                        height: 16px;
                        background: url(/front_mo/images/icon_common_drop-down.png) no-repeat;
                        background-size: 100%;
                        content: "";
                        transition: transform .3s;
                    }
                }

                &_con{
                    line-height: 1.3;
                }
            }

            &.on .accordion_title:after{
                    transform: rotate(-180deg ); 
            }

            .gift{
                &-info{
                    display: table;
                    margin-top: 25px;
                    padding: 20px;
                    width: 100%;
                    overflow: hidden;
                    box-sizing: border-box;
                    border: 1px solid $colorBC;

                    &__price{
                        display: table-cell;
                        width: 210px;
                        text-align: center;
                        vertical-align: middle;
                        
                        p span{
                            display: block;
                        }

                        .price{
                            display: inline-block;
                            position: relative;
                            @extend %font_m;
                            font-weight: 600;

                            &:after{
                                position: absolute;
                                left: 0;
                                bottom: -2px;
                                width: 100%;
                                height: 7px;
                                background: $ocolor;
                                content: '';
                                z-index: -1;
                            }
                        }
                    }

                    &__text{
                         display: table-cell;
                         padding-left: 30px;
                         color: #2d4641;
                         vertical-align: middle;

                        strong{
                            @extend %font_s;

                            span{
                                font-size: 23px;
                                font-weight: normal;
                            }
                        }

                        p{
                            margin-top: 5px;
                            color: $gcolor;
                        }
                    }
                }

                &-list{
                    margin-top: 25px;
                    overflow: hidden;

                    &__thumb{
                        float: left;
                        margin-right: 20px;
                        width: 160px;
                        height: 100px;
                    }

                    &__item{
                        float: left;
                        width: calc(100% - 180px);

                        .item-list{
                            position: relative;
                            margin-bottom: 10px;
                            padding-left: 75px;
                            line-height: 1.5;
                            @extend %font_xs;

                            span{
                                @include line(2);
                                text-decoration: underline;
                            }

                            i{
                                position: absolute;
                                left: 0;
                                top: 5px;
                                padding: 3px 8px;
                                line-height: 1;
                                font-size: 19px;
                                font-style: normal;
                                border: 1px solid $color3D;
                            }

                            &.on, &.on:before, &.on span, &.on i{
                                color: $gcolor;
                                border-color: $gcolor;
                            }

                            &.soldout, &.soldout span, &.soldout i{
                                color: $color88;
                                border-color: $color88;

                            }
                        }
                    }
                }

                &-link{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }
        }

        &_info{
            margin: 0 -30px;
            padding: 10px 0 25px;
            text-align: center;
            background-color: #eee;
            border-bottom: 1px solid #ddd;

            dl{
                display: inline-block;
                margin: 0 auto;
                padding: 25px 100px;
                background-color: #fff;
                border-radius: 100px
            }

            dt{
                @extend %font_m;
                font-weight: 600;
                color: $gcolor;
            }

            dd{
                margin-top:10px;
                font-size: 20px;
                color: $color88;
            }
        }
    }
}

.wdw-type {
    .hd {

        .dawnDelivery--desc {
            padding: 30px 0;
            font-size: 22px;
            line-height: 32px;
        }
        
        &__header {
            position: fixed;
            top: 0;
            z-index: 9;
            width: 100%;
            //background: #FFF;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;

            &__logo {
                padding-top : rem(23px);
                text-align: center;

                a {
                    display: inline-block;
                    width: rem(195px);
                }
            }

            &__inner {
                height: rem(80px);
            }

            &__basket {
                position: absolute;
                top: rem(15px);
                right: rem(35px);
                width: rem(40px);
                height: rem(40px);
                padding: rem(10px);
                background: url(/front_mo/images/icon-header-basket.png) no-repeat 50% 50%;
                background-size: rem(38px 35px);

                &__count {
                    display: block;
                    position: absolute;
                    top: rem(-5px);
                    right: rem(-5px);
                    width: rem(30px);
                    height: rem(30px);
                    background: $gcolor;
                    color: #FFF;
                    font-size: rem(14px);
                    text-align: center;
                    line-height: rem(28px);
                    border-radius: rem(20px);
                }
            }

            &__menu-icon {
                position: absolute;
                top: rem(5px);
                left: rem(15px);
                padding: rem(15px);

                span {
                    display: block;
                    width: rem(38px);
                    height: rem(2px);
                    background: $color3D;
                    margin: rem(9px 0);
                }
            }

            .top-menu {
                height: rem(70px);

                &__list {
                    padding: rme(0 0 0 30px);
                    height: rem(70px);
                    line-height: rem(70px);
                    color: $color3D;

                    &:last-child{
                        padding-right: rem(30px);
                    }

                    a {
                        padding: rem(0 10px);
                        font-size: rem(24px);
                        letter-spacing: rem(-0.5px);
                    }

                    &.on a:after{
                        height: rem(4px);
                    }
                }
            }

            &__st-event {
                background: #FFF;
                transform: translate3d(0, -4.05rem, 0);
                -webkit-transform: translate3d(0, -4.05rem, 0);
                transition: transform 0.25s linear;
                -webkit-transition: transform 0.25s linear;
            }
        }

        &__footer {
            position: relative;
            padding-top: rem(130px);
    
            &__inner {
                padding: rem(40px 30px 55px);
                background: #f9f9f9;
            }

            &__menu-area {
                @extend %clear;

                a {
                    display: block;
                    float: left;
                    width: auto;
                    font-size: rem(20px);

                    &:not(:last-child) {
                        color: $color88;
                    }

                    &:not(:first-child) {
                        margin-left: rem(26px);
                        padding-left: rem(6px);
                    }
                }
            }

            &__sns {
                margin-top: rem(32px);
                @extend %clear;

                a {
                    width: rem(50px);
                    height: rem(50px);

                    &:not(:first-child) {
                        margin-left: rem(20px);
                    }
                }

                $list: intagram, youtube, blog, kakao;
                @for $i from 1 through length($list) {
                    &-#{nth($list, $i)}:after {
                        width: rem(50px);
                        height: rem(50px);
                        background: url("/front_mo/images/icon_footer_sns.png") no-repeat -#{($i - 1) * 2.5}rem;
                    }
                }
            }

            &__cs {
                margin-top: rem(30px);

                p {
                    margin: rem(7px 0);
                    color: $color88;
                    @extend %font_xs;
                }

                &__center {
                    color: $color3D !important;
                    @extend %font_xs;
                    line-height: rem(34px);

                    span {
                        color: $gcolor;
                        font-size: rem(26px);
                        font-weight: bold;
                    }
                }

                &__gcolor {
                    color: $gcolor;
                }
            }

            &__desc {
                margin-top: rem(35px);

                p {
                    margin: rem(7px 0);
                    line-height: rem(28px);
                }

                &__txt{
                    margin: rem(20px 0 0 0) !important;
                }

                &__copyright {
                    margin: rem(20px 0 0 0) !important;
                    font-size: rem(21px) !important;
                }

                &__seper {
                    &:before {
                        padding: rem(0 5px);
                        content: '|';
                    }
                }
            }
        }

        &__btn-{
            right: rem(25px);
            bottom: rem(150px);
            width: rem(58px);
            opacity: 0;

            a{
                margin-top: rem(10px);
                border: 1px solid #A9A9A9;
                border-radius: rem(40px);

                &.go-top{
                    background: #FFF url("/front_mo/images/icon_footer_goTop.png") no-repeat rem(22px 17px);
                }

                &.gr-value{
                    &:after{
                        margin-top: rem(9px);
                        width: rem(37px);
                        height: rem(40px);
                        background: #FFF url("/front_mo/images/sp_ico.png?ver=20211214") no-repeat rem(-267px -145px);
                    }
                }
            }
        }

        &__nav-area {
            position: fixed;
            bottom: -1px;
            left: 50%;
            z-index: 2;
            width: 100%;
            max-width: none;
            height: rem(100px);
            background: #FFF;
            border-top: 1px solid #ddd;
            transform: translate3d(-50%, 0, 0);
            -webkit-transform: translate3d(-50%, 0, 0);
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
        }

        &__nav {
            height: rem(100px);
            font-size: rem(14px);

            strong {
                bottom: rem(24px);
            }

            &:after{
                top: rem(15px);
                width: rem(40px);
                height: rem(36px);
                background-position-y: rem(-114px); 
                background-size: rem(640px) auto;
            }


            $icons: lately, story, home, search, my;
            @for $i from 1 through length($icons) {
                &-#{nth($icons, $i)}:after {
                    background-position-x: -#{($i - 1) * 2 + 3.2}rem; 
                }
            }

            &-active {
                &:before {
                    height: rem(4px);
                }

                &:after {
                    background-position-y: rem(-150px); 
                }
            }

            &-heathcare:after {
                top: rem(15px);
                width: rem(40px);
                height: rem(40px);
                background-position: 0 0;
                background-size: 100% auto;
            }
        }

        &.docbar--hide {
            padding-bottom: 0;
        }

        &.docbar--hide .hd__nav-area {
            transform: translate3d(-50%, 100%, 0);
            -webkit-transform: translate3d(-50%, 100%, 0);
            transition: transform 0.5s linear;
            -webkit-transition: transform 0.5s linear;
        }

        &__page-title {
            position: relative;
            width: 100%;
            height: rem(70px);
            background: #FFF;
            text-align: center;

            &__txt {
                position: absolute;
                top: 50%;
                left: 50%;
                width: rem(480px);
                font-weight: bold;
                transform: translate3d(-50%, -50%, 0);
                -webkit-transform: translate3d(-50%, -50%, 0);
            }

            &__dropdown {

                &__txt {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    padding: rem(0 30px);
                    @extend %font_m;
                    font-weight: bold;
                    cursor: pointer;
                    transform: translate3d(-50%, -50%, 0);
                    -webkit-transform: translate3d(-50%, -50%, 0);

                    &:after {
                        display: block;
                        position: absolute;
                        top: rem(12px);
                        right: 0;
                        width: rem(17px);
                        height: rem(10px);
                        background: url("/front_mo/images/btn_menu_drop-down.png") no-repeat 50% 50%;
                        background-size: rem(17px 10px);
                        content: '';
                    }
                }

                ul {
                    overflow: hidden;
                    display: none;
                    position: relative;
                    width: 100%;
                    // height: 100%;
                    margin-top: rem(68px);
                    font-weight: bold;

                    li {
                        position: relative;
                        height: rem(68px);
                        background: #FFF;
                        line-height: rem(68px);
                        box-sizing: border-box;

                        a {
                            display: inline-block;
                            width: 100%;
                            @extend %font_s;

                            &:after {
                                display: block;
                                box-sizing: border-box;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border: none;
                                border-bottom: 1px solid $colorBC;
                                content: '';
                            }
                        }

                        &:first-child {
                            margin-top: rem(1px);
                        }
                    }

                    .on {
                        a {
                            &:after {
                                display: block;
                                box-sizing: border-box;
                                position: absolute;
                                left: 0;
                                top: rem(-1px);
                                right: 0;
                                bottom: 0;
                                border: rem(3px) solid $ocolor;
                                content: '';
                            }
                        }
                    }
                }

                &--show {
                    overflow: auto;

                    ul {
                        display: block;

                    }
                    .hd__page-title__dropdown__txt {
                        &:after {
                            transform: rotate(180deg);
                            -webkit-transform: rotate(180deg);
                        }
                    }
                }
            }

            &__btn-back {
                top: rem(9px);
                left: rem(20px);
                width: rem(40px);
                height: rem(50px);
                background-size: 0.8rem auto;
            }
        }

        &__fullmenu {

            .scroll-area {
                height: calc(100% - 5.7rem);
            }

            &__btns {
                right: rem(30px);

                a + a {
                    margin-left: rem(30px);
                }

                .newPage {
                    img {
                        width: rem(36px);
                        height: rem(36px);
                    }
                }

                .btn-close {
                    img {
                        width: rem(36px);
                        height: rem(36px);
                    }
                }

                .btn-setting {
                    img {
                        width: rem(36px);
                        height: rem(36px);
                    }
                }
            }

            .wrap-user-area {
                width: 100%;

                .user-area {
                    padding: rem(40px 50px);

                    a {
                        display: inline-block;

                        & + a {
                            margin-left: rem(27px);
                            position-left: rem(27px);
                        }
                    }

                    strong {
                        & + a {
                            margin-left: rem(38px);
                        }
                    }
                }
            }

            .quick-menu {
                padding: rem(30px 0); 

                a {
                    padding-bottom: rem(10px); 
                    font-size: rem(20px);

                }

                $list: mypage, myorder, mycoupon, mypoint;
                @for $i from 1 through length($list) {
                    &-#{nth($list, $i)}:before {
                        width: 8rem;
                        height: rem(40px);
                        margin: 0 auto rem(5px);
                        background: url("/front_mo/images/icon_quick-menu.png?ver=210305") no-repeat -#{($i - 1) * 8}rem;
                        background-position-y: rem(-20px);
                        background-size: rem(640px) auto;
                    }
                }
            }

            .gnb {
                .form_box{
                    display: block;
                }
                
                &__1dep {
                    padding: rem(30px 0 10px);

                    &__title {
                        height: rem(70px);
                        line-height: rem(70px);
                        padding-left: rem(30px);
                        @extend %font_l;

                        // > a:after{
                        //     right: rem(40px);
                        //     width: rem(16px);
                        //     height: rem(16px);
                        // }
                    }

                    .accordion_title {
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: rem(65px);
                        height: rem(64px);
                        background: url("/front_mo/images/icon_arrow_big_down.png") no-repeat 50% 50%;
                        background-size: rem(25px 14px);
                        opacity: 0.8;
                    }

                    &.hmmarket{
                        padding-bottom: rem(50px) !important;

                        .gnb__1dep{
                            &__title{
                                height: rem(120px) !important;
                                line-height: 1 !important;
                                
                                a{
                                    &:after{
                                        right: rem(40px);
                                        width: rem(16px);
                                        height: rem(16px);
                                        border-width: rem(2px 2px 0 0);
                                    }

                                    > span:nth-of-type(1){
                                        width: rem(150px);

                                        &:after{
                                            left: rem(112px);
                                            top: rem(40px);
                                            width: rem(8px);
                                            height: rem(8px);
                                        }

                                        + span{
                                            margin-left: rem(30px);
                                            font-size: rem(20px);

                                            .name{
                                                max-width: rem(220px);
                                            }

                                            &:before{
                                                margin: rem(-10px 0 0 -26px);
                                                width: rem(122px);
                                                height: rem(35px);
                                                background-size: rem(640px); 
                                                background-position: rem(-126px -191px);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &--service {
                        .gnb__2dep {
                            &__list{
                                a{
                                    line-height: 1;
                                    height: rem(170px);

                                    i{
                                        margin: rem(35px auto 25px);
                                    }

                                }
                            }
                        }

                    }

                    &--cont{
                         padding: rem(20px 0 45px);

                        .gnb__2dep{
                            margin-top: rem(16px);

                            &__list{
                                margin: rem(0 4px);
                                width: rem(136px);

                                a{
                                    &:before{
                                        margin-bottom: rem(15px);
                                        height: rem(136px);
                                        background-size: rem(544px) auto;
                                        border-radius: rem(30px);
                                    }
                                }

                                @for $i from 2 through 4 {
                                    &:nth-of-type(#{($i)}) a:before{
                                        background-position-x: (#{6.8-(($i)*6.8)}rem);
                                    }
                                }

                            }
                        }
                    }

                    &--brand{
                        background-size: ren(640px) auto;

                        .gnb__1dep{
                            &__text{
                                margin-bottom: rem(40px);
                                padding-left: rem(30px);
                            }
                        }

                        .gnb__2dep{
                            margin-left: rem(30px);
                            padding: rem(20px 0 20px 20px);
                            margin: rem(0 0 10px 30px);

                            &__list{
                                margin-right: rem(15px);
                                width: rem(120px);
                                
                                &:last-child{
                                    margin-right: rem(20px);
                                }

                                a{
                                    &:before{
                                        margin-bottom: rem(15px);
                                        height: rem(120px);
                                        background-size: rem(1080px) auto;
                                    }
                                }

                                @for $i from 2 through 10 {
                                    &:nth-of-type(#{($i)}) a:before{
                                        background-position-x: (#{6-(($i)*6)}rem);
                                    }
                                }
                            }
                        }
                    }

                }


                &__2dep {
                    &__title {
                        padding: rem(30px 30px 15px);
                    }

                    &__list {
                        a {
                                padding: rem(0 30px);
                                height: rem(90px);
                                line-height: rem(90px);

                            &:after{
                                right: rem(10px);
                                width: rem(16px);
                                height: rem(16px);
                            }

                            i{
                                width: rem(50px);
                                height: rem(50px);
                                margin-right: rem(15px);
                                background-size: rem(150px);
                            }
                        }

                        &:nth-of-type(2n) a:after{
                            right: rem(40px);
                        }
                    }
                }

                .gnb__1dep:nth-of-type(1){
                    // 정기식단 아이콘
                     .gnb__2dep:nth-of-type(1){
                        @for $i from 1 through 5 {
                            li:nth-of-type(#{($i)}) i{
                                background-position-y: (-#{-2.5+(($i)*2.5)}rem); 
                            }
                        }
                    }

                    // 챌린지 아이콘
                    .gnb__2dep:nth-of-type(2){
                        @for $i from 1 through 4 {
                            li:nth-of-type(#{($i)}) i{
                                background-position-y: (-#{10+(($i)*2.5)}rem);
                            }
                        }
                    }
                }

                // 건강마켓 아이콘
                .gnb__1dep:nth-of-type(2){
                    @for $i from 1 through 15 {
                        .gnb__2dep li:nth-of-type(#{($i)}) i{
                            background-position-y: (-#{20+(($i)*2.5)}rem);
                        }
                    }
                }

                // 스토리 아이콘
                .gnb__1dep--story{
                    @for $i from 1 through 3 {
                        .gnb__2dep li:nth-of-type(#{($i)}) i{
                            background-position-y: (-#{55+(($i)*2.5)}rem);
                        }
                    }
                }

                // 서비스 아이콘
                .gnb__1dep--service{
                    @for $i from 1 through 6 {
                        .gnb__2dep li:nth-of-type(#{($i)}) i{
                            background-position-y: (-#{62.5+(($i)*2.5)}rem);
                        }
                    }
                }
            }

            .quick-svc-btn {
                margin: rem(35px 0);
                text-align: center;
                font-size: 0;

                a {
                    display: inline-block;
                    @extend %font_xs;

                    & + a {
                        margin-left: rem(48px);
                        color: $color3D;
                    }
                }
            }

            &--show {
                display: block;
            }
        }

        .today-popup {
            position: fixed;
            z-index: 20;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);

            &__cont {
                overflow: hidden;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 500px;
                background: #FFF;
                transform: translate3d(-50%, -50%, 0);
                -webkit-transform: translate3d(-50%, -50%, 0);

                .swiper {
                    &-wrapper {
                        min-height: rem(350px);
                    }

                    &-pagination {
                        position: absolute;
                        z-index: 2;
                        bottom: rem(20px);
                        width: 100%;
                        text-align: center;
                    }

                    &-pagination-bullet {
                        float: none !important;

                        &:first-child {
                            margin-left: 0 !important;
                        }
                    }
                }
            }

            &__btn {
                position: relative;
                margin-top: rem(1px);
                font-size: 0;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: rem(1px);
                    height: 100%;
                    background: #FFF;
                    transform: translate3d(-50%, 0, 0);
                    -webkit-transform: translate3d(-50%, 0, 0);
                }

                button {
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                    height: rem(70px);
                    text-align: center;
                    line-height: rem(70px);
                    font-size: rem(22px);

                    &.btn-gray {
                        color: $color3D;
                    }
                }
            }
        }

        .progress {
            img {
                width: rem(181px);
            }

            &:before {
                width: rem(95px);
                height: rem(95px);
                background-size: 100%;
            }
        }
        

        .dawnDelivery--desc {
            padding: rem(30px 0);
            font-size: rem(22px);
            line-height: rem(32px);
        }


        .agree-area-mem {
        
            .accordion_con {
                padding: rem(20px 0);
                border-bottom: 0;
    
                .check-list {
    
                    & + .check-list {
                        margin-top: rem(20px);
                    }
                    
                }
            }
        }
    }

	/* 주문 수정 */
	.hd {
		.form_title + .input_type {
			//display:flex;
			align-items:center;

			&.adress{
				display:block;
			}
		}
	}

    // wdw-type 적용하고자 subMain.css에서 가져온 스타일
    .mr_42 {
        margin-right: rem(42px) !important;
    }
    .ml_37 {
        margin-left: rem(37px) !important;
    }
    .mt_0 {
        margin-top: 0 !important;
    }
    .mt_5 {
        margin-top: rem(5px) !important;
    }
    .mt_8 {
        margin-top: rem(8px) !important;
    }
    .mt_10 {
        margin-top: rem(10px) !important;
    }
    .mt_15 {
        margin-top: rem(15px) !important;
    }
    .mt_20 {
        margin-top: rem(20px) !important;
    }
    .mt_22 {
        margin-top: rem(22px) !important;
    }
    .mt_25 {
        margin-top: rem(25px) !important;
    }
    .mt_26 {
        margin-top: rem(26px) !important;
    }
    .mt_27 {
        margin-top: rem(27px) !important;
    }
    .mt_30 {
        margin-top: rem(30px) !important;
    }
    .mt_34 {
        margin-top: rem(34px) !important;
    }
    .mt_35 {
        margin-top: rem(35px) !important;
    }
    .mt_42 {
        margin-top: rem(42px) !important;
    }
    .mt_40 {
        margin-top: rem(40px) !important;
    }
    .mt_44 {
        margin-top: rem(44px) !important;
    }
    .mt_45 {
        margin-top: rem(45px) !important;
    }
    .mt_50 {
        margin-top: rem(50px) !important;
    }
    .mt_55 {
        margin-top: rem(55px) !important;
    }
    .mt_60 {
        margin-top: rem(60px) !important;
    }
    .mt_65 {
        margin-top: rem(65px) !important;
    }
    .mt_70 {
        margin-top: rem(70px) !important;
    }
    .mt_75 {
        margin-top: rem(75px) !important;
    }
    .mt_80 {
        margin-top: rem(80px) !important;
    }
    .mt_85 {
        margin-top: rem(85px) !important;
    }
    .mt_90 {
        margin-top: rem(90px) !important;
    }
    .mt_95 {
        margin-top: rem(95px) !important;
    }
    .mt_100 {
        margin-top: rem(100px) !important;
    }
    .mt_110 {
        margin-top: rem(110px) !important;
    }
    .mt_113 {
        margin-top: rem(113px) !important;
    }
    .accordion_con,
    [data-acd-cont] {
        display: none;
    }
    .accor_head {
        border: 0 !important;
        height: rem(140px);
        background: #fff;
        padding: rem(30px 40px);
    }
    .accor_cnt {
        border: 0;
        background: #eaedf2 !important;
        padding: rem(50px 40px);
    }
    .accor_head li {
        float: left;
    }
    .oder_title.first {
        border-top-color: #fff;
    }
    .oder_title {
        position: relative;
        padding: rem(28px 94px 28px 30px);
        border-top: 1px solid #e4e4e4;
        font-size: rem(32px);
        line-height: 1;
        transition: background 0.4s;
    }
    .oder_title:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: rem(31px);
        width: rem(33px);
        height: rem(17px);
        margin-top: rem(-8px);
        background: url(/front_mo/images/icon_arrow_big.png) no-repeat;
        background-size: rem(33px 17px);
        transform: rotate(-180deg);
    }
    .oder_title:after {
        content: "";
        display: block;
        clear: both;
        width: 0;
        height: 0;
        font-size: 0;
    }
    .oder_title .oder_price {
        float: right;
        font-size: rem(28px);
        color: $colorRD;
        font-weight: 600;
    }
    .accordion_box.on .oder_title {
        border-top: 1px solid #9f9f9f;
        background: #efefef;
    }
    .accordion_box.on .oder_title.first {
        border: none;
    }
    .accordion_box.on .oder_title:before {
        transform: rotate(0);
    }
    .hd .hidden,
    legend {
        overflow: hidden;
        position: absolute;
        width: rem(1px);
        height: rem(1px);
        clip: rect(1px 1px 1px 1px);
    }
    .hd .input_text {
        width: 100%;
        height: rem(70px);
        padding: rem(0 20px);
        background: #fff;
        border: 1px solid #bebebe;
        color: $color3D;
        font-size: rem(24px);
        border-radius: 0;
        box-sizing: border-box;
        line-height: rem(64px);
    }
    .hd .input_text:disabled {
        background: #ebebe4;
    }
    .hd .se_complete p {
        color: $color3D;
    }
    .hd .se_complete p span {
        font-weight: 600;
        color: $color3D;
    }
    .hd .no_data {
        float: none !important;
        width: 100% !important;
        min-height: 0 !important;
        padding: rem(150px 0) !important;
        margin: 0 auto !important;
        border-top: 0 !important;
        line-height: rem(36px);
        font-size: rem(22px);
        color: $color3D;
        text-align: center;
        border: 0 !important;
        background: url(/front_mo/images/icon_list-no.png) no-repeat 50% rem(70px);
        background-size: rem(68px 68px);
    }
    .hd .no_data.type2 {
        padding: rem(38px 0) !important;
        border-bottom: none !important;
        background: 0 0;
    }
    .hd .no_data:before {
        display: none;
    }
    .hd .no_data .sc_result {
        font-size: rem(28px);
        margin-bottom: rem(20px);
        display: inline-block;
        color: #5f5f5f;
    }
    .hd .no_data .sc_text {
        font-size: rem(24px);
        line-height: rem(35px);
        color: #9f9f9f;
    }
    .hd .input_select {
        width: 100% !important;
        max-width: rem(580px);
        height: rem(70px);
        padding: rem(0 20px);
        background:#fff url(/front_mo/images/ico_comm_select.png) no-repeat right rem(20px) top 50%;
        background-size: rem(25px) auto;
        box-sizing: border-box;
        -webkit-appearance: none;
        resize: none;
    }
    .hd .file_info {
        margin-top: rem(15px);
        line-height: rem(36px);
        font-size: rem(22px);
        color: $color88;
    }
    .hd .member__recofirm {
        padding-top: rem(60px);
    }
    .hd .member__cont {
        display: none;
    }
    .hd .member__cont.on {
        display: block;
    }
    .hd .member__top-area {
        margin-bottom: rem(35px);
        text-align: center;
    }
    .hd .member__top-area strong {
        display: block;
        margin-bottom: rem(25px);
        line-height: rem(36px);
        font-size: rem(30px);
        font-weight: 600;
        word-break: keep-all;
    }
    .hd .member__top-area p {
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .hd .sns-user .sns-list {
        text-align: center;
        font-size: 0;
    }
    .hd .sns-user .sns-list li {
        display: inline-block;
        margin: rem(0 20px);
        vertical-align: top;
    }
    .hd .sns-user .sns-list li .sns__name {
        display: block;
        margin-top: rem(15px);
        font-size: rem(22px);
    }
    .hd .myMember__sec {
        margin-top: rem(40px);
    }
    .hd .myMember__sec .myMember__title {
        margin-bottom: rem(15px);
        font-size: rem(24px);
        font-weight: 600;
        color: $color3D;
    }
    .hd .myMember__sec .myMember__title .import {
        font-weight: 400;
        color: $gcolor;
    }
    .hd .myMember__sec .input-box select {
        width: 100%;
        padding-right: rem(70px);
        padding-left: rem(20px);
        height: rem(70px);
        border: 1px solid #bdbdbd;
        background: url(/front_mo/images/ico_member_select.png) no-repeat 94% 50%;
        background-size: rem(30px 16px);
        line-height: rem(70px);
        color: $color3D;
    }
    .hd .myMember__sec .input-box textarea {
        display: block;
    }
    .hd .member-drop .btn-area {
		margin-top: rem(40px);
		display: flex;
		flex-wrap:wrap;
    }
    .hd .member-drop .btn-area > * {
        font-weight: 600;
    }
    .hd .member-drop .btn-area a {
        display: block;
        width: 100%;
        text-align: center;
    }
    .hd .member-drop__notice {
        border-top: rem(3px) solid $gcolor;
        border-bottom: rem(3px) solid $gcolor;
        padding: rem(30px 0);
        counter-reset: meals_list;
    }
    .hd .member-drop__notice li {
        margin-top: rem(30px);
    }
    .hd .member-drop__notice li:first-child {
        margin-top: 0;
    }
    .hd .member-drop__notice li .member-drop__title {
        display: block;
        position: relative;
        padding-left: rem(25px);
        line-height: rem(32px);
        font-size: rem(22px);
        font-weight: 600;
    }
    .hd .member-drop__notice li .member-drop__title+.member-drop__desc {
        margin-top: rem(20px);
    }
    .hd .member-drop__notice li .member-drop__title:before {
        counter-increment: meals_list;
        content: counter(meals_list)".";
        position: absolute;
        top: 0;
        left: 0;
        font-size: rem(22px);
        font-weight: 600;
    }
    .hd .member-drop__notice li .member-drop__desc {
        display: block;
        position: relative;
        margin-left: rem(20px);
        padding-left: rem(15px);
        line-height: rem(32px);
        font-size: rem(22px);
        color: $color88;
    }
    .hd .member-drop__notice .member-drop__desc:before {
        content: '-';
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        font-size: rem(22px);
        color: $color88;
    }
    .hd .member__cont .btn-area {
        margin-top: rem(40px);
    }
    .hd .member-update .btn-area {
        margin-top: rem(40px);
    }
    .hd .member-update .member-box__title+.input-box {
        border-top: 1px solid $colorBC;
    }
    .hd .member-update .input-box {
        font-size: 0;
    }
    .hd .member-update .input-box__block {
        display: block;
        font-size: rem(24px);
        font-weight: 400;
    }
    .hd .member-update .input-box__inline {
        display: inline-block;
        vertical-align: middle;
        font-size: rem(24px);
        font-weight: 400;
    }
    .hd .member-update .accordion-menu {
        margin-top: rem(25px);
    }
    .hd .member-update .input-box .input-box__block {
        margin-bottom: rem(15px);
    }
    .hd .member-update .input-box .input-box__inline:first-child {
        width: rem(120px);
    }
    .hd .member-update .input-box.rows02,
    .hd .member-update .input-box.rows03 {
        line-height: rem(70px);
    }
    .hd .member-update .input-box.rows02 .btn-full {
        max-width: rem(200px);
    }
    .hd .member-update .input-box.rows02 .input-box__inline:nth-child(1) {
        width: rem(120px);
    }
    .hd .member-update .input-box.rows02 .input-box__inline:nth-child(2) {
        width: calc(100% - 120px);
    }
    .hd .member-update .input-box.rows03 .input-box__inline:nth-child(1) {
        width: rem(120px);
    }
    .hd .member-update .input-box.rows03 .input-box__inline:nth-child(2) {
        width: rem(250px);
    }
    .hd .member-update .input-box.rows03 .input-box__inline:nth-child(3) {
        width: rem(200px);
    }
    .hd .member-update .input-box.rows03 .checkbox-round+.checkbox-round {
        margin-left: rem(20px);
    }
    .popup_pwChange .popup_pwChange__txt {
        font-size: rem(22px);
    }
    .popup_pwChange .member-box {
        border-top: 0 !important;
    }
    .popup_pwChange .popup_pwChange__desc {
        margin-top: rem(20px);
        line-height: rem(32px);
        font-size: rem(22px);
        color: $gcolor;
    }
    .layer_wrap {
        z-index: 10000;
        position: relative;
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100vh;
        margin: 0 auto;
    }
    .layer_wrap.fade_out {
        position: fixed;
        top: 0;
        left: 50%;
        width: rem(640px);
        height: 100vh;
        overflow: hidden;
        transform: translateX(-50%);
    }
    .layer_wrap.fade_out .layer_btn,
    .layer_wrap.fade_out .layer_header {
        position: absolute;
    }
    .layer_wrap.active .layer_btn,
    .layer_wrap.active .layer_header {
        position: fixed;
    }
    .layer_header {
        position: fixed;
        z-index: 11000;
        top: 0;
        left: 50%;
        height: rem(92px);
        width: rem(640px);
        border-bottom: 1px solid #dfdfdf;
        background: #fff;
        transform: translateX(-50%);
    }
    .layer_header h2 {
        height: 100%;
        padding: rem(27px 95px 0 38px);
        box-sizing: border-box;
        font-size: rem(36px);
        font-weight: 600;
        letter-spacing: -1.2px;
    }
    .layer_content {
        padding: rem(93px 0 0);
        width: rem(640px);
        margin: 0 auto;
        background: #fff;
        min-height: 100vh;
        box-sizing: border-box;
    }
    .layer_content .sys_pop_btn {
        margin-top: 0;
    }
    .layer_btn {
        position: fixed;
        z-index: 12000;
        top: 0;
        left: calc(50% + 11.6rem);
        margin-top: rem(15px);
    }
    .search_complete {
        margin-bottom: rem(30px);
        font-size: rem(22px);
    }
    .search_complete span {
        color: $colorRD;
    }
    .addressSearch {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .addressSearch .layer_header {
        position: absolute !important;
        height: auto;
    }
    .addressSearch .layer_header h2 {
        padding: rem(19px 30px);
        font-size: rem(28px);
        font-weight: 600;
    }
    .addressSearch .layer_content {
        padding: 0;
        // padding: rem(70px 0 0);
        width: 100%;
        margin: 0 auto;
        // height: calc(100% - 3.5rem);
        box-sizing: border-box;
    }
    #zipAreaFrame > div {overflow: visible !important}
    #zipAreaFrame iframe {height: calc(100vh - 70px) !important; transform-origin:0 0; transform:scale(1); margin-top: 3.5rem !important;}
    .addressSearch .layer_content > div {
        width: 100%;
        height: 100%;
    }
    .addressSearch .btn_icon.close {
        position: absolute;
        z-index: 2;
        top: 50%;
        right: rem(30px);
        width: rem(30px);
        height: rem(30px);
        padding: rem(5px);
        transform: translate3d(0, -50%, 0);
        box-sizing: content-box;
    }
    .addressSearch .btn_icon.close:before {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: rem(3px);
        height: rem(40px);
        background: $color3D;
        transform: rotate(45deg);
        content: '';
    }
    .addressSearch .btn_icon.close:after {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: rem(3px);
        height: rem(40px);
        background: $color3D;
        transform: rotate(-45deg);
        content: '';
    }
    .addressSearch.active {
        display: block;
    }
    .tab_con {
        display: none;
    }
    .tab_con.on {
        display: block;
    }
    .tab__area {
        margin-top: 0;
    }
    .tab__area ul {
        height: auto;
        border-bottom: none;
        padding-top: 0;
    }
    .tab__area ul:after {
        display: block;
        clear: both;
        content: '';
    }
    .tab__area ul li {
        float: left;
        width: rem(290px);
        height: rem(70px);
        border: 1px solid $colorBC;
        margin-left: rem(-1px);
        font-size: rem(24px);
        box-sizing: border-box;
        border-bottom: rem(3px) solid $gcolor;
        text-align: center;
    }
    .tab__area ul li:first-child{
        margin-left: 0;
    }
    .tab__area ul li a {
        display: block;
    }
    .tab__area ul li a span {
        height: rem(70px);
        line-height: rem(70px);
        font-weight: 400;
        color: $color3D;
    }
    .tab__area ul li.on {
        position: relative;
        border: rem(3px) solid $gcolor;
        border-bottom: none;
    }
    .tab__area ul li.on a span {
        font-weight: 600;
    }
    .tab__area ul li.on a > span:after {
        display: none;
    }
    .hd .mypage__btn {
        margin-top: rem(20px);
    }
    .hd .mypage__btn:after {
        content: '';
        display: block;
        clear: both;
    }
    .hd .mypage__btn .mypage__btn-left {
        float: left;
        width: rem(220px);
    }
    .hd .mypage__btn .mypage__btn-left button {
        width: 100%;
        height: rem(55px);
        line-height: rem(55px);
        font-size: rem(22px);
        font-weight: 600;
    }
    .hd .mypage__btn .mypage__btn-right {
        float: right;
    }
    .hd .mypage__btn .mypage__btn-right button {
        width: rem(110px);
        height: rem(55px);
        margin-left: rem(10px);
        line-height: rem(55px);
        font-size: rem(22px);
        font-weight: 600;
    }
    .hd .mypage__info > li {
        border-bottom: rem(3px) solid $color3D;
        padding: rem(20px 0);
    }
    .hd .mypage__info > li:first-child {
        border-top: rem(3px) solid $color3D;
    }
    .hd .mypage__info > li .defult {
        display: block;
        margin-bottom: rem(20px);
        font-size: rem(22px);
        font-weight: 600;
        color: $gcolor;
        padding-bottom: rem(20px);
        border-bottom: 1px solid $colorBC;
    }
    .hd .mypage__info .mypage__info__sec {
        position: relative;
        padding-left: rem(135px);
        font-size: rem(22px);
        color: $color3D;
        line-height: rem(38px);
    }
    .hd .mypage__info .mypage__info__sec .mypage__info__txt {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        vertical-align: top;
        font-weight: 600;
    }
    .hd .mypage__info .mypage__info__sec.dawn__info {
        padding-left: 0;
    }
    .hd .mypage__info .mypage__info__sec.dawn__info .mypage__info__txt {
        display: block;
        position: relative;
    }
    .hd .mypage__info .mypage__info__sec.dawn__info .dawn__info__list {
        margin-top: rem(10px);
        padding: rem(20px 25px);
        background: #f5f5f5;
        line-height: rem(38px);
        font-size: rem(22px);
    }
    .hd .mypage__info .mypage__info__sec.dawn__info .dawn__info__list span {
        display: block;
    }
    .hd .deliveryAdmin .btn-area,
    .hd .memberAccount .btn-area {
        margin-top: rem(40px);
    }
    .hd .memberAccount .btn-area button {
        font-weight: 600;
    }
    .withDraw {
        padding-top: rem(190px);
    }
    .withDraw .opinion .input-box {
        width: rem(580px);
    }
    .withDraw .opinion .input-box textarea {
        width: 100%;
        box-sizing: border-box;
        height: rem(200px);
        border: 1px solid #bfbfbf;
        padding: rem(25px);
        font-size: rem(22px);
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: rem(66px);
        padding: rem(0 80px 0 20px);
        border-radius: 0;
        font-size: rem(24px) !important;
        border: 1px solid #BEBEBE;
        color: $color3D;
    }
    .input_text {
        width: 100%;
        height: rem(66px);
        background: #fff;
        color: #000;
        border-radius: 0;
        font-size: rem(24px);
        border: 1px solid #9f9f9f;
        letter-spacing: -.02em;
        padding: rem(0 20px);
        box-sizing: border-box;
    }
    .textarea_box {
        position: relative;
        display: block;
        height: rem(170px);
        padding: rem(0 0 60px);
        box-sizing: border-box;
        border: 1px solid #9f9f9f;
        border-top: none;
    }
    .textarea_box textarea {
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        padding: rem(25px);
        border: none;
        box-sizing: border-box;
        vertical-align: top;
        line-height: 1.5;
        font-size: rem(24px);
        resize: none;
        background: 0 0;
        word-break: break-all;
        word-wrap: break-word;
        white-space: pre-line;
    }
    .text_count {
        position: absolute;
        bottom: rem(22px);
        right: rem(30px);
        font-size: rem(20px);
        color: #9f9f9f;
    }
    .p-color {
        color: $gcolor;
    }
    .form_box {
        display: none;
    }
    figure {
        text-align: center;
    }
    figure img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }
    figure:after {
        display: inline-block;
        position: relative;
        width: 0;
        height: 100%;
        vertical-align: middle;
        content: "";
    }
    .ml25 {
        margin-left: rem(25px);
    }
    .orderPayment_h3 {
        padding: rem(25px 0);
        font-size: rem(24px);
        color: $color3D;
        font-weight: 600;
    }
    .checks_box {
        font-size: rem(22px);
    }
    .checks_box label {
        margin: rem(0 32px 0 0);
    }
    .blind {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        margin: rem(-1px);
        width: rem(1px);
        height: rem(1px);
    }
    .input--dim {
        background: #f9f9f9 !important;
    }
    .btn--findAdd {
        width: rem(124px);
        height: rem(70px);
        margin-left: rem(8px);
        border-radius: 0;
        box-sizing: border-box;
        font-size: rem(22px);
        text-align: center;
        line-height: rem(66px);
        background: #fff;
        border: 1px solid $colorBC;
        color: $color3D;
        vertical-align: inherit !important;
    }
    .orderPayment_h2.first {
        border-top-color: #fff;
    }
    .orderPayment_h2 {
        position: relative;
        padding: rem(27px 30px 27px 0);
        border-top: rem(5px) solid $gcolor;
        border-bottom: 1px solid $colorBC;
        font-size: rem(26px);
        line-height: 1.2;
        font-weight: 600;
        transition: background 0.4s;
    }
    .orderPayment_h2:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: rem(30px);
        height: rem(16px);
        margin-top: rem(-8px);
        background: url(/front_mo/images/icon_common_drop-down.png) no-repeat;
        background-size: 100%;
        transition: transform .3s;
    }
    .orderPayment_h2:after {
        content: "";
        display: block;
        clear: both;
        width: 0;
        height: 0;
        font-size: 0;
    }
	.orderPayment_h2 span {display:none; color:$gcolor; line-height:1.5; font-size:rem(18px); font-weight:normal; padding-left:rem(25px); text-indent:rem(-25px);}
	.orderPayment_h2 span strong {display:block; margin-top:10px;font-size:rem(22px); font-weight:bold;}
    .orderPayment_h2 em {display:inline-block; color:$color88; font-size:rem(18px); font-weight:normal; padding-left:rem(25px); text-indent:rem(-25px);}
	
    .btn-area {display:flex; margin-top:rem(50px); flex-wrap:wrap; justify-content:center;}
    .form_title {
        display: block;
        margin-top: rem(25px);
        font-size: rem(22px);
        font-weight: 600;
    }
    .form_text{
        margin-top: rem(10px);
        line-height: 1.5;
        font-size: rem(22px);

        span{
            font-weight: 600;
            color: $gcolor;
        }
    }
    .form_title .necessar {
        margin-left: rem(7px);
        font-weight: 400;
        color: $gcolor;
    }
    .form_title+.input_type {
        margin-top: rem(25px);
        @extend %font_xs;
    }
    .form_title .guide{
        display: inline-block;
        margin-top: 3px;
        float: right;
        font-size: rem(18px);
        font-weight: 100;
        color: #707070;
    }
    .input_type.year input:first-child {
        width: rem(240px);
    }
    .input_type.year input {
        width: rem(150px);
    }
    .input_type.phone input {
        width: rem(155px);
        padding-left: rem(22px);
    }
    .input_type.phone span {
        display: inline-block;
        width: rem(6px);
        height: rem(2px);
        margin: rem(-10px 11px 0);
        background: $color88;
        vertical-align: middle;
        text-indent: -9999px;
    }
    .input_type.email input {
        width: 100%;
    }
    .input_type.email span {
        display: none;
        margin: rem(0 14px 0 13px);
        font-size: rem(28px);
        color: #9f9f9f;
        vertical-align: middle;
    }
    .input_type.email span+select {
        display: none;
        width: rem(270px);
        background-position: rem(204px 0);
    }
    .input_type.adress [class*="input"] {
        margin: rem(0 0 10px );
    }
    .input_type.pin{
        display: flex !important;
        align-items: center;

        input {
            margin: 0 !important;
            width: rem(86px) !important;
            padding: 0;
            text-align: center !important;
        }
        span {
            display: inline-block;
            width: rem(6px);
            height: rem(2px);
            margin: rem(0 10px 0);
            background: $color88;
            vertical-align: middle;
            text-indent: -9999px;
        }

        .btn-green{
            margin-left: rem(10px);
            padding: rem(0 27px);
        }
    }
    
    .save_chk_box {
        margin-top: rem(40px);
    }
    .save_chk_box .checks {
        height: rem(40px);
        width: rem(40px);
        margin-right: rem(12px);
    }
    .save_chk_box .checks.on {
        background-image: url(/mobile/img/check2_on.png);
    }
    .save_chk_box label {
        margin: 0;
        font-size: rem(24px);
        color: #5f5f5f;
        vertical-align: middle;
    }
    .form_box .section.b_st {
        border-top: 1px solid #dfdfdf;
        margin-top: rem(50px);
        padding-top: rem(50px);
    }
    .form_box .lunch__txt {
        margin-top: rem(20px);
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .point_score {
        overflow: hidden;
        max-width: rem(375px);
    }
    .point_score .score_text {
        float: right;
        color: $colorRD;
    }
    .layer_check {
        margin-top: rem(30px);
        text-align: right;
        font-size: rem(24px);
        line-height: rem(34px);
        color: #5f5f5f;
    }
    .layer_check label {
        display: inline-block;
        vertical-align: top;
    }
    .layer_check .checks {
        margin-left: rem(10px);
        vertical-align: top;
    }
    .form_info {
        font-size: rem(24px);
        color: #5f5f5f;
    }
    .form_info .necessar:before {
        vertical-align: middle;
    }

    .ordererInfo .pw-desc {
        position: relative;
        margin-top: rem(15px);
        padding-left: rem(18px);
        font-size: rem(22px);
        color: $color88;
        line-height: rem(32px);
    }
    .ordererInfo .pw-desc:before {
        content: '*';
        display: block;
        position: absolute;
        top: rem(4px);
        left: 0;
        width: rem(6px);
        height: rem(6px);
    }
    .ordererInfo .btn-arr {
        padding: rem(15px);
        width: 100% !important;
        min-height: rem(65px) !important;
        height: auto !important;
        line-height: 1.3 !important;
        @extend %font_xs;

        &__txt{
            display: block;
            margin-top: rem(15px);
            font-size: rem(20px);
            color: $color88;
            @extend %clear;

            dt{
                float: left;
                margin-top: rem(5px);
                width: 50%;

                &:nth-of-type(1){margin-top: 0;}
            }

            dd{
                float: right;
                margin-top: rem(5px);
                width: 50%;
                &:nth-of-type(1){margin-top: 0;}
            }
        }
    }
    .deliveryInfo .deliveryInfo-tab {
        display: none;
    }
    .deliveryInfo .deliveryInfo-tab.current {
        display: block;
    }
    .deliveryInfo .tab-area-v2 .tab-list-v2 {
        border-top: none !important;
    }

    .deliveryInfo .ordererInfo--safeNumber {
        margin:rem(15px) 0;
        letter-spacing: -0.9px;
        white-space: nowrap;

        .checks{
            font-size: rem(21.5px);
        }
    }
    .deliveryInfo .ordererInfo--Qmark, .ordererInfo .ordererInfo--Qmark{
        display: inline-block;
        width: rem(30px);
        height: rem(30px);
        margin: rem(2px 0 0 5px);
        vertical-align: top;
        background: url(/front_mo/images/icon_order-payment_Qmark.png) no-repeat 50% 50%;
        background-size: rem(30px 30px);
	}
	
	.deliveryInfo.type-5 {
		.tab-area-v2 .tab-list-v2 {width:100%; border: 0;
			a {border:solid 1px $colorBC;}
		}
		.orderPayment_h2{border-bottom:0;
			span {display:block;}
		}
		.new--sameAs,.new--checks_box {display:none;}
	}

    .ordinary .check-label .check-area {
        top: 0 !important;
        margin-top: rem(12px);
    }
    .ordinary .btn--deliveryList {
        margin-top: rem(15px);
        margin-bottom: rem(10px);
        text-align: right;
    }
    .ordinary .btn--deliveryList button {
        width: rem(180px);
		height: rem(55px);
		background:#fff;
        border: 1px solid $colorBC;
        font-size: rem(22px);
        color: $color3D;
    }
    .ordinary--listbox .ordinary--list {
        padding: rem(25px 0);
        border-bottom: 1px solid $colorBC;
    }
    .ordinary--listbox .ordinary--list:first-child {
        padding: rem(0 0 25px);
    }
    .ordinary--listbox .contbox {
        padding-left: rem(55px);
    }
    .ordinary--listbox .contbox .contbox--list {
        font-size: rem(22px);
    }
    .ordinary--listbox .contbox .contbox--list:not(:first-child) {
        margin-top: rem(15px);
        font-size: rem(22px);
    }
    .ordinary--listbox .contbox .contbox--list .default {
        color: $gcolor;
    }
    .lunch {
        padding-top: rem(25px);
    }
    .lunch .contbox {
        padding-left: 0;
    }
    .lunch .contbox .contbox--list.desc {
        position: relative;
        padding-left: rem(10px);
        color: $gcolor;
        font-weight: 300;
        font-size: rem(20px);
        line-height: 1.4;
        word-break: keep-all;
    }
    .lunch .contbox .contbox--list.desc:before {
        content: '*';
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        color: $gcolor;
    }
    .demand .input_type{
        display: block;
    }
    .demand .input_type select {
        background:#fff url(/front_mo/images/icon_market_drop-down-on.png) no-repeat right rem(20px) top 50%;
        background-size: rem(25px) auto;
    }
    .demand .textarea_box {
        display: none;
    }
    .new--zipCode {
        width: rem(167px) !important;
    }
    .new--sameAs {
        padding: rem(25px 0);
        border-bottom: 1px solid $colorBC;
        font-size: rem(22px);
    }
    .new--checks_box {
        padding: rem(30px 0 25px);
        border-bottom: 1px solid $colorBC;
    }
    .dawnDelivery {
        position: relative;
    }
    .dawnDelivery .dawnDelivery--desc {
        padding: rem(30px 0);
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .dawnDelivery .dawnDelivery--desc strong {
        color: $color3D;

        &:after{
            display: block;
            margin-bottom: rem(-20px);
            content: '';
        }
    }
    .dawnDelivery .dawnDelivery--desc span {
        color: $gcolor;
    }
    .dawnDelivery .dawnDelivery--desc p{
        padding-left: rem(25px);
        margin-top: rem(5px);
        font-size: rem(20px);
        text-indent: rem(-25px);
        letter-spacing: rem(-1px);
        color: $color88;
    }
    .dawnDelivery .normal-delivery {
        position: absolute;
        top: rem(30px);
        right: 0;
    }
    .dawnDelivery-info .infobox {
        padding: rem(15px);
        background: #f5f5f5;
    }
    .dawnDelivery-info .infobox .infobox--list {
        margin-top: rem(20px);
    }
    .dawnDelivery-info .infobox .infobox--list.desc {
        margin-top: rem(20px);
        line-height: 1.4em;
        word-break: keep-all;
    }
    .dawnDelivery-info .infobox .infobox--list.desc .tit {
        display: block;
        margin-bottom: rem(20px);
        color: $gcolor;
    }
    .dawnDelivery-info .infobox .infobox--list.desc .colorOR {
        display: block;
        color: $gcolor;
    }
    .dawnDelivery-info .infobox .infobox--list:first-child {
        margin-top: 0;
    }
    .dawnDelivery-info .infobox .visit-way {
        display: none;
    }
    .dawnDelivery-info .infobox .visit-way.show {
        display: block;
    }
    .dawnDelivery-info .infobox .infobox--list .cont .check-label.pw-area {
        margin-bottom: rem(10px);
    }
    .dawnDelivery-info .infobox .visit-way .input_text {
        display: none;
    }
    .dawnDelivery-info .infobox .infobox--list .title {
        display: block;
        margin-bottom: rem(15px);
        font-size: rem(20px);
    }
    .dawnDelivery-info .infobox .infobox--list .title-v2 {
        width: rem(204px);
        line-height: rem(32px);
    }
    .dawnDelivery-info .infobox .infobox--list .cont {
        font-size: rem(20px);
    }
    .dawnDelivery-info .infobox .infobox--list .cont .check-label {
        display: inline-block;
        height: rem(40px);
    }
    .dawnDelivery-info .infobox .infobox--list .cont .check-label input[type=checkbox]:checked+.check-icon {
        display: block;
    }
    .dawnDelivery-info .infobox .infobox--list .cont .check-label .text {
        padding-left: rem(50px);
        height: rem(40px);
        line-height: rem(40px);
    }
    .dawnDelivery-info .infobox .infobox--list .cont .input_text {
        margin-bottom: rem(10px);
    }
    .dawnDelivery-info .infobox .infobox--list .mb15 {
        margin-bottom: rem(15px);
    }
    .dawnDelivery-info .infobox .infobox--list .ml25 {
        margin-left: rem(25px);
    }
    .dawnDelivery-info .infobox .infobox--list .ml50 {
        margin-left: rem(50px);
    }
    .dawnDelivery-info .checks_box {
        margin-top: rem(20px);
        padding-bottom: rem(20px);
        border-bottom: 0;
    }
    .seperate .orderPayment_h3{border-top: 1px solid $colorBC;}
    .seperate .seperate__cont {
        display: none;
        margin-top: rem(20px);
        border-top: 1px solid $colorBC;
    }
    .seperate .seperate--desc {
        border-top: 1px solid $colorBC;
        padding: rem(15px 0);
        font-size: rem(21.1px);
        line-height: rem(32px);
    }
    .discount-area .coupon-wrap .coupon-list {
        position: relative;
        margin-top: rem(20px);
        padding: rem(35px 0 15px);
        border-top: 1px solid $colorBC;
        border-bottom: 0;

        &:first-child{
            margin-top: 0;
            border-top: 0;
        }
    }
    .discount-area .coupon-wrap .coupon-list .coupon--tit {
        display: inline-block;
        margin-bottom: rem(20px);
        font-size: rem(22px);
    }
    .discount-area .coupon-wrap .coupon-list .coupon--cont {
        display: block;
        font-size: rem(22px);
        line-height: rem(32px);
        letter-spacing: rem(-0.2px);

        &.after2{
            button{
                top: 61% !important;
            }
        }
    }
    .discount-area .coupon-wrap .coupon-list .coupon--cont .guide{
        font-size: rem(20px);
        color: $color88;
    }
    .discount-area .coupon-wrap .coupon-list button {
        width: rem(200px);
        height: rem(55px);
        color: #fff;
        font-size: rem(22px);
        background: $gcolor;
        text-align: center;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .discount-area .coupon-wrap .coupon-list .input_type {
        display: block;
        margin-top: rem(25px);
    }
    .discount-area .coupon-wrap .coupon-list .input_type .input_text {
        width: rem(322px);
        margin-right: rem(20px);
        text-align: right;
    }
    .discount-area .coupon-wrap .coupon-list .coupon--Qmark {
        display: inline-block;
        float: right;
        width: rem(30px);
        height: rem(34px);
        margin-left: rem(7px);
        background: url(/front_mo/images/icon_order-payment_Qmark.png) no-repeat 0 0;
        background-size: rem(30px 30px);
    }
    %cash_receipts {
        display: block;
        margin-top: rem(20px);
        padding: rem(15px 20px);
        width: 100%;
        height: auto !important;
        line-height: rem(28px) !important;
        font-size: rem(22px) !important;
        box-sizing: border-box;

        span{
            display: block;
            font-size: rem(20px);
            color: #999;
        }
    }

    .discount-area .coupon-wrap .coupon-list .btn-arr{
        @extend %cash_receipts;
    }
    .item-confirm {
        &__desc {
            position: relative;
            margin-top: rem(20px);
            padding-left: rem(10px);
            font-size: rem(22px);
            line-height: rem(32px);

            &:before {
                content: "*";
                display: inline-block;
                position: absolute;
                top: rem(2px);
                left: 0;
            }
        }
    }
    .item-confirm .orderPayment_h3 {
        padding: rem(30px 0 25px);
        border-bottom: 1px solid $color3D;
    }
    .hd__orderCommon .price-area {
        padding-top: rem(25px);
    }
    .hd__orderCommon .price-area li {
        width: 100%;
        margin: rem(15px 0 0);
        font-size: rem(22px);
    }
    .hd__orderCommon .price-area li:first-child {
        margin-top: 0;
    }
    .hd__orderCommon .price-area li:after {
        display: block;
        clear: both;
        content: '';
    }
    .hd__orderCommon .price-area li .tit {
        display: inline-block;
        text-align: left;
    }
    .hd__orderCommon .price-area li .price {
        display: inline-block;
        float: right;
        text-align: right;
    }
    .hd__orderCommon .price-area .delivery-fee {background:#f5f5f5; width:100%; margin-top:rem(30px); padding:rem(25px 0); border-top:rem(1px) solid $colorBC; border-bottom:rem(1px) solid $colorBC; font-weight:600;}
    .hd__orderCommon .price-area .delivery-fee .condi {
        display: block;
        width: 100%;
        text-align: right;
        margin-top: rem(10px);
        font-weight: 400;
    }
    .hd__orderCommon .delivery-fee {
		width: rem(540px);
		width:100%;
		box-sizing:border-box;
        padding: rem(25px 0);
        background: transparent;
        border-top: 0;
        border-top: 1px solid $colorBC;
        font-size: rem(22px);
        font-weight: 600;
    }
    .hd__orderCommon .delivery-fee:last-child {
        border-bottom: 1px solid $colorBC;
    }
    .hd__orderCommon .delivery-fee .tit {
        display: inline-block;
        text-align: left;
    }
    .hd__orderCommon .delivery-fee .price {
        display: inline-block;
        float: right;
        text-align: right;
    }
    .hd__orderCommon .delivery-fee .condi {
        display: block;
        width: 100%;
        text-align: right;
        margin-top: rem(10px);
        font-weight: 400;
	}

	.hd__orderCommon .delivery-fee .delivery-fee-limit {text-align:right;
		em {color:$gcolor; font-weight:bold}
	}
	
    .item-confirm .gift {
        position: relative;
        min-height: rem(73px);
        margin-top: rem(30px);
        padding: rem(16px 0);
        border-top: 1px solid $colorBC;
        border-bottom: 1px solid $colorBC;
    }
    .item-confirm .gift .thumb {
        position: absolute;
        top: rem(16px);
        left: 0;
        width: rem(80px);
        height: rem(73px);
    }
    .item-confirm .gift .textbox {
        padding-left: rem(100px);
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .item-confirm .gift .textbox li:first-child {
        margin: 0;
    }
    .item-confirm .planMeals-item .btn--listMore {
        border-bottom: 1px solid $colorBC;
    }
    .item-confirm .planMeals-item {
        padding-bottom: 0;
    }
    .item-confirm .planMeals-item .item-wrap {
        padding: rem(25px 0 0);
        border-top: 0;

        &.planmeals {
            .meals-delivery {
                // width: rem(540px);
				width:100%;
				box-sizing:border-box;
                margin-top: rem(20px);
                padding: rem(25px 0);
				// background: #f5f5f5;
				background: transparent;
                border-top: 1px solid $colorBC;
                border-bottom: 1px solid $colorBC;
                font-size: rem(22px);
                font-weight: 600;

                &__tit {
                    display: inline-block;
                    text-align: left;
                }

                &__price {
                    display: inline-block;
                    // float: right;
                    text-align: right;
                }
            }
        }
    }
    .item-confirm .planMeals-item .item-wrap:first-child {
        border-top: 0;
    }
    .item-confirm .planMeals-item .title {
        padding-bottom: rem(25px);
        font-size: rem(22px);
    }
    .item-confirm .planMeals-item .detail-view {
        position: relative;
        height: rem(53px);
        background: #f9f9f9;
        border-top: 1px solid $colorBC;
        border-bottom: 1px solid $colorBC;
        font-size: rem(22px);
        text-align: center;
        line-height: rem(53px);
    }
    .item-confirm .planMeals-item .detail-view:after {
        display: block;
        position: absolute;
        top: rem(22px);
        left: rem(346px);
        width: rem(17px);
        height: rem(10px);
        background: url(/front_mo/images/btn_menu_drop-down.png) no-repeat 0 0;
        background-size: rem(17px 10px);
        content: '';
    }
    .item-confirm .planMeals-item .product--plan {
        display: none;
    }
    .item-confirm .planMeals-item .detail-cont {
        max-width: rem(580px);
    }
    .item-confirm .planMeals-item .detail-cont thead th {
        overflow: hidden;
        position: absolute;
        width: rem(1px);
        height: rem(1px);
        clip: rect(1px 1px 1px 1px);
    }
    .item-confirm .planMeals-item .detail-cont tbody th {
        border-right: 1px solid $colorBC;
        border-bottom: 1px solid $colorBC;
        font-size: rem(26px);
        font-weight: 400;
        line-height: 1.2;
        font-weight: 400;
    }
    .item-confirm .planMeals-item .detail-cont tbody th strong {
        display: block;
    }
    .item-confirm .planMeals-item .detail-cont tbody td {
        padding: rem(30px 0 30px 20px);
        border-left: 1px solid $colorBC;
        border-bottom: 1px solid $colorBC;
        font-size: rem(22px);
        color: $color3D;
        line-height: rem(32px);
	}

    .item-confirm .planMeals-item .detail-cont tbody td .num {
        font-weight: 600;
	}

    .item-confirm .planMeals-item .detail-cont tbody td .gift {
        border-bottom: none;
        // margin-top: rem(20px);
		// padding: rem(20px 0 0);
		// border-top:none;
		// padding-bottom:rem(20px); padding-top:0;
		// margin-bottom:rem(20px);
		margin:0; padding:0;
		display: flex;
		align-items: center;
	}
	.item-confirm .planMeals-item .detail-cont tbody td .gift:last-child {padding-bottom:0;}
	.item-confirm .planMeals-item .detail-cont tbody td .gift .thumb {
		position: static;
		margin-right:rem(10px);
	}
	.item-confirm .planMeals-item .detail-cont tbody td .gift .textbox{
		padding-left:0;
	}
	.item-confirm .planMeals-item .detail-cont tbody td .gift {
		padding:rem(10px) 0;
		// margin-top:rem(10px);
		border:0;
	}

	.item-confirm .planMeals-item .detail-cont tbody td .gift + .item-title {	
		border-top:rem(1px) solid $colorBC;
		padding-top:rem(20px);
	}

    .normal-item .item-wrap {
        border-top: 1px solid $colorBC;
    }
    .normal-item .item-wrap:first-child {
        border-top: 0;
    }
    .item-confirm .item__inner {
        padding: rem(20px 0);
    }
    .item-confirm .item .item-info {
        position: relative;
        min-height: rem(100px);
        height: auto;
        margin-top: 0;
    }
    .item-confirm .item .item-info+.item-info {
        margin-top: rem(25px);
    }
    .item-confirm .item .item-info .thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: rem(100px);
        height: rem(100px);
    }
    .item-confirm .item .item-info .textbox {
        padding-left: rem(120px);
        font-size: rem(22px);
    }
    .item-confirm .item .item-info .textbox li {
        margin-bottom: rem(5px);
    }
    .item-confirm .item .item-info .textbox li.due-date {
        color: $gcolor;
    }
    .item__box {
        display: block;
    }
    #orderPayment .item-confirm .item {
        border-top: 1px solid $colorBC;
    }
    #orderPayment .item-confirm .item:first-child {
        border-top: 0;
    }
    .payment-amount .detail-box {
        margin-top: rem(15px);
        padding: rem(20px);
        background: #f5f5f5;
    }
    .payment-amount .detail-box li:last-child {
        margin-bottom: 0;
    }
    .payment-amount li.total-area {
        margin-top: rem(30px);
        padding: rem(30px 0 5px);
        border-top: 1px solid $colorBC;
    }
    .payment-amount li.total-area .tit {
        font-size: rem(26px);
    }
    .payment-amount li.total-area .price {
        position: relative;
        margin-top: rem(-10px);
        font-size: rem(36px);
    }
    .payment-amount li.total-area .desc {
        display: inline-block;
        width: 100%;
        margin-top: rem(20px);
        font-size: rem(22px);
        text-align: right;
    }
    .payment-amount li.total-area .price:before {
        display: block;
        position: absolute;
        z-index: -1;
        bottom: rem(-2px);
        left: 0;
        width: 100%;
        height: rem(8px);
        background: $ocolor;
        content: '';
    }
    .payment-method .pay-type ul {
        width: 100%;
		margin: rem(13px 0 25px);
		display: flex;
		flex-wrap: wrap;
    }
    .payment-method .pay-type ul li {	
		flex:1 0 48%;
		width:rem(184px);
        height: rem(68px);
        line-height: rem(68px);
        margin:0 0 1% 1%;
		border: 1px solid #BEBEBE;
		background: #fff;
		color: #000;
		font-size: rem(22px);
		text-align: center;
		box-sizing: border-box;

        &:nth-of-type(2n+1){
            margin-left: 0;
        }
    }
    // .payment-method .pay-type ul li:nth-child(3n+1) {
    //     margin-left: 0;
	// }
    .payment-method .pay-type ul li.on {
        background-color: $gcolor;
        border: none;
        color: #fff;
    }
    .payment-method .pay-type ul li label {
        display: block;
    }
    .payment-method .kakao label {
        background: url(/front_pc/images/ico_kakao_black.png) no-repeat 50% 50%;
        background-size: rem(60px);
        font-size: 0;
    }

    .payment-method .kakao input:checked + label {
        background: $gcolor url(/front_pc/images/ico_kakao_white.png) no-repeat 50% 50%;
        background-size: rem(60px);
    }

    .payment-method .naver input + label {
        position: relative;
        font-size: 0;

        &:after{
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: rem(-13px 0 0 -33px);
            width: rem(66px);
            height: rem(26px);
            background-image: url(/front_mo/images/sp_ico.png);
            background-position:  rem(-136px 0);
            background-size: rem(640px);
            content: '' ;
        }
    }

    .payment-method .naver input:checked + label:after{
        background-position: rem(-206px 0);
    }

    .payment-method .samsung input + label {
        position: relative;
        font-size: 0;

        &:after{
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: rem(-17px 0 0 -38.5px);
            width: rem(77px);
            height: rem(34px);
            background-image: url(/front_mo/images/sp_ico.png);
            background-position:  rem(-99px -35px);
            background-size: rem(640px);
            content: '' ;
        }
    }

    .payment-method .samsung input:checked + label:after{
        background-position: rem(-182px -35px);
    }

    .payment-method .kb input + label {
        position: relative;
        font-size: 0;

        &:after{
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: rem(-9px 0 0 -29.5px);
            width: rem(62px);
            height: rem(20px);
            background-image: url(/front_mo/images/sp_ico.png?ver=220105);
            background-position:  rem(-527px 0);
            background-size: rem(640px);
            content: '' ;
        }
    }

    .payment-method .kb input:checked + label:after{
        background-position: rem(-527px -20px);
    }

    .payment-method .checks_box {
        margin-bottom: rem(25px);
    }
    .payment-method .subscribe_date{
        padding: 20px 0;
        
        dt{
            margin-bottom: rem(10px);
            font-size: rem(26px);

            em{color: $gcolor !important;}
        }
        dd{font-size: rem(22px);}
    }
    .payment-method .cash_receipts{
        @extend %cash_receipts;
    }
    // 그리팅 페이
    .greating-pay {
        border: 1px solid $colorBC;
        background: #f9f9f9;
	}
    .greating-pay__sec.no-card {
        text-align: center;
    }
    .greating-pay__sec.no-card .btn__add-card {
        display: block;
        width: 100%;
        padding: rem(44px 0);
        font-size: rem(22px);
        font-weight: 400;
        color: $color3D;
    }
    .greating-pay__sec.no-card .btn__add-card span {
        display: block;
        position: relative;
        width: rem(50px);
        height: rem(50px);
        margin: 0 auto rem(15px);
        background: $gcolor;
        border-radius: 50%;
    }
    .greating-pay__sec.no-card .btn__add-card span:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(3px);
        height: rem(21px);
        background: #fff;
        transform: translate3d(-50%, -50%, 0);
    }
    .greating-pay__sec.no-card .btn__add-card span:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(21px);
        height: rem(3px);
        background: #fff;
        transform: translate3d(-50%, -50%, 0);
	}
	
	/* 안전거래 */
	.safe-pay {
		h3 {margin:10px 0 15px; color:$color3D; font-size:inherit;}
		.radio__wrap {
			.check-label {display:inline-block; margin-right:10px; padding-left:rem(40px);
				span {font-size:inherit;}
			}
			p {margin:15px 0 10px; color:$color88; line-height:1.6;}
		}
	}

    // 삼성페이
    .samsung-pay{
        border-bottom: 1px solid $colorBC;

        .accordion_box{
            position: relative;
            margin: 0 !important;
            background-image: none !important;
            box-shadow: none !important;
            border: 0 !important;

            .accordion_title{
                position: absolute;
                top: 1.1rem;
                right: 0;
                font-size: 0;

                &:after{
                    display: inline-block;
                    width: 1.5rem;
                    height: 0.8rem;
                    background: url(/front_mo/images/icon_common_drop-down.png) no-repeat;
                    transform: scale(0.75);
                    vertical-align: middle;
                    content: '';
                    background-size: 100%;
                }

                
            }

            .accordion_con{
                border-top: 0 !important;
                padding-bottom: 0 !important;
                display: none;
            }

            &.on{
                .accordion_title{
                    &:after{
                       transform: scale(0.75) rotate(180deg); 
                    }
                }

            }
        }

        .checkbox__label{
            display: inline-block !important;

            &:before{
                margin-right: 0.25rem !important;
            }
        }
    }

    // 간편결제 약관동의
    .agree{
        &-title{
            padding: 10px 0;
            border-top: 1px solid $colorBC;

            .hd-checkbox{
                padding: 10px 0;
            }

            .required{
                color: $gcolor;
            }
        }

        &-description{
            margin-bottom: 10px;
            margin-left: 30px;
            
            .div-text{
                padding: 10px;
                height: 100px;
                line-height: 1.5;
                font-size: 0.9rem;
                border: 1px solid #ddd;
                overflow-y: auto;
                background: #fff;
            }
        }
    }
	
    .hd .card-logo-left.bc {
        background: url(/front_mo/images/ico_card-bc_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.kb {
        background: url(/front_mo/images/ico_card-kb_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.hyundai {
        background: url(/front_mo/images/ico_card-hyundai_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.samsung {
        background: url(/front_mo/images/ico_card-samsung_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.shinhan {
        background: url(/front_mo/images/ico_card-shinhan_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.lotte {
        background: url(/front_mo/images/ico_card-lotte_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.nh {
        background: url(/front_mo/images/ico_card-nh_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.hana {
        background: url(/front_mo/images/ico_card-hana_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.citi {
        background: url(/front_mo/images/ico_card-citi_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.oehwan {
        background: url(/front_mo/images/ico_card-oehwan_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.woori {
        background: url(/front_mo/images/ico_card-woori_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.visa {
        background: url(/front_mo/images/ico_card-visa_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.master {
        background: url(/front_mo/images/ico_card-master_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.diners {
        background: url(/front_mo/images/ico_card-diners_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.american {
        background: url(/front_mo/images/ico_card-american_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.jcb {
        background: url(/front_mo/images/ico_card-jcb_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.union {
        background: url(/front_mo/images/ico_card-union_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.suhyup {
        background: url(/front_mo/images/ico_card-suhyup_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.cu {
        background: url(/front_mo/images/ico_card-cu_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.gwangju {
        background: url(/front_mo/images/ico_card-gwangju_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.jeonbuk {
        background: url(/front_mo/images/ico_card-jeonbuk_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.jeju {
        background: url(/front_mo/images/ico_card-jeju_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.kdb {
        background: url(/front_mo/images/ico_card-kdb_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.mg {
        background: url(/front_mo/images/ico_card-mg_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.epost {
        background: url(/front_mo/images/ico_card-epost_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.sbcheck {
        background: url(/front_mo/images/ico_card-sbcheck_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.okcashbag {
        background: url(/front_mo/images/ico_card-okcashbag_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.hanmi {
        background: url(/front_mo/images/ico_card-hanmi_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.sinsegae {
        background: url(/front_mo/images/ico_card-sinsegae_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.pyeonghwa {
        background: url(/front_mo/images/ico_card-pyeonghwa_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.dongnam {
        background: url(/front_mo/images/ico_card-dongnam_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.joheung {
        background: url(/front_mo/images/ico_card-joheung_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.chughyeob {
        background: url(/front_mo/images/ico_card-chughyeob_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.saneun {
        background: url(/front_mo/images/ico_card-saneun_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.jutaeg {
        background: url(/front_mo/images/ico_card-jutaeg_left.png) no-repeat 0 0;
    }
    .hd .card-logo-left.none {
        background: url(/front_pc/images/ico_card-card_left.png) no-repeat 0 0;
    }
    .hd .card-logo-center.bc {
        background: url(/front_mo/images/ico_card-bc_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.kb {
        background: url(/front_mo/images/ico_card-kb_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.hyundai {
        background: url(/front_mo/images/ico_card-hyundai_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.samsung {
        background: url(/front_mo/images/ico_card-samsung_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.shinhan {
        background: url(/front_mo/images/ico_card-shinhan_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.lotte {
        background: url(/front_mo/images/ico_card-lotte_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.nh {
        background: url(/front_mo/images/ico_card-nh_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.hana {
        background: url(/front_mo/images/ico_card-hana_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.citi {
        background: url(/front_mo/images/ico_card-citi_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.oehwan {
        background: url(/front_mo/images/ico_card-oehwan_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.woori {
        background: url(/front_mo/images/ico_card-woori_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.visa {
        background: url(/front_mo/images/ico_card-visa_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.master {
        background: url(/front_mo/images/ico_card-master_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.diners {
        background: url(/front_mo/images/ico_card-diners_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.american {
        background: url(/front_mo/images/ico_card-american_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.jcb {
        background: url(/front_mo/images/ico_card-jcb_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.union {
        background: url(/front_mo/images/ico_card-union_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.suhyup {
        background: url(/front_mo/images/ico_card-suhyup_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.cu {
        background: url(/front_mo/images/ico_card-cu_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.gwangju {
        background: url(/front_mo/images/ico_card-gwangju_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.jeonbuk {
        background: url(/front_mo/images/ico_card-jeonbuk_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.jeju {
        background: url(/front_mo/images/ico_card-jeju_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.kdb {
        background: url(/front_mo/images/ico_card-kdb_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.mg {
        background: url(/front_mo/images/ico_card-mg_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.epost {
        background: url(/front_mo/images/ico_card-epost_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.sbcheck {
        background: url(/front_mo/images/ico_card-sbcheck_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.okcashbag {
        background: url(/front_mo/images/ico_card-okcashbag_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.hanmi {
        background: url(/front_mo/images/ico_card-hanmi_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.sinsegae {
        background: url(/front_mo/images/ico_card-sinsegae_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.pyeonghwa {
        background: url(/front_mo/images/ico_card-pyeonghwa_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.dongnam {
        background: url(/front_mo/images/ico_card-dongnam_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.joheung {
        background: url(/front_mo/images/ico_card-joheung_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.chughyeob {
        background: url(/front_mo/images/ico_card-chughyeob_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.saneun {
        background: url(/front_mo/images/ico_card-saneun_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.jutaeg {
        background: url(/front_mo/images/ico_card-jutaeg_center.png) no-repeat 0 0;
    }
    .hd .card-logo-center.none {
        background: url(/front_pc/images/ico_card-card_center.png) no-repeat 0 0;
    }
    .greating-pay__sec.card {
        padding: rem(40px 0);
    }
    .greating-pay__sec.card .card__wrap {
        height: auto;
        padding: rem(0 77px);
    }

    .greating-pay__sec .card__sec {
        height: auto;
    }

    .greating-pay__sec.card .card__view {
        padding: rem(12px);
    }
    .greating-pay__sec.card .card__view label {
        display: block;
        position: relative;
        height: rem(215px);
        border: rem(3px) solid $color88;
        background: #fff;
        border-radius: rem(15px);
        box-shadow: rem(3px 3px 9px 3px) rgba(0, 0, 0, .19);
    }
    .greating-pay__sec.card .card__view label:before {
        content: '';
        display:none !important;
        // display: block;
        position: absolute;
        top: rem(16px);
        right: rem(16px);
        width: rem(34px);
        height: rem(34px);
        border: 1px solid $colorBC;
        background: #fff url(/front_mo/images/ico_comm_checkbox.png) no-repeat 50% 50%;
        background-size: rem(20px 15px);
        border-radius: 50%;
    }
    .greating-pay__sec.card .card__view label .card__num {
        display: block;
        position: absolute;
        top: rem(135px);
        left: 50%;
        width: 100%;
        font-size: 0;
        text-align: center;
        transform: translate3d(-50%, 0, 0);
    }
    .greating-pay__sec.card .card__view label .card__num span {
        display: inline-block;
        vertical-align: top;
        margin-left: rem(25px);
        font-size: rem(26px);
        font-weight: 600;
        color: $color3D;
    }
    .greating-pay__sec.card .card__view label .card__num span:first-child {
        margin-left: 0;
    }
    .greating-pay__sec.card .swiper-pagination {
        display: none;
        margin: rem(10px 0 30px);
        text-align: center;
    }
    .greating-pay__sec.card .swiper-pagination .swiper-pagination-bullet {
        float: none;
    }
    .greating-pay__sec.card .card__list .card-logo-left {
        position: absolute;
        top: rem(16px);
        left: rem(28px);
        width: rem(200px);
        height: rem(60px);
        background-size: rem(200px 60px);
    }
    .greating-pay__sec.card input[type=radio]:checked+.card__view label:before {
        border: 1px solid $gcolor;
        background: $gcolor url(/front_mo/images/ico_comm_checkbox-on.png) no-repeat 50% 50%;
        background-size: rem(20px 15px);
    }
    .greating-pay__sec.card  .card__desc {
        width: 100%;
        text-align: center;        
    }
    .greating-pay__sec.card  .card__desc span {
        display: inline-block;
        background: rgba(0,0,0,0.5);
        color: #fff;
        padding: .5rem 1rem;
        margin: 0.5rem 1rem 1.3rem 1rem;
    }
    .greating-pay__sec.card .card__update {
        text-align: center;
        font-size: 0;
    }
    .greating-pay__sec.card .card__update > li {
        display: inline-block;
        vertical-align: top;
        margin: rem(0 5px);
    }
    .greating-pay__sec.card .card__update > li button {
        width: rem(185px);
        height: rem(66px);
        border: 1px solid $colorBC;
        background: #fff;
        font-size: rem(22px);
        font-weight: 400;
    }
    .hd .greatingPay {
        margin: 0;
        padding-top: 0 !important;
    }
    .hd .greatingPay .greatingPay__notice {
        display: block;
        padding: rem(22px 0);
        line-height: 1.5;
        border-bottom: 1px solid $colorBC;
        font-size: rem(22px);
    }
    .hd .greatingPay .greatingPay__tit {
        display: block;
        font-size: rem(22px);
    }
    .hd .greatingPay .btn__default {
        margin-top: rem(40px);
    }
    .hd .greatingPay .input_type {
        display: flex;
        justify-content: space-between;
    }
    .hd .greatingPay .input_type input {
        height: rem(70px);
        border: 1px solid $colorBC;
        border-radius: 0;
        text-align: center;
        font-size: rem(24px);
        color: $color3D;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .hd .greatingPay .input_type input::-webkit-input-placeholder {
        color: $color88;
    }
    .hd .greatingPay .input_type input::-moz-placeholder {
        color: $color88;
    }
    .hd .greatingPay .input_type input:-ms-input-placeholder {
        color: $color88;
    }
    .hd .greatingPay .input_type input::-ms-input-placeholder {
        color: $color88;
    }
    .hd .greatingPay .input_type input::placeholder {
        color: $color88;
    }
    .hd .greatingPay .input_type.input_one input {
        width: 100%;
    }
    .hd .greatingPay .input_type.input_two input {
        width: rem(203px);
    }
    .hd .greatingPay .input_type.input_three input {
        width: rem(132px);
    }
    .hd .greatingPay .input_type.input_four input {
        width: rem(97px);
    }
    .hd .greatingPay .input_type.input_six input {
        width: rem(75px);
    }
    .hd .greatingPay .card-insert {
        margin-top: rem(25px);
    }
    .hd .greatingPay .card-insert > li {
        position: relative;
        padding-left: rem(108px);
        margin-top: rem(25px);
    }
    .hd .greatingPay .card-insert > li:first-child {
        margin-top: 0;
    }
    .hd .greatingPay .card-insert > li .greatingPay__tit {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
    }
    .hd .greatingPay .greatingPay__pwd ul li {
        margin-top: rem(35px);
    }
    .hd .greatingPay .greatingPay__pwd ul li .greatingPay__tit {
        text-align: center;
        margin-bottom: rem(25px);
    }
    .hd .greatingPay .card-view > li {
        position: relative;
        padding: rem(25px 0 25px 120px);
        border-bottom: 1px solid $colorBC;
    }
    .hd .greatingPay .card-view > li .card-logo-center {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: rem(96px);
        height: rem(64px);
        border: 1px solid $color88;
        background-size: 100% auto;
        background-position: rem(0 15px);
        border-radius: rem(5px);
        transform: translate3d(0, -50%, 0);
        box-sizing: border-box;
    }
    .hd .greatingPay .card-view > li .card-view__info {
        line-height: rem(34px);
    }
    .hd .greatingPay .card-view > li .card-view__info .card-view__num {
        font-size: rem(22px);
    }
    .hd .greatingPay .card-view > li .card__delete {
        position: absolute;
        top: 50%;
        right: 0;
        width: rem(90px);
        height: rem(55px);
        border: 1px solid $colorBC;
        background: #fff;
        font-size: rem(22px);
        color: $color3D;
        transform: translate3d(0, -50%, 0);
    }
    .hd .greatingPay.point .card-insert > li {
        padding-left: 0;

        .greatingPay__tit{
            position: relative;
            top: 0;
            transform: none;
            padding-bottom: rem(10px);
        }

        .policy{
            position: relative;
            margin: rem(25px 0 5px);
            border-bottom: 1px solid $colorBC;

            .check_box{
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;

                .checkbox__label{
                    font-size: rem(20px);
                }
            }

            .accordion{
                &_title{
                    position: relative;
                    padding: rem(5px 0 20px);
                    font-size: rem(20px);
                    text-indent: 2.3rem;

                    &:after{
                        position: absolute;
                        right: 0;
                        top: 0.5rem;
                        width: 1.5rem;
                        height: 0.8rem;
                        background: url(/front_mo/images/icon_common_drop-down.png) no-repeat;
                        transform: scale(0.85);
                        vertical-align: middle;
                        content: '';
                        background-size: 100%;
                    }
                }

                &_con{
                    display: none;
                    padding: rem(10px) !important;
                    height: rem(200px);
                    line-height: 1.3;
                    font-size: rem(20px);
                    border-top: 0 !important;
                    overflow-y: auto; 
                    background: $colorBG;
                }
            }

            &.on{
                .accordion_title{
                    &:after{
                       transform: scale(0.75) rotate(180deg); 
                    }
                }

            }

            .check_area{
                &--cont{
                    padding: rem(10px);
                    height: rem(100px);
                    font-size: rem(20px);
                    overflow-y: auto;
                    background-color: #fff;
                }

                .check{
                    display: block;
                    padding: rem(30px 0 10px);

                    &box__label{
                        font-size: rem(20px);
                        font-weight: bold !important;
                    }
                }

                .table-area{
                    table, th, td{
                        font-weight: normal;
                        border: 1px solid $colorBC;
                    }

                    th, td{
                        padding: rem(5px);
                        font-size: rem(19px);
                    }

                    .text.desc{
                        margin-top: 5px;
                        font-size: rem(18px);
                    }
                }

            }
        }

        .number{
            padding: rem(20px 0);
            @extend %font_xs;

            .input_type {
                display: block;
                margin-top: rem(15px);
                
                .input_text {
                    width: rem(322px);
                    margin-right: rem(20px);
                    text-align: right;
                }

                .checkbox__label{
                    display: inline-block;
                }
            }
        }

        .input_type input{
            text-align: left;
            text-indent: rem(15px);
        }

        .input_box .btn-orange{
            margin-left: rem(15px);
            width: rem(180px);
            text-align: center;
        }
    }
    .notice {
        margin-top: rem(25px);
        font-size: rem(20px);
    }
    .notice .notice__title {
        margin-bottom: rem(10px);
    }
    .notice .notice__list {
        position: relative;
        padding-left: rem(12px);
        color: $color88;
        line-height: 1.6;
        word-break: keep-all;
        letter-spacing: -0.5px;
    }   
    .notice .notice__list:before {
        content: '-';
        display: inline-block;
        position: absolute;
        top: rem(-2px);
        left: 0;
    }
    .notice .notice__list .important {
        color: $color3D;
    }
    .notice .notice__list .block {
        display: block;
    }
    .agree-area .form_box {
        padding-bottom: rem(30px);
        border-bottom: 1px solid $colorBC;
    }
    .agree-area .check-all {
        padding: rem(25px 0);
        border-bottom: 1px solid $colorBC;
    }
    .agree-area .desc {
        padding-top: rem(20px);
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .agree-area .check-list a {
        display: inline-block;
        position: relative;
        float: right;
    }
    .agree-area .check-list a:after {
        display: block;
        position: absolute;
        bottom: rem(-8px);
        left: 0;
        width: 100%;
        height: 0;
        content: '';
        border: 1px solid #797979;
    }
    .agree-area .checks_box .checks:before {
        display: none;
    }
    .agree-area-nomem .agree {
        position: relative;
    }
    .agree-area-nomem .agree_box .agree_txt {
        display: block;
        width: 100%;
        padding: rem(20px 0);
        border-bottom: 1px solid $colorBC;
    }
    .agree-area-nomem .agree_box .viewMore-btn {
        display: inline-block;
        position: absolute;
        top: rem(17px);
        right: 0;
        width: rem(40px);
        height: rem(40px);
    }
    .agree-area-nomem .agree_box .viewMore-btn:before {
        display: block;
        position: absolute;
        top: 50%;
        right: rem(7px);
        transform: translateY(-50%);
        width: rem(27px);
        height: rem(2px);
        background-color: $color3D;
        content: '';
        opacity: 0.6;
    }
    .agree-area-nomem .agree_box .viewMore-btn:after {
        display: block;
        position: absolute;
        top: 50%;
        right: rem(19px);
        transform: translateY(-50%);
        width: rem(2px);
        height: rem(27px);
        background-color: $color3D;
        content: '';
        opacity: 0.6;
    }
    .agree-area-nomem .agree_box .agree_txt-detail {
        display: none;
        overflow: hidden;
        overflow-y: auto;
        max-height: rem(300px);
        padding: rem(25px);
        background: #f5f5f5;
        border-bottom: 1px solid $colorBC;
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .agree-area-nomem .agree_box .accordion_box.on .viewMore-btn:after {
        display: none;
    }
    .agree-area-nomem .desc {
        padding-top: rem(22px);
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .orderComplete-msg {
        width: 100%;
        text-align: center;
    }
    .orderComplete-msg .tit {
        display: block;
        margin-bottom: rem(25px);
        color: $color3D;
        font-size: rem(30px);
    }
    .orderComplete-msg .tit:before {
        display: block;
        width: rem(80px);
        height: 0;
        margin: rem(60px auto 35px);
        background: url(/front_mo/images/icon_comm_smile.png) no-repeat center center;
        text-align: center;
        content: '';
    }
    .orderComplete-msg .desc1 {
        font-size: rem(20px);
        color: $color3D;
        line-height: rem(38px);
    }
    .orderComplete-msg .desc2 {
        font-size: rem(20px);
        color: $color3D;
        line-height: rem(32px);
        margin-top: rem(20px);
    }
    .orderComplete-msg .annc {
        font-size: rem(20px);
        margin-top: rem(35px);
        padding: rem(30px);
        background: #ffeddf;
        color: $color3D;
        line-height: rem(32px);
        word-break: keep-all;
    }
    .hd__orderComplete .payment-amount li.total-area {
        padding: rem(25px 0);
        border-bottom: 1px solid $colorBC;
    }
    .hd__orderComplete .payment-amount li.total-area .tit {
        font-size: rem(22px);
    }
    .hd__orderComplete .payment-amount li.total-area .price {
        position: static;
        float: initial;
        margin-top: 0;
        font-size: rem(26px);
    }
    .hd__orderComplete .payment-amount li.total-area .desc {
        display: inline-block;
        float: right;
        width: auto;
        margin-top: 0;
        font-size: rem(22px);
        text-align: right;
    }
    .hd__orderComplete .payment-amount li.total-area .price:before {
        display: none;
    }
    .hd__orderComplete .payment-info ul {
        padding: rem(25px 0);
        border-bottom: 1px solid $colorBC;
    }
    .hd__orderComplete .payment-info ul li {
        display: table;
        width: 100%;
        margin: rem(0 0 10px);
        font-size: rem(22px);
    }
    .hd__orderComplete .payment-info ul li:last-child {
        margin-bottom: 0;
    }
    .hd__orderComplete .payment-info ul li:after {
        display: block;
        clear: both;
        content: '';
    }
    .hd__orderComplete .payment-info ul li .tit {
        display: table-cell;
        width: rem(126px);
        margin-right: rem(45px);
        text-align: left;
    }
    .hd__orderComplete .payment-info ul li .cont {
        display: table-cell;
        line-height: rem(32px);
    }
    .hd__orderComplete .deliveryInfo ul {
        padding: rem(25px 0);
        border-bottom: 1px solid $colorBC;
    }
    .hd__orderComplete .deliveryInfo ul li {
        display: table;
        width: 100%;
        margin: rem(0 0 10px);
        font-size: rem(22px);
    }
    .hd__orderComplete .deliveryInfo ul li:last-child {
        margin-bottom: 0;
    }
    .hd__orderComplete .deliveryInfo ul li:after {
        display: block;
        clear: both;
        content: '';
    }
    .hd__orderComplete .deliveryInfo ul li .tit {
        display: table-cell;
        width: rem(140px);
        margin-right: rem(45px);
        text-align: left;
    }
    .hd__orderComplete .deliveryInfo ul li .cont {
        display: table-cell;
        line-height: rem(32px);
    }
    .giftBox__list {
        padding: rem(20px 0);
        border-bottom: 1px solid $colorBC;

        &:last-child{
            border-bottom: 0;
        }
    }
    .giftBox__list strong {
        display: block;
        margin-bottom: rem(20px);
        font-size: rem(24px);
        font-weight: 600;
    }
    .giftBox__cont {
        position: relative;
        padding-left: rem(200px);
        min-height: rem(160px);
    }
    .giftBox__thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: rem(160px);
        height: rem(160px);
    }
    .giftBox__item__list {
        margin-top: rem(15px);
        line-height: rem(36px);
        font-size: rem(22px);

        &.soldout{
            position: relative;

            &:after{
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                font-size: rem(18px);
                color: #fff;
                text-align: center;
                content: '한정수량이 모두 소진되었습니다.';
                z-index: 2;
                transform: translateY(-50%);
            }

            &:before{
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 85%;
                background: rgba(0,0,0,0.6);
                content: '';
                z-index: 1;
                transform: translateY(-50%);
            }
        }
    }
    .giftBox__item__list label {
        position: relative;
        display: inline-block;
        padding: rem(5px 0 5px 50px);
        min-height: 2.15rem;
        @include line(2);
    }
    .giftBox__item__list label:before {
        content: '';
        display: block;
        position: absolute;
        top: rem(8px);
        left: 0;
        width: rem(36px);
        height: rem(36px);
        background: url(/front_mo/images/btn_comm_radio.png) no-repeat rem(-3px -3px);
        background-size: rem(84px 42px);
    }
    .giftBox__item__list input:checked+label:before {
        background-position: rem(-45px -4px);
    }
    .giftBox__item__thumb {
        position: relative;
        top: rem(-3px);
        left: 0;
        display: inline-block;
        margin-right: rem(5px);
        width: rem(50px);
        height: rem(46px);
        vertical-align: middle;
    }
    .options__line {
        position: relative;
        padding-left: rem(55px);
    }
    .options__line .abs {
        position: absolute;
        top: 0;
        left: 0;
    }
    .designation__opts span {
        display: block;
        line-height: rem(28px);
    }
    .scQna {
        overflow: hidden;
        overflow-y: auto;
        height: rem(370px);
        resize: none;
        box-sizing: border-box;
        line-height: rem(32px);
    }
    .hd .Badge {
        display: inline-block;
        padding: rem(0 5px);
        background: $color3D;
        font-size: rem(20px);
        color: #fff;
        font-style: normal;
    }
    img {
        max-width: 100%;
    }
    .btn--update {
        width: rem(100px);
        height: rem(55px);
        border: 1px solid $gcolor;
        vertical-align: middle;
        font-size: rem(24px);
        font-weight: 600;
        color: #333;
    }
    .btn--delete {
        display: inline-block;
        position: absolute;
        top: rem(25px);
        right: 0;
        width: rem(32px);
        height: rem(32px);
    }
    .btn--delete:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(2px);
        height: rem(30px);
        margin: rem(-15px 0 0 -1px);
        background-color: $color88;
        transform: rotate(45deg);
    }
    .btn--delete:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(2px);
        height: rem(30px);
        margin: rem(-15px 0 0 -1px);
        background-color: $color88;
        transform: rotate(-45deg);
    }
    .btn--listMore {
        display: block;
        padding: rem(15px 0);
        border-top: 1px solid $colorBC;
        background: #f5f5f5;
        text-align: center;
        font-size: rem(22px);
    }
    .btn--listMore span {
        padding-right: rem(32px);
        background: url(/front_mo/images/btn_menu_drop-down.png) no-repeat 100% 50%;
    }
    .btn--listMore.on span {
        padding-right: rem(32px);
        background: url(/front_mo/images/btn_menu_drop-up.png) no-repeat 100% 50%;
    }
    .dvCart-sec {
        position: relative;
        min-height: rem(130px);
        padding: rem(0 0 0 150px);
    }
    .dvCart-sec .dvCart-img {
        position: absolute;
        top: 0;
        left: 0;
    }
    .dvCart-sec .dvCart-img figure {
        width: rem(130px);
        height: rem(130px);
    }
    .dvCart-sec .dvCart-text .dvCart-title {
        display: block;
        padding-right: rem(35px);
        font-size: rem(26px);
        font-weight: 600;
        line-height: rem(32px);
    }
    .dvCart-sec .dvCart-text .dvCart-desc {
        margin: rem(5px 0 -15px);
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .dvCart-sec .dvCart-text .dvCart-opts {
        display: block;
    }
    .dvCart-sec .dvCart-text .dvCart-pdName {
        display: block;
    }
    .dvCart-sec .dvCart-text .dvCart-delivery {
        font-weight: 600;
        color: $gcolor;
    }
    .dvCart-sec .dvCart-price {
        position: absolute;
        right: 0;
        bottom: rem(15px);
        font-size: rem(26px);
        font-weight: 600;
    }
    .dvCart.single {
        position: relative;
        padding: rem(30px 0 30px 50px);
    }
    .dvCart.single .checks_box {
        position: absolute;
        top: rem(30px);
        left: 0;
        height: auto;
        line-height: 1;
    }
    .dvCart.single .checks.on:before {
        margin-right: 0;
    }
    .dvCart.single .btn--delete {
        top: rem(30px);
    }
    .dvCart.single .count_box {
        margin-top: rem(28px);
        width: rem(190px);

        .discount-tooltip{
            top: 0;
            transform: translateX(0) translateY(-0.5rem);
            letter-spacing: rem(-1px);
        }
    }
    #orderCart .dvCart.delivery--stop .dvCart-price {
        color: $gcolor;
    }
    #orderCart .dvCart.delivery--stop .product--price {
        color: $gcolor;
    }

    .hd__orderCart .hd__btn-sec > * {
        height: rem(85px);
        line-height: rem(85px);
    }

    .hd__orderCart .btn-white {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: 600;
        box-sizing: border-box;
    }

    .hd__orderCart .btn-green {
        display: block;
        width: 100%;
        font-weight: 600;
    }

    .product--money--detail {
        padding: rem(25px 0);
        border-top: rem(3px) solid $gcolor;
        border-bottom: 1px solid $gcolor;
    }
    .product--money--detail li {
        font-size: rem(22px);
        margin-top: rem(15px);
    }
    .product--money--detail li:first-child {
        margin-top: 0;
    }
    .product--money--detail li:after {
        content: '';
        display: block;
        clear: both;
    }
    .product--money--detail li .txt {
        float: left;
    }
    .product--money--detail li .price {
        float: right;
        font-weight: 400;
    }
    .product--money--total {
        margin-top: rem(25px);
        font-weight: 600;
    }
    .product--money--total:after {
        content: '';
        display: block;
        clear: both;
    }
    .product--money--total .txt {
        float: left;
        padding-top: rem(10px);
        font-size: rem(26px);
    }
    .product--money--total .price {
        position: relative;
        float: right;
        font-size: rem(36px);
    }
    .product--money--total .price:before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        bottom: rem(-2px);
        left: 0;
        width: 100%;
        height: rem(8px);
        background: $ocolor;
    }
    .count_box {
        position: relative;
        font-size: 0;
    }
    .count_box .num {
        display: inline-block;
        position: relative;
        width: rem(190px);
        height: rem(55px);
        padding: rem(0 53px);
        border: 1px solid $colorBC;
        text-align: center;
        vertical-align: middle;
        line-height: rem(55px);
        color: $color3D;
        box-sizing: border-box;
    }
    .count_box .num button {
        position: absolute;
        top: -1px;
        width: rem(55px);
        height: rem(55px);
        border: 1px solid $colorBC;
        background: #f5f5f5;
    }
    .count_box .num .btn_down {
        left: -1px;
    }
    .count_box .num .btn_down:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(21px);
        height: rem(3px);
        background: $color3D;
        transform: translate(-50%, -50%);
    }
    .count_box .num .btn_up {
        right: -1px;
    }
    .count_box .num .btn_up:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(21px);
        height: rem(3px);
        background: $color3D;
        transform: translate(-50%, -50%);
    }
    .count_box .num .btn_up:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(3px);
        height: rem(21px);
        background: $color3D;
        transform: translate(-50%, -50%);
    }
    .count_box .num input {
        width: rem(80px);
        height: rem(53px);
        padding: 0;
        border: none;
        text-align: center;
        vertical-align: top;
        line-height: rem(53px);
        font-size: rem(24px);
        font-weight: 600;
        color: #333;
        background: transparent;
    }
    .list_top {
        position: relative;
        padding: rem(20px 0);
        border-bottom: 1px solid $colorBC;
    }
    .list_top label {
        display: inline-block;
        font-size: rem(22px);
    }
    .list_top button {
        position: absolute;
        top: 50%;
        right: 0;
        padding-left: rem(30px);
        background: url(/front_mo/images/ico_comm_delete.png) no-repeat 0 50%;
        font-size: rem(22px);
        transform: translate(0, -50%);
    }
    .list_top .btn-box{
        position: absolute;
        top: 50%;
        right: 0;
        white-space: nowrap;
        transform: translateY(-50%);
    }
    .list_top .btn-box a{
        margin-left: 5px;
    }
    .checks {
        display: inline-block;
        vertical-align: rem(2px);
    }
    .checks:before {
        content: '';
        display: inline-block;
        width: rem(34px);
        height: rem(34px);
        margin-right: rem(5px);
        border: 1px solid $colorBC;
        background: #fff url(/front_mo/images/ico_comm_checkbox.png) no-repeat 50% 50%;
        background-size: rem(20px 15px);
        border-radius: 50%;
        vertical-align: rem(-11px);
    }
    .checks.on:before, .checks.on:before {
        background: $gcolor url(/front_mo/images/ico_comm_checkbox-on.png) no-repeat 50% 50%;
        background-size: rem(20px 15px);
        border: 1px solid $gcolor;
    }
    .checks.disabled:before {
        display: none;
    }
    .checks input {
        overflow: hidden;
        position: absolute;
        width: rem(1px);
        height: rem(1px);
        clip: rect(1px 1px 1px 1px);
    }
    .checks label {
        display: inline-block;
        vertical-align: 0;
        margin-left: rem(5px);
        font-size: rem(22px);
    }
    .notice_desc {
        padding: rem(20px);
        font-size: rem(22px);
        color: $color88;
        line-height: rem(32px);
    }
    .dvCart {
        position: relative;
        margin-top: 0;
        border-top: 1px solid $colorBC;
    }
    .dvCart:first-child {
        margin-top: 0;
        border-top: 0;
    }
    .dvCart .checks_box {
        height: rem(76px);
        line-height: rem(76px);
    }
    .dvCart .checks_box strong {
        display: inline-block;
        overflow: hidden;
        max-width: rem(500px);
        vertical-align: top;
        font-size: rem(26px);
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .dvCart .product--detail {
        display: table;
        position: relative;
        width: 100%;
        margin-bottom: rem(25px);
        padding-top: rem(25px);
        border-top: 1px solid $colorBC;
    }
    .dvCart .product--plan {
        display: none;
    }
    .dvCart .product--plan table thead th {
        overflow: hidden;
        position: absolute;
        width: rem(1px);
        height: rem(1px);
        clip: rect(1px 1px 1px 1px);
    }
    .dvCart .product--plan table tbody th {
        padding: rem(25px 10px);
        border-top: 1px solid $colorBC;
        border-right: 1px solid $colorBC;
        text-align: center;
        line-height: 1.2;
        font-size: rem(26px);
        font-weight: 400;
    }
    .dvCart .product--plan table tbody th strong {
        display: block;
        font-weight: 600;
    }
    .dvCart .product--plan table tbody td {
        padding: rem(30px 0 30px 20px);
        border-top: 1px solid $colorBC;
        line-height: rem(32px);
        font-size: rem(22px);
    }
    .dvCart .product--plan table tbody td .num {
        font-weight: 600;
    }
    .dvCart .product--plan table tbody td .product--present {
        margin: rem(20px 0 0);
        border-top: 1px solid $colorBC;
        padding: rem(20px 0 0);
        background: 0 0;
        line-height: 1;
    }
    .dvCart .product--present {
        position: relative;
        margin: rem(25px 0);
        padding: rem(15px);
        background: #f5f5f5;
        font-size: 0;
    }
    .dvCart .product--present .product--present--img {
        display: inline-block;
        position: relative;
        width: rem(80px);
        max-width: rem(80px);
        height: rem(77px);
        vertical-align: top;
        text-align: center;
    }
    .dvCart .product--present .product--present--img img {
        width: 100%;
        vertical-align: top;
    }
    .dvCart .product--present .product--present--name {
        display: inline-block;
        width: rem(309px);
        margin-left: rem(25px);
        vertical-align: top;
        line-height: rem(32px);
        font-size: rem(22px);
    }
    .dvCart .product--present .product--present--name span {
        display: block;
    }
    .dvCart .present--add li {
        position: relative;
        margin-top: rem(20px);
    }
    .dvCart .present--add li:first-child {
        margin-top: 0;
    }
    .dvCart .present--add li .present--add--opts {
        display: block;
        margin-bottom: rem(30px);
        padding-right: rem(40px);
        font-size: rem(22px);
        font-weight: 600;
    }
    .dvCart .present--add li .present--add--detail {
        position: relative;
        min-height: rem(55px);
    }
    .dvCart .present--add li .present--add--detail .present--add--price {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: rem(24px);
        font-weight: 600;
    }
    .dvCart .present--add li .btn--delete {
        top: 0;
    }
    .dvCart .product--price {
        position: relative;
        padding: rem(25px 0);
        border-top: 1px solid $colorBC;
        text-align: right;
        font-size: rem(26px);
        font-weight: 600;
    }
    .dvCart .product--price .btn--buynow {
        position: absolute;
        z-index: 4;
        top: 50%;
        right: 0;
        height: rem(55px);
        padding: rem(0 38px);
        border: 0;
        background: $color88;
        text-align: center;
        font-size: rem(24px);
        font-weight: 600;
        color: #fff;
        transform: translate(0, -50%);
    }
    .dvCart .product--price .btn--buynow.active {
        background: $gcolor;
    }
    .dvCart.stop .textBox {
        position: relative;
    }
    .dvCart.stop .textBox .dim {
        display: block;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .7);
    }
    .dvCart.stop .textBox:before {
        display: block;
        position: absolute;
        z-index: 4;
        top: 50%;
        left: 50%;
        font-size: rem(28px);
        font-weight: 600;
        color: #fff;
        transform: translate3d(-50%, -50%, 0);
    }
    .dvCart.stop01 .textBox:before {
        content: '판매중지 또는 일시품절';
    }
    .dvCart.stop02 .textBox:before {
        content: '판매중지 또는 일시품절';
    }
    .dvCart.stop03 .textBox:before {
        content: '구매불가 (배송불가)';
    }
    .latter_head {
        padding: rem(50px 30px);
        display: inline-block;
        width: rem(580px);
    }
    .latter_head .latter_top {
        font-size: rem(32px);
        text-align: center;
    }
    .latter_head > span {
        font-size: rem(22px);
        color: #5f5f5f;
        padding: rem(14px 0);
        margin-top: rem(27px);
        display: inline-block;
    }
    .latter_list li .oder_title {
        border-top: 1px solid #dfdfdf;
    }
    .latter_list li:last-child {
        border-bottom: 1px solid #dfdfdf;
    }
    .latter_list .on > .oder_title {
        background: #efefef;
    }
    .latter_list .on > .oder_title:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: rem(31px);
        width: rem(33px);
        height: rem(17px);
        margin-top: rem(-8px);
        background: url(/mobile/img/icon_arrow_big_down.png) no-repeat;
    }
    .coupon_head {
        padding: rem(50px 30px);
    }
    .coupon_head h2 {
        text-align: center;
        font-size: rem(30px);
    }
    .coupon_info {
        margin-top: rem(30px);
        text-align: center;
    }
    .coupon_info .coupon_info_bg {
        margin: 0 auto;
        width: rem(520px);
        height: rem(217px);
        background: url(/mobile/img/ms/coupon_bg_big.png) no-repeat;
    }
    .coupon_info .coupon_info_bg .coupon_bg_top {
        padding: rem(40px 50px);
    }
    .coupon_info .coupon_info_bg .coupon_bg_top p {
        font-size: rem(26px);
        line-height: rem(33px);
        color: #5f5f5f;
    }
    .coupon_info .coupon_info_bg .coupon_bg_top p span {
        font-size: rem(42px);
        font-weight: 600;
        color: #000;
        font-weight: 900;
    }
    .coupon_info .coupon_info_bg .coupon_bg_btm {
        padding: rem(20px 30px);
        display: inline-block;
        width: rem(460px);
        line-height: rem(25px);
    }
    .coupon_info .coupon_info_bg .coupon_bg_btm p {
        font-size: rem(24px);
        display: block;
        float: left;
        color: #5f5f5f;
        letter-spacing: rem(-1px);
    }
    .coupon_info .coupon_info_bg .coupon_bg_btm span {
        font-size: rem(24px);
        display: block;
        float: right;
        color: $colorRD;
    }
    .coupon_add {
        margin-top: rem(20px);
    }
    .coupon_add a {
        display: inline-block;
        width: 100%;
        padding: rem(20px 0);
        border-bottom: 1px solid #dfdfdf;
        position: relative;
        vertical-align: top;
    }
    .coupon_add a:last-child {
        margin-bottom: 0;
    }
    .coupon_add a:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        width: rem(10px);
        height: rem(17px);
        background: url(/mobile/img/ms/icon_arrow.png) no-repeat;
        transform: translateY(-50%);
    }
    .coupon_add a p {
        width: 100%;
        font-size: rem(26px);
        float: left;
        color: #5f5f5f;
    }
    .coupon_add a p span {
        color: #000;
        float: right;
        padding-right: rem(50px);
    }
    .coupon_tab:before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        height: rem(20px);
        border-top: 1px solid #dfdfdf;
        background: #efefef;
    }
    .coupon_tab .coupon_poss {
        padding: rem(40px 30px 0);
    }
    .coupon_tab .coupon_poss > p {
        font-size: rem(22px);
        color: #5f5f5f;
    }
    .coupon_tab .coupon_poss > ul {
        margin-top: rem(20px);
    }
    .coupon_tab .coupon_poss > ul > li {
        padding: rem(20px 0);
        text-align: center;
    }
    .coupon_poss_bg {
        position: relative;
        display: block;
        margin: 0 auto;
        width: rem(520px);
        min-height: rem(204px);
        box-sizing: border-box;
        border: 1px solid #cfcfcf;
        border-radius: rem(20px);
    }
    .coupon_poss_bg:after,
    .coupon_poss_bg:before {
        content: '';
        position: absolute;
        top: 50%;
        height: rem(46px);
        width: rem(23px);
        border: solid #cfcfcf;
        background: #fff;
        transform: translateY(-50%);
    }
    .coupon_poss_bg:before {
        left: rem(-2px);
        border-width: rem(1px 1px 1px 0);
        border-radius: rem(0 23px 23px 0);
    }
    .coupon_poss_bg:after {
        right: rem(-2px);
        border-width: rem(1px 0 1px 1px);
        border-radius: rem(23px 0 0 23px);
    }
    .coupon_poss_bg .coupon_poss_info {
        display: inline-block;
        width: rem(402px);
        padding: rem(37px 40px 32px 78px);
    }
    .coupon_poss_bg .coupon_poss_info:before {
        content: 'COUPON';
        position: absolute;
        top: 50%;
        left: rem(13px);
        font-size: rem(16px);
        line-height: rem(30px);
        color: #9f9f9f;
        margin-top: rem(-15px);
        transform: rotate(-90deg);
    }
    .coupon_poss_bg .coupon_poss_info:after {
        content: '';
        position: absolute;
        top: 0;
        left: rem(75px);
        bottom: 0;
        width: rem(3px);
        background: url(/mobile/img/ms/coupon_bg_on.png) repeat-y 0 0;
    }
    .coupon_poss_bg .coupon_poss_inner {
        display: block;
        padding-left: rem(40px);
    }
    .coupon_poss_bg .coupon_poss_inner .free_dv {
        display: block;
        overflow: hidden;
        text-align: left;
        margin-bottom: rem(15px);
    }
    .coupon_poss_bg .coupon_poss_inner .free_dv > .coupon_title {
        font-size: rem(30px);
        line-height: rem(36px);
        color: $colorRD;
        text-decoration: underline;
    }
    .coupon_poss_bg .coupon_poss_inner .free_dv > .day_rming {
        float: right;
        line-height: rem(36px);
        font-size: rem(20px);
        margin-left: rem(15px);
    }
    .coupon_poss_bg .coupon_poss_inner .free_dv > .day_rming span {
        font-weight: 900;
    }
    .coupon_poss_bg .coupon_poss_inner .price_mrtn {
        display: block;
        line-height: rem(26px);
        min-height: rem(52px);
        letter-spacing: rem(-1px);
        text-align: left;
        margin-bottom: rem(10px);
    }
    .coupon_poss_bg .coupon_poss_inner .use_date {
        display: block;
        height: rem(22px);
        color: #5f5f5f;
        letter-spacing: rem(0.6px);
        text-align: left;
    }
    .coupon_poss_bg .coupon_poss_inner em {
        display: block;
        font-style: normal;
        font-size: rem(22px);
        letter-spacing: -.05em;
    }
    .coupon_poss_bg.off {
        background: #f9f9f9;
    }
    .coupon_poss_bg.off .coupon_poss_inner {
        color: #9f9f9f;
    }
    .coupon_poss_bg.off .coupon_poss_inner .free_dv > .coupon_title {
        color: #9f9f9f;
    }
    .coupon_poss_bg.off .coupon_poss_inner .free_dv > .day_rming {
        color: #000;
    }
    .coupon_tab .coupon_finish {
        padding: rem(0 30px 20px);
    }
    .coupon_tab .coupon_finish > ul {}
    .coupon_tab .coupon_finish > ul > li {
        padding: rem(20px 0);
        text-align: center;
    }
    .bg_chg > .oder_title {
        background: #fff;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
    }
    .bg_chg.on > .oder_title {
        background: #efefef;
        border-top: 1px solid #9f9f9f;
    }
    .bg_chg .section {
        padding: rem(40px 30px);
    }
    .pt_title {
        margin-top: rem(49px);
        font-size: rem(30px);
        text-align: center;
    }
    .pt_box {
        display: flex;
        width: rem(260px);
        height: rem(260px);
        margin: rem(28px) auto 0;
        border: rem(18px) solid #dfdfdf;
        border-radius: 100%;
        font-size: rem(26px);
        text-align: center;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
    }
    .pt_box .inner {
        line-height: rem(48px);
    }
    .pt_box strong {
        display: inline-block;
        min-width: rem(41px);
        font-size: rem(42px);
        font-weight: 900;
    }
    .sta_list li {
        overflow: hidden;
        padding: rem(30px 0 22px);
        border-bottom: 1px solid #e4e4e4;
        font-size: rem(26px);
        line-height: 1;
        color: #5f5f5f;
    }
    .sta_list li .pt_num {
        float: right;
        font-size: rem(28px);
    }
    .sta_list li em {
        font-style: normal;
        font-size: rem(27px);
    }
    .data_inner {
        margin-top: rem(50px);
        border-bottom: 1px solid #e4e4e4;
    }
    .data_inner .no_data {
        padding-top: rem(30px) !important;
    }
    .data_title {
        height: rem(50px);
        padding-left: rem(30px);
        line-height: rem(50px);
        font-size: rem(24px);
        background: #efefef;
        color: #5f5f5f;
    }
    /* .data_pt a {
        display: block;
        overflow: hidden;
        padding: 48px 30px;
        border-top: 1px solid #e4e4e4;
        color: #000;
    }
    .data_pt a:first-child {
        border: none;
        padding-top: 40px;
    } */
    .data_pt .left {
        float: left;
    }
    .data_pt .left .txt {
        font-size: rem(22px);
    }
    .data_pt .left .prd_num {
        margin-top: rem(15px);
        font-size: rem(24px);
        color: #5f5f5f;
    }
    .pt_com .right p {
        color: #ff3c00;
    }
    .data_pt .right {
        position: relative;
        float: right;
        padding-right: rem(60px);
    }
    .data_pt .right:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: rem(11px);
        height: rem(18px);
        margin-top: rem(-9px);
        background: url(/mobile/img/ms/icon_arrow.png) no-repeat;
    }
    .data_pt .right .txt {
        margin-top: rem(10px);
        font-size: rem(24px);
        text-align: right;
    }
    .stat_box.use {
        color: $colorRD;
    }
    .prd_info_box {
        position: relative;
        padding: rem(64px 0 42px);
    }
    .prd_info_box:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        height: rem(20px);
        border-top: 1px solid #dfdfdf;
        background: #efefef;
    }
    .prd_info_box.tab_wrap .tab_type2 {
        margin-top: rem(18px);
        border-top: 1px solid #dfdfdf;
    }
    .prd_info_box .title {
        overflow: hidden;
        height: rem(46px);
        padding: rem(0 30px);
        font-size: rem(32px);
        line-height: rem(46px);
        font-weight: 600;
    }
    .prd_info_box .title .checks_box {
        float: right;
        margin-top: rem(-4px);
    }
    .prd_info_box .title .checks_box label {
        font-size: rem(24px);
        font-weight: 400;
    }
    .prd_info_box .title .txt_r {
        float: right;
        font-size: rem(26px);
        font-weight: 400;
    }
    .prd_info_box .oder_list {
        padding: rem(0 30px);
    }
    .prd_info_box .list_prd {
        padding: rem(0 30px);
        margin-top: rem(20px);
        border: none;
    }
    .prd_info_box .list_prd > li {
        padding: rem(0 0 42px);
    }
    .prd_info_box .list_prd li+li {
        padding-top: rem(30px);
    }
    .prd_info_box .list_prd > li .img_box {
        vertical-align: top;
    }
    .prd_info_box .list_prd:last-child > li:last-child {
        padding-bottom: 0;
    }
    .prd_info_box label.title {
        display: block;
    }
    .prd_info_box .title .btn_xsmal {
        float: right;
        height: rem(46px);
        line-height: rem(44px);
    }
    .prd_info_box+.btn_area {
        margin-top: 0;
        padding-top: rem(50px);
        border-top: 1px solid #000;
    }
    .prd_info_box+.btn_area.apply {
        padding-top: 0;
        border-top: none;
    }
    .change_tit {
        overflow: hidden;
        padding: rem(27px 30px);
        font-size: rem(26px);
        color: #5f5f5f;
    }
    .change_tit .checks {
        float: right;
        margin-top: rem(-4px);
    }
    .change_tit.btn_tit {
        padding: rem(16px 30px);
        line-height: rem(48px);
    }
    .change_tit.btn_tit .btn_xsmal {
        float: right;
    }
    .change_list .change_tit {
        margin-top: rem(36px);
        border-top: 1px solid #dfdfdf;
    }
    .change_list .change_tit .dv_end_time {
        vertical-align: top;
    }
    .prd_info_box.change_list .list_prd {
        margin-top: 0;
        padding: rem(0 30px);
    }
    .prd_info_box.change_list .list_prd.first li {
        padding: rem(25px 0 15px);
    }
    .prd_info_box.change_list .list_prd li {
        padding: rem(25px 0 42px);
        border-top: 1px solid #dfdfdf;
    }
    .prd_info_box.change_list .list_prd > li:last-child {
        padding-bottom: 0;
    }
    .prd_info_box .list_text_box:last-child {
        padding-bottom: 0;
    }
    .prd_info_box .list_text {
        font-size: rem(26px);
    }
    .prd_info_box .list_text li+li {
        margin-top: rem(17px);
    }
    .prd_info_box .input_type {
        margin-top: rem(17px);
        padding: rem(0 30px);
    }
    .prd_info_box .form_box {
        margin-top: 0;
        padding: rem(38px 0 40px);
        border-top: 1px solid #e4e4e4;
    }
    .prd_info_box .form_box.first {
        border-top: none;
    }
    .prd_info_box .form_box .form_title {
        padding-left: rem(30px);
        padding-right: rem(30px);
    }
    .prd_info_box .form_box:last-child {
        padding-bottom: 0;
    }
    .prd_info_box .deli_txt {
        margin-top: rem(40px);
        padding: rem(0 30px);
        font-size: rem(24px);
    }
    .prd_info_box .refund_box {
        padding: rem(30px 0 42px);
        border-top: 1px solid #dfdfdf;
    }
    .last_list {
        border-bottom: 1px solid #dfdfdf;
    }
    .check_box {
        overflow: hidden;
    }
    .data_form_wrap {
        display: inline-block;
    }
    .com_text {
        margin-top: rem(80px);
    }
    .com_text .img_box {
        display: inline-block;
        margin-bottom: rem(21px);
    }
    .com_box {
        margin-top: rem(53px);
        padding-top: rem(2px);
        border-top: 1px solid #dfdfdf;
    }
    .com_box .cont_title {
        line-height: rem(38px);
    }
    .com_box .cont_title span {
        font-weight: 400;
    }
    .com_box .sns_list {
        margin-top: rem(29px);
    }
    .banner_bar {
        margin-top: rem(100px);
    }
    .banner_bar img {
        vertical-align: top;
    }
    .join_head {
        padding: rem(50px 30px);
        border-bottom: 1px solid #dfdfdf;
    }
    .join_head p {
        text-align: center;
        font-size: rem(26px);
        letter-spacing: rem(-1px);
    }
    .join_head p img {
        margin-bottom: rem(16px);
    }
    .join_content {
        padding: rem(50px 30px);
        border-bottom: rem(1px solid #dfdfdf);
    }
    .join_content p {
        text-align: center;
        font-size: rem(26px);
        margin-bottom: rem(16px);
    }
    .join_content p img {
        margin-bottom: rem(-4px);
    }
    .join_content .f_size {
        font-size: rem(24px);
        letter-spacing: rem(-1px);
        margin-bottom: rem(19px);
    }
    .join_content .input_type {
        padding: rem(10px 0);
    }
    .join_content .input_type input {
        height: rem(100px);
        border: 0;
        background: #f9f9f9;
    }
    .join_content .login_btn {
        padding: rem(20px 0);
    }
    .join_content .join_finds {
        text-align: center;
        margin-top: rem(30px);
    }
    .join_content .join_finds ul {
        display: inline-block;
        position: relative;
    }
    .join_content .join_finds ul li {
        float: left;
        margin: rem(0 20px);
        font-size: rem(24px);
        letter-spacing: rem(-1px);
    }
    .join_content .join_finds ul li:first-child:after {
        content: "";
        display: block;
        width: rem(1px);
        height: rem(20px);
        position: absolute;
        right: rem(178px);
        top: rem(2px);
        background: #dfdfdf;
    }
    .join_btm {
        padding: rem(50px 30px);
    }
    .join_btm p {
        font-size: rem(24px);
        text-align: center;
        margin-bottom: rem(10px);
        letter-spacing: rem(-1px);
    }
    .join_btm p:nth-child(3) {
        margin-bottom: 0;
    }
    .join_btm p strong {
        font-size: rem(26px);
    }
    .join_btm p strong img {
        margin-bottom: rem(-4px);
    }
    .join_btm .btn_round {
        margin: rem(30px 0);
    }
    .faq_title {
        position: relative;
        font-size: rem(32px);
        display: inline-block;
        width: rem(580px);
        padding: rem(38px 30px);
    }
    .faq_title .btn_list_more {
        position: absolute;
        right: rem(16px);
        top: rem(28px);
    }
    .bg_chg ul li > .bg_chg_me {
        border-top: 1px solid #dfdfdf;
    }
    .bg_chg ul li.on > .bg_chg_me {
        border-top: 1px solid #dfdfdf;
    }
    .bg_chg ul li.on > .bg_chg_me:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: rem(31px);
        width: rem(33px);
        height: rem(17px);
        margin-top: rem(-8px);
        background: url(/front_mo/images/icon_arrow_big_down.png) no-repeat;
    }
    .bg_chg ul li.on > .faq_reply {
        background-color: #f9f9f9;
    }
    .bg_chg > ul > li:last-child {
        border-bottom: 1px solid #dfdfdf;
    }
    .bg_chg > ul > li.no_data:last-child {
        border-bottom: none;
    }
    .cs_center_head {
        margin-top: rem(50px);
        text-align: center;
    }
    .cs_center_head strong {
        letter-spacing: rem(-1px);
    }
    .cs_center_head p {
        font-size: rem(30px);
        margin: rem(27px 0 8px);
    }
    .cs_center_head p img {
        margin-bottom: rem(-3px);
    }
    .cs_center_content {
        padding: rem(50px 30px);
        border-bottom: 1px solid #dfdfdf;
    }
    .cs_center_content dl {
        margin-bottom: rem(33px);
    }
    .cs_center_content dl dt {
        font-size: rem(26px);
        font-weight: 600;
        margin-bottom: rem(13px);
    }
    .cs_center_content dl dd {
        font-size: rem(24px);
        color: #5f5f5f;
        line-height: rem(33px);
    }
    .cs_center_content .cs_btn {
        display: inline-block;
        margin-top: rem(10px);
        width: 100%;
    }
    .cs_center_content .cs_btn button {
        width: rem(280px);
    }
    .cs_center_content .cs_btn .talk {
        background: #ffdc00 url(/mobile/img/icon_talk_btn.png) rem(53px) center no-repeat;
        border: 0;
        color: #3c1e1e;
        padding-left: rem(50px);
    }
    .cs_center_content .cs_btn button:nth-child(2) {
        float: right;
    }
    .cs_faq_top {
        padding-bottom: rem(49px);
        border-bottom: 1px solid #dfdfdf;
    }
    .cs_faq_top:before {
        content: "";
        display: block;
        height: rem(20px);
        background: #efefef;
    }
    .cs_faq_top .accordion_box:last-child {
        border-bottom: 1px solid #dfdfdf;
    }
    .left {
        float: left;
    }
    .bg_chg .faq_title .rigth {
        float: right;
        font-size: rem(24px);
        padding-right: rem(23px);
        position: relative;
        line-height: rem(32px);
    }
    .bg_chg .faq_title .rigth:after {
        content: "";
        display: block;
        width: rem(11px);
        height: rem(18px);
        background: url(/mobile/img/ms/icon_arrow.png) no-repeat;
        position: absolute;
        right: 0;
        top: rem(6px);
    }
    .rigth p a img {
        margin-left: rem(12px);
    }
    .faq_list .oder_title {
        padding: rem(21px 94px 21px 30px);
    }
    .faq_accor span {
        display: inline-block;
        font-size: rem(22px);
        margin-bottom: rem(13px);
        color: #5f5f5f;
    }
    .faq_accor p {
        font-size: rem(28px);
        line-height: 1.5;
    }
    .faq_reply {
        padding: rem(40px 30px);
        font-size: rem(24px) !important;
        line-height: 1.6 !important;
    }
    .faq_reply * {
        font-size: rem(24px) !important;
        line-height: 1.6 !important;
    }
    .faq_reply ul li {
        padding-left: 0;
        font-size: rem(24px);
    }
    .faq_reply ul li:before {
        display: none;
    }
    .cs_notice:before {
        content: "";
        display: block;
        height: rem(20px);
        background: #efefef;
    }
    .notice_accor p {
        font-size: rem(28px);
        margin-bottom: rem(13px);
    }
    .notice_accor span {
        display: inline-block;
        font-size: rem(22px);
        color: #5f5f5f;
    }
    .se_head {
        padding: rem(0 30px);
    }
    .notice_list .accordion_box:last-child {
        border-bottom: 1px solid #dfdfdf;
    }
    .no_data .sc_result {
        font-size: rem(28px);
        margin-bottom: rem(20px);
        display: inline-block;
        color: #5f5f5f;
    }
    .no_data .sc_text {
        font-size: rem(24px);
        line-height: rem(35px);
        color: #9f9f9f;
    }
    .se_head .se_content {
        padding: rem(50px 0);
    }
    .se_head .notice_sc_area {
        position: relative;
    }
    .se_head .input_type select {
        margin-top: rem(20px);
    }
    .se_head .input_text {
        padding: rem(0 80px 0 30px);
    }
    .se_head .searc_area .btn_clear {
        display: none;
        position: absolute;
        top: rem(1px);
        right: 10rem;
        width: rem(80px);
        height: rem(80px);
        background-position: center;
    }
    .kakao_help {
        padding: rem(50px 80px);
        border-bottom: 1px solid #dfdfdf;
    }
    .kakao_help p {
        text-align: center;
        font-size: rem(24px);
        color: #5f5f5f;
        margin-bottom: rem(50px);
    }
    .kakao_help .kakao_time {
        text-align: center;
        font-size: rem(26px);
        font-weight: 600;
    }
    .kakao_help .kakao_time span {
        line-height: rem(33px);
    }
    .kakao_help .kakao_time:before {
        content: "";
        display: block;
        position: relative;
        width: rem(140px);
        height: rem(140px);
        background: url(/mobile/img/kakao_talk_img.png) no-repeat;
        margin: 0 auto rem(30px);
    }
    .kakao_btm {
        padding: rem(40px 30px);
    }
    .kakao_btm p {
        font-size: rem(22px);
        color: #5f5f5f;
        line-height: rem(32px);
        margin-bottom: rem(40px);
    }
    .kakao_btm p strong {
        border-bottom: 1px solid #000;
        padding-bottom: rem(2px);
    }
    .kakao_btm .katalk_btn {
        width: rem(340px);
        background: #ffdc00 url(/mobile/img/icon_talk_btn.png) 50px center no-repeat;
        padding-left: rem(50px);
        border: 0;
        color: #3c1e1e;
    }
    .kakao_btm .cs_btn {
        text-align: center;
        padding: rem(40px 0);
    }
    .inquiry_head {
        padding: rem(50px 30px);
        border-bottom: 1px solid #dfdfdf;
    }
    .inquiry_head label {
        font-size: rem(32px);
    }
    .inquiry_head .input_type {
        margin-top: rem(40px);
    }
    .order_choice {
        padding: rem(30px 30px 0);
        display: inline-block;
        width: rem(580px);
    }
    .order_choice .order_list .order_content span {
        font-size: rem(26px);
        float: left;
        line-height: rem(50px);
    }
    .order_choice .order_list .order_content button {
        width: rem(140px);
        float: right;
    }
    .order_list {
        display: inline-block;
        width: 100%;
    }
    .order_list ul {
        width: 100%;
        display: inline-block;
        display: none;
    }
    .order_list ul li {
        display: inline-block;
        width: 100%;
    }
    .order_list ul li span {
        width: 100%;
        float: left;
        font-size: rem(24px);
        color: #5f5f5f;
        line-height: rem(33px);
        word-break: break-all;
    }
    .order_content {
        display: inline-block;
        width: 100%;
    }
    .or_choice_se {
        font-size: rem(24px);
        color: #9f9f9f;
        margin-top: rem(30px);
    }
    .section.form_boxn {
        padding: rem(30px 30px 40px);
    }
    .btn_area.order_btn button {
        width: rem(280px);
    }
    .order_info_list {
        padding: rem(60px 30px);
    }
    .order_info_list p {
        font-size: rem(24px);
        line-height: rem(36px);
        margin-bottom: rem(45px);
    }
    .order_info_list p span {
        text-decoration: underline;
    }
    .order_info_list table {
        width: 100%;
    }
    .order_info_list table thead {
        border-top: rem(2px) solid #000;
    }
    .order_info_list table thead tr {
        height: rem(80px);
    }
    .order_info_list table thead tr th {
        font-size: rem(24px);
    }
    .order_info_list table tbody {
        border-top: 1px solid #000;
    }
    .order_info_list table tbody tr {
        border-bottom: 1px solid #dfdfdf;
        height: rem(120px);
    }
    .order_info_list table tbody tr td {
        font-size: rem(22px);
        word-break: break-all;
    }
    .order_info_list table tbody tr td a {
        display: inline-block;
        vertical-align: middle;
    }
    .order_info_list table tbody tr td a span {
        width: 100%;
        float: left;
        line-height: rem(30px);
    }
    .order_info_list table .not_sc tr {
        border-bottom: 0;
        cursor: auto;
    }
    .tr_padd {
        padding: rem(0 30px);
    }
    .order_data {
        color: #5f5f5f;
    }
    .order_title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .order_price {
        color: #5f5f5f;
    }
    .rat_per_box {
        padding: rem(50px 0);
        text-align: center;
    }
    .rat_per_box:before {
        content: "";
        display: block;
        width: rem(120px);
        height: rem(125px);
        margin: 0 auto;
        background: url(/mobile/img/ms/icon_reader.jpg) no-repeat;
    }
    .f_ponit {
        color: #a49b98;
    }
    .rat_per_box.fflower:before {
        background-image: url(/mobile/img/ms/icon_fflower.jpg);
    }
    .fflower .f_ponit {
        color: #8e8b83;
    }
    .rat_per_box.starter:before {
        background-image: url(/mobile/img/ms/icon_starter.jpg);
    }
    .starter .f_ponit {
        color: #7d9587;
    }
    .per_title {
        font-size: rem(30px);
        line-height: rem(40px);
    }
    .per_txt {
        margin-top: rem(20px);
        font-size:rem(22px);
        color: #9f9f9f;
    }
    .rat_pay_box {
        padding: rem(50px 0);
        border-top: 1px solid #000;
        text-align: center;
    }
    .rat_pay_tit {
        font-size: rem(26px);
        line-height: rem(34px);
    }
    .rat_pay_txt {
        margin-top: rem(14px);
        font-size: rem(22px);
        line-height: rem(36px);
        color: #9f9f9f;
    }
    .rat_pay_box .btn_link {
        margin-top: rem(14px);
    }
    .benefit_tab {
        margin-top: rem(29px);
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        overflow: hidden;
    }
    .benefit_tab li {
        float: left;
        width: 33.3%;
        font-size: rem(26px);
        color: #9f9f9f;
        text-align: center;
    }
    .benefit_tab li a {
        display: block;
    }
    .benefit_tab li a > span {
        display: inline-block;
        height: rem(88px);
        line-height: rem(88px);
    }
    .benefit_tab li.on {
        color: #000;
    }
    .benefit_tab li.on a > span:after {
        content: "";
        display: block;
        width: 100%;
        height: rem(4px);
        margin-top: rem(-4px);
        background: #000;
    }
    .rating_txt {
        padding: rem(50px 0 40px);
        color: #a49b98;
        font-size: rem(24px);
        text-align: center;
    }
    .rating_txt:before {
        content: "";
        display: block;
        width: rem(120px);
        height: rem(125px);
        margin: 0 auto;
        background: url(/mobile/img/ms/icon_reader.jpg) no-repeat;
    }
    .rating_txt.fflower {
        color: #8e8b83;
    }
    .rating_txt.fflower:before {
        background-image: url(/mobile/img/ms/icon_fflower.jpg);
    }
    .rating_txt.starter {
        color: #7d9587;
    }
    .rating_txt.starter:before {
        background-image: url(/mobile/img/ms/icon_starter.jpg);
    }
    .benefit_banner {
        margin-top: rem(40px);
        display: block;
    }
    .benefit_banner img {
        vertical-align: top;
    }
    .odhstr_head {
        padding-top: rem(50px);
        border-bottom: 1px solid #000;
    }
    .odhstr_rating {
        padding: rem(0 80px);
        text-align: center;
    }
    .odhstr_rating .user_name {
        font-size: rem(30px);
        border-bottom: 1px solid #000;
    }
    .odhstr_rating p {
        font-size: rem(26px);
        line-height: rem(40px);
    }
    .odhstr_rating .rating_icon {
        width: rem(120px);
        height: rem(120px);
        margin: rem(0 auto 26px);
    }
    .odhstr_rating.fflower .rating_icon {
        background: url(/mobile/img/ms/icon_fflower.jpg) no-repeat;
    }
    .odhstr_rating.reader .rating_icon {
        background: url(/mobile/img/ms/icon_reader.jpg) no-repeat;
    }
    .odhstr_rating.starter .rating_icon {
        background: url(/mobile/img/ms/icon_starter.jpg) no-repeat;
    }
    .odhstr_rating p .user_id {
        font-weight: 400;
    }
    .odhstr_rating .rating_today {
        font-size: rem(18px);
        color: #9f9f9f;
        margin-top: rem(10px);
        display: inline-block;
    }
    .odhstr_rating .buttons {
        padding: rem(26px 0 37px);
    }
    .odhstr_rating .buttons .btn_round {
        width: rem(205px);
        height: rem(60px);
        line-height: rem(60px);
        font-size: rem(24px);
        font-weight: 400;
    }
    .odhstr_make {
        padding: rem(50px 30px);
    }
    .odhstr_make .buttons {
        text-align: center;
        margin-top: rem(38px);
    }
    .odhstr_make .buttons .btn_link {
        width: rem(300px);
        height: rem(60px);
        line-height: rem(60px);
        font-weight: 400;
        letter-spacing: rem(-1px);
        padding: rem(0 41px 0 43px);
        background: #000 url(/mobile/img/icon_move.png) no-repeat 90% center;
    }
    .info_text {
        position: relative;
        margin-top: rem(30px);
        padding-left: rem(15px);
        font-size: rem(22px);
        color: #9f9f9f;
    }
    .info_text:before {
        content: "*";
        display: block;
        position: absolute;
        top: rem(2px);
        left: 0;
    }
    .hd__service-list__total {
        margin-bottom: rem(15px);
        font-size: rem(22px);
    }
    .hd__btn__viewMore {
        width: 100%;
        height: rem(70px);
        margin-top: rem(40px);
    }
    .hd__btn__viewMore button {
        width: 100%;
        height: rem(70px);
        line-height: rem(70px);
        font-size: rem(24px);
        box-sizing: border-box;
        text-align: center;
        background: #bebebe;
        color: #fff;
    }
    .hd .service-center__info {
        margin-top: rem(50px);
        text-align: center;
    }
    .hd .service-center__info__number {
        font-size: rem(24px);
        font-weight: 600;
    }
    .hd .service-center__info__number span {
        display: block;
        margin-top: rem(15px);
        font-size: rem(46px);
    }
    .hd .service-center__info__notice {
        margin: rem(15px 0 30px);
        line-height: rem(32px);
        font-size: rem(22px);
    }
    .hd .service-center__info__link {
        font-size: 0;
    }
    .hd .service-center__info__link li {
        display: inline-block;
        overflow: hidden;
        vertical-align: top;
        width: rem(140px);
        height: rem(140px);
        margin: rem(0 15px);
        border: 1px solid #aaa;
        border-radius: 50%;
        box-sizing: border-box;
    }
    .hd .service-center__info__link li a {
        display: block;
    }
    .hd .service-center .cs_faq_top {
        margin-top: rem(40px);
        border-top: rem(3px) solid $gcolor;
        border-bottom: 0;
        padding-bottom: 0;
    }
    .hd .service-center .cs_faq_top:before {
        display: none;
    }
    .hd .service-center .cs_faq_top .accordion_box:last-child {
        border-bottom: 0;
    }
    .hd .service-center .faq_title {
        position: relative;
        font-size: rem(26px);
        display: block;
        width: auto;
        padding: rem(25px 0);
        font-weight: 600;
    }
    .hd .service-center .faq_title .btn_list_more {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: auto;
        height: auto;
        padding-bottom: rem(10px);
        border-bottom: 1px solid #797979;
        font-size: rem(22px);
        font-weight: 400;
        transform: translate3d(0, -50%, 0);
    }
    .faq_title .btn_list_more:after,
    .hd .service-center .faq_title .btn_list_more:before {
        display: none;
    }
    .hd .service-center .cs_notice {
        margin-top: rem(40px);
        border-top: rem(3px) solid $gcolor;
    }
    .hd .service-center .cs_notice:before {
        display: none;
    }
    .hd .service-center .bg_chg ul li {
        border-bottom: 1px solid silver;
    }
    .hd .service-center .bg_chg ul li:first-child {
        border-top: 1px solid silver !important;
        border-bottom: 1px solid silver !important;
    }
    .hd .service-center .bg_chg ul li a {
        display: block;
        overflow: hidden;
        height: rem(70px);
        padding: rem(0 10px);
        line-height: rem(70px);
        font-size: rem(24px);
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .hd .service-center form {
        position: relative;
    }
    .hd .service-center form input {
        padding-right: rem(78px);
    }
    .hd .service-center form .btn__faq {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: rem(78px);
        height: rem(70px);
        background: url(/front_mo/images/ico_service_search.png) no-repeat 50% 50%;
    }
    .hd .notice_list {
        margin-top: rem(30px);
    }
    .hd__service-list__list {
        border-bottom: 1px solid $colorBC;
    }
    .hd__service-list__list:first-child {
        border-top: 1px solid $colorBC;
    }
    .hd__service-list__list a {
        display: block;
        padding: rem(30px 15px);
    }
    .hd__service-list__title {
        display: block;
        overflow: hidden;
        line-height: rem(36px);
        font-size: rem(24px);
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .hd__service-list__title span {
        color: $gcolor;
    }
    .hd__service-list__date {
        display: block;
        margin-top: rem(10px);
        font-size: rem(22px);
    }
    .hd .service-tab {
        margin-bottom: rem(30px);
        text-align: left;
        font-size: 0;
    }
    .hd .service-tab__list {
        display: inline-block;
        position: relative;
        width: rem(192px);
        margin-top: rem(-1px);
        margin-left: rem(-1px);
        vertical-align: top;
        border: 1px solid $colorBC;
    }
    .hd .service-tab__list:nth-child(3n+1) {
        margin-left: 0;
    }
    .hd .service-tab__list:nth-child(-n+3) {
        margin-top: 0;
    }
    .hd .service-tab__list a {
        display: block;
        height: rem(68px);
        text-align: center;
        line-height: rem(68px);
        font-size: rem(24px);
        letter-spacing: -.05em;
    }
    .hd .service-tab__list.on:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: rem(-1px);
        right: rem(-1px);
        bottom: rem(-1px);
        left: rem(-1px);
        border: rem(3px) solid $gcolor;
        box-sizing: border-box;
    }
    .hd .service-tab__list.on a {
        color: $gcolor;
    }
    .hd .faq-list .se_head {
        padding: 0;
        margin-bottom: rem(40px);
    }
    .hd .faq-list .se_head .se_content {
        position: relative;
        margin-top: rem(30px);
        padding: 0;
    }
    .hd .faq-list .se_head .se_complete {
        padding: 0;
    }
    .hd .faq-list .se_head .input_text {
        border: rem(3px) solid $gcolor;
    }
    .hd .faq-list .btn__faq {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: rem(78px);
        height: rem(70px);
        margin-left: 0;
        background: url(/front_mo/images/ico_service_search.png) no-repeat 50% 50%;
    }
    .hd .faq-list .no_data .sc_text {
        font-size: rem(22px);
    }
    .hd .faq-list .faq_list > ul > li {
        border-bottom: 1px solid silver;
    }
    .hd .faq-list .faq_list > ul > li:first-child {
        border-top: 1px solid silver;
    }
    .hd .faq-list .faq_list .faq_accor {
        padding: rem(30px 95px 30px 15px);
        border-top: 0 !important;
        border-bottom: 0 !important;
    }
    .hd .faq-list .faq_accor span {
        display: block;
        font-size: rem(22px);
        margin-bottom: rem(10px);
        color: $color3D;
    }
    .hd .faq-list .faq_accor p {
        line-height: rem(36px);
        font-size: rem(24px);
        font-weight: 600;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
    }
    .hd .faq-list .faq_reply {
        padding: rem(30px);
        font-size: rem(24px) !important;
        line-height: 1.6 !important;
        border-top: 1px solid silver;
        background: #f9f9f9;
        word-break: keep-all;
    }
    .hd .faq-list .faq_reply * {
        font-size: rem(24px) !important;
        line-height: 1.6 !important;
    }
    .hd .faq-list .faq_reply ul li {
        padding-left: 0;
        font-size: rem(22px);
    }
    .hd .faq-list .faq_reply ul li a {
        font-weight: 600;
    }
    .hd .faq-list .faq_reply ul li:before {
        display: none;
    }
    .hd .se_complete {
        margin-bottom: rem(15px);
        font-size: rem(22px);
    }
    .hd .inquery__sec {
        margin-top: rem(30px);
    }
    .hd .inquery__sec .input-box {
        position: relative;
    }
    .hd .inquery__sec .input-box.num {
        padding-right: rem(150px);
    }
    .hd .inquery__sec .input-box.num .btn-full {
        position: absolute;
        top: 0;
        right: 0;
        max-width: rem(130px);
    }
    .hd .inquery__sec .input-box .email {
        color: #bdbdbd;
    }
    .hd .inquery__sec .input-box .email__box {
        display: flex;
        justify-content: space-between;
        line-height: rem(70px);
        font-size: 25px;
        color: #bdbdbd;
    }
    .hd .inquery__sec .input-box .email__box input {
        max-width: rem(170px);
    }
    .hd .inquery__sec .input-box .email__box .input_mail {
        display: none;
        max-width: rem(370px);
    }
    .hd .inquery__sec .input-box .domain__check {
        margin-top: rem(15px);
    }
    .hd .inquery__sec .input-box .email__box select {
        max-width: rem(370px);
        background-position: right 20px top 50%;
    }
    .hd .inquery__sec .input-box select {
        width: 100%;
        padding-right: rem(70px);
        padding-left: rem(20px);
        height: rem(70px);
        border: 1px solid #bdbdbd;
        background: url(/front_mo/images/ico_member_select.png) no-repeat right 20px top 50%;
        line-height: rem(70px);
        color: $color3D;
    }
    .hd .inquery__sec .input-box .on select {
        display: none;
    }
    .hd .inquery__sec .input-box .on .input_mail {
        display: block;
    }
    .hd .inquery__sec .cellphone__number {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(20px);
        line-height: rem(70px);
        font-size: 25px;
        color: #bdbdbd;
    }
    .hd .inquery__sec .cellphone__number input {
        max-width: rem(170px);
    }
    .hd .inquery__sec .inquery__title {
        margin-bottom: rem(15px);
        font-size: rem(24px);
        font-weight: 600;
        color: $color3D;
    }
    .hd .inquery__sec .inquery__title .import {
        font-weight: 400;
        color: $gcolor;
	}

	.hd .inquery__sec .delivery__info {font-size:rem(20px); line-height: 1.4;
		h4 {font-size:rem(22px); font-weight:bold;}
		ul{
			li {
				margin-top: 5px;
				padding-left: 10px;
				text-indent: -12px;
				word-break: keep-all;
			}
		}
		dl {margin:10px 0; padding-left:10px;
			dt {font-size:rem(20px); font-weight:bold;}
			dd {margin-top:5px;}
		}
	}

    .hd .inquery__sec .inquery__notice {
        display: block;
        position: relative;
        margin-top: rem(20px);
        padding-left: rem(12px);
        line-height: rem(32px);
        font-size: rem(22px);
        font-weight: 400;
        color: $color88;
    }
    .hd .inquery__sec .inquery__notice:before {
        content: '*';
        position: absolute;
        top: 0;
        left: 0;
        font-size: rem(22px);
        color: $color88;
    }
    .hd .inquery__sec .inquery__notice a {
        color: $color3D;
    }
    .hd .inquery__sec .file_list ul:after {
        content: '';
        display: block;
        clear: both;
    }
    .hd .inquery__sec .file_list ul li {
        float: left;
        margin-top: 0;
        margin-left: rem(20px);
    }
    .hd .inquery__sec .file_list ul li:first-child {
        margin-left: 0;
    }
    .hd .inquery__sec .file_list ul .image-box {
        display: block;
        overflow: hidden;
        position: relative;
        width: rem(120px);
        height: rem(120px);
        border: 1px solid #c3c3c3;
    }
    .hd .inquery__sec .file_list ul .image-box img {
        width: 100%;
    }
    .hd .inquery__sec .file_list ul .image-box .btn_icon {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: rem(35px);
        height: rem(35px);
        background: $gcolor url(/front_mo/images/ico_file_delte.png) no-repeat 50% 50%;
    }
    .hd .inquery .btn-area {
        margin-top: rem(40px);
    }
    .hd .inqueryNumber .inqueryNumber__list {
        margin-top: rem(35px);
    }
    .hd .inqueryNumber .inqueryNumber__list:first-child {
        margin-top: 0;
    }
    .hd .inqueryNumber .inqueryNumber__list a {
        display: block;
    }
    .hd .inqueryNumber .inqueryNumber__head {
        display: block;
        font-size: 0;
        margin-bottom: rem(20px);
    }
    .hd .inqueryNumber .inqueryNumber__head .date {
        display: inline-block;
        vertical-align: middle;
        margin-right: rem(20px);
        font-size: rem(22px);
    }
    .hd .inqueryNumber .inqueryNumber__head .unique_number {
        display: inline-block;
        vertical-align: middle;
        font-size: rem(22px);
        font-weight: 600;
    }
    .hd .inqueryNumber .inqueryNumber__cont {
        position: relative;
        padding: rem(20px 0);
        border-top: 1px solid $colorBC;
        border-bottom: 1px solid $colorBC;
    }
    .hd .inqueryNumber .inqueryNumber__cont .inqueryNumber__cont__img {
        width: rem(110px);
        height: rem(110px);
    }
    .hd .inqueryNumber .inqueryNumber__cont .inqueryNumber__cont__txt {
        position: absolute;
        top: 50%;
        left: rem(130px);
        line-height: rem(36px);
        font-size: rem(22px);
        transform: translate3d(0, -50%, 0);
    }
    .hd .inqueryNumber .inqueryNumber__cont .inqueryNumber__cont__txt .inqueryNumber__cont__title {
        width: rem(400px);
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
    }
    .hd .popup_inqueryNumber .page__list-none {
        height: auto !important;
        min-height: auto !important;
    }
    .hd .popup_inqueryNumber .page__list-none p {
        padding: rem(90px 0 20px) !important;
    }
    .hd .mypage .odhstr_head {
        padding: 0;
        border-bottom: 0;
        margin-top: rem(45px);
    }
    .hd .mypage .odhstr_rating {
        position: relative;
        padding: 0;
        text-align: left;
        margin-bottom: rem(40px);
    }
    .hd .mypage .odhstr_rating .user_name {
        display: inline-block;
        position: relative;
        font-size: rem(30px);
        border-bottom: 0;
        margin-right: rem(10px);
    }
    .hd .mypage .odhstr_rating .user_name:before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        bottom: rem(-6px);
        left: 0;
        width: 100%;
        height:rem( 8px);
        background: $gcolor;
    }
    .hd .mypage .odhstr_rating .btn__settings {
        position: absolute;
        top: 0;
        right: 0;
        padding-left: rem(40px);
        background: url(/front_mo/images/icon_comm_setting-black.png) no-repeat 0 50%;
        line-height: rem(38px);
        font-size: rem(22px);
        color: $color3D;
    }
    .hd .mypage .odhstr_head .btn-full {
        display: block;
        text-align: center;
        font-weight: 600;
    }
    .hd .mypage .mypage__sec {
        margin-top: rem(40px);
        border-top: rem(3px) solid $gcolor;
    }
    .hd .mypage .mypage__sec.bdx {
        border-top: 0;
    }
    .hd .mypage .mypage__sec .mypage__title {
        position: relative;
        font-size: rem(26px);
        display: block;
        padding: rem(25px 0);
        font-weight: 600;
    }
    .hd .mypage .mypage__sec .mypage__link {
        font-size: 0;
    }
    .hd .mypage .mypage__sec .mypage__link li {
        display: inline-block;
        width: 50%;
        margin-top: rem(-1px);
        margin-right: rem(-1px);
        border: 1px solid $colorBC;
        vertical-align: top;
        box-sizing: border-box;
    }
    .hd .mypage .mypage__sec .mypage__link li:nth-child(-n+2) {
        margin-top: 0;
    }
    .hd .mypage .mypage__sec .mypage__link li:nth-child(even) {
        margin-right: 0;
        border-left: 0;
    }
    .hd .mypage .mypage__sec .mypage__link li a {
        display: block;
        height: rem(68px);
        padding: rem(0 25px);
        line-height: rem(68px);
        font-size: rem(22px);
        color: $color3D;
    }
    .hd .mypage .mypage__sec .mypage__link.other li a {
        font-weight: 600;
        text-align: center;
    }
    .hd .mypage .mypage__sec .mypage__link.other li a img {
        vertical-align: rem(-7px);
    }
    .hd .mypage .mypage__sec .pdOrder > li {
        border-top: 1px solid $colorBC;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list .pdOrder-title {
        display: block;
        line-height: rem(36px);
        font-size: rem(24px);
        font-weight: 600;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list .pdOrder-date {
        display: block;
        line-height: rem(36px);
        font-size: rem(22px);
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list .pdOrder-state {
        display: block;
        line-height: rem(36px);
        font-size: rem(22px);
        font-weight: 600;
        color: $gcolor;
    }
    .hd .mypage .mypage__sec .pdOrder {
        border-bottom: 1px solid $colorBC;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list .gift {
        position: relative;
        margin-top: rem(20px);
        font-size: 0;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list .gift .thumb {
        display: inline-block;
        width: rem(50px);
        height: rem(46px);
        font-size: 0;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list .gift .thumb:after {
        display: inline-block;
        position: relative;
        width: 0;
        height: 100%;
        vertical-align: middle;
        content: "";
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list .gift .textbox {
        display: block;
        position: absolute;
        top: rem(6px);
        left: rem(50px);
        margin-left: rem(20px);
        font-size: rem(22px);
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.single {
        position: relative;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.single > div {
        padding: rem(25px 0 25px 150px);
        min-height:rem( 130px);
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.single .pdOrder-img {
        display: block;
        overflow: hidden;
        position: absolute;
        top: rem(25px);
        left: 0;
        width: rem(130px);
        height: rem(130px);
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi {
        position: relative;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi > div {
        padding: rem(25px 0);
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals {
        display: none;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals__list {
        border-top: 1px solid $colorBC;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals__list .info-area {
        position: relative;
        min-height: rem(180px);
        padding: rem(25px 0 25px 150px);
        font-size: 0;
        box-sizing: border-box;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals__list .info-area .info-area__text {
        position: absolute;
        top: rem(25px);
        left: 0;
        width: rem(130px);
        height: rem(130px);
        border: rem(3px) solid #bebebe;
        text-align: center;
        font-size: rem(22px);
        box-sizing: border-box;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals__list .info-area .info-area__text span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate3d(-50%, -50%, 0);
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals__list .info-area .info-area__text strong {
        display: block;
        font-weight: 600;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals__list .info-area__txt {
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals__list .info-area__txt .product--list {
        position: relative;
        padding-right: rem(65px);
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals__list .info-area__txt .product--list .num {
        position: absolute;
        top: 0;
        right: 0;
    }
    .hd .mypage .mypage__sec .pdOrder .pdOrder-list.multi .regular-meals__list .info-area__condi {
        display: block;
        line-height: rem(36px);
        font-size: rem(22px);
        font-weight: 600;
        color: $gcolor;
    }
    .hd .mypage .mypage__sec .pdOrder li.no_data {
        border-top: rem(3px) solid $gcolor !important;
    }
    .hd .mypage .mypage__sec .service__center {
        margin-top: rem(40px);
    }
    .hd .mypage .mypage__sec .service__center a {
        padding: 0;
        text-align: center;
    }

    .hd .mypage__sec.myCode {position: relative;}
    .hd .mypage__sec.myCode .myCode__sec{border-top: 1px solid $colorBC;}
    .hd .mypage__sec.myCode .myCode__code{display:block; padding: rem(25px 0); font-size: rem(22px);}

    .hd .layer__popup .form_title {
        display: block;
        margin: rem(40px 0 20px);
        font-size: rem(22px);
        font-weight: 600;
    }
    .hd .layer__popup .form_title .import {
        font-weight: 400;
        color: $gcolor;
    }
    .hd .layer__popup .checks {
        border: 0;
        width: auto;
        height: auto;
        margin-right: 0;
        background: 0 0 !important;
    }
    .hd .layer__popup .btn-area {
        margin-top: rem(35px);
    }
    .hd .popup_refund_mod .memberAccount__notice {
        margin-top: rem(30px);
        font-size: rem(22px);
        line-height: rem(36px);
        color: $color88;
        word-break: keep-all;
    }
    .per_mygrade {
        display: block;
        width: rem(110px);
        height: rem(110px);
        line-height: rem(110px);
        border-radius: 100%;
        margin: 0 auto;
        background: $gcolor;
        color: #fff;
        font-size: rem(24px);
        font-weight: 600;
    }
    .per_mygrade.green {
        background: $gcolor;
    }
    .per_mygrade.friends {
        background: #3a8acd;
    }
    .per_mygrade.welcome {
        background: $gcolor;
    }
    .rat_per_box:before {
        display: none;
    }
    .per_txt {
        margin-top: rem(20px);
        font-size: rem(22px);
        color: $color3D;
    }
    .f_ponit {
        color: $gcolor;
    }
    .hd .myBenefit .gradeInfo__list {
        position: relative;
        padding: rem(40px 20px 40px 160px);
        border-bottom: 1px solid $colorBC;
    }
    .hd .myBenefit .gradeInfo__list:first-child {
        border-top: 1px solid $colorBC;
    }
    .hd .myBenefit .gradeInfo__list__txt {
        display: block;
        line-height: 1.6;
        font-size: rem(22px);
    }
    .hd .myBenefit .gradeInfo__list__txt:not(:first-child) {
        margin-top: rem(20px);
    }
    .hd .myBenefit .gradeInfo__list__txt strong {
        display: block;
    }
    .hd .myBenefit .gradeInfo__list:before {
        display: block;
        position: absolute;
        top: 50%;
        left: rem(20px);
        transform: translateY(-50%);
        width: rem(110px);
        height: rem(110px);
        border-radius: 100%;
        color: #fff;
        text-align: center;
        line-height: rem(110px);
        font-size: rem(24px);
        font-weight: 600;
    }
    .hd .myBenefit .gradeInfo__list:nth-child(1):before {
        background: $gcolor;
        content: '더그린';
    }
    .hd .myBenefit .gradeInfo__list:nth-child(2):before {
        background: #3a8acd;
        content: '프랜즈';
    }
    .hd .myBenefit .gradeInfo__list:nth-child(3):before {
        background: $gcolor;
        content: '웰컴';
    }
    .myCouponMain {
        padding-top: rem(190px);
    }
    .myCouponMain .coupon_tab:before {
        display: none;
    }
    .myCouponMain .coupon_tab .coupon_poss {
        padding: 0;
    }
    .myCouponMain .coupon_tab .coupon_poss > ul {
        margin-top: 0;
    }
    .myCouponMain .coupon_tab .coupon_poss > ul > li {
        margin-top: rem(30px);
        padding: rem(0 0 30px);
        border-bottom: 1px solid $colorBC;
    }
    .myCouponMain .coupon_poss_bg {
        width: 100%;
        min-height: auto;
        padding: 0;
        border: none;
        border-radius: 0;
    }
    .coupon_poss_bg:after,
    .myCouponMain .coupon_poss_bg:before {
        display: none;
    }
    .myCouponMain .coupon_poss_bg .coupon_poss_info {
        width: 100%;
        padding: 0;
    }
    .myCouponMain .coupon_poss_bg .coupon_poss_info:before {
        display: none;
    }
    .myCouponMain .coupon_poss_bg .coupon_poss_info:after {
        display: none;
    }
    .myCouponMain .coupon_poss_info {
        width: 100%;
        padding: 0;
    }
    .myCouponMain .coupon_poss_bg .coupon_poss_inner {
        padding-left: 0;
    }
    .myCouponMain .coupon_poss_bg .coupon_poss_inner em {
        display: inline;
    }
    .myCouponMain .coupon_poss_bg .coupon_poss_inner .free_dv > .coupon_title {
        font-size: rem(24px);
        color: $color3D;
        text-decoration: none;
    }
    .myCouponMain .coupon_poss_bg .coupon_poss_inner .price_mrtn {
        min-height: auto;
        font-size: rem(22px);
        color: $color3D;
    }
    .myCouponMain .coupon_poss_bg .coupon_poss_inner .use_date {
        font-size: rem(22px);
        color: $color3D;
    }
    .myCouponMain .apply__target {
        width: 100%;
        height: rem(70px);
        margin-top: rem(30px);
        text-align: center;
        font-size: rem(24px);
        font-weight: 600;
        color: $color3D;
        border: 1px solid $colorBC;
        box-sizing: border-box;
    }
    .myCouponMain .coupon_tab .coupon_finish {
        padding: 0;
    }
    .myCouponMain .coupon_tab .coupon_finish > ul > li {
        margin-top: rem(30px);
        padding: rem(0 0 30px);
        border-bottom: 1px solid $colorBC;
    }
    .myCouponMain .coupon_poss_bg.off {
        background: 0 0;
    }
    .myCouponMain .coupon_tab .coupon_finish .order__num {
        display: block;
        margin-top: rem(10px);
        font-size: rem(22px);
        color: $color3D;
        letter-spacing: rem(0.6px);
        text-align: left;
    }

    .myCouponMain .coupon_tab .coupon_finish .order__num a{
    color: $gcolor;
    }

    .myCouponMain .coupon_poss_bg.off .coupon_poss_inner .day_rming {
        display: block;
        width: 100%;
        height: rem(70px);
        margin-top: rem(30px);
        background: #f5f5f5;
        line-height: rem(70px);
        text-align: center;
        color: $color3D;
        font-size: rem(24px);
        font-weight: 600;
        box-sizing: border-box;
    }
    .recentlyList .select_all_area {
        padding: 0;
    }
    .recentlyList .select_all_area .list_top .checks {
        position: relative;
        width: rem(38px);
        height: rem(38px);
        vertical-align: middle;
        border: none;
    }
    .recentlyList .select_all_area .list_top .checks.on {
        width: rem(38px);
        height: rem(38px);
        border: none;
        background: 0 0;
    }
    .recentlyList .select_all_area .list_top .checks input {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        width: 100%;
        height: 100%;
        clip: initial;
    }
    .recentlyList .select_all_area .list_top button {
        color: $color3D;
    }
    .recentlyList .list_prd {
        margin-top: rem(40px);
    }
    .recentlyList .list_prd:after {
        display: block;
        clear: both;
        content: '';
    }
    .recentlyList .list_prd > li {
        position: relative;
        width: rem(275px);
        float: left;
        margin-top: rem(40px);
    }
    .recentlyList .list_prd > li:nth-child(odd) {
        clear: both;
    }
    .recentlyList .list_prd > li:nth-child(even) {
        margin-left: rem(30px);
    }
    .recentlyList .list_prd > li:nth-child(-n+2) {
        margin-top: 0;
    }
    .recentlyList .list_prd .img_box {
        width: rem(275px);
        height: rem(275px);
        text-align: center;
    }
    .recentlyList .list_prd .img_box img {
        max-width: 100%;
        max-height: 100%;
    }
    .recentlyList .list_prd .text_box {
        position: relative;
        margin-top: rem(20px);
    }
    .recentlyList .list_prd .text_box .prd_txt.volume {
        display: none;
    }
    .recentlyList .prd_txt.price span {
        font-size: rem(26px);
        color: $color3D;
        font-weight: 600;
    }
    .recentlyList .prd_txt.ms_sale {
        font-size: rem(20px);
        color: $color3D;
    }
    .recentlyList .list_prd.view .text_box .ms_sale ins {
        display: inline-block;
        font-size: rem(26px);
        color: $color3D;
        font-weight: 600;
        text-decoration: none;
    }
    .recentlyList .list_prd.view .text_box .ms_sale del {
        display: inline-block;
        margin-left: rem(15px);
        text-decoration: line-through;
    }
    .recentlyList .list_prd .text_box .sold_title {
        display: none;
    }
    .recentlyList .list_prd li.sold_out .img_box:after {
        display: none;
    }
    .recentlyList .list_prd.view .text_box .prd_title {
        display: block;
        width: 100%;
        margin-bottom: rem(10px);
        font-size: rem(22px);
        font-weight: bold;
        line-height: rem(32px);
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
    }
    .recentlyList .list_prd.view .checks-wrap {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    .recentlyList .list_prd.view .checks {
        display: block;
        position: relative;
    }
    .recentlyList .list_prd button.btn_icon.del {
        display: none;
    }
    .recentlyList .prd_btn_box {
        display: none;
    }
    .recentlyList .icon-area {
        margin-top: rem(40px);
        padding: rem(10px);
        border-top: 1px solid $colorBC;
        border-bottom: 1px solid $colorBC;
    }
    .recentlyList .icon-area span {
        display: inline-block;
        width: rem(33px);
        height: rem(36px);
    }
    .recentlyList .icon-area span+span {
        margin-left: rem(16px);
    }
    .recentlyList .icon-area span img {
        display: block;
        max-width: 100%;
    }
    .wishlist .select_all_area {
        padding: 0;
    }
    .wishlist .select_all_area .list_top .checks {
        position: relative;
        width: rem(38px);
        height: rem(38px);
        vertical-align: middle;
        border: none;
    }
    .wishlist .select_all_area .list_top .checks.on {
        width: rem(38px);
        height: rem(38px);
        border: none;
        background: 0 0;
    }
    .wishlist .select_all_area .list_top .checks input {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        width: 100%;
        height: 100%;
        clip: initial;
    }
    .wishlist .select_all_area .list_top button {
        color: $color3D;
    }
    .wishlist .list_prd {
        margin-top: rem(40px);
    }
    .wishlist .list_prd:after {
        display: block;
        clear: both;
        content: '';
    }
    .wishlist .list_prd > li {
        position: relative;
        width: rem(275px);
        float: left;
        margin-top: rem(40px);
    }
    .wishlist .list_prd > li:nth-child(odd) {
        clear: both;
    }
    .wishlist .list_prd > li:nth-child(even) {
        margin-left: rem(30px);
    }
    .wishlist .list_prd > li:nth-child(-n+2) {
        margin-top: 0;
    }
    .wishlist .list_prd .img_box {
        width: rem(275px);
        height: rem(275px);
        text-align: center;
    }
    .wishlist .list_prd .img_box img {
        max-width: 100%;
        max-height: 100%;
    }
    .wishlist .list_prd .text_box {
        position: relative;
        margin-top: rem(20px);
    }
    .wishlist .list_prd .text_box .prd_txt.volume {
        display: none;
    }
    .wishlist .prd_txt.price span {
        font-size: rem(26px);
        color: $color3D;
        font-weight: 600;
    }
    .wishlist .prd_txt.ms_sale {
        font-size: rem(20px);
        color: $color3D;
    }
    .wishlist .list_prd.view .text_box .ms_sale ins {
        display: inline-block;
        font-size: rem(26px);
        color: $color3D;
        font-weight: 700;
        text-decoration: none;
    }
    .wishlist .list_prd.view .text_box .ms_sale del {
        display: inline-block;
        margin-left: rem(15px);
        text-decoration: line-through;
    }
    .wishlist .list_prd .text_box .sold_title {
        display: none;
    }
    .wishlist .list_prd li.sold_out .img_box:after {
        display: none;
    }
    .wishlist .list_prd.view .text_box .prd_title {
        display: block;
        width: 100%;
        margin-bottom: rem(10px);
        font-size: rem(22px);
        font-weight: bold;
        line-height: rem(32px);
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
    }
    .wishlist .list_prd.view .checks-wrap {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    .wishlist .list_prd.view .checks {
        display: block;
        position: relative;
    }
    .wishlist .list_prd button.btn_icon.del {
        display: none;
    }
    .wishlist .prd_btn_box {
        display: none;
    }
    .wishlist .icon-area {
        margin-top: rem(40px);
        padding: rem(10px);
        border-top: 1px solid $colorBC;
        border-bottom: 1px solid $colorBC;
    }
    .wishlist .icon-area span {
        display: inline-block;
        width: rem(33px);
        height: rem(36px);
    }
    .wishlist .icon-area span+span {
        margin-left: rem(16px);
    }
    .wishlist .icon-area span img {
        display: block;
        max-width: 100%;
    }
    .myInquiryList .total__list {
        display: block;
        width: 100%;
        padding: rem(20px 0);
        font-size: rem(22px);
    }
    .myInquiryList .latter_list {
        border-top: rem(3px) solid $gcolor;
    }
    .myInquiryList .latter_list > li {
        border-bottom: 1px solid $colorBC;
    }
    .myInquiryList .latter_list li:after {
        display: none;
    }
    .myInquiryList .latter_list li .oder_title {
        padding: rem(30px 0);
        border: none;
    }
    .myInquiryList .latter_list li .oder_title:before {
        right: 0;
        bottom: rem(30px);
        top: 50%;
        margin-top: rem(-8.5px);
        opacity: 0.6;
    }
    .myInquiryList .qna_title_inner {
        position: relative;
    }
    .myInquiryList .qna_title_inner .qna_sot {
        display: block;
        margin-bottom: rem(10px);
        font-size: rem(22px);
        color: $color3D;
    }
    .myInquiryList .qna_title_inner .qna_title {
        display: block;
        margin-top: 0;
        padding-right: rem(150px);
        font-size: rem(24px);
        color: $color3D;
        line-height: rem(32px);
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
        word-wrap: break-word;
    }
    .myInquiryList .qna_title_inner .qna_data {
        display: block;
        margin-top: rem(10px);
        font-size: rem(24px);
        color: $color88;
    }
    .myInquiryList .qna_title_inner .qna_reply {
        position: absolute;
        top: 50%;
        right: rem(50px);
        color: $color3D;
        font-weight: 600;
        font-size: rem(22px);
        margin-right: 0;
        transform: translate3d(0, -50%, 0);
    }
    .myInquiryList .latter_list .oder_title+.agree_box {
        border-top: 1px solid $colorBC;
    }
    .myInquiryList .latter_list .on > .oder_title {
        background: #fff;
    }
    .myInquiryList .latter_list .on > .oder_title:before {
        background: url(/front_mo/images/icon_arrow_big.png) no-repeat;
        transform: rotate(0);
    }
    .myInquiryList .latter_list .qna_text {
        padding: 0;
    }
    .myInquiryList .head__area {
        position: relative;
        padding: rem(25px 0);
        border-bottom: 1px solid $colorBC;
    }
    .myInquiryList .head__area .thumb {
        overflow: hidden;
        position: absolute;
        top: rem(25px);
        left: 0;
        width: rem(100px);
        height: rem(100px);
    }
    .myInquiryList .head__area .thumb img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }
    .myInquiryList .head__area .thumb:after {
        display: none;
    }
    .myInquiryList .head__area .info {
        min-height: rem(100px);
        padding-left: rem(120px);
    }
    .myInquiryList .head__area .info span {
        display: block;
        line-height: rem(22px);
        font-size: rem(22px);
    }
    .myInquiryList .head__area .info span.item-name {
        margin-top: rem(10px);
        font-size: rem(22px);
        line-height: rem(28px);
    }
    .myInquiryList .cont__area {
        padding: rem(30px 0);
    }
    .myInquiryList .cont__area .txt {
        font-size: rem(22px);
        line-height: 1.5;
        word-break: normal;
        word-wrap: break-word;
    }
    .myInquiryList .cont__area .img {
        display: block;
        margin-top: rem(20px);
        font-size: 0;
    }
    .myInquiryList .cont__area .loaded__file {
        margin-top: rem(50px);
        font-size: rem(22px);
    }
    .myInquiryList .cont__area .loaded__file:before {
        display: inline-block;
        content: '';
        width: rem(28px);
        height: rem(24px);
        margin-right: rem(15px);
        background: url(/front_mo/images/icon_mypage_file.png) no-repeat rem(0 2px);
    }
    .myInquiryList .review__area {
        padding: rem(25px);
        background: #f9f9f9;
        border-top: 1px solid $colorBC;
    }
    .myInquiryList .review__area .head {
        padding-bottom: rem(20px);
        border-bottom: 1px solid $colorBC;
        font-size: rem(22px);
    }
    .myInquiryList .review__area .head .date {
        float: right;
        font-size: rem(22px);
        color: $color88;
    }
    .myInquiryList .review__area .cont {
        padding: rem(20px 0);
        font-size: rem(22px);
        line-height: 1.5;
    }
    .myFaqList .total__list {
        display: block;
        width: 100%;
        padding: rem(20px 0);
        font-size: rem(22px);
    }
    .myFaqList .latter_list {
        border-top: rem(3px) solid $gcolor;
    }
    .myFaqList .latter_list > li {
        border-bottom: 1px solid $colorBC;
    }
    .myFaqList .latter_list li .oder_title {
        padding: rem(30px 0);
        border: none;
    }
    .myFaqList .latter_list li .oder_title:before {
        right: 0;
        bottom: rem(30px);
        top: 50%;
        margin-top: rem(-8.5px);
        opacity: .6;
    }
    .myFaqList .latter_list li .oder_title:after {
        display: none;
    }
    .myFaqList .qna_title_inner {
        position: relative;
    }
    .myFaqList .qna_title_inner .qna_sot {
        display: block;
        font-size: rem(22px);
        color: $color3D;
    }
    .myFaqList .qna_title_inner .qna_title {
        display: block;
        margin-top: 0;
        padding-right: rem(150px);
        font-size: rem(24px);
        color: $color3D;
        line-height: rem(32px);
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
    }
    .myFaqList .qna_title_inner .qna_data {
        display: block;
        margin-top: rem(10px);
        font-size: rem(24px);
        color: $color88;
    }
    .myFaqList .qna_title_inner .qna_reply {
        position: absolute;
        top: 50%;
        right: rem(50px);
        color: $color3D;
        font-weight: 600;
        font-size: rem(22px);
        margin-right: 0;
        transform: translate3d(0,-50%,0);
    }
    .myFaqList .latter_list .oder_title + .agree_box {
        border-top: 1px solid $colorBC;
    }
    .myFaqList .latter_list .on > .oder_title {
        background: #fff;
    }
    .myFaqList .latter_list .on > .oder_title:before {
        background: url(/front_mo/images/icon_arrow_big.png) no-repeat;
        transform: rotate(0);
    }
    .myFaqList .latter_list .qna_text {
        padding: 0;
    }
    .myFaqList .head__area {
        position: relative;
        padding: rem(25px 0);
        border-bottom: 1rem(px) solid $colorBC;
    }
    .myFaqList .head__area .thumb {
        width: rem(100px);
        height: rem(100px);
    }
    .myFaqList .head__area .thumb img {
        display: block;
        width: 100%;
        height: 100%;
    }
    .myFaqList .head__area .info {
        position: absolute;
        top: rem(30px);
        left: rem(120px);
        padding-left: rem(20px);
    }
    .myFaqList .head__area .info span {
        display: block;
        line-height: rem(22px);
        margin-top: rem(10px);
        font-size: rem(22px);
    }
    .myFaqList .head__area .info span.item-name {
        -webkit-line-clamp: 1;
        overflow: hidden;
        -webkit-box-orient: vertical;
        font-size: rem(22px);
        text-overflow: ellipsis;
        display: -webkit-box;
    }
    .myFaqList .cont__area {
        padding: rem(30px 0);
    }
    .myFaqList .cont__area .txt {
        font-size: rem(22px);
        line-height: 1.5;
        word-break: normal;
        word-wrap: break-word;
    }
    .myFaqList .cont__area .img {
        display: block;
        margin-top: rem(20px);
        font-size: 0;
    }
    .myFaqList .cont__area .loaded__file {
        margin-top: rem(50px);
        font-size: rem(22px);
    }
    .myFaqList .cont__area .loaded__file:before {
        display: inline-block;
        content: '';
        width: rem(28px);
        height: rem(24px);
        margin-right: rem(15px);
        background: url(/front_mo/images/icon_mypage_file.png) no-repeat rem(0 2px);
    }
    .myFaqList .review__area {
        padding: rem(25px);
        background: #f9f9f9;
        border-top: 1px solid $colorBC;
    }
    .myFaqList .review__area .head {
        padding-bottom: rem(20px);
        border-bottom: 1px solid $colorBC;
        font-size: rem(22px);
    }
    .myFaqList .review__area .head .date {
        float: right;
        font-size: rem(22px);
        color: $color88;
    }
    .myFaqList .review__area .cont {
        padding: rem(20px 0);
        font-size: rem(22px);
        line-height: 1.5;
        word-break: normal;
        word-wrap: break-word;
    }
    .myQnAList .total__list {
        display: block;
        width: 100%;
        padding: rem(20px 0);
        font-size: rem(22px);
    }
    .myQnAList .latter_list {
        border-top: rem(3px) solid $gcolor;
    }
    .myQnAList .latter_list > li {
        border-bottom: 1px solid $colorBC;
    }
    .myQnAList .latter_list li .oder_title {
        position: relative;
        padding: rem(25px 150px 25px 120px);
        min-height: rem(100px);
        border: 0;
    }
    .myQnAList .latter_list li .oder_title:before {
        right: 0;
        bottom: rem(30px);
        top: 50%;
        margin-top: rem(-8.5px);
        opacity: 0.6;
    }
    .myQnAList .latter_list li .oder_title:after {
        display: none;
    }

    .myQnAList .latter_list .qna__inner .thumb {
        position: absolute;
        top: rem(25px);
        left: 0;
        width: rem(100px);
        height: rem(100px);
    }

    .myQnAList .latter_list .qna__inner .qna__txt .type {
        display: block;
        font-size: rem(22px);
        color: $color3D;
        line-height: rem(32px);
    }

    .myQnAList .latter_list .qna__inner .qna__txt .item_name {
        display: block;
        font-size: rem(22px);
        font-weight: 600;
        line-height: rem(32px);
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
    }

    .myQnAList .latter_list .qna__inner .qna__txt .title {
        display: block;
        margin-top: 0;
        font-size: rem(24px);
        color: $color3D;
        line-height: rem(32px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .myQnAList .latter_list .qna__inner .qna__txt .state {
        position: absolute;
        top: 50%;
        right: rem(50px);
        color: $color3D;
        font-weight: 600;
        font-size: rem(22px);
        margin-right: 0;
        transform: translate3d(0,-50%,0);
    }

    .myQnAList .latter_list .oder_title+.agree_box {
        border-top: 1px solid $colorBC;
    }
    .myQnAList .latter_list .on > .oder_title {
        background: #fff;
    }
    .myQnAList .latter_list .on > .oder_title:before {
        background: url(/front_mo/images/icon_arrow_big.png) no-repeat;
        transform: rotate(0);
    }
    .myQnAList .latter_list .qna_text {
        padding: 0;
    }

    .myQnAList .cont__area {
        padding: rem(25px 0);
    }
    .myQnAList .cont__area .qna_data {
        display: block;
        margin-bottom: rem(10px);
        font-size: rem(24px);
        color: $color88;
    }
    .myQnAList .cont__area .txt {
        font-size: rem(22px);
        line-height: 1.5;
        word-break: normal;
        word-wrap: break-word;
    }
    .myQnAList .cont__area .loaded__file {
        margin-top: rem(50px);
        font-size: rem(22px);
    }
    .myQnAList .cont__area .loaded__file:before {
        display: inline-block;
        content: '';
        width: rem(28px);
        height: rem(24px);
        margin-right: rem(15px);
        background: url(/front_mo/images/icon_mypage_file.png) no-repeat rem(0 2px);
    }
    .myQnAList .review__area {
        padding: rem(25px);
        background: #f9f9f9;
        border-top: 1px solid $colorBC;
    }
    .myQnAList .review__area .head {
        padding-bottom: rem(20px);
        border-bottom: 1px solid $colorBC;
        font-size: rem(22px);
    }
    .myQnAList .review__area .head .date {
        float: right;
        font-size: rem(22px);
        color: $color88;
    }
    .myQnAList .review__area .cont {
        padding: rem(20px 0);
        font-size: rem(22px);
        line-height: 1.5;
    }
    .myPoint .pt_sta_box .section {
        padding: 0;
        margin-top: rem(40px);
    }
    .myPoint .wrap__pt_box {
        display: table;
        width: 100%;
    }
    .myPoint .wrap__pt_box .pt_box {
        display: table-cell;
        width: rem(250px);
        margin: 0;
        border: none;
    }
    .myPoint .wrap__pt_box .pt_box .inner {
        height: rem(218px);
        padding: 0;
    }
    .myPoint .wrap__pt_box .pt_box.hPoint .inner:before {
        display: inline-block;
        width: rem(110px);
        height: rem(110px);
        border: rem(2px) solid $color3D;
        border-radius: 100%;
        background-color: #fff;
        background: url(/front_mo/images/icon_mypage_hPoint.png) no-repeat center center;
        box-sizing: border-box;
        content: '';
    }
    .myPoint .wrap__pt_box .pt_box.spoon .inner {
        border-left: 1px solid $colorBC;
    }
    .myPoint .wrap__pt_box .pt_box.spoon .inner:before {
        display: inline-block;
        width: rem(110px);
        height: rem(110px);
        border-radius: 100%;
        background: $gcolor url(/front_mo/images/icon_mypage_spoon.png) no-repeat center center;
        content: '';
    }
    .myPoint .wrap__pt_box .pt_box .inner .f_gray {
        display: block;
        margin-top: rem(10px);
        font-size: rem(22px);
        line-height: rem(32px);
    }
    .myPoint .wrap__pt_box .pt_box .inner .pt_num {
        display: block;
        font-size: rem(30px);
        line-height: rem(32px);
    }
    .myPoint .wrap__pt_box .pt_box .inner .pt_num strong {
        min-width: auto;
        font-size: rem(30px);
    }
    .myPoint .wrap__pt_box .pt_box .inner .gcolor {
        color: $gcolor;
    }
    .myPoint .hPoint__cont {
        margin: rem(40px 0);
    }
    .myPoint .hPoint__cont .txt {
        font-size: rem(22px);
        line-height: 1.6;
        word-break: keep-all;
    }
    .myPoint .hPoint__cont .txt .tit {
        display: block;
        margin-bottom: rem(10px);
        font-size: rem(24px);
    }
    .myPoint .hPoint__cont .go__site {
        display: block;
        width: 100%;
        height: rem(70px);
        margin-top: rem(40px);
        background: $gcolor;
        color: #fff;
        font-size:rem(22px);
        font-weight: 600;
        line-height: rem(70px);
        text-align: center;
    }
    .myPoint .spoon__cont {
        position: relative;
        margin: rem(40px 0);
    }
    .myPoint .spoon__cont .txt {
        font-size:rem( 22px);
        line-height: 1.6;
        word-break: keep-all;
    }
    .myPoint .spoon__cont .txt .tit {
        display: block;
        margin-bottom: rem(10px);
        font-size: rem(24px);
    }
    .myPoint .spoon__cont .point__conver {
        display: block;
        position: absolute;
        top: rem(20px);
        right: 0;
        width: rem(200px);
        height: rem(55px);
        background: $gcolor;
        color: #fff;
        font-size: rem(22px);
        font-weight: 600;
        line-height: rem(55px);
        text-align: center;
    }
    .myPoint .spoon__cont .list__wrap {
        margin-top: rem(50px);
    }
    .myPoint .spoon__cont .list__wrap .head__area {
        display: table;
        margin: rem(20px 0);
        width: 100%;
    }
    .myPoint .spoon__cont .list__wrap .sort__area {
        display: table-cell;
        width: calc(100% - 10.5rem);
        height: rem(70px);
        vertical-align: middle;
    }
    .myPoint .spoon__cont .list__wrap .sort__area li {
        display: inline-block;
        float: left;
        height: rem(30px);
        font-size: rem(22px);
        line-height: rem(30px);
        padding-right: rem(25px);
    }
    .myPoint .spoon__cont .list__wrap .sort__area li.on {
        color: $gcolor;
        font-weight: 600;
    }
    .myPoint .spoon__cont .list__wrap .sort__area li:not(:first-child) {
        padding-left: rem(25px);
        border-left: 1px solid #7e7e7e;
    }
    .myPoint .spoon__cont .list__wrap .select__area {
        display: table-cell;
        width: rem(210px);
        height: rem(70px);
    }
    .myPoint .spoon__cont .list__wrap .select__area select {
        width: 100%;
        height: rem(70px);
        padding: rem(0 20px);
        border: 1px solid #cdcdcd;
        font-size: rem(22px);
        background: #fff url(/front_mo/images/ico_member_select.png) no-repeat 90% 50%;
    }
    .myPoint .spoon__cont .list__wrap .list__box {
        width: 100%;
        border-top: rem(3px) solid $gcolor;
    }
    .myPoint .spoon__cont .list__wrap .list__box .list {
        position: relative;
        border-bottom: 1px solid #cdcdcd;
        padding: rem(30px 0);
        font-size: rem(22px);
        color: $color3D;
    }
    .myPoint .spoon__cont .list__wrap .list__box .list .tit {
        display: block;
        width: 85%;
        line-height: 1.5;
    }
    .myPoint .spoon__cont .list__wrap .list__box .list .tit strong {
        display: block;
    }
    .myPoint .spoon__cont .list__wrap .list__box .list .date {
        display: block;
        margin-top: rem(10px);
    }
    .myPoint .spoon__cont .list__wrap .list__box .list .point {
        display: block;
        position: absolute;
        top: rem(30px);
        right: 0;
        text-align: right;
    }
    .myPoint .spoon__cont .list__wrap .list__box .list .point strong {
        display: block;
        margin-bottom: rem(10px);
    }
    .hd .anniversaryAdd {
        padding: rem(25px 0);
        border-top: 1px solid $colorBC;
    }
    .hd .anniversaryAdd .anniversary__desc {
        line-height: rem(30px);
        font-size: rem(22px);
    }
    .hd .anniversaryAdd .input-box.date {
        padding-right: rem(215px);
    }
    .hd .anniversaryAdd .input-box.date .date__check {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
    }
    .hd .anniversaryAdd .input-box.date .checkbox-round {
        margin-left: rem(20px);
    }
    .hd .anniversaryList ul li {
        position: relative;
        padding: rem(20px 0);
        border-bottom: 1px solid $colorBC;
    }
    .hd .anniversaryList ul li:first-child {
        border-top: 1px solid $colorBC;
    }
    .hd .anniversaryList ul li .name {
        display: block;
        line-height: rem(38px);
        font-size: rem(22px);
        font-weight: 600;
    }
    .hd .anniversaryList ul li .name .d-day {
        color: $gcolor;
    }
    .hd .anniversaryList ul li .date {
        display: block;
        line-height: rem(38px);
        font-size: rem(22px);
    }
    .hd .anniversaryList ul li .btn-half {
        position: absolute;
        right: 0;
        bottom: rem(20px);
        width: rem(110px);
        height: rem(55px);
        margin-left: rem(10px);
        line-height: rem(55px);
        font-size: rem(22px);
        font-weight: 600;
	}
    .txOrderList .period-check {
        display: block;
        width: rem(580px);
        height: rem(70px);
        margin: rem(40px) auto 0;
        padding: rem(0 25px);
        font-size: rem(22px);
        border: 1px solid $colorBC;
        box-sizing: border-box;
        background: #fff url(/front_mo/images/ico_member_select.png) no-repeat 95% 50%;
    }
    .txOrderList .buy-list {
        width: rem(580px);
        margin: 0 auto;
        border: none;
    }
    .txOrderList .buy-list .list-area {
        border-top: rem(3px) solid $gcolor;
        margin-top: rem(40px);
    }
    .txOrderList .buy-list .buy-info {
        display: block;
        padding: rem(25px 0);
        background: #fff;
        border: none;
        border-bottom: 1px solid $colorBC;
        font-size: rem(22px);
    }
    .txOrderList .buy-list .buy-info .tit {
        display: block;
        color: $color3D;
        margin-bottom: rem(10px);
    }
    .txOrderList .buy-list .buy-info .tit:after {
        display: none;
    }
    .txOrderList .buy-list .buy-info .total-price {
        display: block;
        color: $color3D;
        font-weight: 600;
    }
    .txOrderList .buy-list .buy-info .total-price:before {
        display: none;
    }
    .txOrderList .buy-list .wraper-information {
        padding: rem(25px 0);
        border-bottom: 1px solid #d5d5d5;
    }
    .txOrderList .buy-list .wrap-information {
        position: relative;
        padding: 0;
    }
    .txOrderList .buy-list .information > a {
        font-size: rem(22px);
        color: $color3D;
        font-weight: 600;
    }
    .txOrderList .buy-list .information .price {
        display: block;
        position: static;
        margin-top: rem(10px);
        font-size: rem(22px);
        color: $color3D;
        font-weight: 400;
    }
    .txOrderList .buy-list .information .option {
        display: block;
        position: static;
        padding-top: rem(25px);
        font-size: rem(22px);
        font-weight: 400;
        color: $color3D;
    }
    .txOrderList .buy-list .information .option .option__tit {
        display: block;
        padding-bottom: rem(10px);
    }
    .txOrderList .wraper-information .btn-area {
        padding: 0;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .txOrderList .wraper-information .btn-area a {
        display: inline-block;
        width: rem(194px);
        height: rem(55px);
        line-height: rem(55px);
        color: $color3D;
        font-size: rem(22px);
        text-align: center;
        border: 1px solid $colorBC;
        box-sizing: border-box;
    }
    .txOrderList .wraper-information .btn-area a+a {
        margin-left: rem(13px);
    }
    .hd .amore {
        padding-bottom: rem(150px);
    }

    .hd.amore .member__top-area {
        padding-top: rem(30px);
    }

    .hd.amore .select-wrap.two {
        padding-top: rem(30px);
    }

    .hd.amore .hd__myOrderDetail__sect,
    .hd.amore .hd__applyOrderCancel__sect {
        padding-top: rem(30px);
    }

    .hd.amore .service__center li:first-child {
        display: none;
    }

    .hd.amore .service__center li + li {
        width: 100% !important;
        border-left: 1px solid $colorBC !important;
    }

    .cellphone__number {
        line-height: rem(70px);
    }

    .change-address {

        &__desc {
            margin-top: rem(20px);
            font-size: rem(24px);
        }

        &__list {

            &__inner {
                position: relative;
                
                .title-area {
                    width: calc(100% - 7rem);
                    box-sizing: border-box;
                }
            }
        }

        .btn-change {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 0;
        }
    }

    .popup_chkItem{
        .count_box + .dvCart-desc{
            margin-top: rem(40px);
        }

        .dvCart-price{
            bottom: 0;
        }
    }
}