.hd {
    .checkAddress {
        width: 640px;

        img {
            display: block;
            width: 640px;
        }

        .tab-wrap {
            padding: 0 25px;
            //background: url("/front_mo/images/img_checkAddress_bg.jpg") repeat-y;
            //background-size: 100% auto;
            background: #eaeaea;

            .tab-area-v2 {
                padding-top: 0;
                height: auto;
                overflow: hidden;
                border: 0;

                li {
                    float: left;
                    width: 50%;
                    height: 95px;
                    border: 0;

                    a {
                        display: block;
                        height: 100%;
                        background: url("/front_mo/images/img_checkAddress_tab.jpg?ver=210126");
                        background-size: 200%;
                        text-indent: -999em;

                        &:after {
                            display: none;
                        }
                    }

                    &.n2 a {
                        background-position-x: right;
                    }

                    a:hover,
                    &.tab-list-v2--on a {
                        background-position-y: bottom;
                    }
                }
            }

            .checkAddress_tab {
                display: none;

                &.current {
                    display: block;
                }
            }
        }

        &__sec {
            position: relative;
        }

        // &__btn {
        //     position: absolute;
        //     left: 112px;
        //     top: 126px;
        //     width: 422px;
        //     height: 51px;
        //     text-indent: -999em;
        // }
    }

}