.hd {

    &__applyOrderExchangeConfirm {
        padding-top: 190px;

        .exchange-reason {
            .info-area {
                padding: 20px 20px 0;

                .thumb {
                    width: 100%;
                    margin-bottom: 20px;
                    text-align: left;

                    &:after {
                        display: none;
                    }
                }

                .textbox {
                    font-size: 22px;
                    vertical-align: middle;

                    li {
                       margin-bottom: 10px;
                    }
                }
            }
        }

        .exchange-way, .visit-address {
            .info-area {
                .info-list {
                    display: table;
                    table-layout: fixed;
                    line-height: 1.4;
    
                    &__tit {
                        display: table-cell;
                        width: 210px;
                        @extend %font_xs;
                        vertical-align: top;
                    }
    
                    &__cont {
                        display: table-cell;
                        width: 430px;
                        @extend %font_xs;
                        vertical-align: top        
                    }

                    & + .info-list {
                        padding-top: 20px;
                    }
                }
            }
        }

        .change-info {
            .info-area {

                .info-list {
                    display: table;
                    table-layout: fixed;
                    line-height: 1.4;
    
                    &__tit {
                        display: table-cell;
                        width: 300px;
                        @extend %font_xs;
                        vertical-align: top;
                    }
    
                    &__cont {
                        display: table-cell;
                        width: 430px;
                        @extend %font_xs;
                        vertical-align: top;     
                        font-weight: bold;   
                        text-align: right;
                    }

                    & + .info-list {
                        padding-top: 20px;
                    }
                }
            }
        }

        .annc {
            position: relative;
            margin: -15px 20px 20px;
            padding: 0 20px;
            @extend %font_xs;
            color: $gcolor;
            line-height: 1.4;
            word-break: keep-all;

            &:before {
                display: inline-block;
                position: absolute;
                top: 5px;
                left: 0;
                content: '*';
            }
        }

        .refund-list {
            padding-bottom: 25px;
        }

        .mt0 {
            margin-top: 0;
        }

        &__desc {
            li {
                position: relative;
                padding-left: 15px;
                color: #888;
                @extend %font_xs;
                line-height: 1.6;

                &:before {
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    content: '-';
                }
            }
        }

        .btn-area {
            margin-top: 40px;

            button {
                font-weight: bold;
            }
        }
    }
}