.hd {

    &__eventList {
        
        .event-banner {
            width: 100%;
            height: 200px;
            display: none;

            .swiper-pagination {
                position: absolute;
                bottom: 24px;
                left: 32px;
                z-index: 1;
            }
        }

        &__container {
            width: 580px;
            margin: 40px auto 0;
        }

        .event-cont {

            .event-list {
                position: relative;
                margin-top: 40px;

                &__thumb {
                    overflow: hidden;
                    width: 100%;
                    height: auto;
                    max-height: 310px;
                }

                .textbox {
                    padding-top: 20px;

                    &__tit {
                        display: block;
                        @extend %font_m;
                        @include line(2);
                        line-height: 38px;
                    }

                    &__period {
                        padding-top: 15px;
                        @extend %font_xs;

                        .noti {
                            position: relative;
                            margin-left: 15px;
                            padding-left: 15px;
                            color: $ocolor;
                            font-weight: bold;

                            &:before {
                                display: inline-block;
                                position: absolute;
                                top: 5px;
                                left: 0;
                                width: 0;
                                height: 22px;
                                border: 1px solid #575757;
                                content: '';
                            }
                        }
                    }
                }

                .winnerView {
                    margin-top: 15px;
                    text-align: right;
                }

                .btn-check-winner {
                    display: inline-block;
                    position: relative;
                    padding-right: 20px;
                    background: url(/front_mo/images/ico_arrow_left.png) no-repeat 98% 50%;
                    line-height: 26px;
                    font-size: 22px;

                    &:before {
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        z-index: -1;
                        width: 100%;
                        height: 8px;
                        background: $ocolor;
                        content: '';
                    }
                }
            }
        }
    }
}