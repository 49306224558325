.hd {
    &__login {
        padding-top: 190px;

        .member-login {
            border-bottom: 1px solid#bebebe;

            .tab-area {
                margin-bottom: 45px;
            }

            .checkbox-area {
                margin: 24px 0;
            }

            .login_link_box {
                margin: 34px 0; 

                li {
                    display: inline-block;
                    position: relative;
                    padding-left: 60px;
                    font-size: 20px;

                    &:not(:first-child) {
                        &:before {
                            display: inline-block;
                            width: 1px;
                            height: 22px;
                            border-left: 1px solid #bebebe;
                            content: '';
                            position: absolute;
                            top: 3px;
                            left: 30px;
                        }
                    }
                }
            }

        }

        .nonmember-login {
            margin-top: 45px;
            button {
                font-weight: bold;
            }
        }
    }

    &__orderLogin {

        .order-login {
            .btn-area {
                margin-top: 20px;

                button {
                    font-weight: bold;
                }
            }

            &__desc {
                position: relative;
                margin-top: 25px;
                padding-left: 38px;
                @extend %font_xs;
                line-height: 32px;

                strong {
                    color: $gcolor;
                }

                &:before {
                    display: inline-block;
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width:30px;
                    height: 30px;
                    background: url(/front_mo/images/icon_comm_notice.png) no-repeat 0 0;
                    content: '';
                }
            }
        }

        .join-btn{
            margin-top: 45px;

            button {
                font-weight: bold;
            }
        }
    }

    &__pwChange{
        padding-top: 190px; 
        
        &__h3{
            font-weight: bold;
            text-align: center;
            @extend %font_m;
        }

        &__annc{
            margin-top: 10px;
            @extend %font_xs;
            text-align: center;
            line-height: 32px;

            strong {
                color: $gcolor;
            }
        }

        &__annc2 {
            margin-top: 20px;
            margin-bottom: 45px;
        }

        &__desc {
            position: relative;
            margin-top: 25px;
            padding-left: 38px;
            @extend %font_xs;
            line-height: 32px;

            &:before {
                display: inline-block;
                position: absolute;
                top: 2px;
                left: 0;
                width: 30px;
                height: 30px;
                background: url(/front_mo/images/icon_comm_notice.png) no-repeat 0 0;
                content: '';
            }
        }

        .btn-area {
            margin-top: 50px;

            button {
                font-weight: bold;
            }
        }
    }

    &__inactiveMember {
        &__h3 {
            text-align: center;
            font-weight: bold;
            @extend %font_m;

            strong{
                color: $gcolor;
            }

            &:before {
                display: block;
                width: 80px;
                height: 80px;
                margin: 40px auto 30px;
                background: url(/front_mo/images/icon_important-msg.png) no-repeat center center;
                text-align: center;
                content: '';
            }
        }

        &__annc {
            font-size: 20px;
            line-height: 32px;
        }

        .btn-area {
            margin-top: 60px;

            button {
                font-weight: bold;
            }
        }
    }

    .inactiveMemberAccount {
        padding-top: 190px; 

        &__h3{
            text-align: center;
            font-weight: bold;
            @extend %font_m;
        }

        .form_title {
            padding-top: 35px;
            margin-top: 35px;
            border-top: 1px solid #bcbcbc;
        }

        .send-btn {
            margin-top: 20px;
            font-weight: bold;
        }

        .certi-area {
            margin-top: 40px;

            &__tit {
                display: block;
                padding-bottom: 20px;
                font-weight: bold;
                @extend %font_s;
            }

            .input-area {
                position: relative;

                .input_text {
                    background: transparent;
                }

                .timer {
                    display: block;
                    position: absolute;
                    right: 25px;
                    top: 50%;
                    z-index: -1;
                    font-size: 24px;
                    transform: translateY(-50%);
                }
            }

            .btn-confirm {
                margin-top: 20px;
            }

            .certi-fail, .certi-success {
                border-bottom: 1px solid #bcbcbc;
                padding: 40px 0;
                @extend %font_s;
                text-align: center;

                strong {
                    display: block;
                    font-weight: bold;
                }
            }

            .certi-success {
                line-height: 42px;

                strong {
                    color: $ocolor;
                }

            }
        }

    }

    .inactiveMemberComplete {
        &__tit {
            display: block;
            margin-bottom: 15px;
            color: #3d3d3d;
            font-weight: bold;
            text-align: center;
            line-height: 40px;
            @extend %font_m;

            strong{
                color: $gcolor;
            }

            &:before {
                display: block;
                width: 80px;
                height: 80px;
                margin: 40px auto 35px;
                background: url(/front_mo/images/icon_comm_smile.png) no-repeat center center;
                text-align: center;
                content: '';
            }
        }

        &__desc {
            @extend %font_xs;
            text-align: center;
            line-height: 32px;
        }
    }

    &__idFind {
        padding-top: 190px;

        .tab-area {
            margin-bottom: 45px;
        }

        .desc {
            margin-top: 35px;

            li {
                position: relative;
                padding-left: 16px;
                @extend %font_xs;
                line-height: 32px;
                word-break: keep-all;

                &:before {
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '-';
                }

                strong {
                    color: $gcolor;
                }
            }

        }

        .btn-area {
            margin-top:20px;
        }
    }

    &__idFindResult {
        padding-top: 190px;

        &__h3{
            display: block;
            margin-bottom: 45px;
            @extend %font_m;
            font-weight: bold;
            text-align: center;

            &:before {
                display: block;
                width: 80px;
                height: 80px;
                margin: 50px auto 35px;
                background: url(/front_mo/images/icon_check.png) no-repeat center center;
                text-align: center;
                content: '';
            }
        }

        .info-area{
            padding: 40px 0;
            border-top: 1px solid #bcbcbc;
            border-bottom: 1px solid #bcbcbc;
            @extend %font_xs;
            text-align: center;

            &__id {
                display: block;
                margin-bottom: 20px;
                @extend %font_l;

                .underline {
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        z-index: -1;
                        left: 0;
                        bottom: 0px;
                        width: 100%;
                        height: 8px;
                        background: #FEA45E;
                    }
                }

            }

            &__desc {
                display: block;
                margin-top: 20px;
            }

            .link-area {
                margin-top: 20px;
                @extend %font_xs;
                text-align: center;
    
                button {
                    width: 280px;
                }
    
                button + button {
                    margin-left: 20px;
                }
                
                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }

            }
        }
        
        .send-area {
            margin-top: 20px;
            @extend %font_xs;
            text-align: center;

            &__desc {
                display: block;
                @extend %font_xs;
                text-align: center;
            }

            .btn {
                margin-top: 20px;

                button {
                    width: 280px;
                }
    
                button + button {
                    margin-left: 20px;
                }
                
                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }

        }

        .btn-area {
            
            .btn {
                margin-top: 20px;

                button {
                    width: 280px;
                }
    
                button + button {
                    margin-left: 20px;
                }
                
                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }
        }

    }

    &__idFindNoResult {
        padding-top: 190px;

        &__tit {
            @extend %font_m;
            font-weight: bold;
            text-align: center;

            &:before {
                display: block;
                width: 80px;
                height: 80px;
                margin: 50px auto 30px;
                background: url(/front_mo/images/icon_list-no.png) no-repeat center center;
                background-size: 100%;
                text-align: center;
                content: '';
            }
        }

        &__desc {
            margin-top: 30px;
            @extend %font_xs;
            text-align: center;
            line-height: 32px;
        }

        button {
            font-weight: bold;
        }

    }

    &__pwFind {
        padding-top: 190px;

        &__h3 {
            display: block;
            margin: 45px 0 15px;
            @extend %font_m;
            font-weight: bold;
            text-align: center;
        }

        &__annc {
            @extend %font_xs;
            text-align: center;
            margin-bottom: 45px;
            line-height: 32px;
        }

        .desc {
            margin-top: 35px;

            li {
                position: relative;
                padding-left: 16px;
                @extend %font_xs;
                line-height: 32px;
                word-break: keep-all;

                &:before {
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '-';
                }

                strong {
                    color: $gcolor;
                }
            }

        }

        .input-area {
            margin-top: 45px;

            .ipnut_box + .ipnut_box {
                margin-top: 20px;
            }

            .input-box {
                position: relative;

                .input-alertMsg {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 25px;
                    height: 68px;
                    @extend %font_s;
                    color: $ocolor;
                    line-height: 70px;
                }
                
                &__desc {
                    margin-top: 10px;
                    color: $color88;
                    font-size: 24px;
                    line-height: 32px;
                }

                .colorOR {
                    color: $gcolor;
                }
            }
        }

        .birth-info {
            color: $color88;
            font-size: 24px;

            .year{
                width: 205px;
                margin-right: 10px;
            }

            .month, .day {
                width: 113px;
                margin: 0 10px;
            }
        }

        .btn-area{
            margin-top: 20px;

            button {
                width: 280px;
            }

            button + button {
                margin-left: 20px;
            }

            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }

        &__link {
            border-bottom: 1px solid green;
            color: green;
        }

    }

    .pwFindAccount {
        padding-top: 190px;

        .form_title {
            padding-top: 35px;
            margin-top: 35px;
            border-top: 1px solid #bcbcbc;
        }

        .send-btn {
            margin-top: 20px;
            font-weight: bold;
        }

        .certi-area {
            margin-top: 40px;

            &__tit {
                display: block;
                padding-bottom: 20px;
                font-weight: bold;
                @extend %font_s;
            }

            .input-area {
                position: relative;
                margin-top: 0;

                .input_text {
                    background: transparent;
                }

                .timer {
                    display: block;
                    position: absolute;
                    right: 25px;
                    top: 50%;
                    z-index: -1;
                    font-size: 24px;
                    transform: translateY(-50%);
                }
            }

            .btn-confirm {
                margin-top: 20px;
            }

            .certi-fail, .certi-success {
                border-bottom: 1px solid #bcbcbc;
                padding: 40px 0;
                @extend %font_s;
                text-align: center;

                strong {
                    display: block;
                    font-weight: bold;
                }
            }

            .certi-success {
                line-height: 42px;

                strong {
                    color: $ocolor;
                }

            }
        }
    }

    .pwNewSetting {
        &__desc {
            position: relative;
            margin-top: 25px;
            padding-left: 38px;
            @extend %font_xs;
            line-height: 32px;

            &:before{
                display: inline-block;
                position: absolute;
                top: 2px;
                left: 0;
                width: 30px;
                height: 30px;
                background: url(/front_mo/images/icon_comm_notice.png) no-repeat 0 0;
                content: '';
            }
        }

        .btn-area {
            margin-top: 35px;
        }
        
    }

    .loginSelect {
        width: 168px !important;
        border: 1px solid #BEBEBE;
    }

    .memberTxt {
        margin-top: 10px;
        font-size: 22px;
        line-height: 30px;
        padding-left: 15px;
        text-indent: -15px;
        word-break: keep-all;
    }
}

.wdw-type {
    .hd {
        &__login {
            padding-top: rem(190px);
    
            .member-login {
                border-bottom: rem(1px) solid#bebebe;
    
                .tab-area {
                    margin-bottom: rem(45px);
                }
    
                .checkbox-area {
                    margin: rem(24px 0);
                }
    
                .login_link_box {
                    margin: rem(34px 0); 
    
                    li {
                        display: inline-block;
                        position: relative;
                        padding-left: rem(60px);
                        font-size: rem(20px);
    
                        &:not(:first-child) {
                            &:before {
                                display: inline-block;
                                width:rem(1px);
                                height: rem(22px);
                                border-left: rem(1px) solid #bebebe;
                                content: '';
                                position: absolute;
                                top: rem(3px);
                                left: rem(30px);
                            }
                        }
                    }
                }
    
            }
    
            .sns-login {
                padding-top: rem(33px);
                text-align: center;
    
                &__tit {
                    margin-bottom: rem(26px);
                    font-weight: bold;
                    @extend %font_s;
                }
    
                a {
                    display: inline-block;
                    position: relative;
                    width: rem(100px);
                    height: rem(100px);
                    line-height: rem(70px);
                    text-align: center;
                    border-radius: rem(100px);
                    @extend %font_s;
    
                    & + a {
                        margin-left: rem(42px);
                    }
    
                    span {
                        display: inline-block;
                        padding-top: rem(115px);    
                        line-height: rem(32px);
                    }
                }
    
                $list : naver, kakao, google;
                @for $i from 1 through length($list) {
                  &-#{nth($list, $i)}:before {
                    display: block;
                    position: absolute;
                    top: rem(14px);
                    left: rem(20px);
                    width: rem(40px);
                    height: rem(70px);
                    margin-left: rem(12px);
                    background: url(/front_mo/images/icon_sns-login.png) no-repeat -#{($i - 1) * 2.3}rem;  
                    content: '';
                  }
                }
                
                &-kakao {
                    &:before {
                        width: rem(42px);
                        margin-left: rem(10px);
                        background-position-x: rem(-44px);
                    }
                }
    
                &-naver {
                    background: #3bab36;
                    border:rem(1px) solid #c7c7c7;
                    box-sizing: border-box;
    
                    a {
                        color: #fff;
                    }
                }
    
                &-kakao {
                    background: #fae100;
                }
    
                &-google {
                    background: #f0f0f0;
				}
            }
    
            .nonmember-login {
                margin-top: rem(45px);
                button {
                    font-weight: bold;
                }
            }
		}
    
        &__orderLogin {
    
            .order-login {
                .btn-area {
                    margin-top:rem(20px);
    
                    button {
                        font-weight: bold;
                    }
                }
    
                &__desc {
                    position: relative;
                    margin-top: rem(25px);
                    padding-left: rem(38px);
                    @extend %font_xs;
                    line-height: rem(32px);
    
                    strong {
                        color: $gcolor;
                    }
    
                    &:before {
                        display: inline-block;
                        position: absolute;
                        top: rem(2px);
                        left: 0;
                        width:rem(30px);
                        height: rem(30px);
                        background: url(/front_mo/images/icon_comm_notice.png) no-repeat 0 0;
                        content: '';
                    }
                }
            }
    
            .join-btn{
                margin-top: rem(45px);
    
                button {
                    font-weight: bold;
                }
            }
        }
    
        &__pwChange{
            padding-top: rem(190px); 
            
            &__h3{
                font-weight: bold;
                text-align: center;
                @extend %font_m;
            }
    
            &__annc{
                margin-top: rem(10px);
                @extend %font_xs;
                text-align: center;
                line-height: rem(32px);
    
                strong {
                    color: $gcolor;
                }
            }
    
            &__annc2 {
                margin-top: rem(20px);
                margin-bottom: rem(45px);
            }
    
            &__desc {
                position: relative;
                margin-top: rem(25px);
                padding-left: rem(38px);
                @extend %font_xs;
                line-height: rem(32px);
    
                &:before {
                    display: inline-block;
                    position: absolute;
                    top: rem(2px);
                    left: 0;
                    width: rem(30px);
                    height: rem(30px);
                    background: url(/front_mo/images/icon_comm_notice.png) no-repeat 0 0;
                    content: '';
                }
            }
    
            .btn-area {
                margin-top: rem(50px);
    
                button {
                    font-weight: bold;
                }
            }
        }
    
        &__inactiveMember {
            &__h3 {
                text-align: center;
                font-weight: bold;
                @extend %font_m;
    
                strong{
                    color: $gcolor;
                }
    
                &:before {
                    display: block;
                    width: rem(80px);
                    height: rem(80px);
                    margin: rem(40px auto 30px);
                    background: url(/front_mo/images/icon_important-msg.png) no-repeat center center;
                    text-align: center;
                    content: '';
                }
            }
    
            &__annc {
                font-size: rem(20px);
                line-height: rem(32px);
            }
    
            .btn-area {
                margin-top: rem(60px);
    
                button {
                    font-weight: bold;
                }
            }
        }
    
        .inactiveMemberAccount {
            padding-top: rem(190px); 
    
            &__h3{
                text-align: center;
                font-weight: bold;
                @extend %font_m;
            }
    
            .form_title {
                padding-top: rem(35px);
                margin-top: rem(35px);
                border-top: rem(1px) solid #bcbcbc;
            }
    
            .send-btn {
                margin-top: rem(20px);
                font-weight: bold;
            }
    
            .certi-area {
                margin-top: rem(40px);
    
                &__tit {
                    display: block;
                    padding-bottom: rem(20px);
                    font-weight: bold;
                    @extend %font_s;
                }
    
                .input-area {
                    position: relative;
    
                    .input_text {
                        background: transparent;
                    }
    
                    .timer {
                        display: block;
                        position: absolute;
                        right:rem(25px);
                        top: 50%;
                        z-index: -1;
                        font-size: rem(24px);
                        transform: translateY(-50%);
                    }
                }
    
                .btn-confirm {
                    margin-top: rem(20px);
                }
    
                .certi-fail, .certi-success {
                    border-bottom: rem(1px) solid #bcbcbc;
                    padding: rem(40px 0);
                    @extend %font_s;
                    text-align: center;
    
                    strong {
                        display: block;
                        font-weight: bold;
                    }
                }
    
                .certi-success {
                    line-height: rem(42px);
    
                    strong {
                        color: $ocolor;
                    }
    
                }
            }
    
        }
    
        .inactiveMemberComplete {
            &__tit {
                display: block;
                margin-bottom: rem(15px);
                color: #3d3d3d;
                font-weight: bold;
                text-align: center;
                line-height:rem( 40px);
                @extend %font_m;
    
                strong{
                    color: $gcolor;
                }
    
                &:before {
                    display: block;
                    width: rem(80px);
                    height: rem(80px);
                    margin: rem(40px auto 35px);
                    background: url(/front_mo/images/icon_comm_smile.png) no-repeat center center;
                    text-align: center;
                    content: '';
                }
            }
    
            &__desc {
                @extend %font_xs;
                text-align: center;
                line-height: rem(32px);
            }
        }
    
        &__idFind {
            padding-top: rem(190px);
    
            .tab-area {
                margin-bottom: rem(45px);
            }
    
            .desc {
                margin-top: rem(35px);
    
                li {
                    position: relative;
                    padding-left: rem(16px);
                    @extend %font_xs;
                    line-height: rem(32px);
                    word-break: keep-all;
    
                    &:before {
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '-';
                    }
    
                    strong {
                        color: $gcolor;
                    }
                }
    
            }
    
            .btn-area {
                margin-top:rem(20px);

                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }
        }
    
        &__idFindResult {
            padding-top: rem(190px);
    
            &__h3{
                display: block;
                margin-bottom: rem(45px);
                @extend %font_m;
                font-weight: bold;
                text-align: center;
    
                &:before {
                    display: block;
                    width: rem(80px);
                    height: rem(80px);
                    margin: rem(50px auto 35px);
                    background: url(/front_mo/images/icon_check.png) no-repeat center center;
                    text-align: center;
                    content: '';
                }
            }
    
            .info-area{
                padding: rem(40px 0);
                border-top: rem(1px) solid #bcbcbc;
                border-bottom: rem(1px) solid #bcbcbc;
                @extend %font_xs;
                text-align: center;
    
                &__id {
                    display: block;
                    margin-bottom: rem(20px);
                    @extend %font_l;
    
                    .underline {
                        position: relative;
    
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            z-index: -1;
                            left: 0;
                            bottom: rem(0px);
                            width: 100%;
                            height: rem(8px);
                            background: #FEA45E;
                        }
                    }
    
                }
    
            }

            .btn-area {
                
                .btn {
                    margin-top: 20px;

                    button {
                        width: 280px;
                    }
        
                    button + button {
                        margin-left: 20px;
                    }
                    
                    &:after {
                        content: '';
                        display: table;
                        clear: both;
                    }
                }
            }
        }
    
        &__idFindNoResult {
            padding-top: rem(190px);
    
            &__tit {
                @extend %font_m;
                font-weight: bold;
                text-align: center;
    
                &:before {
                    display: block;
                    width: rem(80px);
                    height: rem(80px);
                    margin: rem(50px auto 30px);
                    background: url(/front_mo/images/icon_list-no.png) no-repeat center center;
                    background-size: 100%;
                    text-align: center;
                    content: '';
                }
            }
    
            &__desc {
                margin-top: rem(30px);
                @extend %font_xs;
                text-align: center;
                line-height: rem(32px);
            }
    
            button {
                font-weight: bold;
            }
    
        }
    
        &__pwFind {
            padding-top: rem(190px);
    
            &__h3 {
                display: block;
                margin: rem(45px 0 15px);
                @extend %font_m;
                font-weight: bold;
                text-align: center;
            }
    
            &__annc {
                @extend %font_xs;
                text-align: center;
                margin-bottom: rem(45px);
                line-height: rem(32px);
            }
    
            .desc {
                margin-top: rem(35px);
    
                li {
                    position: relative;
                    padding-left: rem(16px);
                    @extend %font_xs;
                    line-height: rem(32px);
                    word-break: keep-all;
                    color: $color88;
    
                    &:before {
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '-';
                    }
    
                    strong {
                        color: $gcolor;
                    }
                }
    
            }
    
            .input-area {
                margin-top: rem(45px);
    
                .ipnut_box + .ipnut_box {
                    margin-top: rem(20px);
                }
    
                .input-box {
                    position: relative;
    
                    .input-alertMsg {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: rem(25px);
                        height: rem(68px);
                        @extend %font_s;
                        color: $ocolor;
                        line-height: rem(70px);
                    }

                    &__desc {
                        margin-top: rem(10px);
                        color: $color88;
                        font-size: rem(24px);
                        line-height: rem(32px);
                    }
                    
                    .colorOR {
                        color: $gcolor;
                    }
                }
            }
    
            .birth-info {
                color: $color88;
                font-size: rem(24px);
    
                .year{
                    width: rem(205px);
                    margin-right: rem(10px);
                }
    
                .month, .day {
                    width: rem(113px);
                    margin: rem(0 10px);
                }
            }
    
            .btn-area{
                margin-top: rem(20px);

                button {
                    width: 280px;
                }
    
                button + button {
                    margin-top: rem(20px);
                }
                
                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }

            &__link {
                border-bottom: 1px solid blue;
                color: blue;
            }
    
        }
    
        .pwFindAccount {
            padding-top: rem(190px);
    
            .form_title {
                padding-top: rem(35px);
                margin-top: rem(35px);
                border-top: rem(1px) solid #bcbcbc;
            }
    
            .send-btn {
                margin-top: rem(20px);
                font-weight: bold;
            }
    
            .certi-area {
                margin-top: rem(40px);
    
                &__tit {
                    display: block;
                    padding-bottom: rem(20px);
                    font-weight: bold;
                    @extend %font_s;
                }
    
                .input-area {
                    position: relative;
                    margin-top: 0;
    
                    .input_text {
                        background: transparent;
                    }
    
                    .timer {
                        display: block;
                        position: absolute;
                        right: rem(25px);
                        top: 50%;
                        z-index: -1;
                        font-size: rem(24px);
                        transform: translateY(-50%);
                    }
                }
    
                .btn-confirm {
                    margin-top: rem(20px);
                }
    
                .certi-fail, .certi-success {
                    border-bottom: rem(41px) solid #bcbcbc;
                    padding: rem(40px 0);
                    @extend %font_s;
                    text-align: center;
    
                    strong {
                        display: block;
                        font-weight: bold;
                    }
                }
    
                .certi-success {
                    line-height: rem(42px);
    
                    strong {
                        color: $ocolor;
                    }
    
                }
            }
        }
    
        .pwNewSetting {
            &__desc {
                position: relative;
                margin-top: rem(25px);
                padding-left: rem(38px);
                @extend %font_xs;
                line-height: rem(32px);
    
                &:before{
                    display: inline-block;
                    position: absolute;
                    top: rem(2px);
                    left: 0;
                    width: rem(30px);
                    height: rem(30px);
                    background: url(/front_mo/images/icon_comm_notice.png) no-repeat 0 0;
                    content: '';
                }
            }
    
            .btn-area {
                margin-top: rem(35px);
            }
            
        }
    
        .loginSelect {
            width: rem(155px) !important;
        }
    
        .memberTxt {
            margin-top: rem(10px);
            font-size: rem(22px);
            line-height: rem(30px);
            padding-left: rem(15px);
            text-indent: rem(-15px);
            word-break: keep-all;
        }
    }
}