.hd {

    &__applyOrderExchangeDone {
        padding-top: 150px;

        .annc-area {
            margin: 50px 0;

            &__title {
                display: block;
                @extend %font_l;
                font-weight: bold;
                text-align: center;
                line-height: 1.4;
            }
    
            &__subtit {
                display: block;
                margin-top: 20px;
                font-size: 20px;
                text-align: center;
                line-height: 1.6;
            }
        }

        .btn-area {
            margin-top: 40px;

            a {
                font-weight: bold;
                text-align: center;
            }
        }
    }
}