.hd{
	.name_ellipsis{
		display: inline-block;
        max-width: 161px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow:ellipsis;
        vertical-align: top;
    }

	.giftRecipient{
		padding-bottom:100px;
		
		.btn-full{
			width: 100%;
			max-width: 100%;
		}

		&_inner{
			margin: 30px;
		    line-height: 1.35;
			text-align: center;
		}

		&_title{
			margin: rem(60px 0 40px);
			@extend %font_s;
			text-align: center;

			p{
				@extend %font_l;
				font-weight: 700;
			}
			span{
				font-size: rem(20px);
			}
		}

		&_info{
			margin-bottom: rem(30px);
			padding: rem(10px);
			box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.3);
			overflow: hidden;

			.message{
				position: relative;
				display: inline-block;
				margin: rem(50px auto 30px);
				font-size: rem(28px);
				font-weight: 700;

				&:after{
					position: absolute;
					left: 0;
					bottom: -2px;
					width: 100%;
					height: 8px;
					background: $ocolor;
					content: '';
					z-index: -1;
				}
			}
			
			.text{
				margin-bottom: 20px;
				color: #707070;

				.point_color{
					display: inline-block;
					margin-left: 10px;
				}
			}
			
			.thumb{
				margin:0 auto 20px;
				width: 100%;
				max-width: rem(380px);

				img{width: 100%;}
			}

			select{
				background: url(/front_mo/images/ico_member_select.png) no-repeat right rem(20px) top 50%;
				background-size: rem(30px);
			}

			.accordion{
				&_title{
					position: relative;
					padding: rem(0 50px 0 20px);
					height: rem(65px) !important;
        			line-height: rem(65px);
        			@include line(1);
        			border-bottom: 1px solid $colorBC;
					
					&:before {
					    content: "";
					    display: block;
					    position: absolute;
					    top: 50%;
					    right: rem(15px);
						opacity: 0.6;
					    width: 1.5rem;
					    height: 0.8rem;
					    margin-top: -0.4rem;
					    background: url(/front_mo/images/icon_common_drop-down.png) no-repeat;
					    background-size: 100%;
					}
				}

				&_con{
					display: none;
					padding: rem(10px 20px) !important;

					ul li{
						border-top: 1px solid $colorBC;

						&:first-child{
							border-top: 0;
						}

						a{
							display: block;
							padding: 15px 0;
							@include line(1);
						}
					}
				}

				&_box{
					margin: 0;
					text-align: left;
					border: 1px solid $colorBC;
					background-image: none;
					box-shadow: none;
					font-size: rem(25px) !important;

					&.on{
						.accordion_title:before{
						    transform: rotate(-180deg);
						}
					}
				}
			}


			.btn-arr{
				padding: rem(0 50px 0 20px);
				font-size: rem(25px) !important;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				border-color: $colorBC;

				&:after{
					right: rem(15px);
					opacity: 0.6;
				}
			}

			#itemName{
				display: block;
				margin-bottom: 20px;
				font-size: rem(25px);
			}
		}

		&_guide{
			@extend %font_xs;

			.point_color{
				margin: rem(25px 0);
				display: block;
				font-size: rem(28px);
			}

			.box{
				padding: 20px;
				background: $colorBG;
			}

			.guide_text{
				margin: 5px 0 10px;
				padding-top: 0;
				text-align: left;

				li{
		    		margin: 0 0 0 14px;
		    		padding-top: 5px;
		    		line-height: 32px;
		    		font-size: 20px;
		    		color: $color88;
					text-indent: -10px;

					em{
						color: $gcolor;
					}
				}
			}
		}

		&_gift{
			.gift_title{
				position: relative;
				margin-bottom: 20px;
				font-size: rem(22px);
				font-weight: 700;

				span{
					position: absolute;
					right: 0;
					top: 5px;
					font-size: rem(18px);
					font-weight: 100;
					color: #707070;
				}
			}

			.gift_item{
				padding: 10px;
				background: $colorBG;

				.gift_box{
					display: table;
					width: 100%;
					margin-bottom: 10px;
					padding-bottom: 10px;
					border-bottom: 1px solid #ddd;

					&:last-child{
						margin-bottom: 0;
						padding-bottom: 0;
						border-bottom: 0;
					}
				}
				.checks{
					display: table-cell;
					width: 10%;
				}
				.gift_thumb{
					display: table-cell;
					width: 80px;

					&:after{display: none !important;}
					
				}
				.gift_name{
					display: table-cell;
					text-indent: 20px;
				}
			}
		}
	}

	.giftSelect{
		position: relative;
		margin-top: rem(25px);
		padding: rem(20px);
		line-height: 1.3;
		font-size: rem(22px);
		background: rgba(100,100,100,0.05);

		.btn-round{
			position: absolute;
			right: 0;
			top: rem(-59px);
			padding: rem(8px 10px);
		    font-size: rem(20px);
		}

		&_list{
			margin-top: -80px;

			.dvCart{
				border: 0;
				border-bottom: 1px solid $colorBC;

				.checks:before{display: none;}
				.product--price{
					padding-top: 0;
					border: 0;
				}

				&.single{
					padding-left: 0;
				}
			}

			+ .hd__btn-sec{
				.btn__default + .btn__default.next{
					border-left: 0;
					background: $colorBC;
			        border: 1px solid $colorBC;
			        color: #FFF;
				}
			}
		}
	}

	.giftMulti{
		position: relative;
		margin: rem(50px 30px);
		font-size: rem(24px);
		color: #707070;

		&_select{
			position: absolute;
			right: 0;
			top: -10px;

			select{
				width: rem(100px);
				height: rem(47px);
				padding: rem(0 15px);
				font-size: rem(22px) !important;
		        border-radius: 23px;   
		        background: #fff; 
			}

			&:after{
        	    position: absolute;
			    content: "";
			    top: rem(20px);
			    right: rem(20px);
			    width: 0;
			    height: 0;
			    border: rem(6px) solid transparent;
			    border-color: #000 transparent transparent transparent;
	        }
		}
		
		&_text{
		    margin: rem(20px 0 -20px);
		    line-height: 1.5;
			font-size: 0.9rem;
			color: $gcolor;
		    padding-left: 1.25rem;
    		text-indent: -1.25rem;

    		strong{
    			display: block;
			    font-size: 1.1rem;
    		}
		}

		.discount-tooltip{
			top: -120%;
			right: 0;
    		transform: none;

    		&:after{
    			right: rem(40px);
			    margin-top: 0;
    			transform: rotate(45deg) translateX(0);
    		}
		}
	}

	.giftOrder{
		.hd__plan__order-list li{
			font-size: rem(20px);
		}

		&__img{
			position: relative;
			margin: 0 auto;
			max-width: 640px;
		}

		&__btn{
			position: absolute;
			left: 62%;
			top: 28%;
			width: 25%;
			height: 10%;
		}
	}

	.giftProduct{
		&_list{
			margin-top: rem(20px);
			padding: rem(5px 20px 20px);
			background: rgba(100,100,100,0.05);

			.delivery-fee{
				border: 0;
				border-bottom: 1px solid $colorBC !important;
			}

			.normal-item .item-wrap{
				border-top: 0;

			}
		}

		.planMeals-item{
			padding-bottom: 0;
			font-size: rem(22px);

			.item-wrap{
				padding: rem(20px 0 0);
				border: 0;
			}
		}

		.btn-full{width: 100%;}

	}

	.orderPayment_h2{
		.point_color{
			display: inline-block;
			margin-left: rem(10px);
			font-size: rem(22px);
			font-weight: 100;
			color: $gcolor;

			.name_ellipsis{
				max-width: rem(100px);
				@extend %font_xs;
				color: $gcolor;
			}
		}

		& + .checks_box{
		    margin: rem(-59px 30px 0 0);
		    float: right;
		    transform: scale(0.9);

		    &:after{
		    	overflow:hidden;
		    	clear:both;
		    }

		    .ico_tooltip{
		    	margin-top: rem(2px);
		    }
		}
	}

	.form_title{ 
		.point_color{
			float: right;
			font-size: rem(20px);
			font-weight: 100;
			color: $gcolor;
		}
	}

	.hd__plan__order{
		&-text{
			margin: 15px 0 20px;
			font-size: 21px;
			text-align: center;

			.gift{
				color: $gcolor;
			}
		}
		&-list.gift{
			li{
				&.on.gift{
					position: relative;

					&:before{
						background: #fff;
						color: $gcolor;
						border-color: $gcolor;
					}
					
					.gift_txt{
						position: absolute;
						left: -50px;
						top: -15px;
						line-height: 1.65;
						font-size: 18px;
						color: $gcolor;
						text-align: center;
					}
				}
			}

			&:before{
				width: 160px;
				transform: translate(-100%, 0);
			}

			&:after{
				position: absolute;
				top: 15px;
				right: 50%;
				width: 160px;
				height: 1px;
                background: $gcolor;
                content: '';
                transform: translate3d(100%, 0, 0);
                z-index: -1;
			}
		}
	}

	.btn-yellow{
		color: #333;
		background: #fae100;
		border-color:#fae100;/* ffc000 */
	}

	.accordion_box.more{
		margin-top: 10px;
		border: 1px solid $colorBC;

		.accordion_title{
			padding: 5px 0;
			text-align: center;
			color: #707070;

			&:before{
				content: '더보기';
			}
			&:after{
				display: inline-block;
				margin-left: 5px;
				vertical-align: middle;
				border: 6px solid transparent;
    			border-color: #707070 transparent transparent transparent;
				content: '';
			}
		}
		
		.accordion_con{
			display: none;
			padding: 0 10px;
			background: $colorBG;
		}

		&.on{
			.accordion_title{
				&:before{
					content: '접기';
				}
				&:after{
					margin-top: -14px;
					border-color: transparent transparent #707070 transparent;
				}
			}
		}

		.nomal_list{
			padding: 0;
			border-bottom: 0;

			li{
				padding: 10px 0;
				border-bottom: 1px solid $colorBC;

				&:last-child{
					border-bottom: 0;
				}
			}
		}
	}

	&__orderPayment{
		.giftRecipient_inner{
			margin: rem(30px);

			.guide_text li{
				font-size: rem(20px);
			    line-height: rem(32px);
			    text-indent: rem(-10px);
			}
		}

		.hd__planMeals-detail{
			margin: 0 auto;
			max-width: 640px;

			.close-area{
				height: rem(64px);
				
				.page-mark{
				    width: rem(160px);
					height: rem(62px);
					line-height: rem(62px);
				    border-radius: rem(12px 12px 0 0);

					&:after{
						top: rem(6px);
					    right: rem(-39px);
						border-top: rem(44px) solid transparent;
					    border-right: rem(20px) solid transparent;
					    border-left: rem(20px) solid #fff;
					}
				}

				&__btn-icon{
				    margin-left: rem(10px);
					width: rem(32px);
					height: rem(32px);
				    vertical-align: rem(-8px);

					&:before,&:after{
					    margin: rem(-13px 0 0 -1px);
						height: rem(26px);
					}
				}
			}
		}
	}
}

#giftRecipient{
	top: 0 !important;
    padding: 0 !important;
	height: 100% !important;
   	height: -webkit-fill-available;

   	.hd__popup-v2{
   		position: fixed;
   		margin-top: 0 !important;
   		height: 100% !important;

   		&__inner{
   			height: 100% !important;
   		}
   	}

	.popup_amore{
		margin-top: 0 !important;
		width: 640px;
	}

	.hd__btn-sec{
		position: fixed !important;
		height: calc(85px);
	}

	.addressSearch{
		.layer_header h2 {
	        padding: 12px 15px;
	        font-size: 16px;
	        font-weight: 600;
	    }

	    .btn_icon.close{
    	    right: 10px;
		    width: 15px;
		    height: 15px;
		    padding: 5px;

		    &:before,&:after{
		    	width: 1.5px;
        		height: 20px;
		    }
	    }
	}

	#zipAreaFrame iframe{
		margin-top: 45px !important;
		height: calc(100vh - 45px) !important; 
	}

	&Info{
		.accordion_box {
			margin: rem(30px);
		}

		.agree-all .checkbox__label{
			padding-left: rem(50px);
    		text-indent: rem(-50px);
		}
	}

	.hd__planMeals-detail{
		position: fixed;
		left: 0;
		top: 0;
		width: 640px;
		height: 100%;
		overflow-y: auto !important; 
		background: #fff;
		z-index: 9;
		box-sizing: border-box;
	}

	.board__inner__review .review__title, .detail__cont{
		font-size: 26px;
		font-weight: 100;
	}
}