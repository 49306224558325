.hd {
    // 마이페이지 공통 css
    &__mypage {

        &__btns {
            margin-bottom: 10px;
            width: 100%;
            font-size: 0;
            box-sizing: border-box;

            // .marginT {
            //     margin-top: 10px;
            // }

            .disabled{
                color: $color88;
                background: #e5e5e5;
            }
        }

        &__btn-half {
            margin-right: -1px;
            margin-bottom: 10px;
            width: 50%;
            height: 70px;
            background: #fff;
            border: 1px solid #BBB;
            @extend %font_s;
            color: #3d3d3d;
            text-align: center;
            box-sizing: border-box;
            font-weight: bold;

        }

        &__btn-full {
            margin-bottom: 10px;
            width: 100%;
            height: 70px;
            background: #fff;
            border: 1px solid #BBB;
            @extend %font_s;
            color: #3d3d3d;
            text-align: center;
            box-sizing: border-box;
            font-weight: bold;
        }

        .btn-cancelAll {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 125px;
            height: 70px;
            line-height: 40px;
            background: #f4f4f4;
            border: 1px solid #bcbcbc;
            box-sizing: border-box;
            @extend %font_xs;
            padding: 0 10px;
            color: #3d3d3d;
        }

        .btn-cancelRegular {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 125px;
            height: 70px;
            line-height: 40px;
            border: 1px solid #c1c1c1;
            @extend %font_xs;
            font-weight: bold;
            padding: 0 10px;
            color: #3d3d3d;
        }

        .btn-detail{
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            font-size: 19px;
            color: $color3D;
            letter-spacing: -1px;

            &:after{
                display: inline-block;
                margin: -2px 0 0 7px;
                width: 15px;height: 10px;
                vertical-align: middle;
                transform:rotate(-90deg);
                background: url(/front_mo/images/ico_cart_close.png) no-repeat right center;
                background-size: 100% auto;
                content: '';
                opacity: 0.7;
            }
        }
    }

    [class*="myProduct"] {
        // selectbox 영역
        .select-wrap {
            margin-bottom: 40px;
            font-size: 0;

            select {
                background: url(/front_mo/images/ico_cart_close.png) no-repeat right 20px top 50%;
            }

            &.one select {
                width: 100%;                
            }

            &.two select {
                width: 280px;
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .select-box{
            margin: 20px 0;
            padding: 0 20px;
        }

        .input-box.date{
            position: relative;
            margin: -20px 0 40px;

            .date{
                &__box {
                    display: flex;
                    padding-right: 140px;
                    justify-content: space-between;
                    line-height:70px;
                    @extend %font_s;
                    color:#BDBDBD;

                    input {
                        max-width: 205px;
                        font-size: 21px;
                    }
                }
            }

            .btn-full {
                position: absolute;
                top:0;
                right:0;
                max-width: 130px;
            }   
        }

        .product-wrap {
            overflow: hidden;
            margin-bottom: 30px;
            border-top: 3px solid $color3D;

            background-image: linear-gradient(#fdfbfb 0%, #f5f5f5 100%);
            box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.2);

            .hd__mypage__btns{
                padding: 0 20px;
            }
        }

        // 상품헤더 (제품번호, 주문번호, 기타)
        .head {
            &-txt {
                position: relative;
                padding: 20px;
                border-bottom: 1px solid #BCBCBC;
                z-index: 2;

                &__title {
                    display: block;
                    font: {
                        size: 22px;
                        weight: 600;
                    };
                    line-height: 32px;

                    .point_color2{
                        display: inline-block;
                        margin-left: 20px;
                        width: 205px;
                        font-size: 20px;
                        font-weight: 100;
                        vertical-align: top;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .name_ellipsis{
                            max-width: 90px;
                        }
                    }
                }

                &__desc {
                    display: block;
                    font-size: 22px;
                    line-height: 32px;
                }

                .dvCart-pdName {
                    display: block;
                }
            }
        }

        .item-box {
            &__list {
                position: relative;
                overflow: hidden;

                &:not(:first-child){
                    border-top: 1px solid #BCBCBC;
                }
                
                .inner {
                    position: relative;
                    margin: 0 20px;
                    padding: 20px 0;
                    min-height: 106px;
                    border-top: 1px solid #BCBCBC;
                    
                    &:nth-of-type(1){
                        border-top: 0;
                    }
                }

                .img-box {
                    position: relative;
                    display: inline-block;
                    width: 100px;
                    height: 100px;
                    vertical-align: top;

                    &.hasBadge:before {
                        content: '교환상품';
                        display: block;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 30px;
                        background: $gcolor;
                        color: #FFF;
                        font-size: 22px;
                        text-align: center;
                        line-height: 30px;
                    }
                }

                .date-box {
                    position: relative;
                    display: inline-block;
                    width: 100px;
                    height: 100px;
                    border: 3px solid #BEBEBE;
                    text-align: center;
                    box-sizing: border-box;
                    vertical-align: top;
                    
                    &__inner {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 100%;
                        font-size: 22px;
                        line-height: 1.25;
                        transform: translate3d(-50%, -50%, 0);

                        .middle {
                            display: block;
                            font-weight: 600;
                        }
                    }
                }

                .txt-area {
                    display: inline-block;
                    margin-left: 20px;
                    width: -webkit-calc(100% - 160px);
                    width: calc(100% - 160px);
                    vertical-align: top;
                    
                    &__inner {
                        position: relative;

                        strong.txt-area__desc{
                            margin-top: 5px;
                            color: $color3D;
                        }
                    }

                    &__title {
                        display: block;
                        @extend %font_s;
                        font-weight: normal;
                        line-height: 32px;

                        &.line02 {
                            @include line(2);
                        }
                    }

                    &__desc {
                        display: block;
                        margin-top: 5px;
                        flex-wrap: wrap;
                        @extend %font_xs;
                        line-height: 32px;
                        color: $color88;

						> a {flex:1;}

                        .line01 {
                            display: block;
                            @include line(1);
                        }

                        &.delivery {
                            margin-bottom: 5px;
                            @extend %font_xs;
                            color: $color3D !important;    
                        }

                        .select-wrap {
                            display: inline-block;
                            margin: {
                                bottom: 0;
                                left: 5px;
                            }
                            
                            select{
                                width: 132px;
                                height: 54px;
                                background: url(/front_mo/images/icon_market_drop-down-on.png) no-repeat 90px 50%;
                            }
                        }
                    }

                    &__qty{
                        display: block;
                        line-height: 32px;
                        font-size: 20px;

                        .input_select{
                            max-width: 110px;
                            transform: scale(0.7);
                        }
                    }
                }

                .process-txt {
                    display: block;
                    position: absolute;
                    top: 25px;
                    right: 0;
                    // width: 120px;
                    text-align: right;
                    font: {
                        size: 22px;
                        weight: 600;
                    };
                    color: $gcolor;
                    line-height: 32px;
                    word-break: keep-all;
                }

                .process{
                    margin: 5px 0 25px;
                    font-size: 22px;

                    &-text{
                        font-weight: 600;
                        color: #3D3D3D;
                    }

                    &-delivery, .txt-area__desc{
                        color: $gcolor;

                        &:before{
                            display: inline-block;
                            margin: 4px 10px 0;
                            width: 2px;
                            height: 20px;
                            vertical-align: top;
                            background: $colorBC;
                            content: '';
                        }
                    }

                    .txt-area__desc{
                        display: inline-block;
                        line-height: 1.2;
                        color: $color88;
                    }
                }

                .store{
                    position: relative;
                    margin: 0 0 -2px;
                    padding: 10px 20px;
                    @extend %font_xs;
                    color: #666;
                    background: #f1f1f1;
                    z-index: 1;

                    &:first-child + .inner{
                        border-top: 0;
                    }
                }

                .info-link{
                    display: block;
                    margin: -5px 0 25px;
                    font-size: 21px;
                    color: $color88;

                    .point{
                        position: relative;
                        display: inline-block;
                        position: relative;
                        line-height: 1.3;
                        color: blue;

                        &:after{
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 1px;
                            content: '';
                            background: blue;
                        }
                    }
                }

                .exchange__view {
                    position: relative;

                    &__btn {
                        position: absolute;
                        z-index: 1;
                        top: -44px;
                        right: 0;
                        width: 45px;
                        height: 45px;
                        background: #F9F9F9 url(/front_pc/images/btn_comm_accordion-down.png) no-repeat 50% 50%;
                        border: 1px solid #BCBCBC;

                        &.on {
                            background-image: url(/front_pc/images/btn_comm_accordion-up.png);
                        }
                    }

                    &__sec {
                        display: none;
                        position: relative;
                        margin-bottom: 20px;
                        padding: 0 20px;
                        border: 1px solid #BCBCBC;
                        background: #F9F9F9;

                        .process-txt {
                            right: 20px;
                        }
                    }
                }

                .gift {
                    position: relative;
                    margin:10px 0;
                    padding-left: 60px;
        
                    &__thumb {
                        overflow: hidden;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 50px;
                        height: 45px;
                    }
        
                    &__name {
                        font-size: 22px;
                        line-height: 32px;

                        &.line02 {
                            @include line(2);
                        }
                    }
                }

                // 마켓, 지정일, 중식 case
                .singleCase {
                    position: relative;

                    .inner + .price-area{
                        margin-top: 0;
                        padding: 0 20px 25px;
                    }
                }

                // 식단 case
                .multiCase {
                    .mealTop-area {
                        // border-bottom: 1px solid #BCBCBC;

                        &__inner {
                            position: relative;
                            padding: 25px 20px 0;    
                        }

                        .txt-area__title{
                            margin-bottom: 20px;
                        }
                    }

                    .btn__mealsMore {
                        display: block;
                        padding: 15px 0;
                        border-top: 1px solid #BCBCBC;
                        background: #f5f5f5;
                        text-align: center;
                        font-size: 22px;

                        span {
                            padding-right: 32px;
                            background: url(/front_mo/images/btn_menu_drop-down.png) no-repeat 100% 50%;
                        }

                        &.on span {
                            background: url(/front_mo/images/btn_menu_drop-up.png) no-repeat 100% 50%;
                        }
                    }

                    .regular-meals {
                        display: none;

                        &__list {
                            position: relative;
                            border-top: 1px solid #BCBCBC;

                            // &:first-child {
                            //     border-top: 0;
                            // }

                            .txt-area__desc{
                                color: $color3D;
                            }

                            .num {
								font-weight: 600;
								flex:0 0 auto;
								margin-left:10px;
                            }
                        }
                    }

                    .price-area {
                        padding-top: 25px;
                        border-top: 1px solid #BCBCBC;
                    }
                }

                .list-more{
                    display: none;
                    position: relative;
                    padding: 0 20px;
                    overflow: hidden;

                    &-btn{
                        position: absolute;
                        right: 30px;
                        top: 100px;
                        width: 33px;
                        height: 33px;
                        text-indent: -999em;
                        z-index: 1;
                        background: url(/front_mo/images/ico_cart_close.png) no-repeat right center;
                        background-size: 100% auto;
                        content: '';
                        opacity: 0.5;
                    }

                    .regular-meals__list {
                        position: relative;
                        border-top: 1px solid #BCBCBC;
                         
                        .num{
                            font-weight: 700;
                            margin-left: 10px;
                            color: $color3D;
                        } 

                        .txt-area{
                            width: -webkit-calc(100% - 265px);
                            width: calc(100% - 265px);

                            &__desc{
                                font-size: 20px;
                                color: $color3D;
                            }
                        }

                        .hd__mypage__btns{
                            padding: 0;
                        }
                    }

                    .inner{
                        margin: 0;
                    }

                    .txt-area{
                        .gift__name{
                            font-size: 20px;
                            color: $color3D;
                        }
                    }

                    .item-box__list{
                        border-top: 1px solid #BCBCBC;
                    }

                    .price-area{
                        margin: 20px 0;
                    }
                }

                &.active{
                    .list-more{
                        &-btn{
                             transform: rotate(180deg);
                        }
                    }
                }

                .hd__mypage__btns{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }

        }

        .delivery-fee {
            margin-bottom: -1px;
            padding: 25px 20px;
            @extend %font_xs;

            > div {
                @extend %clear;
                
                font-weight: bold;

                .tit {
                    float: left;                        
                }

                .price {
                    float: right;
                    text-align: right;
                }
            }

            .condi {
                display: block;
                text-align: right;
                margin-top: 10px;
            }
        }

        .price-area {
            padding: 0 0 25px 0;

            li {
                width: 100%;
                font-size: 22px;
                @extend %clear;

                .tit {
                    float: left;
                    line-height: 32px;
                }

                .price {
                    float: right;
                    text-align: right;
                    line-height: 32px;
                }

                & + li {
                    margin-top: 5px;
                }
            }
        }

        .info-area {
            padding: 0 20px;

            .info-list {
                display: table;
                table-layout: fixed;
                padding: 20px 0;

                &:not(:first-child){
                    border-top: 1px solid #BCBCBC;
                }

                &__tit {
                    display: table-cell;
                    width: 180px;
                    @extend %font_xs;
                    color: #666;
                    vertical-align: top;
                    line-height: 32px;
                }

                .detail {
                    display: table-cell;
                    width: 400px;
                    @extend %font_xs;
                    vertical-align: top;
                    line-height: 32px;

                    &__list {
                        display: block;
                        @extend %font_xs;
                        line-height: 32px;

                        &:not(:first-child) {
                            margin-top: 15px;
                        }

                        .ocolor {
                            margin-left: 5px;
                            color: $gcolor;
                        }

                        .rightSide {
                            float: right;
                        }

                        .innerbox {
                            padding-left: 25px;

                            &__list {
                                display: block;
                                margin-top: 15px;
                                color: $color3D;
                            }
                        }

                        .dBlock {
                            display: block;
                        }
                    }
                }

                &-total {
                    border-bottom: 0;

                    .info-list__tit {
                        width: 250px;
                    }
                }
            }

            & + .hd__mypage__btns {
                margin-top: 10px;
            }

            & + .info-area{
                border-top: 1px solid #BCBCBC;
            }
        }

        .infobox {
            padding: 25px 20px;

            &__list {
                display: inline-block;
                width: 100%;

                @extend %vertical;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                &-w100 {
                    width: 100%;

                    .infobox__tit {
                        display: block;
                        width: 100%;
                        margin-bottom: 25px;
                    }
                }

                .innerbox {

                    &__list {
                        display: block;
                        margin-top: 20px;
                        padding-left: 40px;
                        color: $color3D;
                        @extend %font_xs;
                        @extend %vertical;

                        &:last-child {
                            margin-bottom: 10px;
                        }

                        .rightSide {
                            float: right;
                        }
                    }
                }

                .select-box{
                    padding: 0;
                    margin-bottom: 0;
                    background: #fff;
                }
            }

            &__tit {
                @extend %font_xs;
            }

            &__cont {
                float: right;
                width: 280px;
                @extend %font_xs;
                line-height: 32px;
                text-align: right;
            }

            .input-box {
                input {
                    margin-top: 20px;
                }
            }

            & + .infobox {
                border-bottom: 1px solid #DDD;
            }
        }

        .infoboxV2 {
            margin: 0 20px;
            padding: 25px 20px;

            &__list {
                display: table;
                table-layout: fixed;
                width: 100%;

                @extend %vertical;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                .innerbox {

                    &__list {
                        display: block;
                        margin-top: 20px;
                        padding-left: 40px;
                        color: $color3D;
                        @extend %font_xs;
                        @extend %vertical;

                        &:last-child {
                            margin-bottom: 10px;
                        }

                        .rightSide {
                            float: right;
                        }
                    }
                }

                &-w100 {
                    display: block;
                    width: 100%;

                    .infoboxV2__tit {
                        display: block;
                        width: 100%;
                    }

                    .infoboxV2__cont {
                        display: block;
                        width: 100%;
                        margin-top: 15px;

                        .innerbox {
                            display: table;
                            table-layout: fixed;
                            width: 100%;
                            line-height: 1.6;

                            &__tit {
                                display: table-cell;
                                width: 200px;
                            }

                            &__cont {
                                display: table-cell;
                            }
                        }
                    }
                }

            }

            &__tit {
                display: table-cell;
                width: 200px;
                @extend %font_xs;
            }

            &__cont {
                display: table-cell;
                @extend %font_xs;

                .inner-cont {
                    display: block;
                    margin-top: 10px;
                }
            }

            .input-box {
                input {
                    margin-top: 20px;
                }
            }

            + .infobox {
                border-top: 1px solid #DDD;
            }

            .alignR {
                text-align: right;
            }
        }

        .refund-desc {
            padding: 20px 20px;

            li {
                position: relative;
                padding-left: 15px;
                @extend %font_xs;
                line-height: 32px;
                color: $gcolor;

                &:before {
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    content: '-';
                }
            }

            .colorO {
                color: $gcolor;
            }
        }

        .reason-area {
            padding: 25px 20px;
            border: {
                bottom: 1px solid #BCBCBC;
            }

            &__tit {
                display: block;
                @extend %font_xs;
                font-weight: bold;
                line-height: 1.6;
            }

            &__cont {
                display: block;
                @extend %font_xs;
                line-height: 1.6;

                .viewThumb {
                    margin-top: 20px;
                    text-align: center;
                }
            }
        }

        .necessar {
            color: $gcolor;
        }

        .excption__notice {
            margin-top: 20px;
            font-size: 22px;
            line-height: 28px;
        }
    }

    &__myOrderList {
        padding-top: 190px;  
        
        .head-txt__desc {
            font-weight: bold;
            text-decoration: underline;
            color: blue;
        }
    }

    .popup_menuChange {
        .btn-next {
            color: #fff;
        }

        .calendar-area {
            margin-top: -1px;
            padding: 5px;
            border-top: 3px solid $gcolor;

            &__tit {
                padding: 25px 0;
                text-align: center;
                @extend %font_m;
            }

            .calendar {
                border-top: 1px solid #DDD;

                table {
                    thead th {
                        padding: 20px 0;
                        @extend %font_xs;
                        font-weight: 400;
                    }

                    tbody tr td:first-child label {
                        background: none;
                        color: red;
                    }

                    .cal {
                        &__day {
                            padding: 0;
                            text-align: center;
                            border: {
                                bottom: 2px solid #FFF;
                                left: 2px solid #FFF;
                            }

                            label {
                                display: block;
                                padding: 18px 0;
                                background: #F4F4F4;
                                @extend %font_xs;
                                color: #3D3D3D;
                            }

                            input[type="checkbox"] {
                                display: none;
                            }

                            input[type="checkbox"]:checked + label {
                                background: $gcolor;
                                color: #FFF;
                            }
                            
                            &.holiday label {
                                background: none;
                                color: red;
                            }
                        }
    
                        &__disabled {
                            label {
                                background: none;
                                color: #BCBCBC;
                            }
                        }
                    }
                }
            }
        }

        .btn__default {
            position: absolute;
            bottom: 0;
        }
    }

    .popup_menuChoice {

        .menu-choice {
            padding: 0;
            &__tit {
                padding: 25px 0;
                @extend %font_l;
                text-align: center;
			}
			.menu-change-info{
				padding:0 20px 20px;
                line-height: 1.5;
				@extend %font_xs;
                color: $color88;
				p {
                    margin-top:5px; padding-left:20px; text-indent:-20px; word-break: keep-all;
                }
			}
        }

        .mchoice {
            background: #FFF;
            box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.1);

            &__title {
                display: block;
                height: 70px;
                border: {
                    top: 3px solid $gcolor;
                    bottom: 1px solid #DDD;
                }
                // background: $gcolor;
                text-align: center;
                line-height: 70px;
                @extend %font_l;
            }

            &__wrap {
                height: 100%;
                margin: 0 auto;
                padding: 35px 25px;
                border-top: 1px solid #DDD;
                @extend %vertical;
            }

            &__list {
                display: inline-block;
                width: 252px;
                vertical-align: top;
                margin: {
                    top: 30px;
                    left: 26px;
                }
                font-size: 20px;

                &:nth-child(odd) {
                    margin-left: 0;
                }

                &:nth-child(-n+2) {
                    margin-top: 0;
                }

                &__img {
                    position: relative;

                    figure {
                        overflow: hidden;
                        width: 252px;
                        height: 252px;
                    }

                    label {
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            z-index: 2;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: 6px solid transparent;
                            box-sizing: border-box;
                        }
                    }

                    input[type="checkbox"]:checked + label:before {
                        border-color: $gcolor;
                    }
                }

                &__count {
                    position: relative;
                    margin-top: 5px;
                    text-align: center;
                    transform: translate3d(0, 0, 0);
                    -webkit-transform: translate3d(0, 0, 0);

                    input {
                        vertical-align: top;
                        width: 132px;
                        height: 55px;
                        border: 0;
                        background: #F0F0F0;
                        line-height: 55px;
                        text-align: center;
                        @extend %font_xs;
                        font-weight: 600;
                        color: #BCBCBC;
                        box-sizing: border-box;
                        border-radius: 0;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                    }

                    input.on {
                        border: 1px solid $gcolor;
                        background: #FFF;
                        color: #3D3D3D;
                    }

                    .btn__count {
                        position: absolute;
                        display: block;
                        top: 0;
                        width: 55px;
                        height: 55px;
                        background: $gcolor;

                        &-down {
                            left: -1px;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 21px;
                                height: 3px;
                                background: #FFF;
                                transform: translate3d(-50%, -50%, 0);
                            }
                        }

                        &-up {
                            right: -1px;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 21px;
                                height: 3px;
                                background: #FFF;
                                transform: translate3d(-50%, -50%, 0);
                            }

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 3px;
                                height: 21px;
                                background: #FFF;
                                transform: translate3d(-50%, -50%, 0);
                            }
                        }
                    }

                    button:disabled {
                        background: #BCBCBC;
                    }
                }

                &__promotion{
                    display: block;
                    margin-top: 15px;
                    font-weight: normal;
                    color: $color88;
                    @include line(1);
                }

                &__title {
                    display: block;
                    margin: 5px 0;
                    height: 50px;
                    @extend %font_xs;
                    font-weight: 600;
                    color: $color3D;
                    @include line(2);
                }

                &__price-after{
                    strong{
                        @extend %font_s;
                    }
                }

                &__material {
                    margin-top: 15px;
                    padding-top: 15px;
                    border-top: 1px solid $colorBC;

                    li {
                        display: inline-block;
                        vertical-align: top;
                        margin: 0 6px;

                        img {
                            vertical-align: top;
                            width: 35px;
                            height: 35px;
                        }
                    }
                }

                &--on {
                    .mchoice__list__img {

                        label {
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                z-index: 2;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border-color: $gcolor;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
            }
        }

        .btn__check {
            color: #fff;
        }
    }

    // 상품후기 작성 팝업
    .popup_orderReview {
        .orderReview {
            &__sec {
                margin-top: 30px;
                .form__input {
                    margin-top: 20px;
                }
				.warning__text {margin-top:5px; padding-left:20px; color:$color3D; letter-spacing:-.5px; text-indent:-20px; font-size:20px; word-break:keep-all;}
			}
			

            &__title {
                display: block;
                margin-bottom: 15px;
                font-size: 24px;
			}

            &__item {
                position: relative;
                padding: 25px 0 25px 135px;
                min-height: 100px;
                border-bottom: 1px solid #BCBCBC;
                font-size: 0;

                &:first-child {
                    border-top: 1px solid #BCBCBC;
                }

                &__img {
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    top: 25px;
                    left: 0;
                    width: 100px;
                    height: 100px;
                }

                &__info {
                    @extend %font_xs;

                    .item__view {
                        > * {
                            display: block;
                            line-height: 32px;
                        }

                        &__opts {
                            position: relative;
                            margin-top: 10px;
                            padding-left: 60px;

                            span {
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }

            &__file {
                &__list {
                    &:after {
                        content: '';
                        display: block;
                        clear: both;
                    }

                    li {
                        float: left;
                        margin-top: 0;
                        margin-left: 20px;

                        &:first-child {
                            margin-left: 0;
                        }

                        .image-box {
                            display: block;
                            overflow: hidden;
                            position: relative;
                            width: 98px;
                            height: 98px;
                            border: 1px solid #C3C3C3;
                        }

                        .image-box img {
                            width: 100%;
                        }

                        .image-box .btn_icon {
                            display: none;
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 35px;
                            height: 35px;
                            background: $gcolor url("/front_mo/images/ico_file_delte.png") no-repeat 50% 50%;
                        }
                    }
                }

                &__notice {
                    display: block;
                    position: relative;
                    margin-top: 20px;
                    padding-left: 12px;
                    line-height: 32px;
                    font-size: 22px;
                    font-weight: 400;
                    color: $color3D;

                    &:before {
                        content: '*';
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 22px;
                        color: $color3D;
                    }
                }
            }
        }

        .gift {
            position: relative;
            margin-top: 15px;
            padding-left: 60px;

            &__thumb {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 50px;
                height: 45px;
            }

            &__name {
                padding-top: 4px;
                font-size: 22px;
                line-height: 32px;

                &.line02 {
                    @include line(2);
                }
            }
        }
    }

    .dvCart-pdName {
        display: block;
    }
}

.wdw-type {

    .hd {
        // 마이페이지 공통 css
        &__mypage {
    
            &__btns {
                width: 100%;
                margin: rem(0 auto 25px);
                font-size: 0;
    
                .marginT {
                    margin-top: rem(12px);
                }
    
                & + .hd__mypage__btns {
                    margin-top: 2%;
                }
            }
    
            &__btn-half {
                width: rem(50%);
                height: rem(70px);
                margin-top: rem(10px);
                background: #fff;
                border: 1px solid #BBB;
                @extend %font_s;
                color: #3d3d3d;
                text-align: center;
                box-sizing: border-box;
                font-weight: bold;
    
                &:nth-child(-n+2) {
                    margin-top: 0;
                }
            }
    
            &__btn-full {
                width: 100%;
                height: rem(70px);
                background: #fff;
                border: 1px solid #BBB;
                @extend %font_s;
                color: #3d3d3d;
                text-align: center;
                box-sizing: border-box;
                font-weight: bold;
            }
    
            .btn-cancelAll {
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: rem(125px);
                height: rem(70px);
                line-height: rem(40px);
                background: #f4f4f4;
                border: 1px solid #bcbcbc;
                box-sizing: border-box;
                @extend %font_xs;
                padding: rem(0 10px);
                color: #3d3d3d;
            }
    
            .btn-cancelRegular {
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: rem(125px);
                height: rem(70px);
                line-height: rem(40px);
                border: 1px solid #c1c1c1;
                @extend %font_xs;
                font-weight: bold;
                padding: rem(0 10px);
                color: #3d3d3d;
            }
        }
    
        [class*="myProduct"] {
            // selectbox 영역
            .select-wrap {
                margin-bottom: rem(40px);
                font-size: 0;
    
                select {
                    background: url(/front_mo/images/ico_cart_close.png) no-repeat right 20px top 50%;
                    background-size: rem(31px 18px);
                }
    
                &.one select {
                    width: 100%;                
                }
    
                &.two select {
                    width: rem(280px);
                    margin-left: rem(20px);
    
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
    
            .product-wrap {
                margin-bottom: rem(40px);
                border-top: rem(3px) solid $gcolor;
    
            }
    
            // 상품헤더 (제품번호, 주문번호, 기타)
            .head {
                &-txt {
                    margin: rem(0 -20px);
                    padding: rem(20px);

                    &__title {
                        font: {
                            size: rem(22px);
                        };
                        line-height: rem(32px);

                        .point_color2{
                            margin-left: rem(20px);
                            width: rem(205px);
                            font-size: rem(20px);

                            .name_ellipsis{
                                max-width: rem(90px);
                            }
                        }
                    }

                    &__desc {
                        font-size: rem(24px);
                        line-height: rem(32px);
                    }
                }
            }
    
            .item-box {
                &__list {
                    .inner {
                        padding: rem(20px 0);
                        min-height: rem(106px);
                    }

                    .img-box {
                        width: rem(100px);
                        height: rem(100px);

                        &.hasBadge:before {
                            height: rem(30px);
                            font-size: rem(22px);
                            line-height: rem(30px);
                        }
                    }

                    .date-box {
                        width: rem(100px);
                        height: rem(100px);
                        
                        &__inner {
                            font-size: rem(22px);
                        }
                    }

                    .txt-area {
                        margin-left: rem(20px);
                        width: rem(410px);

                        &__title {
                            margin-bottom: rem(5px);
                            line-height: rem(32px);
                        }

                        &__desc {
                            font-size: rem(20px);
                            line-height: rem(32px);

                            &.delivery {
                                margin-bottom: rem(5px);
                                font-size: rem(22px);
                            }

                            .select-wrap {
                                margin: {
                                    left: rem(5px);
                                }
                                
                                select{
                                    width: rem(132px);
                                    height: rem(54px);
                                }
                            }
                        }
                    }

                    .process-txt {
                        top: rem(25px);
                        font: {
                            size: rem(22px);
                        };
                        line-height: rem(32px);
                    }

                    .process{

                        &-text{
                            font-weight: 600;
                            color: #3D3D3D;
                        }

                        &-delivery{
                            color: $gcolor;

                            &:before{
                                margin: rem(4px 10px 0);
                                width: rem(2px);
                                height: rem(16px);
                            }
                        }
                    }

                    .store{
                        margin: rem(0 -20px);
                        padding: rem(10px 20px);
                        font-size: rem(20px);
                    }

                    .exchange__view {
                        position: relative;

                        &__btn {
                            top: rem(-44px);
                            width: rem(45px);
                            height: rem(45px);
                        }

                        &__sec {
                            margin-bottom: rem(20px);
                            padding: rem(0 20px);

                            .process-txt {
                                right: rem(20px);
                            }
                        }
                    }

                    .gift {
                        margin: rem(10px 0);
                        padding-left: rem(60px);
            
                        &__thumb {
                            width: rem(50px);
                            height: rem(45px);
                        }
            
                        &__name {
                            font-size: rem(22px);
                            line-height: rem(32px);
                        }
                    }

                    // 식단 case
                    .multiCase {
                        .mealTop-area {
                            &__inner {
                                padding: rem(25px 0);    
                            }

                            .txt-area__title{
                                margin-bottom: rem(20px);
                            }
                        }

                        .btn__mealsMore {
                            padding: rem(15px 0);
                            font-size: rem(22px);

                            span {
                                padding-right: rem(32px);
                            }
                        }

                        .regular-meals {
                            &__list {
                                .num {
                                    margin-left: rem(10px);
                                }
                            }
                        }

                        .price-area {
                            padding-top: rem(25px);
                        }
                    }

                    .list-more{
                        &-btn{
                            top: rem(100px);
                            width: rem(33px);
                            height: rem(33px);

                        }

                        .regular-meals__list {                         
                            .num{
                                margin-left: rem(10px);
                            } 
                        }

                        .txt-area{
                            &__desc{
                                font-size: rem(20px);
                            }
                        }
                    }

                    &.active{
                        .list-more{
                            &-btn{
                                 transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

    
            .delivery-fee {
                padding: rem(25px 0);
                @extend %font_xs;
    
                > div {
                    @extend %clear;
                    
                    font-weight: bold;
    
                    .tit {
                        float: left;                        
                    }
    
                    .price {
                        float: right;
                        text-align: right;
                    }
                }
    
                .condi {
                    display: block;
                    text-align: right;
                    margin-top: rem(10px);
                }
            }
    
            .price-area {
                padding: rem(0 0 25px 0);
    
                li {
                    width: 100%;
                    font-size: rem(22px);
                    @extend %clear;
    
                    .tit {
                        float: left;
                        line-height: rem(32px);
                    }
    
                    .price {
                        float: right;
                        text-align: right;
                        line-height: rem(32px);
                    }
    
                    & + li {
                        margin-top: rem(10px);
                    }
                }
            }
    
            .info-area {
                .info-list {
                    display: table;
                    table-layout: fixed;
                    padding: rem(25px 0);
                    border-bottom: 1px solid #BCBCBC;
    
                    &__tit {
                        display: table-cell;
                        width: rem(150px);
                        @extend %font_xs;
                        font-weight: bold;
                        vertical-align: top;
                        line-height: rem(32px);
                    }
    
                    .detail {
                        display: table-cell;
                        width: rem(430px);
                        @extend %font_xs;
                        vertical-align: top;
                        line-height: rem(32px);
    
                        &__list {
                            display: block;
                            @extend %font_xs;
                            line-height: rem(32px);
    
                            &:not(:first-child) {
                                margin-top: rem(15px);
                            }
    
                            .ocolor {
                                margin-left: rem(5px);
                                color: $ocolor;
                            }
    
                            .rightSide {
                                float: right;
                            }
    
                            .innerbox {
                                padding-left: rem(25px);
    
                                &__list {
                                    display: block;
                                    margin-top: rem(15px);
                                    color: $color3D;
                                }
                            }
    
                            .dBlock {
                                display: block;
                            }
                        }
                    }
    
                    &-total {
                        border-bottom: 0;
    
                        .info-list__tit {
                            width: rem(250px);
                        }
                    }
                }

                & + .hd__mypage__btns {
                    margin: rem(25px 0 0 0);
                }
                
                & + .receipt {
                    margin: 0;
                }
            }
    
            .infobox {
                padding: rem(25px 0);
                border-top: 1px solid #DDD;
    
                &__list {
                    display: inline-block;
                    width: 100%;
    
                    @extend %vertical;
    
                    &:not(:last-child) {
                        margin-bottom: rem(20px);
                    }
    
                    &-w100 {
                        width: 100%;
    
                        .infobox__tit {
                            display: block;
                            width: 100%;
                            margin-bottom: rem(25px);
                        }
                    }
    
                    .innerbox {
    
                        &__list {
                            display: block;
                            margin-top: rem(20px);
                            padding-left: rem(40px);
                            color: $color3D;
                            @extend %font_xs;
                            @extend %vertical;
    
                            &:last-child {
                                margin-bottom: rem(10px);
                            }
    
                            .rightSide {
                                float: right;
                            }
                        }
                    }
                }
    
                &__tit {
                    @extend %font_xs;
                }
    
                &__cont {
                    float: right;
                    width: rem(290px);
                    @extend %font_xs;
                    line-height: rem(32px);
                    text-align: right;
                }
    
                .input-box {
                    input {
                        margin-top: rem(20px);
                    }
                }
    
                & + .infobox {
                    border-bottom: 1px solid #DDD;
                }
            }
    
            .infoboxV2 {
                padding: rem(25px 0);
                border: {
                    top: 1px solid #BCBCBC;
                    bottom: 1px solid #BCBCBC;
                }
    
                &__list {
                    display: table;
                    table-layout: fixed;
                    width: 100%;
    
                    @extend %vertical;
    
                    &:not(:last-child) {
                        margin-bottom: rem(20px);
                    }
    
                    .innerbox {
    
                        &__list {
                            display: block;
                            margin-top: rem(20px);
                            padding-left: rem(40px);
                            color: $color3D;
                            @extend %font_xs;
                            @extend %vertical;
    
                            &:last-child {
                                margin-bottom: rem(10px);
                            }
    
                            .rightSide {
                                float: right;
                            }
                        }
                    }
    
                    &-w100 {
                        display: block;
                        width: 100%;
    
                        .infoboxV2__tit {
                            display: block;
                            width: 100%;
                        }
    
                        .infoboxV2__cont {
                            display: block;
                            width: 100%;
                            margin-top: rem(15px);
    
                            .innerbox {
                                display: table;
                                table-layout: fixed;
                                width: 100%;
                                line-height: 1.6;
    
                                &__tit {
                                    display: table-cell;
                                    width: rem(200px);
                                }
    
                                &__cont {
                                    display: table-cell;
                                }
                            }
                        }
                    }
    
                }
    
                &__tit {
                    display: table-cell;
                    width: rem(200px);
                    @extend %font_xs;
                }
    
                &__cont {
                    display: table-cell;
                    @extend %font_xs;
    
                    .inner-cont {
                        display: block;
                        margin-top: rem(10px);
                    }
                }
    
                .input-box {
                    input {
                        margin-top: rem(20px);
                    }
                }
    
                & + .infobox {
                    border-top: 1px solid #DDD;
                }
    
                .alignR {
                    text-align: right;
                }
            }
    
            .refund-desc {
                margin-top: rem(30px);
    
                li {
                    position: relative;
                    padding-left: rem(15px);
                    @extend %font_xs;
                    line-height: rem(32px);
                    color: $ocolor;
    
                    &:before {
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        content: '-';
                    }
                }
    
                .colorO {
                    color: $ocolor;
                }
            }
    
            .reason-area {
                padding: rem(25px 0);
                border: {
                    top: 1px solid #BCBCBC;
                    bottom: 1px solid #BCBCBC;
                }
    
                &__tit {
                    display: block;
                    @extend %font_xs;
                    font-weight: bold;
                    line-height: 1.6;
                }
    
                &__cont {
                    display: block;
                    @extend %font_xs;
                    line-height: 1.6;
    
                    .viewThumb {
                        margin-top: rem(20px);
                        text-align: center;
                    }
                }
            }
    
            .necessar {
                color: $gcolor;
            }
    
            .excption__notice {
                margin-top: rem(20px);
                font-size: rem(22px);
                line-height: rem(28px);
            }
        }
    
        &__myOrderList {
            padding-top: rem(190px);  
            
            .head-txt__desc {
                font-weight: bold;
                text-decoration: underline;
                color: blue;
            }
        }
    
        .popup_menuChange {
            .btn-next {
                color: #fff;
            }
    
            .calendar-area {
                margin-top: -1px;
                padding: rem(5px);
                border-top: rem(3px) solid $gcolor;
    
                &__tit {
                    padding: rem(25px 0);
                    text-align: center;
                    @extend %font_m;
                }
    
                .calendar {
                    border-top: 1px solid #DDD;
    
                    table {
                        thead th {
                            padding: rem(20px 0);
                            @extend %font_xs;
                            font-weight: 400;
                        }
    
                        tbody tr td:first-child label {
                            background: none;
                            color: red;
                        }
    
                        .cal {
                            &__day {
                                padding: 0;
                                text-align: center;
                                border: {
                                    bottom:rem( 2px) solid #FFF;
                                    left: rem(2px) solid #FFF;
                                }
    
                                label {
                                    display: block;
                                    padding:rem(18px 0);
                                    background: #F4F4F4;
                                    @extend %font_xs;
                                    color: #3D3D3D;
                                }
    
                                input[type="checkbox"] {
                                    display: none;
                                }
    
                                input[type="checkbox"]:checked + label {
                                    background: $gcolor;
                                    color: #FFF;
                                }
                                
                                &.holiday label {
                                    background: none;
                                    color: red;
                                }
                            }
        
                            &__disabled {
                                label {
                                    background: none;
                                    color: #BCBCBC;
                                }
                            }
                        }
                    }
                }
            }
    
            .btn__default {
                position: absolute;
                bottom: 0;
            }
        }
    
        .popup_menuChoice {
    
            .menu-choice {
                padding: 0;
    
                &__tit {
					padding: rem(25px 0);
					min-height:rem(50px);
					box-sizing: border-box;
                    @extend %font_m;
                    text-align: center;
                }
            }
    
            .mchoice {
                background: #FFF;
				box-shadow: rem(0 10px 20px 5px) rgba(0, 0, 0, 0.1);
				
                &__title {
                    display: block;
                    height: rem(70px);
                    border: {
                        top: rem(3px) solid $gcolor;
                        bottom: 1px solid #DDD;
                    }
                    text-align: center;
                    line-height: rem(70px);
                    @extend %font_l;
				}
    
                &__wrap {
                    height: 100%;
                    margin: 0 auto;
                    padding: rem(35px 25px);
                    border-top: 1px solid #DDD;
                    font-size: 0;
    
                    @extend %vertical;
                }
    
                &__list {
                    display: inline-block;
                    width: rem(252px);
                    vertical-align: top;
                    margin: {
                        top: rem(30px);
                        left: rem(26px);
                    }
    
                    &:nth-child(odd) {
                        margin-left: 0;
                    }
    
                    &:nth-child(-n+2) {
                        margin-top: 0;
                    }
    
                    &__img {
                        position: relative;
    
                        figure {
                            overflow: hidden;
                            width: rem(252px);
                            height: rem(230px);
                        }
    
                        label {
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                z-index: 2;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border: rem(6px) solid transparent;
                                box-sizing: border-box;
                            }
                        }
    
                        input[type="checkbox"]:checked + label:before {
                            border-color: $gcolor;
                        }
                    }
    
                    &__count {
                        position: relative;
                        margin-top: rem(5px);
                        text-align: center;
                        transform: translate3d(0, 0, 0);
                        -webkit-transform: translate3d(0, 0, 0);
    
                        input {
                            vertical-align: top;
                            width: rem(132px);
                            height: rem(55px);
                            border: 0;
                            background: #F0F0F0;
                            line-height: rem(55px);
                            text-align: center;
                            @extend %font_xs;
                            font-weight: 600;
                            color: #BCBCBC;
                            box-sizing: border-box;
                            border-radius: 0;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                        }
    
                        input.on {
                            border: 1px solid $gcolor;
                            background: #FFF;
                            color: #3D3D3D;
                        }
    
                        .btn__count {
                            position: absolute;
                            display: block;
                            top: 0;
                            width: rem(55px);
                            height: rem(55px);
                            background: $gcolor;
    
                            &-down {
                                left: -1px;
    
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: rem(21px);
                                    height: rem(3px);
                                    background: #FFF;
                                    transform: translate3d(-50%, -50%, 0);
                                }
                            }
    
                            &-up {
                                right: -1px;
    
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: rem(21px);
                                    height: rem(3px);
                                    background: #FFF;
                                    transform: translate3d(-50%, -50%, 0);
                                }
    
                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: rem(3px);
                                    height: rem(21px);
                                    background: #FFF;
                                    transform: translate3d(-50%, -50%, 0);
                                }
                            }
                        }
    
                        button:disabled {
                            background: #BCBCBC;
                        }
                    }
	
					&__promotion {
						display: block;
						max-height: 48px;
						font-size: rem(16px);
						margin-top:5px;
						color: $color3D;
						line-height: 1.3;
						@include line(2);
					}
					
                    &__title {
                        display: block;
                        overflow: hidden;
						padding: rem(10px 0 0);
						min-height:rem(56px);
						height:rem(56px);
						border-bottom:0;
						text-align:left;
						white-space: normal;
						line-height: 1.3;
                        @extend %font_xs;
                        font-weight: 600;
						color: #3D3D3D;
						@include line(2);
					}
					&__price{
						font-size:rem(26px);
					}
    
                    &__material {
                        margin-top: rem(10px);
						border-top: 1px solid #BCBCBC;
						padding-top: rem(10px);
						text-align: left;
                        li {
                            display: inline-block;
                            vertical-align: top;
                            margin: rem(0 6px);
    
                            img {
                                vertical-align: top;
                                width: rem(35px);
                                height: rem(35px);
                            }
                        }
                    }
    
                    &--on {
                        .mchoice__list__img {
    
                            label {
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    z-index: 2;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    border-color: $gcolor;
                                    box-sizing: border-box;
                                }
                            }
                        }
                    }
                }
            }
    
            .btn__check {
                color: #fff;
            }
        }
    
        // 상품후기 작성 팝업
        .popup_orderReview {
            .orderReview {
                &__sec {
                    margin-top: rem(30px);
    
                    .form__input {
                        margin-top: rem(20px);
                    }
                }
    
                &__title {
                    display: block;
                    margin-bottom: rem(15px);
                    font-size: rem(24px);
				}
    
                &__item {
                    position: relative;
                    padding: rem(25px 0 25px 205px);
                    border-bottom: 1px solid #BCBCBC;
                    font-size: 0;
    
                    &:first-child {
                        border-top: 1px solid #BCBCBC;
                    }
    
                    &__img {
                        display: block;
                        overflow: hidden;
                        position: absolute;
                        top: rem(30px);
                        left: 0;
                        width: rem(175px);
                        height: rem(155px);
                    }
    
                    &__info {
                        @extend %font_xs;
    
                        .item__view {
                            > * {
                                display: block;
                                line-height: rem(32px);
                            }
    
                            &__opts {
                                position: relative;
                                padding-left: rem(60px);
    
                                span {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
    
                &__file {
                    &__list {
                        &:after {
                            content: '';
                            display: block;
                            clear: both;
                        }
    
                        li {
                            float: left;
                            margin-top: 0;
                            margin-left: rem(20px);
    
                            &:first-child {
                                margin-left: 0;
                            }
    
                            .image-box {
                                display: block;
                                overflow: hidden;
                                position: relative;
                                width: rem(98px);
                                height: rem(98px);
                                border: 1px solid #C3C3C3;
                            }
    
                            .image-box img {
                                width: 100%;
                            }
    
                            .image-box .btn_icon {
                                display: none;
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: rem(35px);
                                height: rem(35px);
                                background: $gcolor url("/front_mo/images/ico_file_delte.png") no-repeat 50% 50%;
                            }
                        }
                    }
    
                    &__notice {
                        display: block;
                        position: relative;
                        margin-top: rem(20px);
                        padding-left: rem(12px);
                        line-height: rem(32px);
                        font-size: rem(22px);
                        font-weight: 400;
                        color: $color3D;
    
                        &:before {
                            content: '*';
                            position: absolute;
                            top: 0;
                            left: 0;
                            font-size: rem(22px);
                            color: $color3D;
                        }
                    }
                }
            }
    
            .gift {
                position: relative;
                margin-top: rem(15px);
                padding-left: rem(60px);
    
                &__thumb {
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: rem(50px);
                    height: rem(45px);
                }
    
                &__name {
                    padding-top: rem(4px);
                    font-size: rem(22px);
                    line-height: rem(32px);
    
                    &.line02 {
                        @include line(2);
                    }
                }
            }
        }
    
        .dvCart-pdName {
            display: block;
        }
    }
}