.hd {
    &__delivery {
        padding-top: 190px;

        .calendar {
            border-top: 1px solid #DDD;
            padding: 0 7px;

            table {
                thead th {
                    padding: 20px 0;
                    @extend %font_xs;
                    font-weight: 400;
				}
				
				tbody tr td:first-child label {
					background: none;
					color: red;
				}

                .cal {
                    &__day {
                        padding: 0;
                        text-align: center;
                        border: {
                            bottom: 2px solid #FFF;
                            left: 2px solid #FFF;
                        }

                        label {
                            display: block;
                            padding: 18px 0;
                            background: $colorBG;
                            @extend %font_xs;
                            color: $color3D;
                        }

                        input[type="checkbox"]:checked + label {
                            background: $gcolor;
                            color: #FFF;
						}
						
						&.holiday label {
							background: none;
							color: red;
						}
                    }

                    &__disabled {
                        label {
                            background: none;
                            color: $colorBC;
                        }
					}
                }
            }

            &__text{
                margin-top: 30px;
                padding: 0 30px;
                line-height: 1.4;
                font-size: 24px;
                text-align: center;

                span{
                    font-size: 18px;
                    color: $color88;
                }
            }
        }
    }
}
