.hd {

    .hd__question-write {
        padding-top: 74px;
        right: initial;
        left: 30px;
    }

    .top-area {
        position: fixed;
        z-index: 5;
        top: 0;
        left: 50%;
        width: 100%;
        max-width: 640px;
        height: 64px;
        background: $gcolor;
        transform: translate(-50%, 0);

        a {
            display: block;
            position: absolute;
            top: 50%;
            right: 30px;
            color: #fff;
            // font-size: 20px;
            @extend %font_xs;
            transform: translate(0, -50%);

            img {
                margin-left: 5px;
                vertical-align: -4px;
            }
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 11px;
            background: #FFF;
        }

        &__btn-icon {
            display: inline-block;
            position: relative;
            width: 32px;
            height: 32px;
            margin-left: 10px;
            vertical-align: -8px;

            &:after,
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2px;
                height: 26px;
                background-color: #fff;
            }

            &:before {
                transform: rotate(45deg);
                margin: -13px 0 0 -1px;
            }

            &:after {
                transform: rotate(-45deg);
                margin: -13px 0 0 -1px;
            }

        }

        &--hidden {
            position: absolute;
        }
    }

    .page-mark {
        display: block;
        position: relative;
        margin: 0 auto;
        width: 350px;
        height: 70px;
        line-height: 68px;
        @extend %font_m;
        font-weight: bold;
        color: #fff;
        text-align: center;

    }

    .write-form {
        width: 580px;
        margin: 0 auto;

        &__title {
            margin: 30px 0 35px;
            text-align: center;
            font: {
                size: 28px;
                weight: 600;
            }
        }

        .send {
            font-size: 0;

            input,
            select,
            textarea {
                width: 100%;
                max-width: 580px;
                margin-top: 20px;
                height: 70px;
                resize: none;
				box-sizing: border-box;
				border-radius: 0;
				-webkit-appearance: none;
				appearance: none;
            }

            select {
                margin-top: 0;
                padding: 0 15px;
                background: url("/front_mo/images/ico_comm_select.png") no-repeat 95% 50%;
				-webkit-appearance: none;
				appearance: none;
            }

            textarea {
                overflow: hidden;
                overflow-y: auto;
                height: 370px;
            }
        }

        .recive {
            margin: 20px 0 25px;
            padding-bottom: 25px;
            border-bottom: 1px solid #BCBCBC;

            &__title {
                display: block;
                @extend %font_s;
                font-weight: 600;
            }

            &__email {
                position: relative;
                margin: 20px 0;
                text-align: center;
                line-height: 70px;
				@extend %font_s;

                input[type="text"] {
                    position: absolute;
                    top: 0;
                    height: 70px;
					text-align: left;
					border-radius: 0;
					-webkit-appearance: none;
					appearance: none;
                }

                input:nth-child(1) {
                    left: 0;
                    width: 260px;
                }

                input:nth-child(2) {
                    right: 0;
                    width: 265px;
                }
            }

            &__desc {
                @extend %font_xs;
            }
        }

        .secret {
            margin-bottom: 25px;

            label {
                display: inline-block;
                @extend %font_s;
                font-weight: 600;
                cursor: pointer;

                &:before {
                    content: '';
                    display: inline-block;
                    width: 52px;
                    height: 52px;
                    margin-right: 15px;
                    vertical-align: -18px;
                    background: #F4F4F4 url(/front_mo/images/btn_comm_secret.png) no-repeat 50% 50%;
                    border: 1px solid #BDBDBD;
                    border-radius: 50%;
                    box-sizing: border-box;
                }
            }

            input[type="checkbox"]:checked + label:before {
                background: #F4F4F4 url(/front_mo/images/btn_comm_secret-on.png?ver=20210316) no-repeat 50% 50%;
                border: 3px solid $gcolor;
            }
        }

        .notice {
            margin-top: 45px;
            padding-bottom: 50px;

            &__title {
                display: block;
                margin-bottom: 25px;
                padding-left: 40px;
                background: url(/front_mo/images/icon_comm_notice.png) no-repeat 0 50%;
                line-height: 28px;
                @extend %font_s;
                font-weight: 600;
            }

            &__desc {
                padding-left: 10px;
                line-height: 1.6;
                @extend %font_xs;
                color: $color88;
                text-indent: -10px;
                word-break: keep-all;
            }
        }
    }
}
