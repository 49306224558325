.hd {

    .brandStory__sec {
        position: relative;
        font-size: 0;

        a {
            display: block;
            position: absolute;
        }

        &.link01 a {
            top: 660px;
            left: 205px;
            width: 405px;
            height: 30px;
        }

        &.link02 a {
            top: 560px;
            left: 295px;
            width: 315px;
            height: 30px;
        }

        &.link03 a {
            top: 540px;
            left: 345px;
            width: 265px;
            height: 30px;
        }

        &__link {
            width: 364px;
            height: 58px;
            top: 17.6%;
            left: 4%;
        }
    }
}