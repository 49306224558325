.hd {
    &__confirm {
        padding-top: 200px;

        .hd__main__h3 {
            margin-bottom: 40px;
        }

        .meals {
            &__confirm {

                &.txt {
                    position: relative;
                    text-align: center;

                    &:before {
                        content: '';
                        display: block;
                        width: 79px;
                        height: 79px;
                        margin: 0 auto 20px;
                        background: $gcolor url(/front_mo/images/icon_common_check-type02.png) no-repeat 50% 50%;
                        border-radius: 50%;
                    }

                    .meals__confirm__btn{
                        position: absolute;
                        right: 15px;
                        top: -10px;
                    }
                }

                &__title {
                    @extend %font_m;
                    font-weight: 400;
                    color: $color3D;
                }

                &__check {
                    display: block;
                    margin: 15px 0 20px;
                    font-size: 30px;
                    color: $color3D;

                    span {
                        display: inline-block;
                        position: relative;
                        z-index: 1;
                        font-weight: 600;
                        line-height: 1;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            z-index: -1;
                            bottom: -5px;
                            width: 100%;
                            height: 8px;
                            background: $ocolor;
                        }
                    }
                }

                &__count {
                    @extend %font_s;
                    color: $color3D;
                }

                &__table {
                    max-width: 520px;
                    margin: 20px auto 0;

                    thead {
                        @extend %hidden;
                    }

                    tbody {

                        th {
                            padding: 20px 0;

                            border: {
                                top: 1px solid $gcolor;
                                right: 1px solid $gcolor;
                                bottom: 1px solid $gcolor;
                                left: 0;
                            }
                            font: {
								size: 24px;
								weight: 400;
							};
                            line-height: 1.4;

                            strong {
                                display: block;
                                font-weight: 600;
                                color: $color3D;
                            }
                        }

                        td {
                            padding: 30px 30px;
                            border: {
                                top: 1px solid $gcolor;
                                right: 0;
                                bottom: 1px solid $gcolor;
                                left: 1px solid $gcolor;
                            }
                            @extend %font_xs;
                            color: $color3D;
                            line-height: 32px;

                            .num {
                                font-weight: 600;
                            }
                        }
                    }

                    .item__add{
                        position: relative;
                        margin-top: 10px;
                        padding: 5px 13px 7px;
                        font-size: 19px;
                        background-color: $ocolor;
                        border-radius: 30px;
                        letter-spacing: -0.5px;

                        .btn-tooltip{
                            top: 34px;
                            animation-name: tooltipMotion2;

                            &:after{
                                top: -1px;
                                transform: rotate(225deg);
                            }
                        }

                        @keyframes tooltipMotion2 {
                            0% {top: 34px;}
                            100% {top: 39px;}
                        }
                    }
                }

                &.accordion_area{
                    margin-top: 50px;

                    .accordion_box{
                        margin: 0 auto;
                        width: 100%;
                        max-width: 520px;
                        border-bottom: 1px solid $colorBC;

                        .accordion_title{
                            position: relative;
                            padding: 25px 0;
                            font-size: 28px;
                            font-weight: 600;

                            em{
                                position: absolute;
                                right: 70px;
                                top: 50%;
                                font-size: 24px;
                                color: $ocolor;
                                transform: translateY(-50%);
                            }

                            &:after{
                                content: "";
                                display: block;
                                position: absolute;
                                top: 50%;
                                right: 0;
                                width: 30px;
                                height: 16px;
                                margin-top: -5px;
                                background: url('/front_mo/images/icon_common_drop-down.png') no-repeat;
                                background-size: 100% auto;
                                opacity: 0.6;
                            }
                        }
                        .accordion_con{
                            display: none;

                            .meals__confirm__table{margin-top: 0;}
                        }

                        &.on{
                            border-bottom: 0;

                            .accordion_title:after{
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }

            }

            &.present {
                padding: 30px;

                .meals__confirm {
                    &__title {
                        font-weight: 600;
                    }

                    &__desc {
                        position: absolute;
                        top: 3px;
                        right: 0;
                        @extend %font_xs;
                    }
                }

                .present {
                    &__wrap {
                        margin-top: 25px;
                        @extend %vertical;
                    }

                    &__list { 
                        display: table;
                        position: relative;
                        width: 100%;
                        min-height: 80px;
                        line-height: 80px;
                        padding: 13px;
                        text-align: left;
                        margin-top: -1px;
						border: 1px solid $colorBC;
						border-top:0;
                        @extend %font_xs;
                        text-align: center;
                        box-sizing: border-box;

                        &:first-child {
							margin-top: 0;
							border-top:solid 1px $colorBC;
                        }

                        &:not(.soldout){
                            &:after{
                                display: block;
                                position: absolute;
                                z-index: 2;
                                top: 0;
                                right: 0;
                                width: 45px;
                                height: 45px;
                                content: '';
                                background: #909090 url(/front_pc/images/ico_meals_check.png) no-repeat 50% 50%/50% auto;
                            }

                            &.on:after{
                                background-color: $gcolor;
                            }
                        }

                        &.on {
                            &:before {
                                content: '';
                                position: absolute;
                                z-index: 1;
                                top: -1px;
                                right: -1px;
                                bottom: -1px;
                                left: -1px;
                                box-sizing: border-box;
                                border: 3px solid $gcolor;
                            }
                        }

                        &.soldout{
                            &:before,&:after{
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                text-align: center;
                                z-index: 3;
                            }

                            &:before{
                                height: 99%;
                                background: rgba(0,0,0,0.6);
                                content: '';
                            }
                            &:after{
                                top: 50%;
                                padding: 0 20px;
                                line-height: 1.4;
                                @extend %font_xs;
                                color: #fff;
                                transform: translateY(-50%);
                                box-sizing: border-box;
                                content: '한정수량이 모두 소진되었습니다';
                            }
                        }

                        figure {
                            display: table-cell;
                            width: 140px;
                            height: 128px;
                            margin-right: 30px;
                            vertical-align: middle;
                        }

                        p {
                            display: table-cell;
                            vertical-align: middle;
                            padding: 0 13px 0 30px;
                            line-height: 32px;
                            @extend %font_xs;
                            word-break: keep-all;
                            text-align: left;
                        }
                    }

                    &__notice {
                        display: block;
                        margin-top: 20px;
                        line-height: 1.5;
                        font-size: 20px;
                        font-weight: 400;
                        color: $color88;

                        span {
                            font-weight: 600;
                        }
                    }
                }

                .alliance {
                    .present__list:before {
                        display: none !important;
                    }

                    .price {
                        display: block;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}


