.hd {
    .hm__inner{
        padding-top: 80px;
    }

    .join-order {
        position: relative;
        margin:40px 0 45px;
        text-align: center;
        counter-reset: meals_list;

        &:before {
            content: '';
            position: absolute;
            top: 15px;
            left: 50%;
            height: 1px;
            width: 320px;
            background: $gcolor;
            transform: translate(-50%, 0);
        }

        li {
            display: inline-block;
            position: relative;
            width: 160px;
            margin: 0 5px;
            vertical-align: top;
            @extend %font_xs;
            color: $color88;

            &:before {
                counter-increment: meals_list;
                content: counter(meals_list);
                display: block;
                box-sizing: border-box;
                width: 35px;
                height: 35px;
                margin: 0 auto 10px;
                text-align: center;
                line-height: 31px;
                border: 2px solid $gcolor;
                background: #FFF;
                font-size: 14px;
                font-weight: 600;
                color: $gcolor;
                border-radius: 50%;
            }

            &.on {
                color: $color3D;
    
                &:before {
                    background: $gcolor;
                    color: #FFF;
                }
            }
        }
    }
    
    .caulse-agree {
        margin-bottom:30px;
        
        &__notice {
            position: relative;
            padding-left: 15px;
            line-height: 36px;
            @extend %font_xs;
            color: $color88;

            &:before {
                content: '-';
                position: absolute;
                top: 0;
                left: 0;
                line-height: 36px;
                @extend %font_xs;
            }
        }
    }

    .accordion-menu {
        ul {
            border-top: 3px solid $color3D;
        }

        &__list {
            border-bottom: 1px solid $colorBC;

            &:first-child{
                border-top: 0;
            }

            &__head {
                position: relative;
                padding: 20px 0;

                .viewMore-btn {
                    display: block;
                    position: absolute;
                    top: 17px;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;

                    &:before {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 7px;
                        transform: translateY(-50%);
                        width: 27px;
                        height: 2px;
                        background-color: $color3D;
                        content: '';
                        opacity: .6;
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 19px;
                        transform: translateY(-50%);
                        width: 2px;
                        height: 27px;
                        background-color: $color3D;
                        content: '';
                        opacity: .6;
                    }
                }
            }

            &__checkbox {
                display: inline-block;
                font-weight: 600;
            }

            &__txt {
                display: none;
                overflow: hidden;
                overflow-y: auto;
                max-height: 300px;
                padding: 25px 30px;
                border-top: 1px solid $colorBC;
                background: $colorBG;
                box-sizing:border-box;

                p {
                    margin-top:20px;
                    @extend %font_xs;
                    color: $color88;
                    line-height: 32px;

                    &:first-child {
                        margin-top:0;
                    }
                }
            }

            &.on {                
                .viewMore-btn:after {
                    display: none;
                }
            }
        }
    }

    .marketing-agree {
        margin-top: 30px;

        &__title {
            display: block;
            @extend %font_xs;
            font-weight: 600;
        }

        &__checkbox-sec {
            margin:20px 0;

            > div {
                display: inline-block;
                margin-right: 45px;
                vertical-align: top;
            }
        }

        &__notice {
            position: relative;
            padding-left: 15px;
            line-height: 36px;
            @extend %font_xs;
            color: $color88;

            &:before {
                content: '-';
                position: absolute;
                top: 0;
                left: 0;
                line-height: 36px;
                @extend %font_xs;
            }

            &.ocolor {
                color:$gcolor;
            }
        }

        &__text{
            @extend %font_xs;
            line-height: 36px;
        }

        &__age__notice {
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid $colorBC;
            line-height: 32px;
            @extend %font_xs;
            color: $color88;
        }
    }
}