.hd {
    &__greatingTable-list {
        .sorting {
            float: right;
            position: relative;
            background: #FFF;
            cursor: pointer;

            strong {
                display: block;
                position: relative;
                padding-right: 20px;
                color: #636363;
                text-align: right;
                @extend %font_xs;
                font-weight: 400;

                &:after {
                    display: block;
                    top: 10px;
                    right: 0;
                    position: absolute;
                    width: 13px;
                    height: 8px;
                    background: #FFF url("/front_mo/images/btn_sorting_drop-down.png") no-repeat 50% 50%;
                    content: '';
                }
            }

            &__list {
                position: relative;
                padding: 0 20px;
                @extend %font_xs;

                a {
                    display: block;
                    color: #888888;
                }
            }

            .select-area {
                display: none;
                position: absolute;
                top: 50px;
                left: -120px;
                z-index: 2;
                padding: 25px 30px;
                border: 1px solid #636363;
                background: #fff;
                //opacity: 0;

                &__inner {
                    position: relative;
                }

                &__list {
                    position: relative;
                    width: 140px;
                    @extend %font_xs;

                    &:not(:first-child) {
                        padding-top: 20px;
                    }

                    &--on {
                        a {
                            color: $ocolor;
                        }

                        &:after {
                            display: block;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            width: 18px;
                            height: 20px;
                            background: #fff url("/front_mo/images/icon_sorting_check-on.png") no-repeat 0 0;
                            content: '';

                        }
                    }
                }

                &:after {
                    display: block;
                    position: absolute;
                    top: -11px;
                    right: 28px;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #636363;
                    border-right: transparent;
                    border-bottom: transparent;
                    background: #fff;
                    transform: rotate(45deg);
                    content: '';
                }

                &--show {
                    display: block;
                }
            }
        }
    }

    .greatingTable {
        margin-top: 35px;

        &__sorting:after {
            content: '';
            display: block;
            clear: both;
        }

        &__cont {
            margin-top: 30px;
            font-size: 0;
        }

        &__list {
            display: inline-block;
            width: 275px;
            vertical-align: top;
            margin: {
                bottom: 40px;
                left: 30px;
            };

            &:nth-child(odd) {
                margin-left: 0;
            }

            &__thumb {
                position: relative;
                margin-bottom: 20px;

                figure {
                    width: 275px;
                    height: 275px;
                }
            }

            &__txt {
                position: relative;
            }

            &__title {
                display: block;
                margin-bottom: 15px;
                line-height: 32px;
                @extend %font_xs;
                font-weight: 600;
                @include line(2);
            }

            &__desc {
                @extend %font_xs;
                color: #BCBCBC;

                img {
                    vertical-align: -2px;
                }
            }

            &__level,
            &__time {
                color: #3D3D3D;
            }

            &__like {
                position: absolute;
                bottom: 0;
                right: 0;
                color: #3D3D3D;

                &.on {
                    color: $ocolor;
                }
            }
        }
    }
}
