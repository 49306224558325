.hd {
    .monthStory {
        &__banner {
            width: 100%;
            height: 380px;
            font-size: 0;
        }

        &__cont {
            margin-top: 40px;
        }

        &__list {
            margin-top: 50px;

            &:first-child {
                margin-top: 0;
            }

            &__inner {
                position: relative;
            }

            &__img {
                margin-bottom: 15px;
                width: 580px;
                height: 326px;
                font-size: 0;
            }

            &__title {
                display: block;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid $colorBC;
                line-height: 34px;
                @extend %font_m;

                span {
                    @include line(2);
                }
            }

            &__desc {
                display: block;
                @include line(1);
                @extend %font_xs;
                font-weight: 400;
                line-height: 28px;
                color: $color88;
            }

            &__sort{
                display: inline-block;
                margin-bottom: 10px;
                padding: 7px 10px;
                font-size: 14px;
                color: #fff;
                background: $color3D;
            }
        }
    }
}