.hd{  
    .myLoginLog {
        &__date {
            margin-top: 40px;

            select {
                margin-top: 0;
                padding: 0 15px;
                background: url(/front_mo/images/ico_comm_select.png) no-repeat 95% 50%;
                -webkit-appearance: none;
            }
        }

        &__list {
            margin-top: 40px;
            
            th {
                padding: 20px 0;
                border-top: 3px solid $color3D;
                @extend %font_xs;
            }

            td {
                padding: 10px 0;
                border: {
                    top: 1px solid #BCBCBC;
                    bottom: 1px solid #BCBCBC;
                }
                text-align: center;
                @extend %font_xs;

                &:nth-child(2) {
                    font-weight: 600;
                }

                img {
                    vertical-align: middle;
                }
            }
        }

        &__device {
            margin-top: 30px;
            text-align: right;

            ul {
                font-size: 0;
                
                li {
                    display: inline-block;
                    margin-left: 20px;
                    vertical-align: top;
                    line-height: 54px;
                    @extend %font_xs;

                    img {
                        vertical-align: top;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
