.hd {
    &__applyOrderCancel {
        padding-top: 190px;

        .btn-area {
            margin-top: 40px;

            button {
                font-weight: bold;
            }
        }


        .singleCase{
            .inner {
                .txt-area__desc .amount{width: 100%;}
            }
        }

        .multiCase{
        }

        .planmeals {
            .meals-delivery {
                padding: 25px 0;
                border-top: 1px solid $colorBC;
                font-size: 22px;
                font-weight: 600;

                &__tit {
                    display: inline-block;
                    text-align: left;
                }

                &__price {
                    display: inline-block;
                    float: right;
                    text-align: right;
                }
            }
        }
    }

    [class*="applyOrder"], [class*="myClaim"] {
        .item-box{
            .checks {
                position: absolute;
                top: 25px;
                left: 0;
            }

            &__list{
                margin-top: -1px;
                position: relative;
                border-top: 1px solid #BCBCBC;

                > .checkbox__label:before{
                    position: absolute;
                    left: 20px;
                    top: 3px;
                    z-index: 2;
                }

                label .store{
                    padding-left: 65px;
                }

                .txt-area{
                    width: -webkit-calc(100% - 175px);
                    width: calc(100% - 175px);
                }


                .inner .checkbox__label, .inner .chkbox, .multiCase .chkbox,  .multiCase .txt-area__title{
                    display: inline-block;
                    vertical-align: top;
                }

                .inner .chkbox .checks, .multiCase .chkbox .checks{
                    position: relative;
                    left: 0;
                    top: 0;
                }

                .multiCase{
                    .mealTop-area__inner{
                        padding-bottom: 20px;
                    }

                    .btn__mealsMore{
                        margin: 0 20px;
                        border: 1px solid #BCBCBC;
                    }

                    .regular-meals{
                        margin: 0 20px;
                    }

                    .price-area{
                        border-top: 0;
                    }
                }
            }

            .process-text{
                color: $gcolor;
            }

            .price-area {
                padding: 20px !important;
            }

            .inner{
                .price-area {
                    padding: 20px 0 !important;
                }
            }

            .delivery-fee{
                padding: 0 20px 25px !important;
            }

            .list-more .regular-meals__list{
                .txt-area{
                    width: -webkit-calc(100% - 125px) !important;
                    width: calc(100% - 125px) !important;
                }

                 .price-area{
                    padding: 0 !important;
                }
            }
        }
        .list_top {
            padding: 20px;
            border-bottom: 0;
        }

        .select-box {
            select {
                background: url(/front_mo/images/icon_market_drop-down-on.png) no-repeat right 15px top 50%;
                border: 1px solid #BEBEBE;
            }

            .textarea-wrap {
                position: relative;
                display: block;
                margin-bottom: 20px;
                height: 170px;
                margin-top: 20px;
                box-sizing: border-box;
                border: 1px solid #BEBEBE;

                textarea {
                    overflow: hidden;
                    overflow-y: auto;
                    width: 100%;
                    height: 100%;
                    padding: 25px;
                    border: none;
                    box-sizing: border-box;
                    vertical-align: top;
                    line-height: 1.5;
                    font-size: 24px;
                    resize: none;
                    background: none;
                    word-break: break-all;
                    word-wrap: break-word;
                    white-space: pre-line;
                }
            }
        }

        .required {
            color: $gcolor;
            font-weight: normal;
        }

        .dawnDelivery-info .infobox {
            padding: 30px 40px 30px 30px;
            border-top: 0;
        }

        .product-wrap{
            .info{
                &-area{
                    margin-bottom: 20px;
                }

                &-list{
                    .select-box{
                        padding: 0;
                        margin-bottom: 0;
                        background: #fff;
                    }
                }
            }
            
            .product-wrap{
                margin-bottom: 0;
                border-top: 0;
                box-shadow: none;
                background: transparent;
                
                .head-txt{
                    margin: 0 -20px;
                    border-bottom: 1px solid #BCBCBC;
                }

                .info-area{
                    padding: 0;
                }

            }

            .input_type.phone{
                .loginSelect{
                    width: 150px !important;
                    background-color: #fff;
                }
                
                input{
                    width: 159px;
                }
            }

        }

        .deliveryWay, .deliveryWay-btn{
            margin-top: 25px;
        }

        .infoboxV2{
            margin: 0;
        }
    }
}