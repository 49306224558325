.hd {
    color: $color3D;

    .point_color{color: $gcolor;}
    .point_color2{color: $gcolor;}

    &#main,
    &#market_main,
    &#market,
    &#planMeals {
        .hd__btn-tutorial {
            display: block;
        }
    }

    button:focus {
        outline: none;
    }

    &__sub {
        padding-top: 150px;
    }

    &__inner {
        width: 580px;
        margin: 0 auto;
    }

    &__title-hidden {
        display: none;
    }

    &__text-uline {
        position: relative;
        z-index: 0;

        &:after {
            display: block;
            position: absolute;
            bottom: 8%;
            left: -4px;
            z-index: -1;
            width: 100%;
            height: 13%;
            padding: 0 4px;
            background-color: $ocolor;
            content: "";
        }
    }

    &__btn-heart {
        display: table;
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 2;
        width: 58px;
        height: 58px;
        background: #FFF;
        border: 1px solid #A8A8A8;
        border-radius: 40px;

        span {
            display: table-cell;
            vertical-align: middle;

            &:after {
                display: table-cell;
                width: 33px;
                height: 30px;
                margin: 0 auto;
                background: url("/front_mo/images/btn_heart-off.png") no-repeat 0 0;
                vertical-align: middle;
                content: "";
            }
        }

        &--active {
            span {
                display: table-cell;
                vertical-align: middle;

                &:after {
                    background: url("/front_mo/images/btn_heart-on.png") no-repeat 0 0;
                }
            }
        }
    }

    .swiper-pagination {
        margin-top: 30px;
        text-align: center;

        .swiper-pagination-bullet {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin: 0 4px;
            background-color: #d6d6d6;
            border-radius: 5px;
            outline: 0;

            &-active {
                width: 25px;
                background-color: $gcolor;
            }
        }

    }

    .tnb-area {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 57px;
        padding-top: 28px;
        background-color: #FFF;
        border-bottom: 10px solid #FFF;

        &__inner {
            position: relative;
            padding: 0 30px;
            height: 57px;
            margin: 0 auto;
            @extend %clear;

            &:after {
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                width: 100%;
                height: 3px;
                background: $gcolor;
                content: '';
            }
        }

        &__list {
            position: relative;
            float: left;
            width: 148px;
            height: 55px;
            @extend %font_xs;
            font-weight: bold;
            text-align: center;
            margin-left: -5px;

            &:first-child {
                margin-left: 0;
            }

            a {
                display: block;
                padding-right: 24px;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 0;
                    height: 0;
                    border: {
                        top: 24px solid transparent;
                        right: 13px solid transparent;
                        bottom: 22px solid #EAEAEA;
                        left: 14px solid #EAEAEA;
                    }
                }

                strong {
                    display: block;
                    background: #eaeaea;
                    border-radius: 20px 20px 0 0;
                    padding: 15px 0;
                    color: #666;
                }
            }

            &--active {
                z-index: 2;

                a {
                    &:after {
                        border: {
                            top: 24px solid transparent;
                            right: 13px solid transparent;
                            bottom: 22px solid $gcolor;
                            left: 14px solid $gcolor;
                        }
                    }

                    strong {
                        background: $gcolor;
                        color: #FFF;
                    }
                }
            }
        }

        &__count {
            display: block;
            position: absolute;
            top: -16px;
            right: 10px;
            z-index: 2;
            min-width: 29px;
            min-height: 29px;
            padding: 0 8px;
            background: #3d3d3d;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 28px;
            border-radius: 15px;
            box-sizing: border-box;
        }

        &--fixed {
            position: fixed;
            top: 63px;
        }
    }

    &--fixed {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
    }

    .input-number-password {
        -webkit-text-security: disc;
    }

    .sns-login {
        padding-top: 33px;
        text-align: center;

        &__tit {
            margin-bottom: 26px;
            font-weight: bold;
            @extend %font_s;
        }

        a {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            line-height: 70px;
            text-align: center;
            border-radius: 50%;
            @extend %font_s;

            & + a {
                margin-left: 42px;
            }

            span {
                display: inline-block;
                padding-top: 95px;
                line-height: 32px;
            }
        }

        .disable{
            -webkit-filter: grayscale(100%);
            filter: gray;
            opacity: 0.5;
        }

        $list : naver, kakao, google;
        @for $i from 1 through length($list) {
          &-#{nth($list, $i)}:before {
            display: block;
            position: absolute;
            top: 7px;
            left: 10px;
            width: 40px;
            height: 70px;
            margin-left: 12px;
            background: url(/front_mo/images/icon_sns-login.png) no-repeat -#{($i - 1) * 46}px;  
            content: '';
          }
        }

        &-kakao {
            &:before {
                width: 42px;
                margin-left: 10px;
                background-position-x: -44px;
            }
        }

        &-naver {
            background: #3bab36;
            border:1px solid #c7c7c7;
            box-sizing: border-box;

            a {
                color: #fff;
            }
        }

        &-kakao {
            background: #fae100;
        }

        &-google {
            background: #f0f0f0;
        }

        &-apple {
            background: #000;
            &:before{
                content: '';
                background: url(/front_mo/images/apple_logo_white.svg) 0 0 no-repeat;
                width:100%; height:100%;
                position: absolute;
                background-size:100% 100%;
                border-radius: 50%;
                left:0;
            }
        }
    }

    .coupon-download {
        &__desc {
            margin-bottom: 20px;
            @extend %font_xs;
        }

        &__list {
            &:not(:first-child) {
                margin-top: 30px;
            }

            .coupon {
                overflow: hidden;
                position: relative;
                height: 180px;
                background-color: $gcolor;
                @extend %clear;
                cursor: pointer;

                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    width: 0;
                    height: 100%;
                }

                &__info {
                    display: inline-block;
                    vertical-align: middle;
                    width: 360px;
                    padding-left: 62px;

                    &:before {
                        display: block;
                        position: absolute;
                        left: -18px;
                        top: 50%;
                        width: 36px;
                        height: 36px;
                        margin-top: -18px;
                        background-color: #FFF;
                        border-radius: 20px;
                        content: '';
                    }
                }

                &__condi {
                    display: block;
                    padding-top: 25px;
                    color: #FFF;
                    font-size: 18px;
                }

                &__name {
                    display: block;
                    color: #FFF;
                    @extend %font_s;
                }

                &__price {
                    display: block;
                    padding-top: 35px;
                    color: #FFF;
                    @extend %font_m;

                    strong {
                        font-size: 46px;
                    }
                }

                .download-btn {
                    display: block;
                    float: right;
                    width: 97px;
                    margin-top: 50px;
                    color: #FFF;
                    @extend %font_xs;
                    text-align: center;
                    line-height: 24px;

                    &:before {
                        display: block;
                        position: absolute;
                        top: -4px;
                        width: 97px;
                        height: 110%;
                        border: 1px dashed #FFF;
                        border-width: 2px;
                        opacity: 0.4;
                        content: '';
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        top: 95px;
                        right: 36px;
                        width: 23px;
                        height: 25px;
                        margin-top: 13px;
                        background: url("/front_mo/images/btn_download.png") no-repeat 0 0;
                        content: '';
                    }

                    &-done {
                        display: none;
                    }
                }

                &--done {
                    background-color: #DCDCDC !important;

                    .download-btn {
                        display: none;
                    }

                    .download-btn-done {
                        display: block;
                        float: right;
                        width: 97px;
                        margin-top: 50px;
                        color: #FFF;
                        font-size: 20px;
                        text-align: center;
                        line-height: 24px;

                        &:before {
                            display: block;
                            position: absolute;
                            top: -4px;
                            width: 97px;
                            height: 110%;
                            border: 1px dashed #FFF;
                            border-width: 2px;
                            opacity: 0.4;
                            content: '';
                        }

                        &:after {
                            display: none;
                        }
                    }
                }
            }

            .download-info {
                margin: 20px 25px 0;

                &__list {
                    @extend %font_xs;
                    line-height: 30px;
                }
            }
        }
    }

    .fadeInUp {
        -webkit-animation: fadeInUp 0.9s both 0.25s cubic-bezier(0.22, 0.61, 0.87, 1.27);
                animation: fadeInUp 0.9s both 0.25s cubic-bezier(0.22, 0.61, 0.87, 1.27);
    }

    .fadeInDown {
        -webkit-animation: fadeInDown 0.9s both 0.25s cubic-bezier(0.22, 0.61, 0.87, 1.27);
                animation: fadeInDown 0.9s both 0.25s cubic-bezier(0.22, 0.61, 0.87, 1.27);
    }

    .delay_500 {
        animation-delay: .5s;
    }

    @-webkit-keyframes fadeInUp {
        0% {
            opacity: 0;
            transform: translate3d(0, 30px, 0);
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    @-webkit-keyframes fadeInDown {
        0% {
            opacity: 0;
            transform: translate3d(0, -30px, 0);
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .px__slider {
        width: 580px;
        height: 530px;
        transition: opacity 0.6s ease, transform 0.3s ease;
    }

    .px__slider.loading {
        opacity: 0;
        visibility: hidden;
    }

    .px__slider__list {
        box-sizing: border-box;
        overflow: hidden;
    }

    .px__slider__inner {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .px__slider__bgimg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 100% auto;
    }

    .px__slider__bgimg .entity-img {
        display: none;
    }

    .comDelivery {
        &__annc {
            @extend %font_xs;
            line-height: 32px;
        }

        &__list-wrap {
            margin: 30px 0;
            border-top: 1px solid $colorBC;
        }

        &__list {
            padding: 25px 0;

            &:not(:last-child) {
                border-bottom: 1px solid $colorBC;
            }
        }

        .contbox {
            padding-left: 60px;

            &__list {
                position: relative;
                @extend %font_xs;

                &:not(:first-child) {
                    margin-top: 10px;
                }

                &__tit {
                    display: inline-block;
                    width: 120px;
                }
            }
        }

        &__desc {
            margin-top: 30px;
            line-height: 32px;
            @extend %font_xs;

            &__list {
                padding-left: 10px;
                text-indent: -14px;
                word-break: keep-all;
            }
        }
    }

    .check-label {
        display: block;
        position: relative;

        input[type="checkbox"],
        [type="radio"] {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;
        }

        .check-area {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);

            .check-icon {
                display: block;
                width: 40px;
                height: 40px;
                background-image: url("/front_mo/images/btn_comm_radio.png?ver=210226")  !important;
                background-position: -2px 0;
                background-repeat: no-repeat;
            }

            input[type="checkbox"]:checked + .check-icon,
            input[type="radio"]:checked + .check-icon {
                display: inline-block;
                width: 40px;
                height: 40px;
                background-image: url("/front_mo/images/btn_comm_radio.png?ver=210226")  !important;
                background-position: -45px 0;
                background-repeat: no-repeat;
            }
        }
    }

    // 버튼 공통
    .btn-full {
        width: 580px;
        height: 70px;
        box-sizing: border-box;
    }

    .btn-half {
        float: left;
        width: 290px;
        height: 70px;
        box-sizing: border-box;
    }

    .btn-green {
        background: $gcolor;
        color: #FFF;
        font-size: 24px;
        line-height: 68px;
        border: 1px solid $gcolor;
        box-sizing: border-box;
    }

    .btn-orange {
        background: #FFF;
        border: 1px solid $gcolor;
        color: $gcolor;
        font-size: 24px;
        line-height: 68px;
    }

    .btn-white {
        background: #FFF;
        border: 1px solid $gcolor;
        color: $color3D;
        font-size: 24px;
        line-height: 68px;
    }

    .btn-white-v2 {
        background: #FFF;
        border: 1px solid #BCBCBC;
        color: $color3D;
        font-size: 24px;
        line-height: 68px;
    }

    .btn-gray {
        background: #a8a8a8;
        border: 1px solid #a8a8a8;
        color: #FFF;
        font-size: 24px;
        line-height: 70px;
    }

    .btn-arr {
        position: relative;
        padding-right: 40px;
        padding-left: 30px;
        height: 65px;
        line-height: 65px;
        background: #FFF;
        border: 1px solid #BCBCBC;
        font-size: 24px;
        color: $color3D;
        text-align: left;
        
        &:after{
            position: absolute;
            top: 50%;
            right: 15px;
            width: 30px;
            height: 16px;
            margin-top: -8px;
            background: url(/front_mo/images/icon_common_drop-down.png) no-repeat;
            background-size: 100%;
            opacity: 0.5;
            transform: rotate(-90deg);
            content: '';
        }
    }

    .btn-tooltip{
        visibility: visible;
        position: absolute;
        z-index: 1;
        top: -51px;
        left: 50%;
        padding: 10px;
        line-height: 1;
        font-size: 19px;
        font-weight: 100;
        color: $color3D;
        text-align: center;
        background-color: #fff;
        border-radius: 7px;
        border: 1px solid #4e4e4e;
        white-space: nowrap;
        letter-spacing: -1px;
        transform: translateX(-50%) rotate(0.001deg);
        -webkit-transform: translate3d(-50%, 0, 0);
        animation: tooltipMotion 0.5s ease-out  0s infinite alternate;
    }
    .btn-tooltip:after
    {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: -7px;
        margin-left: -7px;
        width: 14px;height: 14px;
        background: #fff;
        border: 1px solid #4e4e4e;
        border-left: 0;
        border-top: 0;
        transform: rotate(45deg);

    }
    @keyframes tooltipMotion {
        0% {top: -51px;}
        100% {top: -41px;}
    }

    .btn-round{
        padding: 8px 10px;
        line-height: 1.3;
        font-size: 20px;
        color: #707070;
        background: #fff;
        border: 1px solid #c5c5c5;
        border-radius: 6px;
    }

    .relative{
        position: relative;
    }

    .discount-tooltip{
        visibility: visible;
        position: absolute;
        top: 130%;
        right: 50%;
        padding: 8px 15px;
        line-height: 1.3;
        font-size: 20px;
        font-weight: 100;
        color: #ff770e;
        text-align: center;
        background-color: #fff;
        border-radius: 30px;
        border: 1px solid #ff8a2f;
        white-space: nowrap;
        letter-spacing: -1px;
        transform: translateX(50%);
        z-index: 1;

        &:after{
            content: "";
            position: absolute;
            top: 0;
            right: 50%;
            margin-top: -2px;
            margin-left: -5px;
            width: 10px;height: 10px;
            background: #fff;
            border: 1px solid #ff8a2f;
            border-left: 0;
            border-top: 0;
            transform: rotate(-135deg) translateX(50%);
        }

        &.top{
            top: -150%;

            &:after{
                top: 85%;
                transform: rotate(45deg) translateX(50%);
            }
        }

        &.right{
            right: inherit;
            left: 110%;
            top: 50%;
            transform: translateX(0) translateY(-50%);

            &:after{
                right: inherit;
                left: -2%;
                top: 50%;
                margin-top: -10px;
                transform: rotate(135deg) translateX(0) translateY(-50%);
            }
        }
    } 

    // round 체크박스 공통
    .checkbox-round {
        float: left;
    }

    .checkbox-round + .checkbox-round {
        margin-left: 35px;
    }

    .checkbox__label {
        display: block;
        position: relative;
        @extend %font_xs;

        &:before {
            content: '';
            display: inline-block;
            width: 34px;
            height: 34px;
            margin-right: 10px;
            border: 1px solid #c2c2c2;
            border-radius: 100%;
            vertical-align: middle;
            background: #FFF url("/front_mo/images/icon_member_check-off.png") no-repeat center center;
        }
    }

    .checkbox__input:checked + .checkbox__label:before {
        border: 1px solid $gcolor;
        background: $gcolor url(/front_mo/images/icon_member_check-on.png) no-repeat center center;
    }

    // input[type="text"] 공통
    .input-box + .input-box {
        margin-top: 20px;
    }

    .input_text {
        width: 100%;
        height: 70px;
        padding: 0 20px;
        background: #FFF;
        border: 1px solid #BEBEBE;
        color: $color3D;
        font-size: 24px;
        border-radius: 0;
        box-sizing: border-box;
        line-height: 64px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .input_text:focus,
    input[type="text"]:focus {
        outline: 3px solid $gcolor;
        color: $color3D;
    }

    .input_text::-webkit-input-placeholder {
        color: #BDBDBD;
    }

    .input_text::-moz-placeholder {
        color: #BDBDBD;
    }

    .input_text:-ms-input-placeholder {
        color: #BDBDBD;
    }

    .input_text::-ms-input-placeholder {
        color: #BDBDBD;
    }

    .input_text::placeholder {
        color: #BDBDBD;
    }

    // Tab 공통
    .tab-area {
        width: 100%;
    }

    .tab-area .tab-list {
        float: left;
        position: relative;
        width: 50%;
        height: 67px;
        border: 1px solid $colorBC;
        border-bottom: 3px solid $color3D;
        box-sizing: border-box;
        line-height: 67px;
        text-align: center;
        color: $color88;
    }

    .tab-area .tab-list--on {
        border: 3px solid $color3D;
        border-bottom: none;
        color: $color3D;
        font-weight: bold;
    }

    .tab-area .tab-list a {
        position: relative;
        display: block;
        width: 100%;
        height: 67px;
        line-height: 61px;
        font-size: 24px;
        z-index: 1;
        color: inherit;
    }

    .tab-area-v2 {
        width: 100%;
    }

    .tab-area-v2 .tab-list-v2 {
        float: left;
        position: relative;
        width: 50%;
        height: 67px;
        background: $colorBG;
        border: 1px solid $colorBC;
        box-sizing: border-box;
        line-height: 67px;
        text-align: center;
        color: #9f9f9f;
    }

    .tab-area-v2 .tab-list-v2--on {
        background: #FFF;
        border-bottom: none;
        color: $color3D;
        font-weight: bold;
    }

    .tab-area-v2 .tab-list-v2 a {
        position: relative;
        display: block;
        width: 100%;
        height: 67px;
        line-height: 67px;
        font-size: 24px;
        z-index: 1;
        color: inherit;
    }

    .tab-area-v3{
        display: flex;
        border-bottom: 10px solid #eee;
        background: #fff;

        .tab-list-v3{
            display: block;
            position: relative;
            width: 100%;
            height: 85px;
            border-bottom: 3px solid #e5e5e5;
            box-sizing: border-box;
            line-height: 85px;
            text-align: center;
            color: #555;

            a{
                position: relative;
                display: block;
                font-size: 24px;
                z-index: 1;
                color: inherit;

                &:after{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -12px; 
                    width: 1px;
                    height: 24px;
                    background: $colorBC;
                    content: '';
                }
            }

            &:first-child{
                a:after{
                    display: none;
                }
            }

            &--on{
                border-color: $gcolor;

                a{
                    color: $gcolor;
                    font-weight: bold;

                }
            }
        }
        
    }

    .clearfix {
        &:after {
            display: block;
            clear: both;
            content: "";
        }
    }

    .tab-contents {
        display: none;
    }

    .tab-contents--current {
        display: block;
	}
	
	// 마이페이지 Tab 
	.myInquiryList {	
		.tnb-area {position:static;
			&__inner{width:100%;}
		}
	}

    // 레이어팝업(success & alert 공통)
    .comm-layer {
        height: 370px;
        font-size: 0;

        &__inner {
            display: inline-block;
            width: 100%;
            vertical-align: middle;
            text-align: center;
        }

        &__success {

            &:before {
                content: '';
                display: block;
                width: 79px;
                height: 79px;
                margin: 0 auto 35px;
                background: $gcolor url("/front_mo/images/icon_common_check-type02.png") no-repeat 50% 50%;
                border-radius: 50%;
            }

        }

        &__alert {

            &:before {
                content: '';
                display: block;
                width: 79px;
                height: 79px;
                margin: 0 auto 35px;
                background: url("/front_mo/images/icon_important-msg.png") no-repeat 50% 50%;
                border-radius: 50%;
            }

        }

        &__title {
            display: block;
            font-size: 30px;
            font-weight: 600;
            color: $color3D;
        }

        &__desc {
            margin-top: 25px;
            line-height: 1.6;
            color: $color3D;
            @extend %font_xs;
        }

        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 100%;
        }

        .modal-btn {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 84px;
            border-top: 1px solid $colorBC;
            line-height: 84px;

            &-half {
                display: inline-block;
                width: 50%;
                height: 84px;
                text-align: center;
                font-weight: 600;
                color: $color3D;
                @extend %font_m;

                &:not(:first-child) {
                    position: relative;

                    &:before {
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1px;
                        height: 100%;
                        border-left: 1px solid $colorBC;
                        content: '';
                    }
                }
            }
        }
    }

    // 마이페이지 > 나의쿠폰 > 쿠폰적용대상 팝업
    .popup_coupon-target {
        .coupon-target {

            .infoBox {
                &__title {
                    padding-bottom: 15px;
                    @extend %font_s;
                    border-bottom: 1px solid $colorBC;
                    font-weight: bold;
                }

                &__list{
                    display: block;
                    margin-top: 15px;
                }

                &__cont {
                    overflow-y: auto;
                    max-height: 200px;
                    padding: 20px 0;
                    @extend %font_xs;

                    &__part {
                        display: block;
                        @extend %font_xs;

                        &:not(:first-child) {
                            margin-top: 15px;
                            color: $gcolor;
                        }
                    }

                    &__part2 {
                        display: block;
                        @extend %font_xs;

                        &:not(:first-child) {
                            margin-top: 15px;
                        }

                        .option{
                            position: relative;
                            margin-top: 5px;
                            padding-left: 110px;
                            line-height: 1.5;
                            color: $color88;

                            span{
                                position: absolute;
                                left: 10px;
                                top: 0;
                                width: 110px;
                                vertical-align: top;
                            }
                        }
                    }
                }

                & + .infoBox {
                    margin-top: 30px;
                }

            }

            .desc {
                position: relative;
                padding-top: 25px;
                padding-left: 15px;
                border-top: 1px solid $colorBC;
                @extend %font_xs;
                color: #888;
                line-height: 1.6;

                &:before {
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    content: '-';
                }
            }

        }

    }
  
        
    // 합배송 레이어팝업
    .popup_jointDelivery {

        .jointDelivery {
            line-height: 30px;
            font-size: 20px;

            .joint {
                margin: 20px 0;
                border-top: 1px solid $colorBC;
                border-bottom: 1px solid $colorBC;
    
                &__list {
                    padding: 20px 0;
    
                    & + .joint__list {
                        border-top: 1px solid $colorBC;
                    }

                    .check-label {
                        min-height: 37px;
                    }
                }
    
                &__cont {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 60px;
                }
            }
    
            .not-joint {
                margin-bottom: 20px;
                padding: 20px 0;
                border-bottom: 1px solid $colorBC;
            }
        }

        .btn__order {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 84px;
            line-height: 84px;
            text-align: center;
            font-size: 26px;
            font-weight: 600;
            background: $gcolor;
            color:#fff;
            box-sizing: border-box;
        }

    }

    .popup_pointConver {

        .pointConver {

            &__annc {
                @extend %font_xs;

                &__tit {
                    padding-bottom: 20px;
                    border-bottom: 1px solid #CDCDCD;
                }

                .txt-box {
                    padding: 20px 0;

                    &__list {
                        position: relative;
                        padding-left: 15px;
                        line-height: 1.6;

                        &:before {
                            display: block;
                            position: absolute;
                            left: 0;
                            content: '-';
                        }
                    }
                }
            }

            .input-area {
                width: 100%;
            }

            .btn-area {
                width: 100%;
                margin-top: 20px;

                button {
                    width: 100%;
                }
            }

            &__desc {
                margin-top: 30px;
                line-height: 1.6;
                color: #888;
                @extend %font_xs;

                &__list {
                    position: relative;
                    padding-left: 15px;

                    &:before {
                        display: block;
                        position: absolute;
                        left: 0;
                        content: '-';
                    }
                }
            }
        }
    }

    &__ico-area {
        margin-top: 15px;
        padding: 10px 0;
        border: {
            top: 1px solid $colorBC;
            bottom: 1px solid $colorBC;
        };
        font-size: 0;

        li {
            display: inline-block;
            width: 16%;
            margin-top: 5px;
            vertical-align: top;
            text-align: center;

            img {
                width: 35px;
                height: 35px;
                vertical-align: top;
            }
        }
    }

    // 이용약관, 개인정보처리방침 아코디언이벤트
    .adminSetPage {
        .accordion {

            .accordion__head {
                // background: none;  
                background: url(/front_mo/images/icon_common_drop-down.png) no-repeat right center;
                background-size: 2rem;

                &:before {
                    // content: "열기";
                    // float: right;
                }
            }

            &.on {
                
                .accordion__head {
                    background: url(/front_pc/images/btn_comm_accordion-up.png) no-repeat right center;
                    background-size: 2rem;
                }
                // background: none;

                // .accordion__head {
                //     &:after {
                //         content: "닫기";
                //         display: inline-block;
                //         float: right;
                //     }
                // }
            }

        }
    }

    // icon
    [class^="ico_"]{
        display: inline-block;
        margin-top: -3px;
        width: 30px; height: 30px;
        vertical-align: middle;
        background: url("/front_mo/images/sp_ico.png?ver=211231") no-repeat 0 0;
        background-size: 640px auto;
    }

    .ico_gift{
        background-position: 5px 0px;
    }
    .ico_notify{
        width: 32px;
        height: 40px;
        background-position: -61px -34px;
    }
    .ico_notify-v2{
        width: 32px;
        height: 40px;
        background-position: -61px -74px;
        transform: scale(0.8);
    }
    .ico_tooltip{
        width: 1.5rem;
        height: 1.7rem;
        margin-left: 0.35rem;
        background: url("/front_mo/images/icon_order-payment_Qmark.png") no-repeat 0 0;
        background-size: 1.5rem 1.5rem;
    }
    .ico_delivery{
        width: 55px;
        height: 30px;
        background-position: -74px 0px;
    }

    .ico_eco{
        width: 38px;
        height: 26px;
        background-position: -220px -76px;
    }

    .ico_report{
        margin: -5px 5px 0;
        width: 20px;
        height: 20px;
        background-position: -253px -191px;
    }

    // progress bar
    .progress{
        &_wrap{
            display: flex;
            width: 100%;
            align-items: center;
            vertical-align: top;
        }

        &_bg{
            position: relative;
            flex-grow: 1;
            height: 10px;
            border-radius: 5px;
            background-color: rgba(0,0,0,0.2);
        }

        &_bar{
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 10px;
            font-size: 0;
            text-indent: -9999px;
            border-radius: 5px;
            background-color: $color3D;
        }
    }

    // 배송방법
    .deliveryWay{
        label{
            display: block;
            padding-right: 70px;
            padding-left: 20px;
            height: 65px !important;
            line-height: 65px;
            background: #FFF;
            border: 1px solid #f9f9f9;
            font-size: 24px;
            color: $color3D;

            &:not(:first-child){
                margin-top: 10px;
            }

            &.on{
                border-color:$gcolor;

                .checks:before {
                  background: $gcolor url(/front_mo/images/ico_comm_checkbox-on.png) no-repeat 50% 50%;
                  border: 1px solid $gcolor;
                }
            }
        }

        &-btn{
            padding: 15px;
            width: 100% !important;
            min-height: 65px;
            height: auto !important;
            line-height: 1.3;
            @extend %font_xs;

            &__txt{
                display: block;
                margin-top: 15px;
                font-size: 20px;
                color: $color88;
                @extend %clear;

                dt{
                    float: left;
                    margin-top: 5px;
                    width: 50%;

                    &:nth-of-type(1){margin-top: 0;}
                }

                dd{
                    float: right;
                    margin-top: 5px;
                    width: 50%;
                    &:nth-of-type(1){margin-top: 0;}
                }
            }
        }
    }

    .tab-type3{
        text-align: center;

        li{
            display: inline-block;
            margin: 0 5px;

            a{
                display: block;
                padding: 15px 20px;
                @extend %font_xs;
                background: $colorBG;
                border-radius: 30px;
            }

            &.on{
                a{
                    color: #fff;
                    background: $gcolor;
                }
            }
        }
    }
}

[numberOnly] {
    ime-mode: disabled;
}

.listOrange {
    color: $ocolor;
}

.wdw-type {
    .hd {

        &__sub {
            padding-top: rem(150px);
        }

        &__inner {
            width: rem(580px);
            margin: rem(0 auto);
        }

        &__text-uline {

            &:after {
                display: block;
                position: absolute;
                bottom: 8%;
                left: rem(-4px);
                z-index: -1;
                width: 100%;
                height: 13%;
                padding: rem(0 4px);
                background-color: $ocolor;
                content: "";
            }
        }

        &__btn-heart {
            display: table;
            position: absolute;
            right: rem(20px);
            bottom: 20px;
            z-index: 2;
            width: rem(58px);
            height: rem(58px);
            background: #FFF;
            border: 1px solid #A8A8A8;
            border-radius: rem(40px);

            span {
                display: table-cell;
                vertical-align: middle;

                &:after {
                    display: table-cell;
                    width: rem(33px);
                    height: rem(30px);
                    margin: 0 auto;
                    background: url("/front_mo/images/btn_heart-off.png") no-repeat 0 0;
                    background-size: rem(33px 31px);
                    vertical-align: middle;
                    content: "";
                }
            }

            &--active {
                span {
                    display: table-cell;
                    vertical-align: middle;

                    &:after {
                        background: url("/front_mo/images/btn_heart-on.png") no-repeat 0 0;
                        background-size: rem(33px 31px);
                    }
                }
            }
        }

        .swiper-pagination {
            margin-top: rem(30px);
            @extend %clear;

            .swiper-pagination-bullet {
                display: inline-block;
                float: left;
                width: rem(9px);
                height: rem(9px);
                margin: rem(3px 4px);
                background-color: #333;
                border-radius: rem(20px);
            }

            .swiper-pagination-bullet-active {
                width: rem(9px);
                height: rem(9px);
                margin: rem(0 15px);
                background-color: #FFF;
                border: rem(3px) solid #000;
            }
        }

        .tnb-area {
            height: rem(57px);
            padding-top: rem(28px);
            border-bottom: rem(10px) solid #FFF;

            &__inner {
                padding: rem(0 30px);
                height: rem(57px);
                @extend %clear;

                &:after {
                    width: 100%;
                    height: rem(3px);
                }
            }

            &__list {
                position: relative;
                float: left;
                width: rem(148px);
                height: rem(55px);
                @extend %font_xs;
                font-weight: bold;
                text-align: center;
                margin-left: rem(-5px);

                &:first-child {
                    margin-left: 0;
                }

                a {
                    display: block;
                    padding-right: rem(24px);

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 0;
                        height: 0;
                        border: {
                            top: rem(24px) solid transparent;
                            right: rem(13px) solid transparent;
                            bottom: rem(22px) solid #EAEAEA;
                            left: rem(14px) solid #EAEAEA;
                        }
                    }

                    strong {
                        display: block;
                        background: #eaeaea;
                        border-radius: rem(20px 20px 0 0);
                        padding: rem(15px 0);
                    }
                }

                &--active {
                    z-index: 2;

                    a {
                        &:after {
                            border: {
                                top: rem(24px) solid transparent;
                                right: rem(13px) solid transparent;
                                bottom: rem(22px) solid $gcolor;
                                left: rem(14px) solid $gcolor;
                            }
                        }

                        strong {
                            background: $gcolor;
                            color: #FFF;
                        }
                    }
                }
            }

            &__count {
                display: block;
                position: absolute;
                top: rem(-16px);
                right: rem(10px);
                z-index: 2;
                min-width: rem(29px);
                min-height: rem(29px);
                padding: rem(0 8px);
                color: #FFF;
                font-size: rem(14px);
                text-align: center;
                line-height: rem(28px);
                border-radius: rem(15px);
                box-sizing: border-box;
            }

            &--fixed {
                position: fixed;
                padding-top: rem(30px);
            }
        }

        &--fixed {
            position: fixed;
            left: 50%;
            transform: translateX(-50%);

            .progress:before{
                position: sticky;
                left: rem(640px);
            }
        }

        .coupon-download {
            &__desc {
                margin-bottom: rem(20px);
                @extend %font_xs;
            }

            &__list {
                &:not(:first-child) {
                    margin-top: rem(30px);
                }

                .coupon {
                    overflow: hidden;
                    position: relative;
                    height: rem(180px);
                    background-color: $gcolor;
                    @extend %clear;
                    cursor: pointer;

                    &:before {
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        width: 0;
                        height: 100%;
                    }

                    &__info {
                        display: inline-block;
                        vertical-align: middle;
                        width: rem(360px);
                        padding-left: rem(62px);

                        &:before {
                            display: block;
                            position: absolute;
                            left: rem(-18px);
                            top: 50%;
                            width: rem(36px);
                            height: rem(36px);
                            margin-top: rem(-18px);
                            background-color: #FFF;
                            border-radius: rem(20px);
                            content: '';
                        }
                    }

                    &__condi {
                        display: block;
                        padding-top: rem(25px);
                        color: #FFF;
                        font-size: rem(18px);
                    }

                    &__name {
                        display: block;
                        color: #FFF;
                        @extend %font_s;
                    }

                    &__price {
                        display: block;
                        padding-top: rem(35px);
                        color: #FFF;
                        @extend %font_m;

                        strong {
                            font-size: rem(46px);
                        }
                    }

                    .download-btn {
                        display: block;
                        float: right;
                        width: rem(97px);
                        margin-top: rem(50px);
                        color: #FFF;
                        @extend %font_xs;
                        text-align: center;
                        line-height: rem(24px);

                        &:before {
                            display: block;
                            position: absolute;
                            top: rem(-4px);
                            width: rem(97px);
                            height: 110%;
                            border: rem(1px) dashed #FFF;
                            border-width: rem(2px);
                            opacity: 0.4;
                            content: '';
                        }

                        &:after {
                            display: block;
                            position: absolute;
                            top: rem(95px);
                            right: rem(36px);
                            width: rem(23px);
                            height: rem(25px);
                            margin-top: rem(13px);
                            background: url("/front_mo/images/btn_download.png") no-repeat 0 0;
                            background-size: rem(23px 25px);
                            content: '';
                        }

                        &-done {
                            display: none;
                        }
                    }

                    &--done {
                        background-color: #DCDCDC !important;

                        .download-btn {
                            display: none;
                        }

                        .download-btn-done {
                            display: block;
                            float: right;
                            width: rem(97px);
                            margin-top: rem(50px);
                            color: #FFF;
                            font-size: rem(20px);
                            text-align: center;
                            line-height: rem(24px);

                            &:before {
                                display: block;
                                position: absolute;
                                top: rem(-4px);
                                width: rem(97px);
                                height: 110%;
                                border: rem(1px) dashed #FFF;
                                border-width: rem(2px);
                                opacity: 0.4;
                                content: '';
                            }

                            &:after {
                                display: none;
                            }
                        }
                    }
                }

                .download-info {
                    margin: rem(20px 25px 0);

                    &__list {
                        @extend %font_xs;
                        line-height: rem(30px);
                    }
                }
            }
        }

        .fadeInUp {
            -webkit-animation: fadeInUp 0.9s both 0.25s cubic-bezier(0.22, 0.61, 0.87, 1.27);
                    animation: fadeInUp 0.9s both 0.25s cubic-bezier(0.22, 0.61, 0.87, 1.27);
        }

        .fadeInDown {
            -webkit-animation: fadeInUp 0.9s both 0.25s cubic-bezier(0.22, 0.61, 0.87, 1.27);
                    animation: fadeInUp 0.9s both 0.25s cubic-bezier(0.22, 0.61, 0.87, 1.27);
        }

        @-webkit-keyframes fadeInUp {
            0% {
                opacity: 0;
                transform: translate3d(0, 30px, 0);
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        @keyframes fadeInUp {
            0% {
                opacity: 0;
                transform: translate3d(0, 30px, 0);
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        @-webkit-keyframes fadeInDown {
            0% {
                opacity: 0;
                transform: translate3d(0, -30px, 0);
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        @keyframes fadeInDown {
            0% {
                opacity: 0;
                transform: translate3d(0, -30px, 0);
            }

            50% {
                opacity: 1;
            }

            100% {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        .px__slider {
            width: rem(580px);
            height: rem(530px);
            transition: opacity 0.6s ease, transform 0.3s ease;
        }

        .px__slider.loading {
            opacity: 0;
            visibility: hidden;
        }

        .px__slider__list {
            box-sizing: border-box;
            overflow: hidden;
        }

        .px__slider__inner {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: 100%;
        }

        .px__slider__bgimg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 100% auto;
        }

        .px__slider__bgimg .entity-img {
            display: none;
        }

        .comDelivery {
            &__annc {
                @extend %font_xs;
                line-height: rem(32px);
            }

            &__list-wrap {
                margin: rem(30px 0);
                border-top: rem(1px) solid $colorBC;
            }

            &__list {
                padding: rem(25px 0);

                &:not(:last-child) {
                    border-bottom: rem(1px) solid $colorBC;
                }
            }

            .contbox {
                padding-left: rem(60px);

                &__list {
                    position: relative;
                    @extend %font_xs;

                    &:not(:first-child) {
                        margin-top: rem(10px);
                    }

                    &__tit {
                        display: inline-block;
                        width: rem(120px);
                    }
                }
            }

            &__desc {
                margin-top: rem(30px);
                line-height: rem(32px);
                @extend %font_xs;

                &__list {
                    padding-left: rem(10px);
                    text-indent: rem(-14px);
                    word-break: keep-all;
                }
            }
        }

        .check-label {
            display: block;
            position: relative;

            input[type="checkbox"],
            [type="radio"] {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: 0 auto;
            }

            .check-area {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);

                .check-icon {
                    display: block;
                    width: rem(40px);
                    height: rem(40px);
                    background-image: url("/front_mo/images/btn_comm_radio.png?ver=210226")  !important;
                    background-position: rem(-2px 0);
                    background-repeat: no-repeat;
                    background-size: rem(84px 42px);
                }

                input[type="checkbox"]:checked + .check-icon,
                input[type="radio"]:checked + .check-icon {
                    display: inline-block;
                    width: rem(40px);
                    height: rem(40px);
                    background-image: url("/front_mo/images/btn_comm_radio.png?ver=210226")  !important;
                    background-position: rem(-45px 0);
                    background-repeat: no-repeat;
                    background-size: rem(84px 42px);
                }
            }
        }

        .checkbox{
            background: $colorBG;
            padding: rem(25px);

            strong{
                vertical-align: middle;
            }

            p{
                margin-top: rem(5px);
                line-height: rem(30px);
            }

            .green{
                color: $gcolor;
            }
        }

        // 버튼 공통
        .btn-full {
            width: rem(580px);
            height: rem(70px);
            box-sizing: border-box;
        }

        .btn-half {
            float: left;
            width: rem(290px);
            height: rem(70px);
            box-sizing: border-box;
        }

        .btn-green {
            color: #FFF;
            font-size: rem(24px);
            line-height: rem(68px);
        }

        .btn-orange {
            font-size: rem(24px);
            line-height: rem(68px);
        }

        .btn-white {
            font-size: rem(24px);
            line-height: rem(68px);
        }

        .btn-white-v2 {
            color: $color3D;
            font-size: rem(24px);
            line-height: rem(68px);
        }

        .btn-gray {
            font-size: rem(24px);
            line-height: rem(70px);
        }

        .btn-arr {
            padding-right: rem(40px);
            padding-left: 1rem;
            height: rem(65px);
            line-height: rem(65px);
            background: #FFF;
            font-size: rem(24px);
            
            &:after{
                right: rem(15px);
                width: rem(30px);
                height: rem(16px);
                margin-top: rem(-8px);
            }
        }

        .btn-tooltip{
            top: rem(-51px);
            padding: rem(10px);
            font-size: rem(19px);
            border-radius: rem(7px);
        }

        .btn-tooltip:after{
            margin-top: rem(-7px);
            margin-left: rem(-7px);
            width: rem(14px);height: rem(14px);

        }
        @keyframes tooltipMotion {
            0% {top: rem(-51px);}
            100% {top: rem(-41px);}
        }

        .btn-round{
            padding: rem(8px 10px);
            font-size: rem(20px);
            border-radius: rem(6px);
        }

        .discount-tooltip{
            padding: rem(8px 15px);
            font-size: rem(20px);
            border-radius: rem(30px);

            &:after{
                margin-top: rem(-2px);
                margin-left: rem(-5px);
                width: rem(10px);height: rem(10px);
            }

            &.right:after{
                margin-top: rem(-10px);
                margin-left: rem(-7px);
            }
        } 

        // round 체크박스 공통
        .checkbox-round {
            float: left;
        }

        .checkbox-round + .checkbox-round {
            margin-left: rem(35px);
        }

        .checkbox__label {
            display: block;
            position: relative;
            @extend %font_xs;

            &:before {
                content: '';
                display: inline-block;
                width: rem(34px);
                height: rem(34px);
                margin-right: rem(10px);
                border: 1px solid #c2c2c2;
                border-radius: 100%;
                vertical-align: middle;
                background: #FFF url("/front_mo/images/icon_member_check-off.png") no-repeat center center;
                background-size: rem(27px 17px);
            }
        }

        .checkbox__input:checked + .checkbox__label:before {
            border: rem(1px) solid $gcolor;
            background: $gcolor url(/front_mo/images/icon_member_check-on.png) no-repeat center center;
            background-size: rem(27px 17px);
        }

        // input[type="text"] 공통
        .input-box + .input-box {
            margin-top: rem(20px);
        }

		select:focus,
        .input_text:focus,
        input[type="text"]:focus {
            outline: rem(3px) solid $gcolor;
            color: $color3D;
        }

        .input_text::-webkit-input-placeholder {
            color: #BDBDBD;
        }

        .input_text::-moz-placeholder {
            color: #BDBDBD;
        }

        .input_text:-ms-input-placeholder {
            color: #BDBDBD;
        }

        .input_text::-ms-input-placeholder {
            color: #BDBDBD;
        }

        .input_text::placeholder {
            color: #BDBDBD;
        }

        // Tab 공통
        .tab-area {
            width: 100%;
        }

        .tab-area .tab-list {
            float: left;
            position: relative;
            width: 50%;
            height: rem(67px);
            border: 1px solid $colorBC;
            border-bottom: 3px solid $gcolor;
            box-sizing: border-box;
            line-height: rem(67px);
            text-align: center;
            color: #9f9f9f;
        }

        .tab-area .tab-list--on {
            border: rem(3px) solid $gcolor;
            border-bottom: none;
            color: $color3D;
            font-weight: bold;
        }

        .tab-area .tab-list a {
            position: relative;
            display: block;
            width: 100%;
            height: rem(67px);
            line-height: rem(67px);
            font-size: rem(24px);
            z-index: 1;
            color: inherit;
        }

        .tab-area-v2 {
            width: 100%;
        }

        .tab-area-v2 .tab-list-v2 {
            float: left;
            position: relative;
            width: 50%;
            height: rem(67px);
            background: $colorBG;
            border: 1px solid $colorBC;
            box-sizing: border-box;
            line-height: rem(67px);
            text-align: center;
            color: #9f9f9f;
        }

        .tab-area-v2 .tab-list-v2--on {
            background: transparent;
            border-bottom: none;
            color: $color3D;
            font-weight: bold;
        }

        .selectDelivery.tab-list-v2--on {
            border-right: none;
        }

        .newDelivery.tab-list-v2--on {
            border-left: none;
        }

        .tab-area-v2 .tab-list-v2 a {
            position: relative;
            display: block;
            width: 100%;
            height: rem(67px);
            line-height: rem(67px);
            font-size: rem(24px);
            z-index: 1;
            color: inherit;
        }

        .clearfix {
            &:after {
                display: block;
                clear: both;
                content: "";
            }
        }

        .tab-contents {
            display: none;
        }

        .tab-contents--current {
            display: block;
        }

        // 레이어팝업(success & alert 공통)
        .comm-layer {
            height: rem(370px);
            font-size: 0;

            &__inner {
                display: inline-block;
                width: 100%;
                vertical-align: middle;
                text-align: center;
            }

            &__success {

                &:before {
                    content: '';
                    display: block;
                    width: rem(79px);
                    height: rem(79px);
                    margin: rem(0 auto 35px);
                    background: $gcolor url("/front_mo/images/icon_common_check-type02.png") no-repeat 50% 50%;
                    background-size: rem(37px 29px);
                    border-radius: 50%;
                }

            }

            &__alert {

                &:before {
                    content: '';
                    display: block;
                    width: rem(79px);
                    height: rem(79px);
                    margin: rem(0 auto 35px);
                    background: url("/front_mo/images/icon_important-msg.png") no-repeat 50% 50%;
                    background-size: rem(80px 80px);
                    border-radius: 50%;
                }

            }

            &__title {
                display: block;
                font-size: rem(30px);
                font-weight: 600;
                color: $color3D;
            }

            &__desc {
                margin-top: rem(25px);
                line-height: 1.6;
                color: $color3D;
                @extend %font_xs;
            }

            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 0;
                height: 100%;
            }

            .modal-btn {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: rem(84px);
                border-top: 1px solid $colorBC;
                line-height: rem(84px);

                &-half {
                    display: inline-block;
                    width: 50%;
                    height: rem(84px);
                    text-align: center;
                    font-weight: 600;
                    color: $color3D;
                    @extend %font_m;

                    &:not(:first-child) {
                        position: relative;

                        &:before {
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: rem(1px);
                            height: 100%;
                            border-left: rem(1px) solid $colorBC;
                            content: '';
                        }
                    }
                }
            }
        }

        // 마이페이지 > 나의쿠폰 > 쿠폰적용대상 팝업
        .popup_coupon-target {
            .coupon-target {

                .infoBox {

                    &__title {
                        padding-bottom: rem(15px);
                        @extend %font_s;
                        border-bottom: rem(1px) solid $colorBC;
                        font-weight: bold;
                    }

                    &__cont {
                        overflow-y: auto;
                        max-height: rem(200px);
                        padding: rem(20px 0);
                        @extend %font_xs;

                        &__part {
                            display: block;
                            @extend %font_xs;

                            &:not(:first-child) {
                                margin-top: rem(10px);
                            }
                        }

                        &__part2 {
                            display: block;
                            @extend %font_xs;
                            font-weight: bold;

                            &:not(:first-child) {
                                margin-top: rem(15px);
                            }
                        }
                    }

                    & + .infoBox {
                        margin-top: rem(30px);
                    }

                }

                .desc {
                    position: relative;
                    padding-top: rem(25px);
                    padding-left: rem(15px);
                    border-top: rem(1px) solid $colorBC;
                    @extend %font_xs;
                    color: #888;
                    line-height: 1.6;

                    &:before {
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        content: '-';
                    }
                }

            }

        }

        .popup_pointConver {

            .pointConver {

                &__annc {
                    @extend %font_xs;

                    &__tit {
                        padding-bottom: rem(20px);
                        border-bottom: rem(1px) solid #CDCDCD;
                    }

                    .txt-box {
                        padding: rem(20px 0);

                        &__list {
                            position: relative;
                            padding-left: rem(15px);
                            line-height: 1.6;

                            &:before {
                                display: block;
                                position: absolute;
                                left: 0;
                                content: '-';
                            }
                        }
                    }
                }

                .input-area {
                    width: 100%;
                }

                .btn-area {
                    width: 100%;
                    margin-top: rem(20px);

                    button {
                        width: 100%;
                    }
                }

                &__desc {
                    margin-top: rem(30px);
                    line-height: 1.6;
                    color: #888;
                    @extend %font_xs;

                    &__list {
                        position: relative;
                        padding-left: rem(15px);

                        &:before {
                            display: block;
                            position: absolute;
                            left: 0;
                            content: '-';
                        }
                    }
                }
            }
        }

        &__ico-area {
            margin-top: rem(15px);
            padding: rem(10px 0);
            border: {
                top: rem(1px) solid $colorBC;
                bottom: rem(1px) solid $colorBC;
            };
            font-size: 0;

            li {
                display: inline-block;
                // width: 20%;
                width: 16%;
                margin-top: rem(5px);
                vertical-align: top;
                text-align: center;

                &:nth-child(-n+5) {
                    margin-top: 0;
                }

                img {
                    width: rem(35px);
                    height: rem(35px);
                    vertical-align: top;
                }
            }
        }
    }

	/* 주문 현황 추가 */
	.hd {
		&__orderPayment{
			width:100%;
			// padding:7.5rem 20px 0;
			box-sizing:border-box;
			> .accordion_box{
				background-image: linear-gradient(#fdfbfb 0%, $colorBG 100%);
				box-shadow:0 5px 5px 2px rgba(0, 0, 0, 0.2);
				margin: rem(30px);
				border-top: rem(3px) solid $color3D;

                &.banner,&.inner{
                    background: #fff;box-shadow: none;border:0;
                }
                
				.accordion_wrap {
					padding:rem(0 20px 0);
				}
                .accordion_con{
                    border-top: 1px solid $colorBC;
                    padding-bottom: rem(20px);

                    .wdw-type .hd .btn-full{
                        width: 100%;
                    }
                }
				.orderPayment_h2 {
                    border:0;

                    + .checks_box label{
                        margin: 0;
                    }
                }
                
                &.agree-area{
                    position: relative;

                    .desc{
                        border-top: 1px solid $colorBC;
                        padding-bottom: rem(20px);

                        &-list{
                            padding-left: rem(22px);
                            line-height: 1.6;
                            text-indent: rem(-22px);
                            font-size: rem(20px);
                            color: $color88;
                            word-break: keep-all;
                        }
                    }

                    + .agree-all{
                        .checkbox__label{
                            padding-left: rem(45px);
                            line-height: 1.5;
                            @extend %font_xs;

                            &:before{
                                position: absolute;
                                left: 0;
                                top: rem(4px);
                            }
                        }

                    }

                    &.on .desc{border-top:0;}

                    .checkbox__label{
                        @extend %font_s;
                    }
                    .agree-arr{
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 10%;
                        height: rem(95px);
                        z-index: 1;

                        + .accordion_con{
                            padding-top: 0;
                            border-top: 0;
                        }
                    }
                }
			}

            .accordion_box .checks_btn{
                label{
                    display: block;
                    padding-right: rem(70px);
                    padding-left: rem(20px);
                    height: rem(65px) !important;
                    line-height: rem(65px);
                    background: #FFF;
                    border: rem(1px) solid #9f9f9f;
                    font-size: rem(24px);
                    color: $color3D;

                    &:not(:first-child){
                        margin-top: 10px;
                    }

                    &.on{
                        border-color:$gcolor;

                        .checks:before {
                          background: $gcolor url(/front_mo/images/ico_comm_checkbox-on.png) no-repeat 50% 50%;
                          background-size: 1rem 0.75rem;
                          border: 0.05rem solid $gcolor;
                        }
                    }
                }
            }

            .btn-area.fixed{
                position: fixed;
                left:0; bottom: 0;
                width: 100%;
                z-index: 1;

                .btn-full{width: 100%;}
            }

            .btn__delete{
                display: inline-block;
                margin: rem(0 10px);
                width: rem(25px);
                height: rem(33px);
                vertical-align: top;
                background: url(/front_mo/images/ico_comm_delete.png) no-repeat 0 50%;
                text-indent: -999em;
                background-size: rem(25px);
            }

            .fork{
                &_tit{
                    position: relative;
                    padding: 1.35rem 1.5rem 1.35rem 0;
                    font-size: 1.3rem;
                    line-height: 1.2;
                    font-weight: 600;

                    .necessar{
                        font-weight: normal;
                        color: $gcolor;
                    }
                }

                &_txt{
                    padding: rem(20px 0);
                    line-height: 1.5;
                    @extend %font_xs;
                    border-top: 1px solid #BCBCBC;
                }

                &_list{
                    padding-bottom: rem(20px);

                    label{
                        display: inline-block;
                        font-weight: 600;

                        &:nth-of-type(1){
                            margin-right: rem(30px);
                            color: $gcolor;
                        }
                    }

                    .ico_eco{
                        width: rem(38px);
                        height: rem(26px);
                        background-position: rem(-220px -76px);
                        background-size: rem(640px auto);
                    }
                }

                .tooltip{
                    .btn-tooltip{
                        left: inherit;
                        right: 0;
                        margin-top: rem(60px);
                        padding: rem(10px);
                        font-size: rem(19px);
                        color: $color3D;
                        background: $ocolor;
                        border: 0;
                        text-indent: 0;
                        border-radius: rem(30px);
                        transform: none;

                        &:after{
                                margin-top: rem(-7px);
                                margin-left: rem(-7px);
                                width: rem(14px);
                                height: rem(14px);
                            background: $ocolor;
                            border: 0;
                        }
                    }
                }
            }
		}

		.groupOrder__inner {
			max-height:400px;
			overflow-y: scroll;
			padding:0 rem(25px) rem(30px);
			.btn-half{flex:0 0 50%;}
		}
	}

    /* -- alert 및 confirm 창 스타일 -- */
    .pop_wrap {
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        width: rem(440px);
        padding: rem(74px 70px);
        overflow: hidden;
        background: #fff;
        z-index: 11000;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }

    .pop_wrap.on {
        display: block;
    }

    .pop_header {
        top: 0;
        left: 0;
        padding: rem(50px 40px 0);
        font-size: rem(32px);
        font-weight: bold;
    }

    .pop_header:after {
        content: "";
        display: block;
        width: rem(30px);
        height: rem(2px);
        margin-top: rem(21px);
        background: #000;
    }

    .pop_content {
        }

    .pop_content .text {
        width: 100%;
        font-size: rem(24px);
        line-height: rem(34px);
        color: $color3D;
        text-align: center;
    }

    .mask {
        z-index: 10500;
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
    }

    .mask.on {
        display: block;
    }

    button[class*="btn"] {
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
    }

    .btn_area {
        display: block;
        text-align: center;
        margin-top: rem(55px);
        font-size: 0;
    }

    .btn_square {
        width: rem(220px);
        height: rem(70px);
        line-height: rem(70px);
        text-align: center;
        font-size: rem(24px);
        color: #fff;
        font-weight: bold;
        box-sizing: border-box;
    }
    /* -- //alert 및 confirm 창 스타일 -- */

}
