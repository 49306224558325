.hd {

    &__bestList {
        overflow: hidden;
        
        .best-banner {
            display: none;
            position: relative;
            width: 100%;
            height: 380px;

            .swiper-pagination {
                position: absolute;
                bottom: 24px;
                left: 32px;
                z-index: 1;
            }
        }

        &__cate-wrap {
            position: relative;
            width: 100%;
            max-width: 640px;
            z-index: 3;
            background: #f5f5f5;

            &--move {
                width: 100%;
                top: 150px;
                left: 50%;
                transform: translate3d(-50%, -81px, 0);
                transition: transform 0.5s linear;
            }

            .best-btn-next {
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                width: 68px;
                height: 80px;
                background: #f5f5f5;
                border-left: 1px solid #bcbcbc;

                &:after {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: url(/front_mo/images/icon_market_drop-down-on.png) no-repeat 50% 50%;
                    content: "";
                    transform: rotate(-90deg);
                }
            }
        }

        &__cate {
            position: relative;
            padding: 0 68px 0 25px;
            @extend %clear;

            .cate-slider {
                width: 100%;
                height: 80px;
                line-height: 80px;
                @extend %clear;

                &__list {
                    float: left;
                    width: auto;
                    height: 80px;

                    a{
                        display: inline-block;
                        padding: 0 26px;
                        line-height: 2.3;
                        @extend %font_xs;
                        font-weight: bold;
                        vertical-align: middle;
                    }

                    &--on {
                        a {
                            background: $gcolor;
                            color: #fff;
                            border-radius: 40px;
                        }
                    }
                }
            }

		}
		
		&__product {margin-top:30px; padding:0 20px;}

        // .contents {
        //     &__box {
		// 		width:600px; 
		// 		margin:50px auto 0; 
		// 		counter-reset:best_list; 
		// 		display:flex; 
		// 		flex-wrap:wrap; 
		// 		justify-content:space-between;
        //     }

        //     &__list {
		// 		position:relative; flex:0 0 290px; width:290px; border-bottom:solid 1px #bcbcbc; padding-bottom:20px;
        //         &__no {
        //             display: inline-block;
        //             position: relative;
        //             margin-bottom: 24px;
        //             padding: 0 5px;
        //             @extend %font_l;

        //             &:after {
		// 				display: inline-block;
		// 				counter-increment : best_list;
		// 				content:counter(best_list, decimal-leading-zero);
		// 				font-weight:600;
		// 			}

        //             &:before {
        //                 display: inline-block;
        //                 position: absolute;
        //                 left: 0;
        //                 bottom: 0;
        //                 z-index: -1;
        //                 width: 100%;
        //                 height: 8px;
        //                 background: $ocolor;
        //                 content: '';
        //             }
        //         }

        //         // &:nth-child(even) {
        //         //      margin-left: 30px;
        //         // }

        //         &:nth-child(n+3) {
        //             padding-top: 40px;
        //         }

        //         .item{
		// 			$self:&;
        //             position: relative;
		// 			width: 100%;
        //             @extend %vertical;
		// 			&__tag { display:flex; overflow:hidden; margin-bottom:5px;
		// 				span {overflow:hidden; height:26px; border:solid 1px #c2c2c2; border-radius:3px; color:#006450; line-height:26px; text-align:center; font-size:16px;
		// 					padding:0 5px; min-width: 50px;
		// 					& + span {margin-left:5px;}
		// 				}
		// 				// 태그가 있을때 여백 제거
		// 				& + .item__title {margin-top:0;}
		// 			}

        //             &__thumb {
        //                 width: 100%;
		// 				height: 290px;
		// 				position: relative;
		// 				figure{
		// 					background: #ddd;
		// 					height:100%;
		// 				}

		// 				.hd__ico-area{
		// 					// margin:0;
		// 					// position: absolute;
		// 					// top:0;
		// 					// bottom:0;
		// 					// right:-45px;
		// 					// background: rgba(0, 0, 0, 0.5);
		// 					// padding:0 5px;
		// 					// transition:all .25s;

		// 					display:none;
		// 					&:before {
		// 						content: "";
		// 						position: absolute;
		// 						width: 50px;
		// 						height: 50px;
		// 						background: url(/front_mo/images/foodsearch.svg) 0 0 no-repeat;
		// 						top: 5px;
		// 						left:-65px;
		// 						background-size: 100% 100%;
		// 					}

		// 					&.active {
		// 						right:0;
		// 						&:before {
		// 							left:50px;
		// 						}
		// 					}
		// 					li {display: block; width:100%;}
		// 				}
        //             }

        //             &__info {
        //                 position: relative;
        //                 margin-top: 5px;
		// 			}

		// 			&__title {margin-top:37px;}
					
		// 			&__sub {
        //                 font-size: 20px;
        //                 line-height: 30px;
		// 				color: #888;
        //                 @include line(2);
        //             }

		// 			&__name {
		// 				display: block;
        //                 width: 100%;
		// 				min-height:60px;
        //                 line-height: 30px;
		// 				font-weight: bold;
		// 				margin-bottom:5px;
        //                 @extend %font_xs;
        //                 @include line(2);
		// 			}

		// 			&__price-ori {
		// 				color:#888;
        //                 @extend %font_xs;
		// 				text-decoration: line-through;
		// 				& + .item__price-dc{margin-top:0;}
		// 			}
					

        //             &__price-dc {
		// 				display:block;
		// 				margin-top:33px;
        //                 @extend %font_xs;
        //                 text-decoration: none;
        //                 strong {
        //                     @extend %font_m;
        //                 }
		// 			}
					
		// 			&__cart {position:absolute; right:10px; bottom:20px; z-index:1;
		// 				span {display:block; position:relative; font:0/0 a;
		// 					&:before {content:""; display:block; background:url(/front_pc/images/icon_cart.png) no-repeat center center; background-size:100% 100%; width:55px; height:50px; margin:0 auto;}
		// 				}
		// 			}

        //             .hd__btn-heart {
        //                 right: 12px;
        //                 top: 178px;
        //             }
        //         }
        //     }
        // }
    }
}