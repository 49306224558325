.hd {
    .greatingLife-list {
        &__cont {
            margin-top: 40px;
    
            &__list {
                position: relative;
                margin-top: 30px;
    
                &:first-child {
                    margin-top: 0;
                }
    
                a {
                    display: block;
                    font-size: 0;
                }
            }
    
            &__title {
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                text-align: center;
                @extend %font_l;
                font-weight: 600;
                color: #FFF;
                transform: translate3d(0, -50%, 0);
            }
        }

        &__program {
            position: relative;
            margin-top: 40px;
            padding: 40px;
            border: 1px solid #C0C0C0;

            &__title {
                display: block;
                padding-bottom:20px;
                border-bottom: 3px solid $gcolor;
                @extend %font_l;
                font-weight: bold;
            }

            &__desc {
                margin-top: 25px;
                line-height: 32px;
                @extend %font_xs;
            }

            &__link {
                display: block;
                position: absolute;
                right: 33px;
                bottom: 40px;
                text-align: right;
                line-height: 53px;
                @extend %font_m;
                font-weight: bold;
                color: #3d3d3d;
                
                &:after {
                    content:'';
                    display: inline-block;
                    width: 53px;
                    height: 53px;
                    margin-left: 12px;
                    vertical-align: middle;
                    background: url(/front_mo/images/btn_main_right-arrow.png) no-repeat 0 0 / 100%;
                  }
            }
        }
    }

    .gTable__title {
        position: relative;
        margin-top: 25px;
        line-height: 60px;

        h3 {
            @extend %font_l;
            font-weight: 600;
        }
    }
}